import React from "react";
import { Row, Col, Table, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import PageTitle from "../../components/common/page-title";
import CommonListBase from "../../base/common-list-base";
import ListFilters from "../../components/common/list-filters";

class BranchManagement extends CommonListBase {
  state = {
    listReq: "branch/list?",
    delReq: "branch/delete?branchid=",
    editReq: "/inventories/branch-edit/",
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    sorting: [],
    record: "",
    screen: "branch-management",
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "Branch Name",
        dataIndex: "branch_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Status",
        dataIndex: "is_active",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => (text ? "Active" : "Inactive"),
        width: 142,
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "x",
        width: 92,
        render: (record) => (
          <Button
            onClick={() => this.editItem(record.branch_id)}
            icon={<EditOutlined />}
            className="ant-btn-reset"
            style={{ marginLeft: "16px" }}
          />
        ),
      },
    ];

    const filterColumns = [
      { columnName: "branch_name", displayName: "Branch Name", default: true },
      { columnName: "is_active", displayName: "Status" },
    ];

    this.setState({ columnsDefault: columns, filterColumns }, () =>
      this.setDefaultColumns(columns)
    );
  };

  componentDidMount() {
    // Get List
    const { pagination } = this.state;
    this.getResults({ pagination });
    this.setColumns();
  }

  render() {
    const { data, columns, pagination, loading, filterColumns, screen } =
      this.state;

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageTitle
              titleText="Branch Management"
              isAddBtn={"Add New Branch"}
              handleAdd={() =>
                this.props.history.push("/inventories/branch-add")
              }
            />

            <ListFilters
              items={filterColumns}
              handleSettings={this.handleSettings}
              handleFilters={this.handleFilters}
              key={filterColumns}
              screen={screen}
            />

            <Table
              columns={columns}
              rowKey={(record) => record.branch_id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              bordered
              scroll={{ x: 400 }}
              size="small"
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default BranchManagement;
