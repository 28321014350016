import React, { Component } from "react";
import { Button, Row, Col, Table, InputNumber } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import EstimatePayeeListInput from "./estimate-payee-list-input";

class EstimateSiteList extends Component {
  state = {
    loading: false,
    payout_estimate_site_list: [],
    key: 1,
    columns: [],
  };

  setColumns = () => {
    let columns = [
      {
        title: "IID",
        dataIndex: "inventory_code",
      },
      {
        title: "City",
        dataIndex: "city",
      },
      {
        title: "Location",
        dataIndex: "location",
      },
      {
        title: "Size",
        dataIndex: "size",
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "x",
        render: (record) => (
          <React.Fragment>
            <Button
              icon={<DeleteOutlined />}
              className="ant-btn-reset"
              style={{ marginLeft: "12px" }}
              onClick={() => this.props.deleteInventory(record)}
            />
          </React.Fragment>
        ),
        align: "center",
      },
    ];

    this.setState({ columns });
  };

  componentDidMount() {
    this.setColumns();
    const { payout_estimate_site_list } = this.props || [];
    this.setState({ payout_estimate_site_list });
  }

  render() {
    const { loading, payout_estimate_site_list, columns, key } = this.state;

    return (
      <Table
        columns={columns}
        rowKey={(record) => record?.ha_inventory_id}
        dataSource={payout_estimate_site_list}
        pagination={false}
        loading={loading}
        onChange={this.handleTableChange}
        bordered
        scroll={{ x: 400 }}
        size="small"
        key={key}
      />
    );
  }
}

export default EstimateSiteList;
