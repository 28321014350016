import React, { Component } from "react";
import { Tabs, message, Upload, Row, Col, Image } from "antd";
import { InboxOutlined } from "@ant-design/icons";

class PlanCustomPhotos extends Component {
  state = {};

  componentDidMount() {
    let photosLib = [];
    this.props.plan_inventory_details &&
      this.props.plan_inventory_details.map(
        (x) => x.ha_images && x.ha_images.map((y) => photosLib.push(y.data_url))
      );

    this.setState({ photosLib });
  }

  render() {
    const { photosLib } = this.state;

    const { TabPane } = Tabs;

    const { Dragger } = Upload;

    const props = {
      name: "file",
      multiple: true,
      action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
      onChange(info) {
        const { status } = info.file;
        if (status !== "uploading") {
          console.log(info.file, info.fileList);
        }
        if (status === "done") {
          message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === "error") {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };

    return (
      <Tabs defaultActiveKey="1" className="customphotos-tabs">
        <TabPane tab="Upload Photo" key="1" forceRender="true">
          <Row>
            <Col span="24">
              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload. Strictly prohibit from
                  uploading company data or other band files
                </p>
              </Dragger>
            </Col>
          </Row>
        </TabPane>

        <TabPane tab="Library" key="2" forceRender="true">
          <Row gutter="16">
            {photosLib &&
              photosLib.map((x, key) => (
                <Col span="4" key={key}>
                  <Image width="100%" height="160px" src={x} />
                </Col>
              ))}
          </Row>
        </TabPane>
      </Tabs>
    );
  }
}

export default PlanCustomPhotos;
