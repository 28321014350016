import {getRelayUrl} from "../services/ajax-requester";

export const downloadFile = (fileName, url, isObject = false) => {
    const a = document.createElement("a");
    a.href = isObject? window.URL.createObjectURL(url) : getRelayUrl(url, fileName);
    a.style.display = "none";
    a.download = fileName;
    a.target = "_";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};
