import React from "react";
import { Row, Col, Table, Button, DatePicker } from "antd";
import PageTitleDetails from "../../components/common/page-title-detail";
import ListFilters from "../../components/common/list-filters";
import CommonListBase from "../../base/common-list-base";
import {
  currencyFormatter,
  dateFormatter,
} from "../../components/common/common-formatter";
import { ExportOutlined } from "@ant-design/icons";
import PortalUser from "../../iam/components/portal-user";
import * as dayjs from "dayjs";

class SalesMonthlyList extends CommonListBase {
  constructor(props) {
    super(props);
    this.state = {
      listReq: this.getListReq(),
      data: [],
      pagination: {
        current: 1,
        pageSize: 100,
      },
      loading: false,
      columns: [],
      columnsDefault: [],
      filterColumns: [],
      sorting: [],
      screen: "dashboard-sales-drilldown",
      isDrawer: false,
      record: "",
      isEditMode: false,
    };
  }

  setColumns = () => {
    let columns = [
      {
        title: "IID",
        dataIndex: "inventory_code",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "City",
        dataIndex: "city_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Location",
        dataIndex: "location",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "From",
        dataIndex: "start_date",
        render: (text) => dateFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "To",
        dataIndex: "end_date",
        render: (text) => dateFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Days",
        dataIndex: "no_of_days",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Monthly Rate",
        dataIndex: "cost_per_month",
        render: (text) => currencyFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 120,
      },
      {
        title: "Amount",
        dataIndex: "cost_total",
        render: (text) => currencyFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Customer",
        dataIndex: "customer_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Display",
        dataIndex: "display_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 140,
      },
      {
        title: "Employee",
        dataIndex: "employee_id",
        render: (text) => <PortalUser user={text} />,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 140,
      },
      {
        title: "Invoice Date",
        dataIndex: "invoice_date",
        render: (text) => text && dateFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 120,
      },
      {
        title: "Invoice Number",
        dataIndex: "invoice_no",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 140,
      },
      {
        title: "Sales Type",
        dataIndex: "status",
        render: (text) => (
          <span style={{ textTransform: "capitalize" }}>{text}</span>
        ),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
    ];

    const filterColumns = [
      {
        columnName: "general",
        displayName: "General",
        default: true,
        hideFromSettings: true,
      },
      {
        columnName: "inventory_code",
        displayName: "IID",
        hideFilter: true,
      },
      { columnName: "city_name", displayName: "City", hideFilter: true },
      { columnName: "location", displayName: "Location", hideFilter: true },
      {
        columnName: "start_date",
        displayName: "From",
        hideFilter: true,
      },
      { columnName: "end_date", displayName: "To", hideFilter: true },
      { columnName: "no_of_days", displayName: "Days", hideFilter: true },
      {
        columnName: "cost_per_month",
        displayName: "Monthly Rate",
        hideFilter: true,
      },
      { columnName: "cost_total", displayName: "Amount", hideFilter: true },
      { columnName: "customer_name", displayName: "Customer" },
      { columnName: "display_name", displayName: "Display" },
      { columnName: "employee_id", displayName: "Employee" },
      {
        columnName: "invoice_date",
        displayName: "Invoice Date",
        hideFilter: true,
      },
      { columnName: "invoice_no", displayName: "Invoice Number" },
      { columnName: "status", displayName: "Sales Type", hideFilter: true },
    ];

    this.setState({ columnsDefault: columns, filterColumns }, () =>
      this.setDefaultColumns(columns)
    );
  };

  getYear = () => {
    const now = new Date();
    const year = now.getFullYear();
    const financialYear = now.getMonth() >= 3 ? year : year - 1;
    return financialYear.toString().slice(-2);
  };

  handleMonthClick = (month) => {
    this.props.handleDetails(month);
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  getMonthDates = (month) => {
    const monthsRange = {
      apr: "durationfrom=2024-04-01&durationto=2024-04-30",
      may: "durationfrom=2024-05-01&durationto=2024-05-31",
      jun: "durationfrom=2024-06-01&durationto=2024-06-30",
      jul: "durationfrom=2024-07-01&durationto=2024-07-31",
      aug: "durationfrom=2024-08-01&durationto=2024-08-31",
      sep: "durationfrom=2024-09-01&durationto=2024-09-30",
      oct: "durationfrom=2024-10-01&durationto=2024-10-31",
      nov: "durationfrom=2024-11-01&durationto=2024-11-30",
      dec: "durationfrom=2024-12-01&durationto=2024-12-31",
      jan: "durationfrom=2025-01-01&durationto=2025-01-31",
      feb: "durationfrom=2025-02-01&durationto=2025-02-28",
      mar: "durationfrom=2025-03-01&durationto=2025-03-31",
      total: "durationfrom=2024-04-01&durationto=2025-03-31",
    };

    return monthsRange[month];
  };

  getListReq = () => {
    const { month, type, value } = this?.props?.match?.params;

    let req =
      "dashboard/sales/details?durationGroup=range&resultgroup=" + type + "&";

    if (value) {
      if (type === "city") {
        req =
          req +
          "stateName=" +
          value?.split("|")[0] +
          "&districtName=" +
          value?.split("|")[1] +
          "&cityName=" +
          value?.split("|")[2] +
          "&";
      }

      if (type === "employee") {
        req = req + "employeeId=" + value + "&";
      }

      if (type === "payoutestimate") {
        req = req + "payoutEstimateId=" + value + "&";
      }
    }

    return req + this.getMonthDates(month) + "&";
  };

  onDateChange = (date, dateString) => {
    const { listReq, pagination } = this.state;

    let updatedReq = listReq
      .replace(/(durationfrom=)[^&]+/, `$1${dateString[0]}`)
      .replace(/(durationto=)[^&]+/, `$1${dateString[1]}`);

    this.setState({ listReq: updatedReq }, () =>
      this.getResults({ pagination })
    );
  };

  getDefaultDates = () => {
    const { month } = this?.props?.match?.params;

    const dateRange = {
      apr: [dayjs("2024-04-01"), dayjs("2024-04-30")],
      may: [dayjs("2024-05-01"), dayjs("2024-05-31")],
      jun: [dayjs("2024-06-01"), dayjs("2024-06-30")],
      jul: [dayjs("2024-07-01"), dayjs("2024-07-31")],
      aug: [dayjs("2024-08-01"), dayjs("2024-08-31")],
      sep: [dayjs("2024-09-01"), dayjs("2024-09-30")],
      oct: [dayjs("2024-10-01"), dayjs("2024-10-31")],
      nov: [dayjs("2024-11-01"), dayjs("2024-11-30")],
      dec: [dayjs("2024-12-01"), dayjs("2024-12-31")],
      jan: [dayjs("2025-01-01"), dayjs("2025-01-31")],
      feb: [dayjs("2025-02-01"), dayjs("2025-02-28")],
      mar: [dayjs("2025-03-01"), dayjs("2025-03-31")],
    };

    return dateRange[month];
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.getResults({ pagination });
    this.setColumns();
  }

  render() {
    const { data, columns, pagination, loading, filterColumns, screen } =
      this.state;

    const { RangePicker } = DatePicker;

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageTitleDetails
              titleText={"Sales Details"}
              isBackBtn="true"
              handleBack={this.handleBack}
            />

            <div className="page-title-custom-btn sales-dtl-btns">
              <RangePicker
                allowClear={false}
                inputReadOnly={true}
                onChange={this.onDateChange}
                style={{ marginRight: "16px", width: "242px" }}
                defaultValue={this.getDefaultDates()}
              />
              <Button
                type="primary"
                // onClick={this.handleExport}
                icon={<ExportOutlined />}
                className="icon-btn"
              >
                Excel Export
              </Button>
            </div>

            <ListFilters
              items={filterColumns}
              handleSettings={this.handleSettings}
              handleFilters={this.handleFilters}
              key={filterColumns}
              screen={screen}
            />

            {this?.state?.total_sales && (
              <div
                style={{
                  position: "relative",
                  marginRight: "42px",
                  marginTop: "-48px",
                  float: "right",
                  background: "green",
                  height: "32px",
                  lineHeight: "32px",
                  borderRadius: "6px",
                  fontWeight: "bold",
                  color: "#fff",
                  padding: "0px 8px",
                }}
              >
                {currencyFormatter(this?.state?.total_sales)}
              </div>
            )}

            <Table
              columns={columns}
              rowKey={(record) => record?.id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              bordered
              scroll={{ x: 400 }}
              size="small"
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default SalesMonthlyList;
