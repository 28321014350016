import React, { Component } from "react";
import {
  Form,
  Select,
  Button,
  Row,
  Col,
  InputNumber,
  Input,
  Space,
  DatePicker,
} from "antd";
import { ajaxRequester } from "../../services/ajax-requester";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import * as dayjs from "dayjs";

class InventoryAdvancedFilters extends Component {
  state = {
    mediaTypeList: [],
    segmentList: [],
    stateList: [],
    districtList: [],
    cityList: [],
    areaList: [],
  };
  formRef = React.createRef();

  setDefaultData = () => {
    const { advancedFilters } = this.props;

    this.formRef.current.setFieldsValue({
      territory: (advancedFilters?.territory_details &&
        advancedFilters?.territory_details.length > 0 &&
        advancedFilters?.territory_details) || [""],
      media_type1:
        advancedFilters?.media_type?.length > 0
          ? advancedFilters?.media_type[0]
          : null,
      media_type2:
        advancedFilters?.media_type?.length > 1
          ? advancedFilters?.media_type[1]
          : null,
      media_type3:
        advancedFilters?.media_type?.length > 2
          ? advancedFilters?.media_type[2]
          : null,
      media_type4:
        advancedFilters?.media_type?.length > 3
          ? advancedFilters?.media_type[3]
          : null,

      width: advancedFilters?.width,
      height: advancedFilters?.height,
      light_type: advancedFilters?.light_type,
      sqft_from:
        advancedFilters?.sqft?.from !== advancedFilters?.sqft?.to
          ? advancedFilters?.sqft?.from
          : "",
      sqft_to:
        advancedFilters?.sqft?.from !== advancedFilters?.sqft?.to
          ? advancedFilters?.sqft?.to
          : "",
      sqft:
        advancedFilters?.sqft?.from === advancedFilters?.sqft?.to
          ? advancedFilters?.sqft?.from
          : "",
      display_name: advancedFilters?.display_name,
      base_rate_from: advancedFilters?.base_rate?.from,
      base_rate_to: advancedFilters?.base_rate?.to,
      available_from:
        advancedFilters?.available_from &&
        dayjs(advancedFilters?.available_from),
      available_within_days: advancedFilters?.available_within_days,
      segment_id: advancedFilters?.segment_id,
      customer_id: advancedFilters?.customer_id,
      year: advancedFilters?.year,
      supplier_id: advancedFilters?.supplier_id,
      photo_type1:
        advancedFilters?.operation_photo_type?.length > 0
          ? advancedFilters?.operation_photo_type[0]
          : null,
      photo_type2:
        advancedFilters?.operation_photo_type?.length > 0
          ? advancedFilters?.operation_photo_type[1]
          : null,
      photo_type3:
        advancedFilters?.operation_photo_type?.length > 0
          ? advancedFilters?.operation_photo_type[2]
          : null,
      upload_from_date:
        advancedFilters?.upload_from_date &&
        dayjs(advancedFilters?.upload_from_date),
      upload_to_date:
        advancedFilters?.upload_to_date &&
        dayjs(advancedFilters?.upload_to_date),
      uploaded_within_days: advancedFilters?.uploaded_within_days,
      base_rate_per_sqft_from: advancedFilters?.base_rate_per_sqft?.from,
      base_rate_per_sqft_to: advancedFilters?.base_rate_per_sqft?.to,
    });
  };

  onFinish = (values) => {
    let data = {};

    data.territory_details = values.territory.filter(
      (x) => x.state || x.district || x.city || x.area
    );

    let media_type = [];
    values.media_type1 && media_type.push(values.media_type1);
    values.media_type2 && media_type.push(values.media_type2);
    values.media_type3 && media_type.push(values.media_type3);
    values.media_type4 && media_type.push(values.media_type4);

    if (media_type.length > 0) {
      data.media_type = media_type;
    }

    let operation_photo_type = [];
    values.photo_type1 && operation_photo_type.push(values.photo_type1);
    values.photo_type2 && operation_photo_type.push(values.photo_type2);
    values.photo_type3 && operation_photo_type.push(values.photo_type3);

    if (operation_photo_type.length > 0) {
      data.operation_photo_type = operation_photo_type;
    }

    data.light_type = values.light_type;
    data.sqft =
      values?.sqft && !values?.sqft_from && !values?.sqft_from
        ? {
            from: values.sqft,
            to: values.sqft,
          }
        : (values.sqft_from === 0 || values.sqft_from) && {
            from: values.sqft_from,
            to: values.sqft_to,
          };
    !data?.sqft && delete data?.sqft;
    !data?.sqft?.from && delete data?.sqft?.from;
    !data?.sqft?.to && delete data?.sqft?.to;
    data.width = values.width;
    data.height = values.height;
    data.display_name = values.display_name;
    data.base_rate = (values.base_rate_from === 0 || values.base_rate_from) && {
      from: values.base_rate_from,
      to: values.base_rate_to,
    };
    data.available_from = values.available_from;
    data.available_within_days = values.available_within_days;
    data.segment_id = values.segment_id && Number(values.segment_id);
    data.customer_id = values.customer_id && Number(values.customer_id);
    data.year = values.year;
    data.advanced_filter = true;
    data.supplier_id = values.supplier_id;
    data.base_rate_per_sqft = (values.base_rate_per_sqft_from === 0 ||
      values.base_rate_per_sqft_to) && {
      from: values.base_rate_per_sqft_from,
      to: values.base_rate_per_sqft_to,
    };

    if (this?.props?.photoBucket) {
      data.upload_from_date = values?.upload_from_date;
      data.upload_to_date = values?.upload_to_date;
      data.uploaded_within_days = values?.uploaded_within_days;

      !values?.upload_from_date && delete data?.upload_from_date;
      !values?.upload_to_date && delete data?.upload_to_date;
      !values?.uploaded_within_days && delete data?.uploaded_within_days;
    }

    this.props.handleAdvancedFilters(data);
  };

  // Get Media Type List from Lookup
  getMediaTypeList = () => {
    let reqUrl = "lookup/mediatype";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ mediaTypeList: data.response }, () =>
          this.setDefaultData()
        );
      },
      "GET"
    );
  };

  // Get Sagment List from Lookup
  getSegmentList = () => {
    let reqUrl = "lookup/segment";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ segmentList: data.response });
      },
      "GET"
    );
  };

  // Get State List from Lookup
  getStateList = () => {
    let reqUrl = "lookup/state";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ stateList: data.response });
      },
      "GET"
    );
  };

  // Get District List from Lookup
  getDistrictList = (state) => {
    let reqUrl = "lookup/district?state=" + state;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ districtList: data.response });
      },
      "GET"
    );
  };

  // Get City List from Lookup
  getCityList = (state, district) => {
    let reqUrl = "lookup/city?state=" + state + "&district=" + district;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ cityList: data.response });
      },
      "GET"
    );
  };

  // Get Area List from Lookup
  getAreaList = (state, district, city) => {
    let reqUrl =
      "lookup/area?state=" + state + "&district=" + district + "&city=" + city;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ areaList: data.response });
      },
      "GET"
    );
  };

  // Handle Select Focus
  handleTerritorySelect = (item, key) => {
    let territory = this.formRef.current.getFieldValue("territory")[key];

    switch (item) {
      case "district":
        territory?.state && this.getDistrictList(territory.state);
        break;

      case "city":
        territory?.state &&
          territory?.district &&
          this.getCityList(territory.state, territory.district);
        break;

      case "area":
        territory?.state &&
          territory?.district &&
          territory?.city &&
          this.getAreaList(territory.state, territory.district, territory.city);
        break;

      default:
        break;
    }
  };

  // Handle Select Change
  handleTerritoryChange = (item, key) => {
    let territory = this.formRef.current.getFieldValue("territory");

    switch (item) {
      case "state":
        territory[key].district = null;
        territory[key].city = null;
        territory[key].area = null;
        break;

      case "district":
        territory[key].city = null;
        territory[key].area = null;
        break;

      case "city":
        territory[key].area = null;
        break;

      default:
        break;
    }
    this.formRef.current.setFieldsValue({
      territory: territory,
    });
  };

  // On Value change reset related field
  ValuesChange = (value) => {
    if (value["available_from"]) {
      this.formRef.current.setFieldsValue({
        available_within_days: undefined,
      });
    }

    if (value["available_within_days"]) {
      this.formRef.current.setFieldsValue({ available_from: undefined });
    }

    if (value["width"] || value["height"] || value["sqft"]) {
      this.formRef.current.setFieldsValue({
        sqft_from: undefined,
        sqft_to: undefined,
      });
    }

    if (value["sqft_from"] || value["sqft_to"]) {
      this.formRef.current.setFieldsValue({
        width: undefined,
        height: undefined,
        sqft: undefined,
      });
    }

    if (this?.props?.photoBucket) {
      if (value["upload_from_date"]) {
        this.formRef.current.setFieldsValue({
          uploaded_within_days: undefined,
        });
      }

      if (value["upload_to_date"]) {
        this.formRef.current.setFieldsValue({
          uploaded_within_days: undefined,
        });
      }

      if (value["uploaded_within_days"]) {
        this.formRef.current.setFieldsValue({
          upload_from_date: undefined,
          upload_to_date: undefined,
        });
      }
    }
  };

  resetFilters = () => {
    this.props.handleAdvancedFilters({});
  };

  // Get Supplier List from Lookup
  getSupplierList = () => {
    let reqUrl = "supplier/lookup";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ supplierList: data.response });
      },
      "GET"
    );
  };

  componentDidMount() {
    this.getMediaTypeList();
    this.getSegmentList();
    this.getStateList();
    this.getSupplierList();
  }

  render() {
    const {
      mediaTypeList,
      segmentList,
      stateList,
      districtList,
      cityList,
      areaList,
      supplierList,
    } = this.state;

    const { Option } = Select;

    const lightTypeList = [
      { light_type_id: "NL", light_type: "NL" },
      { light_type_id: "FL", light_type: "FL" },
      { light_type_id: "BL", light_type: "BL" },
    ];

    const yearList = [
      {
        id: 1,
        year: "2020",
      },
      {
        id: 2,
        year: "2021",
      },
    ];

    const { inventoryType, photoBucket } = this.props;

    return (
      <Form
        name="advanced_filters"
        onFinish={this.onFinish}
        onFinishFailed={this.onFinishFailed}
        ref={this.formRef}
        onValuesChange={this.ValuesChange}
        layout="inline"
        className="advanced-filters advanced-filters-mobile"
      >
        {inventoryType === "tainventory" && (
          <Row style={{ width: "100%", marginBottom: "16px" }}>
            <Col span="4" xs={24} xl={4} style={{ marginTop: "5px" }}>
              Supplier
            </Col>

            <Col span="18" xs={24} xl={18}>
              <Row>
                <Col span="6" xs={24} xl={6}>
                  <Form.Item name="supplier_id">
                    {supplierList && (
                      <Select placeholder="Supplier" allowClear={true}>
                        {supplierList.map((x) => (
                          <Option key={x.supplier_id} value={x.supplier_id}>
                            {x.supplier_name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        <Row style={{ width: "100%" }}>
          <Col span="4" xs={24} xl={4} style={{ marginTop: "5px" }}>
            Territory
          </Col>

          <Form.List name="territory">
            {(fields, { add, remove }) => {
              return (
                <Col span="18" xs={24} xl={18}>
                  {fields.map((field) => (
                    <Space key={field.key}>
                      <Row style={{ marginBottom: "16px" }}>
                        <Col span="6" xs={12} xl={6}>
                          <Form.Item
                            {...field}
                            name={[field.name, "state"]}
                            fieldKey={[field.fieldKey, "state"]}
                          >
                            <Select
                              placeholder="State"
                              allowClear={true}
                              showSearch={true}
                              onChange={() =>
                                this.handleTerritoryChange("state", field.key)
                              }
                            >
                              {stateList.map((x) => (
                                <Option key={x.state} value={x.state}>
                                  {x.state}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span="6" xs={12} xl={6}>
                          <Form.Item
                            {...field}
                            name={[field.name, "district"]}
                            fieldKey={[field.fieldKey, "district"]}
                          >
                            <Select
                              placeholder="District"
                              allowClear={true}
                              showSearch={true}
                              onFocus={() =>
                                this.handleTerritorySelect(
                                  "district",
                                  field.key
                                )
                              }
                              onChange={() =>
                                this.handleTerritoryChange(
                                  "district",
                                  field.key
                                )
                              }
                            >
                              {districtList.map((x) => (
                                <Option key={x.district} value={x.district}>
                                  {x.district}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span="6" xs={12} xl={6}>
                          <Form.Item
                            {...field}
                            name={[field.name, "city"]}
                            fieldKey={[field.fieldKey, "city"]}
                          >
                            <Select
                              placeholder="City"
                              showSearch={true}
                              allowClear={true}
                              onFocus={() =>
                                this.handleTerritorySelect("city", field.key)
                              }
                              onChange={() =>
                                this.handleTerritoryChange("city", field.key)
                              }
                            >
                              {cityList.map((x) => (
                                <Option key={x.city} value={x.city}>
                                  {x.city}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span="6" xs={12} xl={6}>
                          <Form.Item
                            {...field}
                            name={[field.name, "area"]}
                            fieldKey={[field.fieldKey, "area"]}
                          >
                            <Select
                              placeholder="Area"
                              allowClear={true}
                              showSearch={true}
                              onFocus={() =>
                                this.handleTerritorySelect("area", field.key)
                              }
                            >
                              {areaList.map((x) => (
                                <Option key={x.area} value={x.area}>
                                  {x.area}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>

                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      ) : null}
                    </Space>
                  ))}

                  <PlusOutlined
                    className="dynamic-add-button"
                    onClick={() => {
                      add();
                    }}
                  />
                </Col>
              );
            }}
          </Form.List>
        </Row>

        {!photoBucket && (
          <Row style={{ width: "100%", marginBottom: "16px" }}>
            <Col span="4" xs={24} xl={4} style={{ marginTop: "5px" }}>
              Media Type
            </Col>

            <Col span="18" xs={24} xl={18}>
              <Row>
                <Col span="5" xs={12} xl={5}>
                  <Form.Item name="media_type1">
                    <Select placeholder="Select" allowClear={true}>
                      {mediaTypeList.map((x) => (
                        <Option key={x.media_type_id} value={x.media_type_id}>
                          {x.media_type}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span="1" xs={2} xl={1} className="mobile-hide">
                  <span
                    style={{
                      marginLeft: "-9px",
                      marginTop: "6px",
                      display: "inline-block",
                    }}
                  >
                    AND
                  </span>
                </Col>

                <Col span="5" xs={12} xl={5}>
                  <Form.Item name="media_type2">
                    <Select placeholder="Select" allowClear={true}>
                      {mediaTypeList.map((x) => (
                        <Option key={x.media_type_id} value={x.media_type_id}>
                          {x.media_type}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span="1" xs={2} xl={1} className="mobile-hide">
                  <span
                    style={{
                      marginLeft: "-9px",
                      marginTop: "6px",
                      display: "inline-block",
                    }}
                  >
                    AND
                  </span>
                </Col>

                <Col span="5" xs={12} xl={5}>
                  <Form.Item name="media_type3">
                    <Select placeholder="Select" allowClear={true}>
                      {mediaTypeList.map((x) => (
                        <Option key={x.media_type_id} value={x.media_type_id}>
                          {x.media_type}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span="1" xs={2} xl={1} className="mobile-hide">
                  <span
                    style={{
                      marginLeft: "-9px",
                      marginTop: "6px",
                      display: "inline-block",
                    }}
                  >
                    AND
                  </span>
                </Col>

                <Col span="6" xs={12} xl={6}>
                  <Form.Item name="media_type4">
                    <Select placeholder="Select" allowClear={true}>
                      {mediaTypeList.map((x) => (
                        <Option key={x.media_type_id} value={x.media_type_id}>
                          {x.media_type}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {photoBucket && (
          <Row style={{ width: "100%", marginBottom: "16px" }}>
            <Col span="4" xs={24} xl={4} style={{ marginTop: "5px" }}>
              Photo Type
            </Col>

            <Col span="18" xs={24} xl={18}>
              <Row>
                <Col span="5" xs={12} xl={6}>
                  <Form.Item name="photo_type1">
                    <Select placeholder="Select" allowClear={true}>
                      <Option key="M" value="M">
                        Mounting
                      </Option>
                      <Option key="U" value="U">
                        Unmounting
                      </Option>
                      <Option key="O" value="O">
                        Monitoring
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col
                  span="1"
                  xs={2}
                  xl={3}
                  className="mobile-hide"
                  style={{ textAlign: "center" }}
                >
                  <span
                    style={{
                      marginLeft: "-9px",
                      marginTop: "6px",
                      display: "inline-block",
                    }}
                  >
                    AND
                  </span>
                </Col>

                <Col span="5" xs={12} xl={6}>
                  <Form.Item name="photo_type2">
                    <Select placeholder="Select" allowClear={true}>
                      <Option key="M" value="M">
                        Mounting
                      </Option>
                      <Option key="U" value="U">
                        Unmounting
                      </Option>
                      <Option key="O" value="O">
                        Monitoring
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col
                  span="1"
                  xs={2}
                  xl={3}
                  className="mobile-hide"
                  style={{ textAlign: "center" }}
                >
                  <span
                    style={{
                      marginLeft: "-9px",
                      marginTop: "6px",
                      display: "inline-block",
                    }}
                  >
                    AND
                  </span>
                </Col>

                <Col span="5" xs={12} xl={6}>
                  <Form.Item name="photo_type3">
                    <Select placeholder="Select" allowClear={true}>
                      <Option key="M" value="M">
                        Mounting
                      </Option>
                      <Option key="U" value="U">
                        Unmounting
                      </Option>
                      <Option key="O" value="O">
                        Monitoring
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        <Row style={{ width: "100%", marginBottom: "16px" }}>
          <Col span="4" xs={24} xl={4} style={{ marginTop: "5px" }}>
            Size
          </Col>

          <Col span="18" xs={24} xl={18}>
            <Row>
              <Col span="6" xs={8} xl={6}>
                <Form.Item name="width">
                  <InputNumber placeholder="Width" style={{ width: "100%" }} />
                </Form.Item>
              </Col>

              <Col span="6" xs={8} xl={6}>
                <Form.Item name="height">
                  <InputNumber placeholder="Height" style={{ width: "100%" }} />
                </Form.Item>
              </Col>

              <Col span="6" xs={8} xl={6}>
                <Form.Item name="sqft">
                  <InputNumber placeholder="SQFT" style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row style={{ width: "100%", marginBottom: "16px" }}>
          <Col span="4" xs={24} xl={4} style={{ marginTop: "5px" }}>
            SQFT Between
          </Col>

          <Col span="18" xs={24} xl={18}>
            <Row>
              <Col span="6" xs={12} xl={6}>
                <Form.Item name="sqft_from">
                  <InputNumber
                    placeholder="SQFT From"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>

              <Col span="6" xs={12} xl={6}>
                <Form.Item name="sqft_to">
                  <InputNumber
                    placeholder="SQFT To"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row style={{ width: "100%", marginBottom: "16px" }}>
          <Col span="4" xs={24} xl={4} style={{ marginTop: "5px" }}>
            Light
          </Col>

          <Col span="18" xs={24} xl={18}>
            <Row>
              <Col span="6" xs={24} xl={6}>
                <Form.Item name="light_type">
                  {lightTypeList && (
                    <Select placeholder="Light Type" allowClear={true}>
                      {lightTypeList.map((x) => (
                        <Option key={x.light_type_id} value={x.light_type_id}>
                          {x.light_type}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row style={{ width: "100%", marginBottom: "16px" }}>
          <Col span="4" xs={24} xl={4} style={{ marginTop: "5px" }}>
            Display Name
          </Col>
          <Col span="18" xs={24} xl={18}>
            <Row>
              <Col span="12" xs={24} xl={12}>
                <Form.Item name="display_name">
                  <Input placeholder="Running Display Name" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        {!photoBucket && (
          <Row style={{ width: "100%", marginBottom: "16px" }}>
            <Col span="4" xs={24} xl={4} style={{ marginTop: "5px" }}>
              Base Rate Between
            </Col>

            <Col span="18" xs={24} xl={18}>
              <Row>
                <Col span="6" xs={12} xl={6}>
                  <Form.Item name="base_rate_from">
                    <InputNumber
                      placeholder="Base Rate From"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>

                <Col span="6" xs={12} xl={6}>
                  <Form.Item name="base_rate_to">
                    <InputNumber
                      placeholder="Base Rate To"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {!photoBucket && (
          <Row style={{ width: "100%", marginBottom: "16px" }}>
            <Col span="4" xs={24} xl={4} style={{ marginTop: "5px" }}>
              Base Rate / SQFT
            </Col>

            <Col span="18" xs={24} xl={18}>
              <Row>
                <Col span="6" xs={12} xl={6}>
                  <Form.Item name="base_rate_per_sqft_from">
                    <InputNumber
                      placeholder="Base Rate / SQFT From"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>

                <Col span="6" xs={12} xl={6}>
                  <Form.Item name="base_rate_per_sqft_to">
                    <InputNumber
                      placeholder="Base Rate / SQFT To"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {!photoBucket && (
          <Row style={{ width: "100%", marginBottom: "16px" }}>
            <Col span="4" xs={24} xl={4} style={{ marginTop: "5px" }}>
              Sites Available From
            </Col>

            <Col span="18" xs={24} xl={18}>
              <Row>
                <Col span="6" xs={10} xl={6}>
                  <Form.Item name="available_from">
                    <DatePicker
                      style={{ marginRight: "16px", width: "140px" }}
                      placeholder="Available From"
                      inputReadOnly={true}
                      // onChange={this.handleAvailableDate}
                    />
                  </Form.Item>
                </Col>

                <Col span="1" xs={2} xl={1}>
                  <span
                    style={{
                      marginLeft: "-2px",
                      marginTop: "6px",
                      display: "inline-block",
                    }}
                  >
                    OR
                  </span>
                </Col>

                <Col span="6" xs={12} xl={6}>
                  <Form.Item name="available_within_days">
                    <InputNumber
                      placeholder="Within x days"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {photoBucket && (
          <Row style={{ width: "100%", marginBottom: "16px" }}>
            <Col span="4" xs={24} xl={4} style={{ marginTop: "5px" }}>
              Photo Uploaded
            </Col>

            <Col span="18" xs={24} xl={18}>
              <Row>
                <Col span="6" xs={10} xl={6}>
                  <Form.Item name="upload_from_date">
                    <DatePicker
                      style={{ marginRight: "16px", width: "140px" }}
                      placeholder="Start Date"
                      inputReadOnly={true}
                    />
                  </Form.Item>
                </Col>

                <Col span="6" xs={10} xl={6}>
                  <Form.Item name="upload_to_date">
                    <DatePicker
                      style={{ marginRight: "16px", width: "140px" }}
                      placeholder="End Date"
                      inputReadOnly={true}
                    />
                  </Form.Item>
                </Col>

                <Col span="1" xs={2} xl={1}>
                  <span
                    style={{
                      marginLeft: "-2px",
                      marginTop: "6px",
                      display: "inline-block",
                    }}
                  >
                    OR
                  </span>
                </Col>

                <Col span="6" xs={12} xl={6}>
                  <Form.Item name="uploaded_within_days">
                    <InputNumber
                      placeholder="In Last x days"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {photoBucket && (
          <Row style={{ width: "100%", marginBottom: "16px" }}>
            <Col span="4" xs={24} xl={4} style={{ marginTop: "5px" }}>
              Segment Search
            </Col>

            <Col span="18" xs={24} xl={18}>
              <Row>
                <Col span="6">
                  <Form.Item name="segment_id">
                    {segmentList && (
                      <Select placeholder="Segment" allowClear={true}>
                        {segmentList.map((x) => (
                          <Option key={x.segment_id} value={x.segment_id}>
                            {x.segment}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>

                <Col span="6">
                  <Form.Item name="customer_id">
                    <Input placeholder="Customer Name" />
                  </Form.Item>
                </Col>

                <Col span="6">
                  <Form.Item name="year">
                    {yearList && (
                      <Select placeholder="Year" allowClear={true}>
                        {yearList.map((x) => (
                          <Option key={x.id} value={x.id}>
                            {x.year}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        <Row style={{ width: "100%" }}>
          <Col span="24">
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Search
              </Button>

              <Button
                type="primary"
                onClick={this.resetFilters}
                style={{ marginLeft: "16px" }}
              >
                Reset
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default InventoryAdvancedFilters;
