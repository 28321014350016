import React, { Component } from "react";
import { Column } from "@ant-design/charts";
import CommonListBase from "../../base/common-list-base";

class SalesEstimateChart extends CommonListBase {
  state = {
    listReq:
      "dashboard/sales?resultgroup=payoutestimate&durationGroup=current_finyear&",
    delReq: "sistercompany/delete?payout_estimate_id=",
    dtlReq: "sistercompany",
    data: [],
    pagination: {
      current: 1,
      pageSize: 5,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    sorting: [],
    screen: "dashboard-city-list",
    isDrawer: false,
    record: "",
    isEditMode: false,
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.getResults({ pagination });
  }

  dataFormating = (data) => {
    const output = [];

    data.forEach((item) => {
      const { payout_estimate_name, monthly_sales } = item;

      monthly_sales.forEach((monthly) => {
        const { month_name, year, total_sales } = monthly;
        const formattedMonth = `${month_name} ${year.toString().slice(-2)}`;

        output.push({
          month: formattedMonth,
          type: payout_estimate_name,
          amount: total_sales,
        });
      });
    });

    return output;
  };

  render() {
    const { data } = this.state;
    return (
      <Column
        {...{
          data: this.dataFormating(data),
          group: true,
          xField: "month",
          yField: "amount",
          seriesField: "type",
          colorField: "type",
        }}
      />
    );
  }
}

export default SalesEstimateChart;
