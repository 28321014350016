import React, { Component } from "react";
import { Button, Collapse, List } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import UploadDocs from "../common/upload-docs";
import { ajaxRequester } from "../../services/ajax-requester";
import { downloadFile } from "../../helpers/download-helper";

class PlanDocuments extends Component {
  state = {};

  handleRemove = (item, document_type, document_name) => {
    const { results, page } = this.state;
    const deletePath =
      page === "plan"
        ? page + "/document/delete?planId="
        : page + "/document/delete?campaignid=";

    const id = page === "plan" ? results.plan_id : results.campaign_id;

    var reqUrl =
      deletePath +
      id +
      "&documenttype=" +
      document_type +
      "&documentid=" +
      item.id;
    var reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      () => {
        let resultsUpdates = results;

        resultsUpdates.documents[document_name] = resultsUpdates.documents[
          document_name
        ].filter((x) => x.id !== item.id);

        this.setState({ results: resultsUpdates });
      },
      "DELETE"
    );
  };

  updateData = (item, document_name) => {
    let results = this.state.results;
    results.documents[document_name].push(item);
    this.setState({ results });
  };

  viewDocument = (item) => {
    downloadFile(item.name, item.data_url);
  };

  componentDidMount() {
    const { results, page } = this.props;
    this.setState({ results, page });
  }

  render() {
    const { results, page } = this.state;

    const uploadPath = page + "/document/upload";
    const deletePath = page + "/document/delete";

    const id = page === "plan" ? results?.plan_id : results?.campaign_id;

    let email_count =
      (results?.documents &&
        results?.documents["email_confirmation"]?.length) ||
      0;
    let purchase_count =
      (results?.documents && results?.documents["purchase_order"]?.length) || 0;
    let other_count =
      (results?.documents && results?.documents["others"]?.length) || 0;

    let email_confirmation =
      results?.documents && results?.documents["email_confirmation"];
    let purchase_order =
      results?.documents && results?.documents["purchase_order"];
    let others = results?.documents && results?.documents["others"];

    const { Panel } = Collapse;

    return (
      <div>
        <Collapse defaultActiveKey={["1"]} accordion>
          <Panel header={"Email Confirmation (" + email_count + ")"} key="1">
            <UploadDocs
              id={id}
              document_type={1}
              uploadPath={uploadPath}
              deletePath={deletePath}
              updateData={(item) => this.updateData(item, "email_confirmation")}
            />
            {email_confirmation && email_confirmation.length > 0 && (
              <List
                dataSource={email_confirmation}
                renderItem={(item) => (
                  <List.Item>
                    <Button
                      type="link"
                      className="ant-btn-reset"
                      onClick={() => this.viewDocument(item)}
                    >
                      {item.name}
                    </Button>
                    <Button
                      style={{ float: "right" }}
                      icon={<DeleteOutlined />}
                      size="small"
                      className="ant-btn-reset"
                      onClick={() =>
                        this.handleRemove(item, 1, "email_confirmation")
                      }
                    />
                  </List.Item>
                )}
                size="small"
                style={{ marginTop: "16px" }}
              />
            )}
          </Panel>

          <Panel header={"Purchase Order (" + purchase_count + ")"} key="2">
            <UploadDocs
              id={id}
              document_type={2}
              uploadPath={uploadPath}
              deletePath={deletePath}
              updateData={(item) => this.updateData(item, "purchase_order")}
            />
            {purchase_order && purchase_order.length > 0 && (
              <List
                dataSource={purchase_order}
                renderItem={(item) => (
                  <List.Item>
                    <Button
                      type="link"
                      className="ant-btn-reset"
                      onClick={() => this.viewDocument(item)}
                    >
                      {item.name}
                    </Button>
                    <Button
                      style={{ float: "right" }}
                      icon={<DeleteOutlined />}
                      size="small"
                      className="ant-btn-reset"
                      onClick={() =>
                        this.handleRemove(item, 2, "purchase_order")
                      }
                    />
                  </List.Item>
                )}
                size="small"
                style={{ marginTop: "16px" }}
              />
            )}
          </Panel>

          <Panel header={"Others (" + other_count + ")"} key="3">
            <UploadDocs
              id={id}
              document_type={3}
              uploadPath={uploadPath}
              deletePath={deletePath}
              updateData={(item) => this.updateData(item, "others")}
            />

            {others && others.length > 0 && (
              <List
                dataSource={others}
                renderItem={(item) => (
                  <List.Item>
                    <Button
                      type="link"
                      className="ant-btn-reset"
                      onClick={() => this.viewDocument(item)}
                    >
                      {item.name}
                    </Button>
                    <Button
                      style={{ float: "right" }}
                      icon={<DeleteOutlined />}
                      size="small"
                      className="ant-btn-reset"
                      onClick={() => this.handleRemove(item, 3, "others")}
                    />
                  </List.Item>
                )}
                size="small"
                style={{ marginTop: "16px" }}
              />
            )}
          </Panel>
        </Collapse>
      </div>
    );
  }
}

export default PlanDocuments;
