import React, { Component } from "react";
import { Form, Button, Input, Descriptions } from "antd";

class SoldItems extends Component {
  state = {};

  formRef = React.createRef();

  onFinish = (values) => {
    this.props.handleCreateGroup(values);
  };

  render() {
    const { modalTitle, groupDetails } = this.props;
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    return (
      <React.Fragment>
        {!groupDetails && (
          <Form
            name="operation-modal"
            {...layout}
            onFinish={this.onFinish}
            ref={this.formRef}
          >
            <Form.Item name="label_name" label="Group Name">
              <Input placeholder="Group Name" />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8 }}>
              <Button type="primary" htmlType="submit">
                Create Group
              </Button>
            </Form.Item>
          </Form>
        )}

        {groupDetails && (
          <React.Fragment>
            <Descriptions column={2} title={groupDetails.label_name}>
              <Descriptions.Item label="Total SQFT">
                <b>{groupDetails?.total_sqft}</b>
              </Descriptions.Item>

              <Descriptions.Item label="Sold Amount">
                <b>{groupDetails?.sold_amount}</b>
              </Descriptions.Item>

              <Descriptions.Item label="Weight / SQFT">
                <b>{groupDetails?.weight_per_sqft}</b>
              </Descriptions.Item>

              <Descriptions.Item label="Rate / KG">
                <b>{groupDetails?.rate_per_kg}</b>
              </Descriptions.Item>

              <Descriptions.Item label="Total Weight in KG">
                <b>{groupDetails?.total_weight_in_kg}</b>
              </Descriptions.Item>

              <Descriptions.Item label="Effective Rate / SQFT">
                <b>{groupDetails?.effective_rate_per_sqft}</b>
              </Descriptions.Item>
            </Descriptions>

            <Button
              type="primary"
              onClick={() => this.props.handleSold(groupDetails)}
            >
              Sold
            </Button>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default SoldItems;
