import React, { Component } from "react";
import { Button, Input, Select, Drawer, Checkbox, Typography } from "antd";
import { SettingOutlined, SaveOutlined } from "@ant-design/icons";

class ListFiltersPublic extends Component {
  state = {
    filterCol: this.props.items.find((x) => x.default)?.displayName,
    filterVal: "",
    isDrawer: false,
    checkedValues: this.props.items,
    isDisabled: true,
  };

  handleSelect = (e) => {
    this.setState({ filterCol: e });
  };

  handleSearch = (e) => {
    this.setState({ filterVal: e.target.value }, () => {
      this.handleFilters();
    });
  };

  handleFilters = () => {
    this.props.handleFilters(
      this.props.items.find((x) => x.displayName === this.state.filterCol),
      this.state.filterVal
    );
  };

  handleSettings = () => {
    this.setState({ isDrawer: !this.state.isDrawer });
  };

  handleCheck = (checkedValues) => {
    this.setState({ checkedValues, isDisabled: false });
  };

  updateCheck = () => {
    this.setState({ isDrawer: !this.state.isDrawer });
    let values = [];
    this.state.checkedValues.map((item) => values.push({ title: item }));
    values.push({ title: "Actions" });
    this.props.handleSettings(values);
  };

  componentDidMount() {
    this.nameInput.focus();
  }

  render() {
    const { Search } = Input;
    const { Option } = Select;
    const { Title } = Typography;
    const { items, screen } = this.props;
    const { isDrawer, isDisabled } = this.state;

    const settingItems = items
      .filter((x) => !x.hideFromSettings)
      .map((x) => x.displayName);

    let defaultChecked = items.map((x) => !x.unchecked && x.displayName);

    return (
      (<div className="list-filters">
        <Input.Group compact>
          <Select
            name="filtercol"
            defaultValue={items.find((x) => x.default)?.displayName}
            onChange={this.handleSelect}
          >
            {items.map(
              (item) =>
                !item?.hideFilter && (
                  <Option key={item.displayName}>{item.displayName}</Option>
                )
            )}
          </Select>
          <Search
            placeholder={
              "Filter by " +
              items[0]?.displayName +
              ", " +
              items[1]?.displayName +
              "..."
            }
            onChange={this.handleSearch}
            onSearch={this.handleFilters}
            allowClear
            enterButton
            size="middle"
            ref={(input) => {
              this.nameInput = input;
            }}
          />
          <Button
            onClick={this.handleSettings}
            icon={<SettingOutlined />}
            size="middle"
            style={{ float: "right" }}
          />
          <Drawer
            title="Settings"
            placement="right"
            open={isDrawer}
            onClose={this.handleSettings}
            width={420}
            rootClassName="filters-drawer"
          >
            <Title level={5}>Select columns</Title>

            <Checkbox.Group
              options={settingItems}
              defaultValue={defaultChecked}
              onChange={this.handleCheck}
              className="filters-checkbox"
            />
            <div style={{ textAlign: "center" }}>
              <Button
                type="primary"
                onClick={this.updateCheck}
                icon={<SaveOutlined />}
                style={{ marginTop: "24px" }}
                disabled={isDisabled}
              >
                Save
              </Button>
            </div>
          </Drawer>
        </Input.Group>
      </div>)
    );
  }
}

export default ListFiltersPublic;
