import { dateFormatter } from "../../components/common/common-formatter";

const ExcelJS = require("exceljs");

export const exportInvoicesToXlsx = (
  reportName,
  inventory_details,
  configuration,
  settings
) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet(reportName);
  let inventories = inventory_details || [];

  const getEmployee = (employee_id) => {
    let employeeDetails = localStorage.getItem("user:" + employee_id);
    employeeDetails = JSON.parse(employeeDetails);
    let employeeName =
      employeeDetails?.data?.firstName + " " + employeeDetails?.data?.lastName;

    return employeeName || "";
  };

  inventories = inventories.map((x, key) => ({
    ...x,
    sr: key + 1,
    employee_id: x?.employee_id ? getEmployee(x?.employee_id) : "",
    pending_inv_start_date: dateFormatter(x?.pending_inv_start_date),
    pending_inv_end_date: dateFormatter(x?.pending_inv_end_date),
  }));

  let columns = [
    { title: "Sr", columnName: "sr", width: 4, align: "center" },
    {
      title: "Employee",
      columnName: "employee_id",
      width: 16,
      align: "left",
    },
    {
      title: "Customer Name",
      columnName: "customer_name",
      width: 18,
      align: "left",
    },
    {
      title: "Display Name",
      columnName: "display_name",
      width: 18,
      align: "left",
    },
    {
      title: "City",
      columnName: "city",
      width: 12,
      align: "left",
    },
    {
      title: "Media",
      columnName: "media_type",
      width: 12,
      align: "left",
    },
    {
      title: "Location",
      columnName: "location",
      width: 24,
      align: "left",
    },
    {
      title: "W",
      columnName: "width",
      width: 8,
      align: "center",
    },
    {
      title: "H",
      columnName: "height",
      width: 8,
      align: "center",
    },
    {
      title: "SQFT",
      columnName: "sqft",
      width: 8,
      align: "center",
    },
    {
      title: "Start Date",
      columnName: "pending_inv_start_date",
      width: 10,
      align: "center",
    },
    {
      title: "End Date",
      columnName: "pending_inv_end_date",
      width: 10,
      align: "center",
    },
    {
      title: "Days",
      columnName: "pending_inv_no_of_days",
      width: 6,
      align: "center",
    },
    {
      title: "Display Cost",
      columnName: "pending_rental_cost",
      width: 12,
      align: "right",
      numberFormat: true,
    },
    {
      title: "Printing Cost",
      columnName: "pending_printing_cost",
      width: 12,
      align: "right",
      numberFormat: true,
    },
    {
      title: "Installation Cost",
      columnName: "pending_installing_cost",
      width: 16,
      align: "right",
      numberFormat: true,
    },
    {
      title: "Ageing From DoD (in Days)",
      columnName: "aging_days",
      width: 24,
      align: "center",
    },
  ];

  settings?.length > 0 &&
    (columns = columns.filter((x) => settings.includes(x.title)));

  const getColAddress = (add) => {
    let address = add.replace(/[0-9]/g, "");
    return address;
  };

  // Header Rows
  sheet.addRow([reportName]);
  sheet.getCell("A1").font = {
    bold: true,
    size: 14,
  };

  // Adding Colums Title
  let titleRowNum = 2;
  sheet.addRow(columns.map((x) => x.title));
  sheet.getRow(titleRowNum).eachCell((cell, index) => {
    cell.font = {
      bold: true,
    };
    cell.border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    cell.alignment = { horizontal: columns[index - 1]?.align };
    sheet.getColumn(getColAddress(cell.address)).width =
      columns[index - 1].width || 0;
  });

  // Adding Sites
  const numFmt = "#,##0";
  let total = 0;
  let total_printing_cost = 0;
  let total_installing_cost = 0;
  let total_monthly_rate = 0;
  let total_amount = 0;
  let gst_amount = 0;
  let grand_total = 0;
  let numRows = 0;
  let siteRows = [];

  inventories.forEach((inventory) => {
    total += inventory.rental_cost;
    total_printing_cost += inventory.pending_printing_cost;
    total_installing_cost += inventory.pending_installing_cost;
    total_monthly_rate += inventory.pending_rental_cost;
    total_amount += inventory.total_amount;
    gst_amount += inventory.gst_amount;
    grand_total += inventory.grand_total;
    numRows += 1;

    sheet.addRow(columns.map((x) => inventory[x.columnName] || ""));

    if (inventory.ha_base_inventories?.length > 0) {
      siteRows.push("subrow");
      numRows += 1;
      inventory.ha_base_inventories.forEach((groupInventory, gIndex) => {
        sheet.addRow(columns.map((x) => groupInventory[x.columnName] || ""));
        siteRows.push("subrow");
      });
    } else {
      siteRows.push("row");
    }
  });

  siteRows.forEach((row, count) => {
    sheet.getRow(titleRowNum).eachCell((cell, index) => {
      let newcell = sheet.getCell(
        getColAddress(cell.address) + (titleRowNum + count + 1)
      );
      newcell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      newcell.alignment = { horizontal: columns[index - 1]?.align };
      columns[index - 1]?.numberFormat && (newcell.numFmt = numFmt);
      row === "subrow" &&
        (newcell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "F2F2F2" },
        });
    });
  });

  sheet.getCell("N1").value = total_monthly_rate;
  sheet.getCell("O1").value = total_printing_cost;
  sheet.getCell("P1").value = total_installing_cost;
  sheet.getRow(1).eachCell((cell) => {
    cell.numFmt = numFmt;
  });

  sheet.getCell("N1").font = {
    bold: true,
  };
  sheet.getCell("O1").font = {
    bold: true,
  };
  sheet.getCell("P1").font = {
    bold: true,
  };
  // sheet.getCell("M1").alignment = { horizontal: "right" };

  // Exporting File
  workbook.xlsx.writeBuffer().then((buffer) => {
    let blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    let a = document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.style.display = "none";
    a.download = configuration?.exportName ?? "export.xlsx";
    document.body.appendChild(a);
    a.click();
  });
};
