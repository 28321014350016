import { Component } from "react";
import { ajaxRequester } from "../services/ajax-requester";
import AdminApi from "../iam/api/admin-api";

class CommonListBase extends Component {
  state = {};

  // Get Inventories List
  getResults = (params = {}) => {
    this.setState({ loading: true });
    let reqUrl = this.state.listReq + this.getReqParams(params);
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState(
          {
            loading: false,
            data: data.response,
            pagination: {
              ...params.pagination,
              total: data?.page_info?.total_records,
            },
            mediaTypeList: data.mediaTypeList,
            navKey: this.state.navKey + 1,
            filters: params.filters ? params.filters : this.state.filters,
            sortField: params.sortField
              ? params.sortField
              : this.state.sortField,
            sortOrder: params.sortOrder
              ? params.sortOrder
              : this.state.sortOrder,
            estimate_type_list: data?.estimate_type_list,
            total_sales: data?.total_sales,
          },
          () => this.updateResults()
        );
      },
      "GET"
    );
  };

  // Getting Request Parameters
  getReqParams = (params) => {
    let reqParams = "";

    reqParams =
      "pageno=" +
      params.pagination.current +
      "&pagesize=" +
      params.pagination.pageSize;

    if (params.sortField && params.sortOrder) {
      reqParams =
        reqParams +
        "&sortColumn=" +
        params.sortField +
        "&sortOrder=" +
        (params.sortOrder === "ascend" ? 1 : -1) +
        (this.state.filters ? this.state.filters : "");
    }

    if (params.filters) {
      reqParams =
        reqParams +
        params.filters +
        (this.state.sortField && this.state.sortField
          ? "&sortColumn=" +
            this.state.sortField +
            "&sortOrder=" +
            (this.state.sortOrder === "ascend" ? 1 : -1)
          : "");
    }

    if (params?.pagination?.current !== this.state?.pagination?.current) {
      reqParams =
        reqParams +
        (this.state.sortField && this.state.sortField
          ? "&sortColumn=" +
            this.state.sortField +
            "&sortOrder=" +
            (this.state.sortOrder === "ascend" ? 1 : -1)
          : "") +
        (this.state.filters ? this.state.filters : "");
    }

    return reqParams;
  };

  // Change Pagging Sorting and Filters
  handleTableChange = (pagination, filters, sorter) => {
    this.getResults({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };

  // Handle Global Filters
  handleFilters = (filterColumn, filterValue) => {
    let { pagination } = this.state;
    pagination.current = 1;

    let filters = "";

    if (filterValue) {
      filters =
        "&columnName=" +
        filterColumn.columnName +
        "&columnValue=" +
        filterValue.replace("&", "%26");
    } else {
      filters = "&columnName=" + "" + "&columnValue=" + "";
    }
    this.getResults({ pagination, filters });
  };

  // Handle Setting show hide columns
  handleSettings = (values) => {
    let columnsDefault = this.state.columnsDefault;
    let columns = this.state.columns;
    columns = columnsDefault.filter(({ title }) =>
      values.some((exclude) => exclude.title === title)
    );
    this.setState({ columns });
  };

  // Currency Formatter
  currencyFormatter = (currency) => {
    let x = currency;
    x = x.toString();
    let lastThree = x.substring(x.length - 3);
    let otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers !== "") lastThree = "," + lastThree;
    let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res;
  };

  // Redirect to Item add form
  addItem = () => {
    this.props.history.push(this.state.addReq);
  };

  // Redirect to Item edit form
  editItem = (recordId) => {
    this.props.history.push(this.state.editReq + recordId);
  };

  // Delete Item from List based on Id
  deleteItem = (recordId) => {
    this.setState({ isLoading: true });
    var reqUrl = this.state.delReq + recordId;
    var reqObj = "";
    ajaxRequester(
      reqUrl,
      reqObj,
      () => {
        const { pagination } = this.state;
        this.getResults({ pagination });
      },
      "DELETE"
    );
  };

  // Redirect to Item details screen
  detailsItem = (recordId) => {
    this.props.history.push(this.state.dtlReq + recordId);
  };

  // Callback if want to update results
  updateResults = () => {};

  // Set Default Columns
  setDefaultColumns = (columns) => {
    AdminApi.syncColumsSettings()
      .then((columnsSettings) => {
        this.setState({ columnsSettings: columnsSettings || {} }, () =>
          this.applyDefaultColums(columns)
        );
      })
      .catch((nothing) => {});
  };

  // Apply Default Columns
  applyDefaultColums = (columns) => {
    let { columnsSettings, columnsDefault, screen } = this.state;
    if (screen && columnsSettings) {
      let colConfig = columnsSettings[screen];

      if (colConfig && colConfig.length > 1) {
        columns = columnsDefault.filter(({ title }) =>
          colConfig.some((exclude) => exclude.title === title)
        );
      }
      this.setState({ columns });
    }
  };
}

export default CommonListBase;
