import React, { Component } from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Row,
  Col,
  Select,
  message,
  Drawer,
  Table,
  Popconfirm,
} from "antd";
import {
  ProfileOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  FileImageOutlined,
  AimOutlined,
  BulbTwoTone,
} from "@ant-design/icons";
import { ajaxRequester } from "../../services/ajax-requester";
import InventoryAddBaseInventory from "./inventory-add-base-inventory";
import InventoryAddSelectInventory from "./inventory-add-select-inventory";
import MapLatLng from "../common/map-latlng";
import UploadFiles from "../common/upload-files";
import PhotoGallery from "../../components/common/photo-gallery";

class InventoryAddForm extends Component {
  state = {
    isLoading: false,
    inventoryLocationList: [],
    value: "",
    inventoryType: "",
    isDrawer: false,
    ha_base_inventories: "",
    haBaseMode: false,
    ha_group_inventories: "",
    mapVisible: false,
    mapKey: 1,
    latlng: "",
  };

  formRef = React.createRef();

  // Set Default Data if Edit Mode False
  setDefaultData = () => {
    this.formRef.current.setFieldsValue({
      media_type: 1,
      structure: "S",
      is_active: true,
      side_type: "single",
      quantity: 1,
    });
  };

  // Set Data if Edit Mode
  setData = () => {
    const data = this.props.results;

    this.formRef.current.setFieldsValue({
      media_type: data.media_type,
      structure: data.structure,
      is_active: data.is_active,
      location: data.location,
      width: data.width,
      height: data.height,
      sqft: data.sqft,
      quantity: data.quantity,
      light_type: data.light_type,
      side_type: data.side_type,
      traffic_facing_from: data.traffic_facing_from,
      traffic_going_to: data.traffic_going_to,
      base_rate: data.base_rate,
      card_rate: data.card_rate,
      lat_long_x: data.lat_long.x || "",
      lat_long_y: data.lat_long.y || "",
      inventory_location_id: data.inventory_location_id,
      supplier_id: data?.supplier_id || "",
    });

    data.structure === "M" &&
      this.setState(
        {
          ha_base_inventories: data.ha_base_inventories,
          inventoryType: data.structure,
        },
        () => this.resetSQFT()
      );

    this.props.isGroup &&
      this.setState({ ha_group_inventories: data.ha_group_inventories });

    this.setState({
      inventoryLocationList: [
        {
          inventory_location_id: data.inventory_location_id,
          inventory_location: data.inventory_location,
        },
      ],
    });

    this.setState({
      latlng: {
        lat: data.lat_long.x || "",
        lng: data.lat_long.y || "",
      },
    });
  };

  // Get Locations
  getInventoryLocation = (value) => {
    let reqUrl = "lookup/location?location=" + value;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ inventoryLocationList: data.response });
      },
      "GET"
    );
  };

  handleSearch = (value) => {
    if (value) {
      this.getInventoryLocation(value);
    } else {
      this.setState({ inventoryLocationList: [] });
    }
  };

  onFinish = (values) => {
    if (values.structure === "M") {
      if (this.state.ha_base_inventories.length >= 2) {
        values.ha_base_inventories = this.state.ha_base_inventories;
        this.props.handleAddInventory(values);
        this.setState({ isLoading: true });
      } else {
        message.error("Minimum 2 Base Inventories Required.");
      }
    } else {
      if (this.props.isGroup) {
        if (this.state.ha_group_inventories.length >= 2) {
          values.ha_group_inventories = this.state.ha_group_inventories;
          this.props.handleAddInventory(values);
          this.setState({ isLoading: true });
        } else {
          message.error("Minimum 2 Inventory Required.");
        }
      } else {
        this.props.handleAddInventory(values);
        this.setState({ isLoading: true });
      }
    }
  };

  onFinishFailed = () => {
    this.warning();
  };

  success = () => {
    message.success(
      "Inventory " +
        (this.props.mode === "edit" ? "updated" : "added") +
        " successfully."
    );
    this.props.handleBack();
  };

  warning = () => {
    message.warning("Please enter required fields.");
  };

  ValuesChange = (value, values) => {
    if (
      value["width"] ||
      value["height"] ||
      value["quantity"] ||
      value["side_type"]
    ) {
      let total =
        (values["width"] || 1) *
        (values["height"] || 1) *
        (values["quantity"] || 1) *
        (values["side_type"] === "double" ? 2 : 1);

      !this.state.ha_base_inventories &&
        this.state.ha_base_inventories.length === 0 &&
        this.formRef.current.setFieldsValue({ sqft: total });
    }

    if (value["structure"]) {
      this.setState({ inventoryType: values["structure"] });
    }

    if (value["quantity"]) {
      this.state.ha_base_inventories &&
        this.state.ha_base_inventories.length > 0 &&
        this.resetSQFT();
    }
  };

  handleDrawer = (record) => {
    this.setState({
      isDrawer: !this.state.isDrawer,
      haBaseMode: record,
    });
  };

  addBaseInventory = (values) => {
    let ha_base_inventories = [...this.state.ha_base_inventories];

    if (this.state.haBaseMode) {
      ha_base_inventories = ha_base_inventories.filter(
        (x) =>
          x.ha_inventory_id !== values?.ha_inventory_id ||
          x.uuid !== values?.uuid
      );
      ha_base_inventories.push(values);
    } else {
      ha_base_inventories.push(values);
    }

    this.setState({ ha_base_inventories, isDrawer: false }, () =>
      this.resetSQFT()
    );
  };

  deleteBaseInventory = (record) => {
    let ha_base_inventories = [...this.state.ha_base_inventories];
    ha_base_inventories = ha_base_inventories.filter(
      (x) =>
        x.ha_inventory_id !== record.ha_inventory_id || x.uuid !== record.uuid
    );
    this.setState({ ha_base_inventories }, () => this.resetSQFT());
  };

  editBaseInventory = (record) => {
    this.handleDrawer(record);
  };

  resetSQFT = () => {
    let quantity = this.formRef.current.getFieldValue("quantity");
    let totalSQFT = 0;
    this.state.ha_base_inventories.map((x) => (totalSQFT = totalSQFT + x.sqft));
    totalSQFT = totalSQFT * quantity;
    this.formRef.current.setFieldsValue({ sqft: totalSQFT });
  };

  deleteGroupedInventory = (record) => {
    let ha_group_inventories = [...this.state.ha_group_inventories];
    ha_group_inventories = ha_group_inventories.filter(
      (x) =>
        x.ha_inventory_id !== record.ha_inventory_id || x.uuid !== record.uuid
    );
    this.setState({ ha_group_inventories });
  };

  addGroupInventory = (values) => {
    let ha_group_inventories = [...this.state.ha_group_inventories];
    values.map((x) => ha_group_inventories.push(x));
    this.setState({ ha_group_inventories, isDrawer: false });
  };

  // Currency Formatter
  currencyFormatter = (currency) => {
    let x = currency;
    x = x.toString();
    let lastThree = x.substring(x.length - 3);
    let otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers !== "") lastThree = "," + lastThree;
    let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res;
  };

  handleMap = () => {
    this.setState({ mapVisible: true, mapKey: this.state.mapKey + 1 });
  };

  setLatLong = (latlng) => {
    this.setState({ latlng });
    this.formRef.current.setFieldsValue({
      lat_long_x: latlng.lat,
      lat_long_y: latlng.lng,
    });
  };

  // Handle Photos SlideShoe
  handlePhotos = (record) => {
    this.setState({
      isShowPhotos: !this.state.isShowPhotos,
      photos: record ? record?.ha_images : "",
      photosTitle: record ? record : "",
    });
  };

  componentDidMount() {
    this.setDefaultData();
    this.props.mode === "edit" && this.setData();
    const { isSuccess } = this.props;
    isSuccess && this.success();
  }

  render() {
    const {
      isLoading,
      inventoryLocationList,
      inventoryType,
      isDrawer,
      ha_base_inventories,
      haBaseMode,
      ha_group_inventories,
      mapVisible,
      mapKey,
      latlng,
      isShowPhotos,
      photos,
      photosTitle,
    } = this.state;

    const { mode, isGroup, mediaTypeList, supplierList } = this.props;

    const { Option } = Select;

    const layout = {
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
    };

    const layout2Col = {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
    };

    const layout1Col = {
      labelCol: { span: 3 },
      wrapperCol: { span: 21 },
    };

    const structureList = [
      { structure_id: "S", structure: "Single Display" },
      { structure_id: "M", structure: "Multi Display" },
    ];

    const sideTypeList = [
      { side_type_id: "single", side_type: "Single" },
      { side_type_id: "double", side_type: "Double" },
    ];

    const statusList = [
      { status_id: true, status: "Active" },
      // { status_id: false, status: "Inactive" },
    ];

    const lightTypeList = [
      { light_type_id: "NL", light_type: "NL" },
      { light_type_id: "FL", light_type: "FL" },
      { light_type_id: "BL", light_type: "BL" },
    ];

    let ha_base_inventoriesColumns = [
      {
        title: "IID",
        dataIndex: "inventory_code",
      },
      {
        title: "Side Type",
        dataIndex: "side_type",
        render: (text) => (
          <span style={{ textTransform: "capitalize" }}>{text}</span>
        ),
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
      },
      {
        title: "Description",
        dataIndex: "description",
        width: 280,
      },
      {
        title: "Width",
        dataIndex: "width",
      },
      {
        title: "Height",
        dataIndex: "height",
      },
      {
        title: "SQFT",
        dataIndex: "sqft",
      },
      {
        title: "Light Type",
        dataIndex: "light_type",
      },
      {
        title: "Base Rate",
        dataIndex: "base_rate",
        render: (text) => this.currencyFormatter(Number(text)),
      },
      {
        title: "Card Rate",
        dataIndex: "card_rate",
        render: (text) => this.currencyFormatter(Number(text)),
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "x",
        render: (record) => (
          <React.Fragment>
            <Button
              onClick={() => this.editBaseInventory(record)}
              icon={<EditOutlined />}
              className="ant-btn-reset"
            />
            <Popconfirm
              title="Are you sure delete?"
              placement="left"
              okText="Yes"
              cancelText="No"
              onConfirm={() => this.deleteBaseInventory(record)}
            >
              <Button
                icon={<DeleteOutlined />}
                className="ant-btn-reset"
                style={{ marginLeft: "12px" }}
              />
            </Popconfirm>
          </React.Fragment>
        ),
      },
    ];

    let ha_group_inventoriesColumns = [
      {
        title: "Media",
        dataIndex: "media_type",
        render: (text) => <span style={{ whiteSpace: "nowrap" }}>{text}</span>,
      },
      {
        title: "Qty",
        dataIndex: "quantity",
      },
      {
        title: "IID",
        dataIndex: "inventory_code",
      },
      {
        title: "City",
        dataIndex: "city",
      },
      {
        title: "Area",
        dataIndex: "area",
      },
      {
        title: "Location",
        dataIndex: "location",
      },
      {
        title: "Size",
        render: (text) => text.width + "x" + text.height,
      },
      {
        title: "Light",
        dataIndex: "light_type",
      },
      {
        title: "Photo",
        dataIndex: "images",
        render: (text, record) => (
          <Button
            className="ant-btn-reset"
            disabled={record?.ha_images?.length > 0 ? false : true}
            icon={<FileImageOutlined />}
            onClick={() => this.handlePhotos(record)}
          />
        ),
      },
      {
        title: "Card Rate",
        dataIndex: "card_rate",
        render: (text) => this.currencyFormatter(Number(text)),
      },
      {
        title: "Log",
        dataIndex: "is_inventory_status_read",
        render: (text) =>
          text ? (
            <span style={{ whiteSpace: "nowrap" }}>
              <BulbTwoTone twoToneColor="#cccccc" /> Read
            </span>
          ) : (
            <span style={{ whiteSpace: "nowrap" }}>
              <BulbTwoTone twoToneColor="#fadb14" /> Unread
            </span>
          ),
      },
      {
        title: "Booking Status",
        dataIndex: "booking_status",
        render: (text) =>
          text === "A" ? "Available" : text === "B" ? "Blocked" : "NA",
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "x",
        render: (record) => (
          <React.Fragment>
            <Popconfirm
              title="Are you sure delete?"
              placement="left"
              okText="Yes"
              cancelText="No"
              onConfirm={() => this.deleteGroupedInventory(record)}
            >
              <Button
                icon={<DeleteOutlined />}
                className="ant-btn-reset"
                style={{ marginLeft: "12px" }}
              />
            </Popconfirm>
          </React.Fragment>
        ),
      },
    ];

    const inventoryBaseType = this.props.inventoryType;

    return (
      <Row>
        <Col span="23" xs={24} xl={23}>
          <Form
            {...layout}
            name="inventoryAddForm"
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            ref={this.formRef}
            initialValues={{}}
            onValuesChange={this.ValuesChange}
          >
            <Row>
              {inventoryBaseType === "tainventory" && (
                <Col span="6" xs={24} xl={6}>
                  <Form.Item
                    label="Supplier"
                    name="supplier_id"
                    rules={[{ required: true, message: "Select Supplier!" }]}
                  >
                    {supplierList && (
                      <Select placeholder="Select Supplier">
                        {supplierList.map((x) => (
                          <Option key={x.supplier_id} value={x.supplier_id}>
                            {x.supplier_name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              )}

              <Col span="6" xs={24} xl={6}>
                <Form.Item
                  label="Media Type"
                  name="media_type"
                  rules={[{ required: true, message: "Select Media Type!" }]}
                >
                  <Select
                    placeholder="Select Media Type"
                    disabled={mode === "edit" ? true : false}
                  >
                    {mediaTypeList &&
                      mediaTypeList.map((x) => (
                        <Option key={x.media_type_id} value={x.media_type_id}>
                          {x.media_type}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              {!isGroup && (
                <React.Fragment>
                  <Col span="6" xs={24} xl={6}>
                    <Form.Item
                      label="Status"
                      name="is_active"
                      rules={[{ required: true, message: "Select Status!" }]}
                    >
                      {statusList && (
                        <Select placeholder="Select Status">
                          {statusList.map((x) => (
                            <Option key={x.status_id} value={x.status_id}>
                              {x.status}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>

                  <Col span="6" xs={24} xl={6}>
                    <Form.Item
                      label="Structure"
                      name="structure"
                      rules={[{ required: true, message: "Select Structure!" }]}
                    >
                      {structureList && (
                        <Select placeholder="Select Structure">
                          {structureList.map((x) => (
                            <Option key={x.structure_id} value={x.structure_id}>
                              {x.structure}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>

                  {inventoryType === "M" && (
                    <Col
                      span="6"
                      xs={24}
                      xl={6}
                      className="ant-text-right add-base-inv-btn"
                      style={
                        inventoryBaseType === "tainventory" && {
                          position: "absolute",
                          right: "0px",
                          top: "-64px",
                        }
                      }
                    >
                      <Button
                        type="primary"
                        onClick={() => this.handleDrawer("")}
                        icon={<PlusOutlined />}
                        className="icon-btn"
                      >
                        Add Base Inventory
                      </Button>
                    </Col>
                  )}
                </React.Fragment>
              )}

              {isGroup && (
                <Col
                  span={inventoryBaseType !== "tainventory" ? "18" : "12"}
                  xl={inventoryBaseType !== "tainventory" ? "18" : "12"}
                  className="ant-text-right add-group-inv-btn"
                >
                  <Button
                    type="primary"
                    onClick={() => this.handleDrawer("")}
                    icon={<PlusOutlined />}
                    className="icon-btn"
                  >
                    Add Inventory
                  </Button>
                </Col>
              )}
            </Row>

            <Row>
              <Col span="12" xs={24} xl={12}>
                <Form.Item
                  label="Area"
                  name="inventory_location_id"
                  {...layout2Col}
                  rules={[{ required: true, message: "Select Area!" }]}
                >
                  <Select
                    showSearch
                    placeholder="Search Area, City, District, State"
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    onSearch={this.handleSearch}
                    notFoundContent={null}
                  >
                    {inventoryLocationList.map((x) => (
                      <Option
                        key={x.inventory_location_id}
                        value={x.inventory_location_id}
                      >
                        {x.inventory_location}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span="12" xs={24} xl={12}>
                <Form.Item
                  label="Location"
                  name="location"
                  {...layout2Col}
                  rules={[
                    {
                      required: true,
                      pattern: new RegExp(/^[^/]*$/),
                      message: "Input Location! ( / not allowed)",
                    },
                  ]}
                >
                  <Input placeholder="Location" />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span="6" xs={24} xl={6}>
                <Form.Item
                  label="Side Type"
                  name="side_type"
                  rules={[{ required: true, message: "Select Side Type!" }]}
                >
                  {sideTypeList && (
                    <Select placeholder="Side Type">
                      {sideTypeList.map((x) => (
                        <Option key={x.side_type_id} value={x.side_type_id}>
                          {x.side_type}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>

              <Col span="6" xs={24} xl={6}>
                <Form.Item
                  label="Quantity"
                  name="quantity"
                  rules={[{ required: true, message: "Input Quantity!" }]}
                >
                  <InputNumber
                    min={0}
                    style={{ width: "100%" }}
                    placeholder="Quantity"
                  />
                </Form.Item>
              </Col>

              <Col span="6" xs={24} xl={6}>
                <Form.Item
                  label="Width"
                  name="width"
                  rules={[
                    {
                      required: inventoryType !== "M" ? true : false,
                      message: "Input Width!",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Width"
                    min={0}
                  />
                </Form.Item>
              </Col>

              <Col span="6" xs={24} xl={6}>
                <Form.Item
                  label="Height"
                  name="height"
                  rules={[
                    {
                      required: inventoryType !== "M" ? true : false,
                      message: "Input Height!",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Height"
                    min={0}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span="6" xs={24} xl={6}>
                <Form.Item label="SQFT" name="sqft">
                  <Input
                    disabled
                    style={{
                      background: "rgba(0, 0, 0, 0.02)",
                      color: "rgba(0, 0, 0, 0.85)",
                    }}
                  />
                </Form.Item>
              </Col>

              {inventoryBaseType !== "tainventory" && (
                <Col span="6" xs={24} xl={6}>
                  <Form.Item
                    label="Base Rate"
                    name="base_rate"
                    rules={[{ required: true, message: "Base Rate!" }]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      placeholder="Base Rate"
                    />
                  </Form.Item>
                </Col>
              )}

              {inventoryBaseType === "tainventory" && (
                <Col span="6" xs={24} xl={6}>
                  <Form.Item label="Best Rate" name="base_rate">
                    <InputNumber
                      style={{ width: "100%" }}
                      placeholder="Best Rate"
                      disabled
                    />
                  </Form.Item>
                </Col>
              )}

              <Col span="6" xs={24} xl={6}>
                <Form.Item
                  label="Card Rate"
                  name="card_rate"
                  rules={[{ required: true, message: "Card Rate!" }]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Card Rate"
                    min={0}
                  />
                </Form.Item>
              </Col>

              <Col span="6" xs={24} xl={6}>
                <Form.Item
                  label="Light Type"
                  name="light_type"
                  rules={[{ required: true, message: "Select Light Type!" }]}
                >
                  {lightTypeList && (
                    <Select placeholder="Select Light Type">
                      {lightTypeList.map((x) => (
                        <Option key={x.light_type_id} value={x.light_type_id}>
                          {x.light_type}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span="6" xs={24} xl={6}>
                <Form.Item label="Traffic From" name="traffic_facing_from">
                  <Input placeholder="Traffic Facing From" />
                </Form.Item>
              </Col>

              <Col span="6" xs={24} xl={6}>
                <Form.Item label="Traffic To" name="traffic_going_to">
                  <Input placeholder="Traffic Going To" />
                </Form.Item>
              </Col>

              <Col span="6" xs={24} xl={6}>
                <Form.Item label="Latitude" name="lat_long_x">
                  <Input style={{ width: "100%" }} placeholder="Latitude" />
                </Form.Item>
              </Col>

              <Col span="6" xs={24} xl={6}>
                <Form.Item label="Longitude" name="lat_long_y">
                  <Input
                    style={{ width: "78%" }}
                    className="lat-long-input"
                    placeholder="Longitude"
                  />
                </Form.Item>
              </Col>

              <Button
                onClick={this.handleMap}
                style={{ position: "absolute", right: "0px" }}
                icon={<AimOutlined />}
                className="lat-long-icon"
              />
            </Row>

            <Row>
              <Col span="12" xs={24} xl={12}>
                <Form.Item label="Video Link" name="videoLink" {...layout2Col}>
                  <Input placeholder="Video Link" />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span="20" xs={24} xl={20}>
                <Form.Item label="Upload Photo" name="images" {...layout1Col}>
                  <UploadFiles
                    id={this.props?.results?.ha_inventory_id}
                    uploadPath={"hainventory/photo/upload"}
                    deletePath={"hainventory/photo/delete"}
                    photos={this.props?.results?.ha_images}
                    mode={mode}
                    handleUploadImages={this.props.handleUploadImages}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span="6" xs={24} xl={6}>
                <Form.Item
                  label="Created By"
                  name="created_by"
                  hidden="true"
                  initialValue="Ashok"
                >
                  <Input placeholder="Created By" />
                </Form.Item>
              </Col>

              <Col span="6" xs={24} xl={6}>
                <Form.Item label="Description" name="description" hidden="true">
                  <Input placeholder="Description" />
                </Form.Item>
              </Col>
            </Row>

            {ha_base_inventories && (
              <Row>
                <Col span="24" xs={24} xl={24} className="base-inventory-list">
                  <Table
                    columns={ha_base_inventoriesColumns}
                    rowKey={(record) => record.ha_inventory_id || record.uuid}
                    dataSource={ha_base_inventories}
                    pagination={false}
                    scroll={{ x: 400 }}
                    size="small"
                    bordered
                  />
                </Col>
              </Row>
            )}

            {isGroup && ha_group_inventories && (
              <Row>
                <Col span="24" xs={24} xl={24} className="base-inventory-list">
                  <Table
                    columns={ha_group_inventoriesColumns}
                    rowKey={(record) => record.ha_inventory_id}
                    dataSource={ha_group_inventories}
                    pagination={false}
                    scroll={{ x: 400 }}
                    size="small"
                    bordered
                  />
                </Col>
              </Row>
            )}

            <Form.Item
              style={{ textAlign: "center" }}
              wrapperCol={{ span: 24 }}
            >
              <Button
                type="primary"
                size={"large"}
                icon={<ProfileOutlined />}
                htmlType="submit"
                loading={isLoading ? true : false}
              >
                {mode === "edit" ? "Update " : "Add "}
                {isGroup ? "Grouped Inventory" : "Inventory"}
              </Button>
            </Form.Item>
          </Form>

          <Drawer
            title={
              (haBaseMode ? "Update" : "Add") +
              (isGroup ? " Inventory" : " Base Inventory")
            }
            placement="right"
            open={isDrawer}
            onClose={this.handleDrawer}
            width={isGroup ? "88%" : "50%"}
            rootClassName="base-inventory-drawer"
            key={isDrawer}
          >
            {isGroup ? (
              <InventoryAddSelectInventory
                key={isDrawer}
                addGroupInventory={this.addGroupInventory}
                isFromGroup={true}
                existingInventories={ha_group_inventories}
                inventoryType={inventoryBaseType}
              />
            ) : (
              <InventoryAddBaseInventory
                key={isDrawer}
                sideTypeList={sideTypeList}
                lightTypeList={lightTypeList}
                haBaseMode={haBaseMode}
                addBaseInventory={this.addBaseInventory}
                inventoryType={inventoryBaseType}
              />
            )}
          </Drawer>

          {mapVisible && (
            <MapLatLng
              latlng={latlng}
              setLatLong={this.setLatLong}
              key={mapKey}
            />
          )}

          {isShowPhotos && (
            <PhotoGallery
              photos={photos}
              photosTitle={photosTitle}
              handlePhotos={this.handlePhotos}
            />
          )}
        </Col>
      </Row>
    );
  }
}

export default InventoryAddForm;
