import React, { Component } from "react";
import PageTitleDetails from "../../components/common/page-title-detail";
import { ajaxRequester } from "../../services/ajax-requester";
import {
  Button,
  Form,
  Input,
  Select,
  Switch,
  Row,
  Col,
  message,
  Radio,
  DatePicker,
  InputNumber,
  Drawer,
  Tag,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import UserPickerDropDown from "../../iam/components/user-picker-dropdown";
import PlanSelectInventory from "../../components/plan/plan-select-inventories";
import SelectPayee from "../../components/purchase/select-payee";
import EstimatePayeeList from "../../components/purchase/estimate-payee-list";
import * as dayjs from "dayjs";
import UploadFileLocal from "../../components/common/upload-file-local";
import EstimateSiteList from "../../components/purchase/estimate-site-list";
import IncrementSlabList from "../../components/purchase/increment-slab-list";

class PayoutEstimateAddEdit extends Component {
  state = {
    page: "payout-estimate-add",
    loading: false,
    stateList: [],
    districtList: [],
    cityList: [],
    estimateTypeList: [],
    sisterCompanyList: [],
    mode: this.props.match.path.includes("add") ? "Add" : "Edit",
    results: "",
    isDrawer: false,
    isPayeeDrawer: false,
    payout_estimate_site_list: [],
    payout_estimate_payee_list: [],
    increment_slab: [],
    payee_slab: [{ id: "", per: "", amount: "" }],
    annual_amount: 0,
    estimate_payee_key: 1,
    estimate_site_key: 1,
    increment_slab_key: 1,
  };

  formRef = React.createRef();

  // Get Payee Details
  getDetails = () => {
    const { id } = this.props.match.params;
    let reqUrl = "payoutestimate/detail?payoutEstimateId=" + id;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ details: data.response }, () => this.setDefaultData());
      },
      "GET"
    );
  };

  // Redirect to Payee List
  handleBack = () => {
    this.props.history.goBack();
  };

  // Get Estimate Type List from Lookup
  getEstimateTypeList = () => {
    let reqUrl = "estimatetype/list?showActive=true";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ estimateTypeList: data.response });
      },
      "GET"
    );
  };

  // Get Sister Company List from Lookup
  getSisterCompanyList = () => {
    let reqUrl = "sistercompany/list?showActive=true";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ sisterCompanyList: data.response });
      },
      "GET"
    );
  };

  // Get State List from Lookup
  getStateList = () => {
    let reqUrl = "lookup/state";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ stateList: data.response });
      },
      "GET"
    );
  };

  // Get District List from Lookup
  getDistrictList = (state) => {
    let reqUrl = "lookup/district?state=" + state;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ districtList: data.response });
      },
      "GET"
    );
  };

  // Get City List from Lookup
  getCityList = (state, district) => {
    let reqUrl = "lookup/city?state=" + state + "&district=" + district;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ cityList: data.response });
      },
      "GET"
    );
  };

  // Handle Select Change
  handleTerritoryChange = (item, key) => {
    let state = this.formRef.current.getFieldValue("state");
    let district = this.formRef.current.getFieldValue("district");
    let city = this.formRef.current.getFieldValue("city");

    switch (item) {
      case "state":
        district = null;
        city = null;
        this.setState({ districtList: [], cityList: [], areaList: [] });
        break;

      case "district":
        city = null;
        this.setState({ cityList: [] });
        break;

      default:
        break;
    }
    this.formRef.current.setFieldsValue({
      state: state,
      district: district,
      city: city,
    });
  };

  // Handle Select Focus
  handleTerritorySelect = (item, key) => {
    let state = this.formRef.current.getFieldValue("state");
    let district = this.formRef.current.getFieldValue("district");

    switch (item) {
      case "district":
        state && this.getDistrictList(state);
        break;

      case "city":
        state && district && this.getCityList(state, district);
        break;

      default:
        break;
    }
  };

  setDefaultData = () => {
    const { details } = this?.state;

    this.formRef.current.setFieldsValue({
      is_active: details?.is_active,
      estimate_type_id: details?.estimate_type,
      payout_estimate_name: details?.payout_estimate_name,
      payout_from_company_id: details?.payout_from_account_name,
      payout_approval_by_id: details?.payout_approval_by_id,
      authority_id: details?.authority_id,
      payout_interval: details?.payout_interval,
      payout_type: details?.payout_type,
      schedule_payout: details?.schedule_payout || "Y",
      annual_amount: details?.annual_amount,
      mapped_sqft: details?.mapped_sqft,
      amount_sqft_month: details?.amount_sqft_month,
      state: details?.state_name,
      district: details?.district_name,
      city: details?.city_name,
      schedule_start_date: dayjs(details?.schedule_start_date),
      tenure_from: dayjs(details?.tenure_from),
      tenure_to: dayjs(details?.tenure_to),
      due_date_type: details?.due_date_type,
      due_date_days: details?.due_date_days,
    });

    this.setState({
      payout_estimate_site_list: details?.payout_estimate_site_list || [],
      payout_estimate_payee_list: details?.payout_estimate_payee_list || [],
      increment_slab: details?.increment_slab || [],
      annual_amount: details?.annual_amount || 0,
    });
  };

  ValuesChange = (value, values) => {
    const { payout_estimate_payee_list } = this.state;
    if (value["annual_amount"]) {
      this.setState({ annual_amount: value["annual_amount"] }, () =>
        this.calcAnnualAmount(payout_estimate_payee_list)
      );

      if (values["mapped_sqft"]) {
        let amount_sqft_month = parseInt(
          value["annual_amount"] / values["mapped_sqft"] / 12 || 0
        );
        this.formRef.current.setFieldsValue({ amount_sqft_month });
      } else {
        this.formRef.current.setFieldsValue({ amount_sqft_month: 0 });
      }
    }

    if (value["tenure_from"] || value["tenure_to"]) {
      let { increment_slab_key, increment_slab } = this.state;
      let tenure_from = values["tenure_from"];
      let tenure_to = values["tenure_to"];
      let year_diff = dayjs(tenure_to).diff(dayjs(tenure_from), "years");

      if (year_diff > 0) {
        let incrementArr = [...Array(year_diff).keys()];
        incrementArr = incrementArr.map((x, key) => ({
          from_date: dayjs(values["tenure_from"])
            .add(key + 1, "years")
            .format("YYYY-MM-DD"),
          to_date:
            incrementArr.length !== key + 1
              ? dayjs(values["tenure_from"])
                  .add(key + 2, "years")
                  .add(-1, "days")
                  .format("YYYY-MM-DD")
              : dayjs(values["tenure_to"]).format("YYYY-MM-DD"),
          increment_per: increment_slab[key]?.increment_per || 0,
        }));

        this.setState({
          increment_slab: incrementArr || [],
          increment_slab_key: increment_slab_key + 1,
        });
      }
    }

    if (value["tenure_from"]) {
      this.formRef.current.setFieldsValue({ schedule_start_date: "" });
    }
  };

  onFinish = (values) => {
    let { payout_estimate_payee_list } = this.state;
    let totalAmount = 0;
    payout_estimate_payee_list.map(
      (x) => (totalAmount = x.payee_annual_amount + totalAmount)
    );

    if (values.annual_amount === totalAmount) {
      this.handleAddEstimate(values);
    } else {
      message.error("Annual Payout Amount must match with Total Payee Amount");
    }
  };

  handleAddEstimate = (values) => {
    const { mode, details, cityList, file } = this.state;
    const { estimateTypeList, sisterCompanyList } = this.state;
    let {
      payout_estimate_site_list,
      payout_estimate_payee_list,
      increment_slab,
    } = this.state;

    if (mode === "Edit") {
      values.payout_estimate_id = details?.payout_estimate_id;
      values.city_id =
        cityList.find((x) => x?.city === values?.city)?.city_id ||
        details?.city_id;
    } else {
      values.city_id = cityList.find((x) => x?.city === values?.city)?.city_id;
    }

    values.estimate_type_id = estimateTypeList?.find(
      (x) => x?.estimate_type === values?.estimate_type_id
    )?.estimate_type_id;

    values.payout_from_company_id = sisterCompanyList?.find(
      (x) => x?.sister_company_name === values?.payout_from_company_id
    )?.sister_company_id;

    payout_estimate_site_list = payout_estimate_site_list.map((x) => ({
      ha_inventory_id: x.ha_inventory_id,
    }));

    let reqUrl = "payoutestimate/" + (mode === "Edit" ? "update" : "add");
    let reqObj = {
      request: {
        ...values,
        schedule_start_date:
          values?.schedule_start_date &&
          values?.schedule_start_date.format("YYYY-MM-DDTHH:mm:ss"),
        tenure_from:
          values?.tenure_from &&
          values?.tenure_from.format("YYYY-MM-DDTHH:mm:ss"),
        tenure_to:
          values?.tenure_to && values?.tenure_to.format("YYYY-MM-DDTHH:mm:ss"),
        payout_estimate_site_list: payout_estimate_site_list || [],
        payout_estimate_payee_list: payout_estimate_payee_list || [],
        increment_slab: increment_slab || [],
      },
    };
    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data?.payout_estimate_id || data?.success) {
        message.success(
          "Payout Estimate has been " +
            (mode === "Edit" ? "updated" : "added") +
            " successfully"
        );
        file && this.handleUpload(data?.payout_estimate_id);
        this.handleBack();
        this.generatePendingEstimate();
      } else {
        message.error(
          data?.detail ||
            data?.title ||
            "An error occured while processing your request."
        );
      }
    });
  };

  generatePendingEstimate = () => {
    let reqUrl = "pendingestimate/generate";
    let reqObj = "";
    ajaxRequester(reqUrl, reqObj, (data) => {}, "GET");
  };

  // Handle Drawer
  handleDrawer = () => {
    this.setState({
      isDrawer: !this.state.isDrawer,
    });
  };

  handlePayeeDrawer = () => {
    this.setState({
      isPayeeDrawer: !this.state.isPayeeDrawer,
    });
  };

  addSelectedInventory = (inventories) => {
    this.handleDrawer();

    let { payout_estimate_site_list, estimate_site_key } = this?.state;

    inventories && inventories.map((x) => payout_estimate_site_list.push(x));

    this.setState({
      payout_estimate_site_list,
      estimate_site_key: estimate_site_key + 1,
    });

    this.calcMappedSqft(payout_estimate_site_list);
  };

  deleteInventory = (inventory) => {
    let { payout_estimate_site_list, estimate_site_key } = this?.state;

    payout_estimate_site_list = payout_estimate_site_list.filter(
      (x) => x.ha_inventory_id !== inventory.ha_inventory_id
    );

    this.setState({
      payout_estimate_site_list,
      estimate_site_key: estimate_site_key + 1,
    });

    this.calcMappedSqft(payout_estimate_site_list);
  };

  calcMappedSqft = (inventories) => {
    let { annual_amount } = this.state;
    let mapped_sqft = 0;
    inventories.map((x) => (mapped_sqft = x.sqft + mapped_sqft));
    this.formRef.current.setFieldsValue({ mapped_sqft });

    if (annual_amount && mapped_sqft) {
      let amount_sqft_month = parseInt(annual_amount / mapped_sqft / 12 || 0);
      this.formRef.current.setFieldsValue({ amount_sqft_month });
    } else {
      this.formRef.current.setFieldsValue({ amount_sqft_month: 0 });
    }
  };

  handleAddPayee = (selectedPayee) => {
    this.handlePayeeDrawer();

    let { payout_estimate_payee_list } = this?.state;

    selectedPayee &&
      selectedPayee.map((x) => payout_estimate_payee_list.push({ ...x }));

    this.calcAnnualAmount(payout_estimate_payee_list);
  };

  deletePayee = (payee) => {
    let { payout_estimate_payee_list } = this?.state;

    payout_estimate_payee_list = payout_estimate_payee_list.filter(
      (x) => x.payee_id !== payee.payee_id
    );

    this.calcAnnualAmount(payout_estimate_payee_list);
  };

  calcAnnualAmount = (payout_estimate_payee_list) => {
    let { annual_amount, estimate_payee_key } = this?.state;

    let payee_per = 100 / payout_estimate_payee_list.length;
    let payee_annual_amount = annual_amount / payout_estimate_payee_list.length;

    payout_estimate_payee_list = payout_estimate_payee_list.map((x) => ({
      ...x,
      payee_per,
      payee_annual_amount,
    }));

    estimate_payee_key++;

    this.setState({
      payout_estimate_payee_list,
      estimate_payee_key,
    });
  };

  handlePayeeListChange = (payout_estimate_payee_list) => {
    this.setState({ payout_estimate_payee_list });
  };

  handleIncrementSlab = (increment_slab) => {
    this.setState({ increment_slab });
  };

  handleDocument = (file) => {
    this.setState({ file });
  };

  handleUpload = (ref_id) => {
    const { id } = this?.props?.match?.params;

    let reqUrl = "payoutestimate/document/upload";
    let request = this.state?.file;
    request.reference_id = Number(ref_id || id);

    let reqObj = {
      request,
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (!data) {
        message.error("Document Upload Failed");
      }
    });
  };

  disabledDate = (current) => {
    let billed_end_date = this.state?.details?.billed_end_date;
    return current && current < dayjs(billed_end_date, "YYYY-MM-DD");
  };

  disabledStartDate = (current) => {
    let tenure_from = this.formRef.current.getFieldValue("tenure_from");
    return current && current < dayjs(tenure_from, "YYYY-MM-DD");
  };

  daysClear = () => {
    this.formRef.current.setFieldsValue({ due_date_days: 0 });
  };

  componentDidMount() {
    this.state.mode === "Edit" && this.getDetails();
    this.getStateList();
    this.getEstimateTypeList();
    this.getSisterCompanyList();
  }

  render() {
    const {
      mode,
      loading,
      stateList,
      districtList,
      cityList,
      estimateTypeList,
      sisterCompanyList,
      details,
      isDrawer,
      isPayeeDrawer,
      payout_estimate_site_list,
      payout_estimate_payee_list,
      estimate_payee_key,
      estimate_site_key,
      increment_slab,
      increment_slab_key,
    } = this?.state;

    const { Option } = Select;

    const layout = {
      labelCol: { span: 12 },
      wrapperCol: { span: 12 },
    };

    return (
      <React.Fragment>
        <Row>
          <Col span={24} xs={24} xl={24}>
            <PageTitleDetails
              titleText={mode + " Payout Estimate"}
              isBackBtn="true"
              handleBack={this.handleBack}
            />
            <Form
              name="payout_estimate_add"
              className="payout_estimate_add"
              {...layout}
              onFinish={this.onFinish}
              onValuesChange={this.ValuesChange}
              ref={this.formRef}
              initialValues={{
                is_active: true,
                payout_interval: "M",
                payout_type: "A",
                schedule_payout: "Y",
              }}
            >
              <Row>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item
                    label="Estimate Type"
                    name="estimate_type_id"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Estimate Type !",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Estimate Type"
                      allowClear={true}
                      showSearch={true}
                    >
                      {estimateTypeList.map((x) => (
                        <Option key={x.estimate_type} value={x.estimate_type}>
                          {x.estimate_type}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item
                    label="Payout Estimate Name"
                    name="payout_estimate_name"
                    rules={[
                      {
                        required: true,
                        message: "Please Input Payout Estimate Name !",
                      },
                    ]}
                  >
                    <Input placeholder="Payout Estimate Name" />
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item
                    label="Payout from Company"
                    name="payout_from_company_id"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Payout from Company !",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Estimate Type"
                      allowClear={true}
                      showSearch={true}
                    >
                      {sisterCompanyList.map((x) => (
                        <Option
                          key={x.sister_company_name}
                          value={x.sister_company_name}
                        >
                          {x.sister_company_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item
                    label="Payout Approval From"
                    name="payout_approval_by_id"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Payout Approval From !",
                      },
                    ]}
                  >
                    <UserPickerDropDown
                      user={(details && details?.payout_approval_by_id) || ""}
                    />
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item label="Authority ID" name="authority_id">
                    <Input placeholder="Authority ID" />
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item label="Upload Agreement" name="upload_agreement">
                    {(mode === "Add" || details) && (
                      <UploadFileLocal
                        handleUpload={this.handleDocument}
                        document_type={"1"}
                        mode={mode}
                        existingFile={details?.documents?.agreement}
                        deletePath={
                          "payoutestimate/document/delete?payoutEstimateId=" +
                          details?.payout_estimate_id
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item
                    label="State"
                    name="state"
                    rules={[
                      {
                        required: true,
                        message: "Please Select State !",
                      },
                    ]}
                  >
                    <Select
                      placeholder="State"
                      allowClear={true}
                      showSearch={true}
                      onChange={() => this.handleTerritoryChange("state")}
                    >
                      {stateList.map((x) => (
                        <Option key={x.state} value={x.state}>
                          {x.state}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item
                    name="district"
                    label="District"
                    rules={[
                      {
                        required: true,
                        message: "Please Select District !",
                      },
                    ]}
                  >
                    <Select
                      placeholder="District"
                      allowClear={true}
                      showSearch={true}
                      onChange={() => this.handleTerritoryChange("district")}
                      onFocus={() => this.handleTerritorySelect("district")}
                    >
                      {districtList.map((x) => (
                        <Option key={x.district} value={x.district}>
                          {x.district}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item
                    label="City"
                    name="city"
                    rules={[
                      {
                        required: true,
                        message: "Please Select City !",
                      },
                    ]}
                  >
                    <Select
                      placeholder="City"
                      showSearch={true}
                      allowClear={true}
                      onChange={() => this.handleTerritoryChange("city")}
                      onFocus={() => this.handleTerritorySelect("city")}
                    >
                      {cityList.map((x) => (
                        <Option key={x.city} value={x.city}>
                          {x.city}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item label="Schedule Payout" name="schedule_payout">
                    <Radio.Group defaultValue="M">
                      <Radio value="Y">Yes</Radio>
                      <Radio value="N">No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item
                    label="Payout Type"
                    name="payout_type"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Payout Type !",
                      },
                    ]}
                  >
                    <Select placeholder="Payout Type">
                      <Option key="A" value="A">
                        Advance
                      </Option>
                      <Option key="P" value="P">
                        After Period
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item
                    label="Payout Interval"
                    name="payout_interval"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Payout Interval !",
                      },
                    ]}
                  >
                    <Select placeholder="Payout Interval">
                      <Option key="M" value="M">
                        Monthly
                      </Option>
                      <Option key="B" value="B">
                        Bi Monthly
                      </Option>
                      <Option key="Q" value="Q">
                        Quarterly
                      </Option>
                      <Option key="H" value="H">
                        Half Yearly
                      </Option>
                      <Option key="Y" value="Y">
                        Yearly
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={8} xs={24} xl={8}>
                  <Form.Item
                    label="From Date"
                    name="tenure_from"
                    rules={[
                      {
                        required: true,
                        message: "Please Select From Date !",
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder="From Date"
                      inputReadOnly={true}
                      disabled={details && !details?.start_date_editable}
                    />
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item
                    label="To Date"
                    name="tenure_to"
                    rules={[
                      {
                        required: true,
                        message: "Please Select To Date !",
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder="To"
                      inputReadOnly={true}
                      disabledDate={
                        details &&
                        !details?.start_date_editable &&
                        this.disabledDate
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item
                    label="Schedule Start Date"
                    name="schedule_start_date"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Schedule Start Date !",
                      },
                    ]}
                  >
                    <DatePicker
                      inputReadOnly={true}
                      disabled={details && !details?.start_date_editable}
                      disabledDate={this.disabledStartDate}
                    />
                  </Form.Item>
                </Col>

                <Col span={8} xs={24} xl={8}>
                  <Form.Item
                    label="Due Date Type"
                    name="due_date_type"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Due Date Type !",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Payout Interval"
                      allowClear
                      onClear={this.daysClear}
                    >
                      <Option key="Before" value="B">
                        Before
                      </Option>
                      <Option key="After" value="A">
                        After
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item
                    label="Due Date Days"
                    name="due_date_days"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Due Date Days !",
                      },
                    ]}
                  >
                    <InputNumber placeholder="Due Date Days" min={0} />
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}></Col>

                {increment_slab?.length > 0 && (
                  <React.Fragment>
                    <Col span={4} xs={24} xl={4}></Col>
                    <Col
                      span={12}
                      xs={24}
                      xl={12}
                      style={{ marginBottom: "16px" }}
                    >
                      <IncrementSlabList
                        key={increment_slab_key}
                        {...this.state}
                        handleIncrementSlab={this.handleIncrementSlab}
                      />
                    </Col>
                    <Col span={4} xs={24} xl={4}></Col>
                  </React.Fragment>
                )}

                <Col span={8} xs={24} xl={8}>
                  <Form.Item label="Map HA Sites" name="map_ha_sites">
                    <Button onClick={this.handleDrawer}>
                      Select HA Inventories
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item label="Mapped SQFT" name="mapped_sqft">
                    <InputNumber
                      placeholder="Mapped SQFT"
                      style={{ width: "100%" }}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item
                    label="Amount / SQFT / Month"
                    name="amount_sqft_month"
                  >
                    <InputNumber
                      placeholder="Amount / SQFT / Month"
                      style={{ width: "100%" }}
                      disabled
                    />
                  </Form.Item>
                </Col>
                {payout_estimate_site_list.length > 0 && (
                  <React.Fragment>
                    <Col span={4} xs={24} xl={4}></Col>
                    <Col
                      span={12}
                      xs={24}
                      xl={12}
                      style={{ marginBottom: "24px" }}
                    >
                      <EstimateSiteList
                        key={estimate_site_key}
                        payout_estimate_site_list={payout_estimate_site_list}
                        deleteInventory={this.deleteInventory}
                      />
                    </Col>
                    <Col span={4} xs={24} xl={4}></Col>
                  </React.Fragment>
                )}
                <Col span={8} xs={24} xl={8}>
                  <Form.Item label="Add Payee" name="add_payee">
                    <Button onClick={this.handlePayeeDrawer}>Add</Button>
                    {!payout_estimate_payee_list.length > 0 && (
                      <Tag color="orange" style={{ marginLeft: "16px" }}>
                        Payee Required
                      </Tag>
                    )}
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item
                    label="Annual Payout Amount"
                    name="annual_amount"
                    rules={[
                      {
                        required: true,
                        message: "Please Input Annual Payout Amount !",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="Annual Payout Amount"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}></Col>
                <Col span={4} xs={24} xl={4}></Col>
                {payout_estimate_payee_list?.length > 0 && (
                  <React.Fragment>
                    <Col span={12} xs={24} xl={12}>
                      <EstimatePayeeList
                        key={estimate_payee_key}
                        {...this.state}
                        deletePayee={this.deletePayee}
                        handlePayeeListChange={this.handlePayeeListChange}
                      />
                    </Col>
                  </React.Fragment>
                )}

                <Form.Item
                  label="Payout Estimate Status"
                  name="is_active"
                  valuePropName="checked"
                  hidden
                >
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
                <Col span={24}>
                  <Form.Item
                    className="ant-text-center"
                    style={{ marginTop: "24px" }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Button
                      type="primary"
                      size={"large"}
                      icon={<FileAddOutlined />}
                      htmlType="submit"
                      loading={loading}
                      disabled={!payout_estimate_payee_list.length > 0}
                    >
                      {mode} Payout Estimate
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>

        <Drawer
          title="Add HA Inventories"
          placement="right"
          open={isDrawer}
          onClose={this.handleDrawer}
          width="88%"
          key={isDrawer}
        >
          <PlanSelectInventory
            key={isDrawer}
            addSelectedInventory={this.addSelectedInventory}
            inventory_details={payout_estimate_site_list}
            {...this.state}
          />
        </Drawer>

        <Drawer
          title="Add Payee"
          placement="right"
          open={isPayeeDrawer}
          onClose={this.handlePayeeDrawer}
          width="88%"
          key={isPayeeDrawer}
        >
          <SelectPayee
            key={isPayeeDrawer}
            {...this.state}
            handleAddPayee={this.handleAddPayee}
          />
        </Drawer>
      </React.Fragment>
    );
  }
}

export default PayoutEstimateAddEdit;
