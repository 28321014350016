import React, { Component } from "react";
import {
  Row,
  Col,
  Table,
  Button,
  Popconfirm,
  Form,
  Select,
  message,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import PageTitle from "../../components/common/page-title";
import { ajaxRequester } from "../../services/ajax-requester";

class CityUpdate extends Component {
  state = {
    data: [],
    loading: false,
    stateList: [],
    districtList: [],
    cityList: [],
    areaList: [],
  };

  formRef = React.createRef();

  // Get List
  getResults = () => {
    this.setState({ loading: true });
    let reqUrl = "user/cityupdate/list";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          loading: false,
          data: data.response,
        });
      },
      "GET"
    );
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "State",
        dataIndex: "state_name",
      },
      {
        title: "District",
        dataIndex: "district_name",
      },
      {
        title: "City",
        dataIndex: "city_name",
      },
      {
        title: "Area",
        dataIndex: "area_name",
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "x",
        width: 92,
        render: (record) => (
          <Popconfirm
            title="Are you sure want to delete?"
            placement="left"
            okText="Yes"
            cancelText="No"
            onConfirm={() => this.updateResults(record, "Delete")}
          >
            <Button
              icon={<DeleteOutlined />}
              className="ant-btn-reset"
              style={{ marginLeft: "12px" }}
            />
          </Popconfirm>
        ),
      },
    ];

    this.setState({ columns });
  };

  updateResults = (record, action) => {
    let { data } = this.state;

    action === "Add" && data.push(record);

    action === "Delete" &&
      (data = data.filter((x) => x.city_update_id !== record.city_update_id));

    let reqUrl = "user/cityupdate/subscribe";
    let reqObj = {
      request: data,
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data.success) {
        message.success(
          action === "Add"
            ? "Record has been added successfully"
            : "Record has been deleted successfully"
        );
        this.getResults();
      } else {
        message.error(
          data?.detail || "An error occured while processing your request."
        );
        this.getResults();
      }
    });
  };

  // Get State List from Lookup
  getStateList = () => {
    let reqUrl = "lookup/state";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ stateList: data.response });
      },
      "GET"
    );
  };

  // Get District List from Lookup
  getDistrictList = (state) => {
    let reqUrl = "lookup/district?state=" + state;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ districtList: data.response });
      },
      "GET"
    );
  };

  // Get City List from Lookup
  getCityList = (state, district) => {
    let reqUrl = "lookup/city?state=" + state + "&district=" + district;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ cityList: data.response });
      },
      "GET"
    );
  };

  // Get Area List from Lookup
  getAreaList = (state, district, city) => {
    let reqUrl =
      "lookup/area?state=" + state + "&district=" + district + "&city=" + city;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ areaList: data.response });
      },
      "GET"
    );
  };

  // Handle Select Change
  handleTerritoryChange = (item, key) => {
    let state = this.formRef.current.getFieldValue("state");
    let district = this.formRef.current.getFieldValue("district");
    let city = this.formRef.current.getFieldValue("city");
    let area = this.formRef.current.getFieldValue("area");

    switch (item) {
      case "state":
        district = null;
        city = null;
        area = null;
        this.setState({ districtList: [], cityList: [], areaList: [] });
        break;

      case "district":
        city = null;
        area = null;
        this.setState({ cityList: [], areaList: [] });
        break;

      case "city":
        area = null;
        this.setState({ areaList: [] });
        break;

      default:
        break;
    }
    this.formRef.current.setFieldsValue({
      state: state,
      district: district,
      city: city,
      area: area,
    });
  };

  // Handle Select Focus
  handleTerritorySelect = (item, key) => {
    let state = this.formRef.current.getFieldValue("state");
    let district = this.formRef.current.getFieldValue("district");
    let city = this.formRef.current.getFieldValue("city");
    let area = this.formRef.current.getFieldValue("area");

    switch (item) {
      case "district":
        state && this.getDistrictList(state);
        break;
      case "city":
        state && district && this.getCityList(state, district);
        break;
      case "area":
        state && district && city && this.getAreaList(state, district, city);
        break;
      default:
        break;
    }
  };

  onFinish = (values) => {
    const { stateList, districtList, cityList, areaList } = this.state;
    let record = {
      state_id:
        (values?.state &&
          stateList.find((x) => x.state === values?.state)?.state_id) ||
        0,
      district_id:
        (values?.district &&
          districtList.find((x) => x.district === values?.district)
            ?.district_id) ||
        0,
      city_id:
        (values?.city &&
          cityList.find((x) => x.city === values?.city)?.city_id) ||
        0,
      area_id:
        (values?.area &&
          areaList.find((x) => x.area === values?.area)?.area_id) ||
        0,
    };
    this.updateResults(record, "Add");
  };

  componentDidMount() {
    // Get List
    this.getResults();
    this.setColumns();
    this.getStateList();
  }

  render() {
    const {
      data,
      columns,
      loading,
      stateList,
      districtList,
      cityList,
      areaList,
    } = this.state;
    const { Option } = Select;

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageTitle titleText="City Update" />

            <Form
              name="city_update"
              onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
              ref={this.formRef}
              onValuesChange={this.ValuesChange}
              layout="inline"
              className="city-update"
            >
              <Row style={{ width: "100%", marginBottom: "24px" }}>
                <Col span="5" xs={12} xl={5}>
                  <Form.Item
                    name="state"
                    rules={[
                      {
                        required: true,
                        message: "Please select state!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="State"
                      allowClear={true}
                      showSearch={true}
                      onChange={() => this.handleTerritoryChange("state")}
                    >
                      {stateList.map((x) => (
                        <Option key={x.state} value={x.state}>
                          {x.state}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span="5" xs={12} xl={5}>
                  <Form.Item name="district">
                    <Select
                      placeholder="District"
                      allowClear={true}
                      showSearch={true}
                      onFocus={() => this.handleTerritorySelect("district")}
                      onChange={() => this.handleTerritoryChange("district")}
                    >
                      {districtList.map((x) => (
                        <Option key={x.district} value={x.district}>
                          {x.district}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span="5" xs={12} xl={5}>
                  <Form.Item name="city">
                    <Select
                      placeholder="City"
                      showSearch={true}
                      allowClear={true}
                      onFocus={() => this.handleTerritorySelect("city")}
                      onChange={() => this.handleTerritoryChange("city")}
                    >
                      {cityList.map((x) => (
                        <Option key={x.city} value={x.city}>
                          {x.city}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span="5" xs={12} xl={5}>
                  <Form.Item name="area">
                    <Select
                      placeholder="Area"
                      allowClear={true}
                      showSearch={true}
                      onFocus={() => this.handleTerritorySelect("area")}
                    >
                      {areaList.map((x) => (
                        <Option key={x.area} value={x.area}>
                          {x.area}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span="4" xs={12} xl={4}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Add
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>

            <Table
              columns={columns}
              rowKey={(record) => record.city_update_id}
              dataSource={data}
              pagination={false}
              loading={loading}
              bordered
              scroll={{ x: 400 }}
              size="small"
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default CityUpdate;
