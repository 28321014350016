import React, { Component } from "react";
import { Button, Input, Select, Drawer, Checkbox, Typography } from "antd";
import { SettingOutlined, SaveOutlined } from "@ant-design/icons";
import AdminApi from "../../iam/api/admin-api";
import * as dayjs from "dayjs";

class ListFiltersDashboard extends Component {
  state = {
    filterCol: this.props.items.find((x) => x.default)?.displayName,
    filterVal: "",
    isDrawer: false,
    checkedValues: this.props.items,
    isDisabled: true,
  };

  handleSelect = (e) => {
    this.setState({ filterCol: e });
  };

  handleSearch = (e) => {
    this.setState({ filterVal: e.target.value }, () => {
      e.target.value === "" && this.handleFilters();
    });
  };

  handleFilters = () => {
    let filterCol = this.props.items.find(
      (x) => x.displayName === this.state.filterCol
    );
    if (
      filterCol.columnName === "employee_id" ||
      filterCol.columnName === "payout_approval_by_id"
    ) {
      // Find from admin
      AdminApi.queryUsers(this.state.filterVal)
        .then((res) => {
          if (res?.result?.length > 0) {
            this.props.handleFilters(
              filterCol,
              res.result.map((x) => x.id).join(",")
            );
            return;
          }
          this.props.handleFilters(filterCol, "000");
        })
        .catch((err) => {
          this.props.handleFilters(filterCol, "000");
        });
    } else {
      this.props.handleFilters(filterCol, this.state.filterVal);
    }
  };

  handleSettings = () => {
    this.setState({ isDrawer: !this.state.isDrawer });
  };

  handleCheck = (checkedValues) => {
    this.setState({ checkedValues, isDisabled: false });
  };

  updateCheck = () => {
    this.setState({ isDrawer: !this.state.isDrawer });
    let values = [];
    this.state.checkedValues.map((item) => values.push({ title: item }));
    values.push({ title: "Actions" });
    this.props.handleSettings(values);

    let { columnsSettings } = this.state;

    columnsSettings[this.props.screen] = values;

    AdminApi.syncColumsSettings({
      data: columnsSettings,
      updatedDate: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
    });
  };

  componentDidMount() {
    !window.matchMedia("(max-width: 575px)").matches && this.nameInput.focus();
    AdminApi.syncColumsSettings()
      .then((columnsSettings) => {
        this.setState({ columnsSettings: columnsSettings || {} });
      })
      .catch((nothing) => {});
  }

  render() {
    const { Search } = Input;
    const { items, screen } = this.props;
    const { columnsSettings } = this.state;

    let defaultChecked = items.map((x) => x.displayName);

    if (screen && columnsSettings) {
      let colConfig = columnsSettings[screen]?.map((x) => x.title);
      colConfig?.length > 0 && (defaultChecked = colConfig);
    }

    return (
      <div className="list-filters list-filters-dashboard">
        <Search
          placeholder={"Filter by " + items[0]?.displayName}
          onChange={this.handleSearch}
          onSearch={this.handleFilters}
          allowClear
          enterButton
          size="middle"
          ref={(input) => {
            this.nameInput = input;
          }}
        />
      </div>
    );
  }
}

export default ListFiltersDashboard;
