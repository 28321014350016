import React, { Component } from "react";
import { Row, Col, Table, Button, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import PageTitle from "../../components/common/page-title";
import { ajaxRequester } from "../../services/ajax-requester";
import ListFilters from "../../components/common/list-filters";

class InventoriesContract extends Component {
  state = {
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    selectedRowKeys: [],
    selectedRows: [],
  };

  // Get Inventories Inventories List
  getInventories = (params = {}) => {
    this.setState({ loading: true });

    let reqUrl =
      "contract/list?pageno=" +
      params.pagination.current +
      "&pagesize=" +
      params.pagination.pageSize +
      (params.filters ? params.filters : "");

    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          loading: false,
          data: data.response,
          pagination: {
            ...params.pagination,
            total: data.page_info.total_records,
          },
        });
      },
      "GET"
    );
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "Contract Name",
        dataIndex: "contract_name",
      },
      {
        title: "Owner Name",
        dataIndex: "owner_name",
      },
      {
        title: "Address",
        dataIndex: "address",
      },
      {
        title: "Phone",
        dataIndex: "phone_no",
      },
      {
        title: "Email",
        dataIndex: "email",
      },
      {
        title: "Contract Type",
        dataIndex: "contract_type",
      },
      {
        title: "Status",
        dataIndex: "is_active",
        render: (text) => (text ? "Active" : "Inactive"),
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "x",
        render: (record) => (
          <React.Fragment>
            <Popconfirm
              title="Are you sure delete?"
              placement="left"
              okText="Yes"
              cancelText="No"
              onConfirm={() => this.deleteInventory(record)}
            >
              <Button
                icon={<DeleteOutlined />}
                className="ant-btn-reset"
                style={{ marginLeft: "12px" }}
              />
            </Popconfirm>
          </React.Fragment>
        ),
      },
    ];

    this.setState({ columns, columnsDefault: columns });
  };

  // Change Pagging Sorting and Filters
  handleTableChange = (pagination, filters, sorter) => {
    this.getInventories({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };

  // Handle Global Filters
  handleFilters = (filterColumn, filterValue) => {
    let filters = "";
    const { pagination } = this.state;
    if (filterValue) {
      if (filterColumn === "Media") {
        filters = "&columnName=media_type";
      } else if (filterColumn === "Qty") {
        filters = "&columnName=quantity";
      } else if (filterColumn === "IID") {
        filters = "&columnName=inventory_code";
      } else if (filterColumn === "City") {
        filters = "&columnName=city";
      } else if (filterColumn === "Area") {
        filters = "&columnName=area";
      } else if (filterColumn === "Location") {
        filters = "&columnName=location";
      } else if (filterColumn === "Size") {
        filters = "&columnName=width";
      } else if (filterColumn === "Light") {
        filters = "&columnName=light_type";
      } else if (filterColumn === "Photo") {
        filters = "&columnName=images";
      } else if (filterColumn === "Card Rate") {
        filters = "&columnName=card_rate";
      } else if (filterColumn === "Inventory Status") {
        filters = "&columnName=booking_status";
      } else if (filterColumn === "Booking Status") {
        filters = "&columnName=booking_status";
      } else if (filterColumn === "Campaign Start Date") {
        filters = "&columnName=campaign_start_date";
      } else if (filterColumn === "Campaign End Date") {
        filters = "&columnName=campaign_end_date";
      }
      filters = filters + "&columnValue=" + filterValue;
    }
    this.getInventories({ pagination, filters });
  };

  // Handle Setting show hide columns
  handleSettings = (values) => {
    let columnsDefault = this.state.columnsDefault;
    let columns = this.state.columns;
    columns = columnsDefault.filter(({ title }) =>
      values.some((exclude) => exclude.title === title)
    );
    this.setState({ columns });
  };

  // Add Sites
  addContract = () => {
    var reqUrl = "contract/create";
    var reqObj = {
      request: {
        contract_name: "Mayur Raval",
        owner_name: "Dilip Patel",
        phone_no: "9955224455",
        email: "mayur@ims.com",
        contract_type: "P",
        start_date: "2020-01-01T00:00:00",
        end_date: "2025-01-01T00:00:00",
        created_by: "varun",
        created_date: "0001-01-01T00:00:00",
      },
    };
    ajaxRequester(reqUrl, reqObj, () => {
      const { pagination } = this.state;
      this.getInventories({ pagination });
    });
  };

  // Delete inventory List based on Id
  deleteInventory = (inventory) => {
    this.setState({ isLoading: true });
    var reqUrl = "tracking/inventory/delete";
    var reqObj = {
      request: {
        employee_id: 1,
        ha_inventory_id: [inventory.ha_inventory_id],
        created_by: "varun",
      },
    };
    ajaxRequester(reqUrl, reqObj, () => {
      const { pagination } = this.state;
      this.getInventories({ pagination });
    });
  };

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys, selectedRows });
  };

  deleteMultiInventory = () => {
    this.setState({ isLoading: true });
    var reqUrl = "tracking/inventory/delete";
    var reqObj = {
      request: {
        employee_id: 1,
        ha_inventory_id: this.state.selectedRowKeys,
        created_by: "varun",
      },
    };
    ajaxRequester(reqUrl, reqObj, () => {
      this.setState({ selectedRowKeys: [] });
      const { pagination } = this.state;
      this.getInventories({ pagination });
    });
  };

  componentDidMount() {
    // Get Inventories Tracking List
    const { pagination } = this.state;
    this.getInventories({ pagination });
    this.setColumns();
  }

  render() {
    const { data, columns, pagination, loading, selectedRowKeys } = this.state;

    const filterColumns = [
      "Contract Name",
      "Owner Name",
      "Address",
      "Phone",
      "Email",
      "Contract Type",
      "Status",
    ];

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,

      getCheckboxProps: (record) => ({
        disabled: record.is_read,
        is_read: record.is_read,
      }),
    };

    //const hasSelected = selectedRowKeys.length > 0;

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageTitle
              titleText="Contract List"
              isAddBtn={"Create Contract"}
              handleAdd={this.addContract}
            />

            <ListFilters
              items={filterColumns}
              handleSettings={this.handleSettings}
              handleFilters={this.handleFilters}
            />

            {/* <Button
              type="primary"
              onClick={this.deleteMultiInventory}
              disabled={!hasSelected}
              loading={loading}
              style={{
                marginBottom: "16px",
                float: "right",
                marginTop: "-48px",
                marginRight: "48px",
              }}
            >
              Delete
            </Button> */}

            <Table
              columns={columns}
              rowKey={(record) => record.contract_id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              bordered
              scroll={{ x: 400 }}
              size="small"
              rowSelection={rowSelection}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default InventoriesContract;
