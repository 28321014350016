import React from "react";
import { Layout } from "antd";
import { AntDesignOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import EazyLogo from "../../assets/images/EazyLogoWhite.svg";

const AppHeaderPublic = (props) => {
  const { Header } = Layout;
  const { Title } = Typography;

  return (
    <Header>
      <div className="logo">
        <Title
          level={3}
          style={{ color: "#fff", cursor: "pointer", fontSize: "0px" }}
          onClick={() => window.open("https://www.easyoutdoor.in", "_blank")}
        >
          <img src={EazyLogo} alt="EasyOutdoor" />
        </Title>
      </div>
    </Header>
  );
};

export default AppHeaderPublic;
