import React, { Component } from "react";
import { Row, Col, Table, Button, Upload, message, Popover } from "antd";
import {
  UploadOutlined,
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
  DownloadOutlined,
  ImportOutlined,
} from "@ant-design/icons";
import PageTitleDetails from "../../components/common/page-title-detail";
import XLSX from "xlsx";
import { ajaxRequester } from "../../services/ajax-requester";
import SampleExcelTA from "../../assets/samples/import-masters-sample.xlsx";

class MastersImport extends Component {
  state = {
    count: 0,
    inventoryType: this.props.match.path.includes("ta-")
      ? "tainventory"
      : "hainventory",
  };

  handleUpload = ({ file }) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = (e) => {
      const bufferArray = e.target.result;
      const wb = XLSX.read(bufferArray, { type: "buffer" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const xlsdata = XLSX.utils.sheet_to_json(ws);
      this.validateData(xlsdata);
    };
  };

  validateData = (xlsdata) => {
    let data = xlsdata;

    data = data.map((x) => ({
      ...x,
      isValid: this.checkValid(x),
    }));

    this.setState({ data, isImport: true });
  };

  handleValidate = () => {
    this.setState({ btnLoading: true });
    let data = this.state.data;

    let reqData = data.map((x) => ({
      ...x,
    }));

    var reqUrl = this.state.inventoryType + "/import/validate";
    var reqObj = { request: reqData };

    ajaxRequester(reqUrl, reqObj, (res) => {
      res &&
        res?.response.map(
          (x, key) =>
            x.Value &&
            x.Value.length > 0 &&
            ((data[key].isValid = false), (data[key].error = x.Value))
        );

      this.setState({ data, isValidate: true, btnLoading: false });
    });
  };

  handleUploadInventories = () => {
    this.setState({ btnImportLoading: true });

    let data = this.state.data;

    data = data.map((x) => ({
      ...x,
      state: x?.state && x?.state?.replace(/\s+/g, " ").trim(),
      district: x?.district && x?.district?.replace(/\s+/g, " ").trim(),
      city: x?.city && x?.city?.replace(/\s+/g, " ").trim(),
      area: x?.area && x?.area?.replace(/\s+/g, " ").trim(),
    }));

    data = data.filter((x) => x.isValid !== false);

    var reqUrl = "location/import";
    var reqObj = { request: data };

    ajaxRequester(reqUrl, reqObj, (res) => {
      res && message.success("Masters Uploaded Successfully");

      res &&
        res?.response.map(
          (x, key) =>
            x.Value &&
            x.Value.length > 0 &&
            ((data[key].isValid = false), (data[key].error = x.Value))
        );

      this.setState({
        data,
        btnLoading: false.valueOf,
        isImport: false,
        isValidate: false,
        btnImportLoading: false,
      });
    });
  };

  checkValid = (record) => {
    let isValid = true;

    if (!record.state || !record.district || !record.city || !record.area) {
      isValid = false;
    }

    return isValid;
  };

  // Redirect to List
  handleBack = () => {
    this.props.history.goBack();
  };

  render() {
    const {
      data,
      selectedRowKeys,
      btnLoading,
      btnImportLoading,
      isImport,
      isValidate,
    } = this.state;

    const columns = [
      {
        title: "State",
        dataIndex: "state",
      },
      {
        title: "District",
        dataIndex: "district",
      },
      {
        title: "City",
        dataIndex: "city",
      },
      {
        title: "Area",
        dataIndex: "area",
      },
      {
        title: "Status",
        dataIndex: "isValid",
        render: (text, record) =>
          text ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : (
            <Popover
              content={
                (record?.error &&
                  record?.error.length > 0 &&
                  record?.error.map((x) => {
                    return <div>{x}</div>;
                  })) ||
                "Required values can not be empty."
              }
              trigger="click"
              title="Error"
              placement="topRight"
            >
              <ExclamationCircleTwoTone twoToneColor="#ff4d4f" />
            </Popover>
          ),
      },
    ];

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageTitleDetails
              titleText={"Import Masters"}
              isBackBtn="true"
              handleBack={this.handleBack}
            />

            <Button
              type="primary"
              style={{
                top: "-62px",
                float: "right",
                marginBottom: "-62px",
                position: "relative",
              }}
              onClick={() => window.open(SampleExcelTA)}
              icon={<DownloadOutlined />}
              className="icon-btn"
            >
              Download Sample
            </Button>

            <div style={{ display: "flex", marginBottom: "16px" }}>
              <Upload customRequest={this.handleUpload} fileList={[]}>
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>

              {/* {isImport && (
                <Button
                  type="primary"
                  onClick={this.handleValidate}
                  style={{ marginLeft: "16px" }}
                  loading={btnLoading}
                >
                  Validate
                </Button>
              )} */}

              {isImport && (
                <Button
                  type="primary"
                  onClick={this.handleUploadInventories}
                  style={{ marginLeft: "16px" }}
                  loading={btnImportLoading}
                  icon={<ImportOutlined />}
                  className="icon-btn"
                >
                  Import Masters
                </Button>
              )}
            </div>

            <Table
              columns={columns}
              dataSource={data}
              bordered
              scroll={{ x: 400 }}
              size="small"
              pagination={false}
              rowClassName={(record) => !record.isValid && "imp-inv-error"}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default MastersImport;
