import imageCompression from 'browser-image-compression';
import pica from "pica";
import piexif from "piexifjs";
import Jimp from "jimp/es";
import Semaphore from "./semaphore";

const semaphore = new Semaphore(4);

export const getImageDimensions = (url) => {
    return new Promise(((resolve, reject) => {
        let image = new Image();
        image.onload = function () {
            resolve({
                url: url,
                width: this.naturalWidth,
                height: this.naturalHeight
            });
            image.onload = null;
            image.src = null;
        };
        image.src = url;
    }));
};

export const getImageBlobData = (name, url) => {
    return new Promise((resolve, reject) => {
        fetch(url).then(x => x.blob()).then((x) => resolve({
            name: name,
            data: x
        })).catch(reject);
    })
}

const blobToBase64 = (blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
        let woExifData = piexif.remove(reader.result);
        resolve(woExifData);
    }
    reader.onerror = error => reject(error);
});

const compress = function (data) {
    return new Promise((resolve, reject) => {
        imageCompression(data, {
            useWebWorker: true,
            maxWidthOrHeight: 1100,
            maxSizeMB: 0.3,
            alwaysKeepResolution: true,
            maxIteration: 80,
        }).then(resolve).catch(reject);
    });
}

const resizeImage3 = function (url) {
    return new Promise((resolve, reject) => {
        getImageBlobData('', url)
            .then(imageData => {
                let imageUrl = URL.createObjectURL(imageData.data);
                getImageDimensions(imageUrl)
                    .then(imageSize => {

                        let height = imageSize.height < 920 ? imageSize.height : 920;
                        let width = imageSize.height < 920 ? imageSize.width : Math.round(imageSize.width * (height / imageSize.height));

                        let canvas = document.createElement("canvas");
                        canvas.width = width;
                        canvas.height = height;

                        let image = new Image();
                        image.onload = function () {
                            pica()
                                .resize(image, canvas, {unsharpAmount: 160, unsharpThreshold: 1})
                                .then(result => pica().toBlob(result, "image/jpeg", 0.5))
                                .then(blob => blobToBase64(blob))
                                .then(base64Data => {
                                    resolve({
                                        url: url,
                                        width: width,
                                        height: height,
                                        data: base64Data
                                    })
                                })
                                .finally(() => {
                                    URL.revokeObjectURL(imageUrl);
                                    image.onload = null;
                                    image.src = null;
                                })
                        }
                        image.src = imageUrl;

                    }).catch(reject);
            }).catch(reject);
    })
}

const resizeImage4 = function (url) {
    let localUrl = ((url.indexOf("relay") != -1 && url.indexOf("resize") == -1) ? `${url}&resizeHeight=920&quality=50` : url);
    return new Promise((resolve, reject) => {
        semaphore.acquire().then(() => {
            getImageBlobData('', localUrl)
                .then(imageData => {
                    let imageUrl = URL.createObjectURL(imageData.data);
                    getImageDimensions(imageUrl)
                        .then(imageSize => {
                            blobToBase64(imageData.data)
                                .then(base64Data => {
                                    resolve({
                                        url: url,
                                        width: imageSize.width,
                                        height: imageSize.height,
                                        data: base64Data
                                    })
                                }).catch(reject);
                        }).catch(reject);
                }).catch(reject)
                .finally(()=> semaphore.release());
        }).catch(()=>{
            semaphore.release();
        })
    })
}

const resizeImage = function (originalUrl) {
    return new Promise((resolve, reject) => {
        getImageBlobData('', originalUrl)
            .then(imageData => compress(imageData.data))
            .then(imageBlobData => {
                    let smallImageUrl = URL.createObjectURL(imageBlobData);
                    return getImageDimensions(smallImageUrl);
                }
            )
            .then(smallImageSize => {

                let {url, width, height} = smallImageSize;

                let canvas = document.createElement('canvas');
                canvas.width = width;
                canvas.height = height;

                let image = new Image();
                image.onload = function () {
                    pica()
                        .resize(image, canvas, {
                            unsharpAmount: 100,
                            unsharpRadius: 0.5
                        })
                        .then(result => pica().toBlob(result, 'image/jpeg', 0.6))
                        .then(blob => blobToBase64(blob))
                        .then(base64Data => {
                            resolve({
                                url: originalUrl,
                                width: width,
                                height: height,
                                data: base64Data
                            })
                        })
                        .finally(() => {
                            URL.revokeObjectURL(url);
                            image.onload = null;
                            image.src = null;
                        });
                }
                image.src = url;
            }).catch(reject);
    });
}

const resizeImage2 = function (url) {
    return new Promise((resolve, reject) => {
        fetch(url)
            .then(result => result.arrayBuffer())
            .then(buffer => Jimp.read(buffer))
            .then(data => data.quality(50).resize(Jimp.AUTO, 920).normalize())
            .then(data => {
                let width = data.getWidth();
                let height = data.getHeight();
                data.getBase64Async(Jimp.MIME_JPEG).then(base64Image => {
                    let output = {
                        url: url,
                        width: width,
                        height: height,
                        data: base64Image
                    };
                    resolve(output);
                }).catch(reject);
            }).catch(reject);
    });
}

export const getImageBlobDataWithDimension = (url, isCompress = false) => {
    let toggle = 4;
    if (toggle === 1) {
        return resizeImage(url)
    }
    if (toggle === 2) {
        return resizeImage2(url);
    }
    if (toggle === 3) {
        return resizeImage3(url);
    }
    if (toggle === 4) {
        return resizeImage4(url);
    }
    // 1. Create promise
    return new Promise((resolve, reject) => {

        // 2. Get image blob data through fetch
        getImageBlobData('', url).then((result => {

            // 3. Create object url
            let dataUrl = URL.createObjectURL(result.data);

            // 4. Load image
            let image = new Image();

            image.onload = function () {
                let self = this;
                // 5. Convert blob to base64
                blobToBase64(result.data)
                    .then((base64Data) => {
                        resolve({
                            url: url,
                            width: self.naturalWidth,
                            height: self.naturalHeight,
                            data: base64Data
                        })
                    }).catch(reject)
                    .finally(() => {
                        image.onload = null;
                        image.src = null;
                    });
                URL.revokeObjectURL(dataUrl);
            }
            image.src = dataUrl;
        })).catch(reject);
    });

}

export const getImageHeight = (width, image) => {
    return (image.height * width) / image.width;
}

export const getImageWidth = (height, image) => {
    return (image.width * height) / image.height;
}
