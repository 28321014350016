import React, { Component } from "react";
import { InputNumber } from "antd";

class EstimatePayeeListInput extends Component {
  state = {
    value: this.props.value,
    defaultValue: this.props.value,
    column: this.props.column,
    isDateDisabled: false,
  };

  onFinish = () => {
    this.props.handlePayeeInput(
      this.props.column === "payee_annual_amount"
        ? parseInt(this.state.value)
        : Math.round(this.state.value * 100) / 100,
      this.props.record,
      this.props.column
    );
  };

  onChange = (value) => {
    this.setState({ value });
  };

  render() {
    const { value, defaultValue } = this.state;

    return (
      <InputNumber
        name="input"
        value={value || defaultValue || 0}
        onChange={this.onChange}
        onBlur={this.onFinish}
      />
    );
  }
}

export default EstimatePayeeListInput;
