import React, { Component } from "react";
import { Radio } from "antd";
import { ajaxRequester } from "../../services/ajax-requester";

class OperationTabs extends Component {
  state = { options: [], value: "", count: [] };

  onChange = (e) => {
    this.setState(
      {
        value: e.target.value,
      },
      () => this.props.handleTabEvent(e.target.value)
    );
  };

  getCount = () => {
    let reqUrl = "operation/flexstockwisecount";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        const options = [
          {
            label: "Stock on Hand (" + data?.response?.stockonhand + ")",
            value: "stockonhand",
          },
          {
            label: "Stolen / Torn (" + data?.response?.stolentorn + ")",
            value: "stolentornflex",
          },
          {
            label: "Sold Items (" + data?.response?.solditems + ")",
            value: "solditems",
          },
          {
            label: "Returned to Clients (" + data?.response?.returned + ")",
            value: "returneditems",
          },
        ];

        this.setState({ options, value: "stockonhand" });
      },
      "GET"
    );
  };

  componentDidMount() {
    this.getCount();
  }

  render() {
    const { options, value, count } = this.state;

    return (
      <div className="operation-tabs">
        <Radio.Group
          options={options}
          onChange={this.onChange}
          value={options.find((x) => x.value === value) ? value : ""}
          optionType="button"
          buttonStyle="solid"
          //loading={true}
        />
      </div>
    );
  }
}

export default OperationTabs;
