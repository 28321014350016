import { getImageRelayUrl, getRelayUrl } from "../../services/ajax-requester";
import pptxgen from "pptxgenjs";
import {
  getImageBlobDataWithDimension,
  getImageDimensions,
  getImageHeight,
  getImageWidth,
} from "../../helpers/image-helper";
import { dateFormatter } from "../../components/common/common-formatter";

const getEmployeeDetails = (employee_id, employee_phone) => {
  let employeeDetails = localStorage.getItem("user:" + employee_id) || '""';
  employeeDetails = JSON.parse(employeeDetails);
  let employeeName =
    employeeDetails?.data?.firstName + " " + employeeDetails?.data?.lastName;
  let employeeNumber = employeeDetails.data?.phoneNumber;
  return !employee_phone ? employeeName : employeeNumber;
};

const resizeHeight = 920;
const quality = 50;

export const exportCampaignToPPTx = (
  reportName,
  inventory_details,
  details,
  configuration,
  settings,
  photoType,
  callBack
) => {
  let inventories = inventory_details || [];

  const bannerLogo =
    configuration?.configuration_details?.documents?.logo?.at(-1)?.data_url ||
    "";

  const website = configuration?.configuration_details?.website || "";

  let imageTypes = photoType;
  let imageMode = imageTypes?.length;

  if (reportName === "Mounting Report") {
    inventories = inventories.map((x) => ({
      ...x,
      ha_images: x?.mounting_photos,
    }));
  }

  if (reportName === "Monitoring Report") {
    inventories = inventories.map((x) => ({
      ...x,
      ha_images: x?.monitoring_photos,
    }));
  }

  if (reportName === "Unmounting Report") {
    inventories = inventories.map((x) => ({
      ...x,
      ha_images: x?.unmounting_photos,
    }));
  }

  inventories = inventories.map((x) => ({
    ...x,
    ha_images: x?.ha_images?.sort((a, b) => b?.is_primary - a?.is_primary),
  }));

  // Fetch all URLs
  let urls = [];
  inventories
    .filter((inventory) => inventory?.ha_images?.length > 0)
    .forEach((inventory) => {
      if (imageMode === 1) {
        if (
          inventory?.ha_images.find((x) => x?.photo_category === imageTypes[0])
        ) {
          urls.push(
            getImageRelayUrl(
              inventory?.ha_images.find(
                (x) => x?.photo_category === imageTypes[0]
              )?.data_url,
              null,
              resizeHeight,
              quality,
              true
            )
          );
        }
      }

      if (imageMode === 2) {
        if (
          inventory?.ha_images.find((x) => x?.photo_category === imageTypes[0])
        ) {
          urls.push(
            getImageRelayUrl(
              inventory?.ha_images.find(
                (x) => x?.photo_category === imageTypes[0]
              )?.data_url,
              null,
              resizeHeight,
              quality,
              true
            )
          );
        }
        if (
          inventory?.ha_images.find((x) => x?.photo_category === imageTypes[1])
        ) {
          urls.push(
            getImageRelayUrl(
              inventory?.ha_images.find(
                (x) => x?.photo_category === imageTypes[1]
              )?.data_url,
              null,
              resizeHeight,
              quality,
              true
            )
          );
        }
      }

      if (imageMode === 3) {
        if (
          inventory?.ha_images.find((x) => x?.photo_category === imageTypes[0])
        ) {
          urls.push(
            getImageRelayUrl(
              inventory?.ha_images.find(
                (x) => x?.photo_category === imageTypes[0]
              )?.data_url,
              null,
              resizeHeight,
              quality,
              true
            )
          );
        }
        if (
          inventory?.ha_images.find((x) => x?.photo_category === imageTypes[1])
        ) {
          urls.push(
            getImageRelayUrl(
              inventory?.ha_images.find(
                (x) => x?.photo_category === imageTypes[1]
              )?.data_url,
              null,
              resizeHeight,
              quality,
              true
            )
          );
        }
        if (
          inventory?.ha_images.find((x) => x?.photo_category === imageTypes[2])
        ) {
          urls.push(
            getImageRelayUrl(
              inventory?.ha_images.find(
                (x) => x?.photo_category === imageTypes[2]
              )?.data_url,
              null,
              resizeHeight,
              quality,
              true
            )
          );
        }
      }
    });

  let imagePromises = urls.map((x) => getImageBlobDataWithDimension(x, true));
  Promise.all(imagePromises)
    .then((results) => {
      let imageIndex = [];
      results.forEach((x) => {
        imageIndex.push(x);
      });

      let pres = new pptxgen();
      pres.layout = "LAYOUT_4x3";

      // Introduction Slide
      let introSlide = pres.addSlide();

      introSlide.addShape(pres.ShapeType.rect, {
        x: 0,
        y: 0,
        w: 0.07,
        h: "100%",
        fill: "#3782C3",
      });

      if (bannerLogo) {
        // Adding Logo
        introSlide.addImage({
          path: getRelayUrl(bannerLogo),
          x: 0.5,
          y: 1,
          w: 5,
          h: 1.5,
          sizing: { type: "contain", w: 5, h: 1.5 },
        });
      }

      introSlide.addShape(pres.shapes.LINE, {
        x: 0.5,
        y: 3,
        w: 9.0,
        h: 0.0,
        line: { width: 1 },
      });

      if (localStorage.getItem("user:" + details?.employee_id)) {
        introSlide.addText(
          (details?.employee_id && getEmployeeDetails(details?.employee_id)) ||
            "Employee Name",
          {
            x: 0.5,
            y: 3.25,
            w: 5,
            h: 0.4,
            fontSize: 16,
            fontFace: "Calibri",
          }
        );

        introSlide.addText(
          (details?.employee_id &&
            getEmployeeDetails(details?.employee_id, "phone")) ||
            "Employee Number",
          {
            x: 0.5,
            y: 3.65,
            w: 5,
            h: 0.4,
            fontSize: 16,
            fontFace: "Calibri",
          }
        );
      }

      introSlide.addText(dateFormatter(new Date()), {
        x: 5.5,
        y: 3.25,
        w: 4,
        h: 0.4,
        fontSize: 18,
        bold: true,
        fontFace: "Calibri",
        align: "right",
      });

      if (website) {
        introSlide.addText(website, {
          x: 5.5,
          y: 6.75,
          w: 4,
          h: 0.4,
          fontSize: 15,
          fontFace: "Calibri",
          align: "right",
          hyperlink: {
            url: "http://" + website,
            tooltip: website,
          },
        });
      }

      introSlide.addText(reportName + " - " + details?.display_name, {
        x: 0.5,
        y: 4.15,
        w: 5,
        h: 0.4,
        fontSize: 16,
        fontFace: "Calibri",
      });

      // Inventories Slides
      inventories
        .filter((inventory) => inventory.ha_images?.length > 0)
        .forEach((inventory, index) => {
          let inventorySlide1;
          let inventorySlide2;
          let inventorySlide3;

          let photoType1 = "";
          let photoType2 = "";
          let photoType3 = "";

          let image = "";
          let imageWidth = 9.5;
          let imageHeight = 5.25;

          let image1 = "";
          let imageWidth1 = 9.5;
          let imageHeight1 = 5.25;

          let image2 = "";
          let imageWidth2 = 9.5;
          let imageHeight2 = 5.25;

          let image3 = "";
          let imageWidth3 = 9.5;
          let imageHeight3 = 5.25;

          switch (imageMode) {
            case 1:
              if (
                inventory.ha_images.find(
                  (x) => x?.photo_category === imageTypes[0]
                )
              ) {
                image1 = imageIndex.find(
                  (x) =>
                    x.url ===
                    getImageRelayUrl(
                      inventory.ha_images.find(
                        (x) => x?.photo_category === imageTypes[0]
                      )?.data_url,
                      null,
                      resizeHeight,
                      quality,
                      true
                    )
                );
              }

              if (image1?.height >= image1?.width) {
                imageWidth1 = getImageWidth(imageHeight1, image1);
              }
              if (image1?.width >= image1?.height) {
                imageHeight1 = getImageHeight(imageWidth1, image1);
              }
              break;

            case 2:
              if (
                inventory.ha_images.find(
                  (x) => x?.photo_category === imageTypes[0]
                )
              ) {
                image1 = imageIndex.find(
                  (x) =>
                    x.url ===
                    getImageRelayUrl(
                      inventory.ha_images.find(
                        (x) => x?.photo_category === imageTypes[0]
                      )?.data_url,
                      null,
                      resizeHeight,
                      quality,
                      true
                    )
                );
              }

              if (image1?.height >= image1?.width) {
                imageWidth1 = getImageWidth(imageHeight1, image1);
              }
              if (image1?.width >= image1?.height) {
                imageHeight1 = getImageHeight(imageWidth1, image1);
              }

              if (
                inventory.ha_images.find(
                  (x) => x?.photo_category === imageTypes[1]
                )
              ) {
                image2 = imageIndex.find(
                  (x) =>
                    x.url ===
                    getImageRelayUrl(
                      inventory.ha_images.find(
                        (x) => x?.photo_category === imageTypes[1]
                      )?.data_url,
                      null,
                      resizeHeight,
                      quality,
                      true
                    )
                );
              }

              if (image2?.height >= image2?.width) {
                imageWidth2 = getImageWidth(imageHeight2, image2);
              }
              if (image2?.width >= image2?.height) {
                imageHeight2 = getImageHeight(imageWidth2, image2);
              }
              break;

            case 3:
              if (
                inventory.ha_images.find(
                  (x) => x?.photo_category === imageTypes[0]
                )
              ) {
                image1 = imageIndex.find(
                  (x) =>
                    x.url ===
                    getImageRelayUrl(
                      inventory.ha_images.find(
                        (x) => x?.photo_category === imageTypes[0]
                      )?.data_url,
                      null,
                      resizeHeight,
                      quality,
                      true
                    )
                );
              }

              if (image1?.height >= image1?.width) {
                imageWidth1 = getImageWidth(imageHeight1, image1);
              }
              if (image1?.width >= image1?.height) {
                imageHeight1 = getImageHeight(imageWidth1, image1);
              }

              if (
                inventory.ha_images.find(
                  (x) => x?.photo_category === imageTypes[1]
                )
              ) {
                image2 = imageIndex.find(
                  (x) =>
                    x.url ===
                    getImageRelayUrl(
                      inventory.ha_images.find(
                        (x) => x?.photo_category === imageTypes[1]
                      )?.data_url,
                      null,
                      resizeHeight,
                      quality,
                      true
                    )
                );
              }

              if (image2?.height >= image2?.width) {
                imageWidth2 = getImageWidth(imageHeight2, image2);
              }
              if (image2?.width >= image2?.height) {
                imageHeight2 = getImageHeight(imageWidth2, image2);
              }

              if (
                inventory.ha_images.find(
                  (x) => x?.photo_category === imageTypes[2]
                )
              ) {
                image3 = imageIndex.find(
                  (x) =>
                    x.url ===
                    getImageRelayUrl(
                      inventory.ha_images.find(
                        (x) => x?.photo_category === imageTypes[2]
                      )?.data_url,
                      null,
                      resizeHeight,
                      quality,
                      true
                    )
                );
              }

              if (image3?.height >= image3?.width) {
                imageWidth3 = getImageWidth(imageHeight3, image3);
              }
              if (image3?.width >= image3?.height) {
                imageHeight3 = getImageHeight(imageWidth3, image3);
              }
              break;

            default:
              break;
          }

          if (
            inventory.ha_images.find((x) => x?.photo_category === imageTypes[0])
          ) {
            image = imageIndex.find(
              (x) =>
                x.url ===
                getImageRelayUrl(
                  inventory.ha_images.find(
                    (x) => x?.photo_category === imageTypes[0]
                  )?.data_url,
                  null,
                  resizeHeight,
                  quality,
                  true
                )
            );
          }

          if (image?.height >= image?.width) {
            imageWidth = getImageWidth(imageHeight, image);
          }
          if (image?.width >= image?.height) {
            imageHeight = getImageHeight(imageWidth, image);
          }

          let imageOptions = {};
          switch (imageMode) {
            case 1:
              if (
                inventory.ha_images.find(
                  (x) => x?.photo_category === imageTypes[0]
                )
              ) {
                imageOptions = {
                  x: 0.25,
                  y: 0.25,
                  w: imageWidth1,
                  h: imageHeight1,
                  sizing: {
                    type: "contain",
                    w: 9.5,
                    h: 5.25,
                  },
                };
                if (image1?.data ?? null) {
                  imageOptions.data = image1.data;
                } else if (image1?.url) {
                  imageOptions.path = image1.url;
                } else {
                  imageOptions.path = getImageRelayUrl(
                    inventory.ha_images.find(
                      (x) => x?.photo_category === imageTypes[0]
                    )?.data_url,
                    null,
                    resizeHeight,
                    quality,
                    true
                  );
                }
                inventorySlide1 = pres.addSlide();
                inventorySlide1.addImage(imageOptions);
              }
              photoType1 =
                imageTypes[0] === "N"
                  ? "Newspaper"
                  : imageTypes[0] === "L"
                  ? "Long Shot"
                  : imageTypes[0] === "S"
                  ? "Short Shot"
                  : "";
              break;

            case 2:
              if (
                inventory.ha_images.find(
                  (x) => x?.photo_category === imageTypes[0]
                )
              ) {
                imageOptions = {
                  x: 0.25,
                  y: 0.25,
                  w: imageWidth1,
                  h: imageHeight1,
                  sizing: {
                    type: "contain",
                    w: 9.5,
                    h: 5.25,
                  },
                };
                if (image1?.data ?? null) {
                  imageOptions.data = image1.data;
                } else if (image?.url) {
                  imageOptions.path = image1.url;
                } else {
                  imageOptions.path = getImageRelayUrl(
                    inventory.ha_images.find(
                      (x) => x?.photo_category === imageTypes[0]
                    )?.data_url,
                    null,
                    resizeHeight,
                    quality,
                    true
                  );
                }
                inventorySlide1 = pres.addSlide();
                inventorySlide1.addImage(imageOptions);
              }

              if (
                inventory.ha_images.find(
                  (x) => x?.photo_category === imageTypes[1]
                )
              ) {
                imageOptions = {
                  x: 0.25,
                  y: 0.25,
                  w: imageWidth2,
                  h: imageHeight2,
                  sizing: {
                    type: "contain",
                    w: 9.5,
                    h: 5.25,
                  },
                };
                if (image2?.data ?? null) {
                  imageOptions.data = image2.data;
                } else if (image2?.url) {
                  imageOptions.path = image2.url;
                } else {
                  imageOptions.path = getImageRelayUrl(
                    inventory.ha_images.find(
                      (x) => x?.photo_category === imageTypes[1]
                    )?.data_url,
                    null,
                    resizeHeight,
                    quality,
                    true
                  );
                }
                inventorySlide2 = pres.addSlide();
                inventorySlide2.addImage(imageOptions);
              }

              photoType1 =
                imageTypes[0] === "N"
                  ? "Newspaper"
                  : imageTypes[0] === "L"
                  ? "Long Shot"
                  : imageTypes[0] === "S"
                  ? "Short Shot"
                  : "";

              photoType2 =
                imageTypes[1] === "N"
                  ? "Newspaper"
                  : imageTypes[1] === "L"
                  ? "Long Shot"
                  : imageTypes[1] === "S"
                  ? "Short Shot"
                  : "";
              break;

            case 3:
              if (
                inventory.ha_images.find(
                  (x) => x?.photo_category === imageTypes[0]
                )
              ) {
                imageOptions = {
                  x: 0.25,
                  y: 0.25,
                  w: imageWidth1,
                  h: imageHeight1,
                  sizing: {
                    type: "contain",
                    w: 9.5,
                    h: 5.25,
                  },
                };
                if (image1?.data ?? null) {
                  imageOptions.data = image1.data;
                } else if (image1?.url) {
                  imageOptions.path = image1.url;
                } else {
                  imageOptions.path = getImageRelayUrl(
                    inventory.ha_images.find(
                      (x) => x?.photo_category === imageTypes[0]
                    )?.data_url,
                    null,
                    resizeHeight,
                    quality,
                    true
                  );
                }
                inventorySlide1 = pres.addSlide();
                inventorySlide1.addImage(imageOptions);
              }

              if (
                inventory.ha_images.find(
                  (x) => x?.photo_category === imageTypes[1]
                )
              ) {
                imageOptions = {
                  x: 0.25,
                  y: 0.25,
                  w: imageWidth2,
                  h: imageHeight2,
                  sizing: {
                    type: "contain",
                    w: 9.5,
                    h: 5.25,
                  },
                };
                if (image2?.data ?? null) {
                  imageOptions.data = image2.data;
                } else if (image2?.url) {
                  imageOptions.path = image2.url;
                } else {
                  imageOptions.path = getImageRelayUrl(
                    inventory.ha_images.find(
                      (x) => x?.photo_category === imageTypes[1]
                    )?.data_url,
                    null,
                    resizeHeight,
                    quality,
                    true
                  );
                }
                inventorySlide2 = pres.addSlide();
                inventorySlide2.addImage(imageOptions);
              }

              if (
                inventory.ha_images.find(
                  (x) => x?.photo_category === imageTypes[2]
                )
              ) {
                imageOptions = {
                  x: 0.25,
                  y: 0.25,
                  w: imageWidth3,
                  h: imageHeight3,
                  sizing: {
                    type: "contain",
                    w: 9.5,
                    h: 5.25,
                  },
                };
                if (image3?.data ?? null) {
                  imageOptions.data = image3.data;
                } else if (image3?.url) {
                  imageOptions.path = image3.url;
                } else {
                  imageOptions.path = getImageRelayUrl(
                    inventory.ha_images.find(
                      (x) => x?.photo_category === imageTypes[2]
                    )?.data_url,
                    null,
                    resizeHeight,
                    quality,
                    true
                  );
                }
                inventorySlide3 = pres.addSlide();
                inventorySlide3.addImage(imageOptions);
              }

              photoType1 =
                imageTypes[0] === "N"
                  ? "Newspaper"
                  : imageTypes[0] === "L"
                  ? "Long Shot"
                  : imageTypes[0] === "S"
                  ? "Short Shot"
                  : "";

              photoType2 =
                imageTypes[1] === "N"
                  ? "Newspaper"
                  : imageTypes[1] === "L"
                  ? "Long Shot"
                  : imageTypes[1] === "S"
                  ? "Short Shot"
                  : "";

              photoType3 =
                imageTypes[2] === "N"
                  ? "Newspaper"
                  : imageTypes[2] === "L"
                  ? "Long Shot"
                  : imageTypes[2] === "S"
                  ? "Short Shot"
                  : "";
              break;

            default:
              break;
          }

          let c1 = {
            text:
              inventory.sr +
              ") " +
              inventory.inventory_code +
              " - " +
              inventory.city +
              ", " +
              inventory.location +
              " - " +
              inventory.size,
            options: {
              bold: true,
              align: "center",
              colspan: 2,
              fill: "#dddddd",
            },
          };

          let c2 = [
            { text: "Media Type : ", options: { bold: true } },
            { text: inventory.media_type },
          ];

          let c3 = [
            { text: "Lights : ", options: { bold: true } },
            { text: inventory.light_type },
          ];

          let c4 = [];

          let c5 = [
            { text: "Photo Type : ", options: { bold: true } },
            { text: photoType1 },
          ];

          let c6 = [
            { text: "Photo Type : ", options: { bold: true } },
            { text: photoType2 },
          ];

          let c7 = [
            { text: "Photo Type : ", options: { bold: true } },
            { text: photoType3 },
          ];

          if (reportName === "Mounting Report") {
            c4 = [
              {
                text: "Mounting Date : ",
                options: { bold: true },
              },
              { text: dateFormatter(inventory?.mounting_date) },
            ];
          }

          if (reportName === "Monitoring Report") {
            c4 = [
              {
                text: "Monitoring Performed On : ",
                options: { bold: true },
              },
              { text: dateFormatter(inventory?.monitoring_performed_date) },
            ];
          }

          if (reportName === "Unmounting Report") {
            c4 = [
              {
                text: "Unmounting Photo Date : ",
                options: { bold: true },
              },
              {
                text: dateFormatter(inventory?.unmounting_photo_performed_date),
              },
            ];
          }

          // let row1 = [c1];
          // let row2 = [{ text: c2 }, { text: c3 }];
          // let row3 = [{ text: c4 }, { text: "" }];

          // let table = [];
          // table.push(row1);
          // table.push(row2);
          // table.push(row3);

          switch (imageMode) {
            case 1:
              inventorySlide1 &&
                inventorySlide1.addTable(
                  [
                    [c1],
                    [{ text: c2 }, { text: c3 }],
                    [{ text: c4 }, { text: c5 }],
                  ],
                  {
                    x: 0.25,
                    y: 5.75,
                    w: 9.5,
                    rowH: [0.6, 0.4, 0.4],
                    valign: "middle",
                    fontFace: "Calibri",
                    fontSize: 16,
                  }
                );
              break;

            case 2:
              inventorySlide1 &&
                inventorySlide1.addTable(
                  [
                    [c1],
                    [{ text: c2 }, { text: c3 }],
                    [{ text: c4 }, { text: c5 }],
                  ],
                  {
                    x: 0.25,
                    y: 5.75,
                    w: 9.5,
                    rowH: [0.6, 0.4, 0.4],
                    valign: "middle",
                    fontFace: "Calibri",
                    fontSize: 16,
                  }
                );

              inventorySlide2 &&
                inventorySlide2.addTable(
                  [
                    [c1],
                    [{ text: c2 }, { text: c3 }],
                    [{ text: c4 }, { text: c6 }],
                  ],
                  {
                    x: 0.25,
                    y: 5.75,
                    w: 9.5,
                    rowH: [0.6, 0.4, 0.4],
                    valign: "middle",
                    fontFace: "Calibri",
                    fontSize: 16,
                  }
                );
              break;

            case 3:
              inventorySlide1 &&
                inventorySlide1.addTable(
                  [
                    [c1],
                    [{ text: c2 }, { text: c3 }],
                    [{ text: c4 }, { text: c5 }],
                  ],
                  {
                    x: 0.25,
                    y: 5.75,
                    w: 9.5,
                    rowH: [0.6, 0.4, 0.4],
                    valign: "middle",
                    fontFace: "Calibri",
                    fontSize: 16,
                  }
                );

              inventorySlide2 &&
                inventorySlide2.addTable(
                  [
                    [c1],
                    [{ text: c2 }, { text: c3 }],
                    [{ text: c4 }, { text: c6 }],
                  ],
                  {
                    x: 0.25,
                    y: 5.75,
                    w: 9.5,
                    rowH: [0.6, 0.4, 0.4],
                    valign: "middle",
                    fontFace: "Calibri",
                    fontSize: 16,
                  }
                );

              inventorySlide3 &&
                inventorySlide3.addTable(
                  [
                    [c1],
                    [{ text: c2 }, { text: c3 }],
                    [{ text: c4 }, { text: c7 }],
                  ],
                  {
                    x: 0.25,
                    y: 5.75,
                    w: 9.5,
                    rowH: [0.6, 0.4, 0.4],
                    valign: "middle",
                    fontFace: "Calibri",
                    fontSize: 16,
                  }
                );
              break;

            default:
              break;
          }
        });

      pres
        .writeFile({
          fileName: configuration.exportName ?? "presentation.pptx",
          compression: true,
        })
        .then(() => callBack(true))
        .catch(() => callBack(false));
    })
    .catch((e) => {
      console.log(e);
    });
};
