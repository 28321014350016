import React, { Component } from "react";
import { Row, Col, Table, Typography, Button } from "antd";
import { ajaxRequester } from "../../services/ajax-requester";
import { FileImageOutlined } from "@ant-design/icons";
import PhotoGallery from "../../components/common/photo-gallery";

class TransactionsSiteList extends Component {
  state = {
    data: [],
    loading: false,
    columns: [],
    screen: "trans-site-list",
  };

  getResults = () => {
    const { pending_estimate_id } = this.props;
    let reqUrl =
      "scheduledtransaction/mappedsites?pendingEstimateId=" +
      pending_estimate_id;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ data: data.response });
      },
      "GET"
    );
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "Site Description",
        dataIndex: "inventory_code",
        render: (text, record) =>
          record?.inventory_code +
          " - " +
          record?.city +
          " - " +
          record?.location +
          " - " +
          record?.size,
      },
      {
        title: "Current Display",
        dataIndex: "display_name",
        render: (text, record) => (
          <Button
            className="ant-btn-reset"
            type="link"
            onClick={() => this.statusInventory(record, "booking")}
            style={{ marginRight: "8px" }}
          >
            {text}
          </Button>
        ),
      },
      {
        title: "Occupancy %",
        dataIndex: "occupancy_per",
      },
      {
        title: "Photo",
        dataIndex: "images",
        render: (text, record) => (
          <Button
            className="ant-btn-reset"
            disabled={
              record?.monitoring_photos?.length > 0 ||
              record?.mounting_photos?.length > 0
                ? false
                : true
            }
            icon={<FileImageOutlined />}
            onClick={() => this.handlePhotos(record)}
          />
        ),
      },
    ];

    this.setState({ columns });
  };

  // Redirect to inventory status screen
  statusInventory = (inventory, type) => {
    let inventoryTitle =
      inventory.inventory_code +
      " - " +
      inventory.city +
      ", " +
      inventory.location +
      " - " +
      inventory.width +
      "x" +
      inventory.height;

    if (type === "booking") {
      window.open(
        `/#/inventories/${
          type === "booking" ? "inventory-booking-status" : "inventory-status"
        }/${inventory.ha_inventory_id}/${inventoryTitle}`,
        "_blank"
      );
    } else {
      this.props.history.push(
        `/inventories/${
          type === "booking" ? "inventory-booking-status" : "inventory-status"
        }/${inventory.ha_inventory_id}/${inventoryTitle}`
      );
    }
  };

  // Handle Photos SlideShoe
  handlePhotos = (record) => {
    this.setState({
      isShowPhotos: !this.state.isShowPhotos,
      photos: record
        ? record?.monitoring_photos || record?.mounting_photos
        : "",
      photosTitle: record ? record : "",
    });
  };

  componentDidMount() {
    this.getResults();
    this.setColumns();
  }

  render() {
    const { data, columns, loading, isShowPhotos, photos, photosTitle } =
      this.state;
    const { Title } = Typography;
    return (
      data &&
      data.length > 0 && (
        <React.Fragment>
          <Row style={{ marginTop: "24px" }}>
            <Col span={24}>
              <Title level={5} style={{ marginBottom: "16px" }}>
                Site Details
              </Title>

              <Table
                columns={columns}
                rowKey={(record) => record?.monitoring_photos}
                dataSource={data}
                pagination={false}
                loading={loading}
                bordered
                scroll={{ x: 400 }}
                size="small"
              />
            </Col>
          </Row>

          {isShowPhotos && (
            <PhotoGallery
              photos={photos}
              photosTitle={photosTitle}
              handlePhotos={this.handlePhotos}
            />
          )}
        </React.Fragment>
      )
    );
  }
}

export default TransactionsSiteList;
