import {useState} from "react";
import {Alert, Button, Divider, Form, Input, Modal, Steps, Radio} from "antd";
import styled from "styled-components";
import AdminApi from "../api/admin-api";

export const ContentContainer = styled.div`
  margin-top: 20px
`

const ForgotPassword = props => {

    const [current, setCurrent] = useState(0);
    const [identity, setIdentity] = useState("");
    const [otp, setOtp] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [formData, setFormData] = useState({});
    const [otpMode, setOtpMode] = useState('email');

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const verifyOtp = () => {
        AdminApi.forgotPassword(identity, formData.otp, formData.password).then(()=> {
            Modal.success({
                content: "Password updated successfully"
            })
            props.onComplete();
        }).catch(e => {
            Modal.error({
                content: e
            })
        })
    }

    const otpOptions = [
        {
            label: "Receive OTP By Email",
            value: "email"
        },
        {
            label: "Receive OTP By SMS",
            value: "sms"
        }
    ]

    const steps = [
        {
            title: 'Enter Identity',
            content: <>

                <Input onChange={(e) => {
                    setIdentity(e.target.value);
                }} placeholder={"Enter your username, email or phone number"}/>
                <br />
                <br />
                <Radio.Group
                    options={otpOptions}
                    onChange={(v) => {
                        setOtpMode(v.target.value)
                    }}
                    value={otpMode}
                    optionType="button"
                    buttonStyle="solid"
                />
            </>,
        },
        {
            title: 'Verify your identity',
            content: <>
                <br/>
                <Alert message="An OTP has been sent to your contact details, use it below to proceed" type="info"
                       showIcon/>
                <br/>
                <Form layout={"vertical"} onValuesChange={(item, formData) => {
                    setFormData(formData);
                }}>
                    <Form.Item name={"otp"} label={"OTP"}>
                        <Input placeholder={"Enter OTP"} type={"number"}/>
                    </Form.Item>
                    <Divider/>
                    <Form.Item name={"password"} label={"Password"}>
                        <Input type={"password"} placeholder={"Enter new password"}/>
                    </Form.Item>
                    <Form.Item name={"confirmPassword"} label={"Confirm Password"}>
                        <Input type={"password"} placeholder={"Confirm new password"}/>
                    </Form.Item>
                </Form>

            </>,
        }
    ];



    return (
        <Modal destroyOnClose={true} centered title={"Forgot Password"} onCancel={props.onComplete}
               open={props.open} footer={[
            current === 1 ? <Button onClick={prev} style={{
                float: "left"
            }}>Back</Button> : <></>,
            current === 0 ? <Button onClick={props.onComplete} style={{
                float: "left"
            }}>Cancel</Button> : <></>,
            current === 0 ? <Button type={"primary"} onClick={() => {
                // call forgot password otp server
                AdminApi.requestForgotPassword(identity, otpMode).then(()=> {
                    next();
                }).catch((e)=> {
                    // ignore
                })
            }}
                                    disabled={(identity?.trim() === "") ?? true}>Next</Button> : <></>,
            current === 1 ? <Button type={"primary"} onClick={verifyOtp} disabled={
                !(formData.otp && ("" + formData.otp).length === 6 && formData.password === formData.confirmPassword && formData.password?.trim().length > 2)
            }>Verify</Button> : <></>,
            <div style={{
                "clear": "both"
            }}></div>
        ]}>
            <Steps current={current}>
                {steps.map(item => (
                    <Steps.Step key={item.title} title={item.title}/>
                ))}
            </Steps>
            <ContentContainer>
                {steps[current].content}
            </ContentContainer>
        </Modal>
    )
}

export default ForgotPassword;
