import React, { Component } from "react";
import {
  Form,
  Select,
  Button,
  Input,
  DatePicker,
  Radio,
  InputNumber,
  Row,
  Col,
  Switch,
} from "antd";
import { ajaxRequester } from "../../services/ajax-requester";
import * as dayjs from "dayjs";
import UserPickerDropDown from "../../iam/components/user-picker-dropdown";
import { RolesPermissions } from "../../helpers/roles-permissions";
import AdminApi from "../../iam/api/admin-api";

class PlanAdd extends Component {
  state = {
    loading: false,
    plansList: [],
    customersList: [],
    activeTab: "new",
    isUpdateDisabled: false,
  };

  formRef = React.createRef();

  // Get Inventories List
  getPlans = () => {
    let reqUrl = "plan/list?employeeid=1&pageno=1&pagesize=99999";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          plansList: data.response,
        });
      },
      "GET"
    );
  };

  // Get Plan Details
  getPlanDetails = (planid) => {
    var reqUrl = "plan/detail?planid=" + Number(planid);
    var reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          existingPlan: data.response,
          plan_inventory_details: data.response.plan_inventory_details,
          isUpdateDisabled: false,
        });
      },
      "GET"
    );
  };

  // Get Customer List
  getCustomers = () => {
    let reqUrl = "customer/lookup";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          customersList: data.response,
        });
      },
      "GET"
    );
  };

  // Get Customer List
  getCustomersByEmployee = (employee_id) => {
    let reqUrl = "customer/lookup?employeeid=" + employee_id;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          customersList: data.response,
        });
      },
      "GET"
    );
  };

  onFinish = (values) => {
    const { activeTab, plansList, customersList } = this.state;
    const plan = plansList.find((x) => x.plan_id === values.plan_id);

    this.setState({ loading: true });

    values.plan_type = activeTab;
    values.start_date = values.dates[0].format("YYYY-MM-DDTHH:mm:ss");
    values.end_date = values.dates[1].format("YYYY-MM-DDTHH:mm:ss");
    values.employee_id = values?.employee_id || plan?.employee_id;
    values.customer_id = customersList.find(
      (x) => x?.customer_name === values?.customer_id
    )?.customer_id;

    if (activeTab === "existing") {
      const { existingPlan, plan_inventory_details } = this.state;
      values.customer_id = existingPlan?.customer_id;
      values.existingInventories = plan_inventory_details;
      values.printing_install_charges = existingPlan?.printing_install_charges;
      values.discount_criteria = existingPlan?.discount_criteria;
      values.plan_key = existingPlan?.plan_key;
    }

    this.props.addToPlan(values);
  };

  onTabChange = (e) => {
    this.setState({
      activeTab: e.target.value,
      isUpdateDisabled: e.target.value === "existing" ? true : false,
    });

    this.formRef.current.resetFields();
  };

  ValuesChange = (value, values) => {
    let plan = this.state.plansList.find(
      (x) => x.plan_id === values["plan_id"]
    );

    if (value["plan_id"]) {
      let display_name = plan.display_name;
      let dates = [
        dayjs(plan.start_date, "YYYY/MM/DD"),
        dayjs(plan.end_date, "YYYY/MM/DD"),
      ];
      let no_of_days = plan.no_of_days;
      let is_rotational = plan?.is_rotational;

      this.formRef.current.setFieldsValue({
        display_name,
        dates,
        no_of_days,
        is_rotational,
      });

      this.setState({ isUpdateDisabled: true });

      this.getPlanDetails(values["plan_id"]);
    }

    if (value["dates"]) {
      let no_of_days = values["dates"][1].diff(values["dates"][0], "days") + 1;
      this.formRef.current.setFieldsValue({
        no_of_days,
      });
    }

    if (value["no_of_days"]) {
      this.formRef.current.setFieldsValue({
        dates: [
          dayjs(values["dates"][0], "YYYY/MM/DD"),
          dayjs(values["dates"][0], "YYYY/MM/DD").add(
            values["no_of_days"] - 1,
            "days"
          ),
        ],
      });
    }

    if (value["employee_id"]) {
      this.getCustomersByEmployee(values["employee_id"]);
    }
  };

  disabledDate = (current) => {
    return current && current < dayjs().startOf("day");
  };

  componentDidMount() {
    this.getPlans();
    //this.getCustomers();
    RolesPermissions("sr") &&
      this.getCustomersByEmployee(AdminApi.getCurrentUser()?.Id);
  }

  render() {
    const { loading, plansList, customersList, activeTab, isUpdateDisabled } =
      this.state;

    const { Option } = Select;

    const { RangePicker } = DatePicker;

    const layout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };

    const tabs = [
      { label: "New Plan", value: "new" },
      { label: "Existing Plan", value: "existing" },
    ];

    return (
      <Form
        name="plan_add"
        {...layout}
        onFinish={this.onFinish}
        ref={this.formRef}
        className="plan_add"
        onValuesChange={this.ValuesChange}
      >
        <Form.Item>
          <Radio.Group
            options={tabs}
            value={activeTab}
            onChange={this.onTabChange}
            optionType="button"
            buttonStyle="solid"
          />
        </Form.Item>

        {activeTab === "existing" && (
          <Form.Item name="plan_id" label="Existing Plan">
            <Select placeholder="Existing Plan">
              {plansList.map((x) => (
                <Option key={x.plan_id} value={x.plan_id}>
                  {x.display_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          name="display_name"
          label="Display Name"
          rules={[
            {
              required: true,
              pattern: new RegExp(/^[^/]*$/),
              message: "Please Input Display Name ! ( / not allowed)",
            },
          ]}
        >
          <Input placeholder="Display Name" />
        </Form.Item>

        {activeTab === "new" && (
          <React.Fragment>
            {RolesPermissions("ad") && (
              <Form.Item name="employee_id" label="Employee">
                <UserPickerDropDown />
              </Form.Item>
            )}

            <Form.Item name="customer_id" label="Customer">
              <Select placeholder="Customer" allowClear showSearch={true}>
                {customersList.map((x) => (
                  <Option key={x.customer_name} value={x.customer_name}>
                    {x.customer_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </React.Fragment>
        )}

        <Row>
          <Col span="18">
            <Form.Item
              name="dates"
              label="Dates"
              rules={[{ required: true, message: "Please Input Dates !" }]}
              {...{
                labelCol: { span: 8 },
                wrapperCol: { span: 16 },
              }}
            >
              <RangePicker
                allowClear={false}
                disabledDate={this.disabledDate}
                inputReadOnly={true}
              />
            </Form.Item>
          </Col>

          <Col span="6">
            <Form.Item
              name="no_of_days"
              wrapperCol={{ offset: 2 }}
              className="without-label"
            >
              <InputNumber
                min={1}
                style={{ width: "100%" }}
                placeholder="Days"
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="is_rotational" label="Is Rotational">
          <Switch />
        </Form.Item>

        <Form.Item name="notes" label="Notes">
          <Input.TextArea placeholder="Notes" />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 6 }} className="model-btns">
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            key={isUpdateDisabled}
            disabled={isUpdateDisabled}
          >
            {activeTab === "new" ? "Create" : "Update"} Plan
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default PlanAdd;
