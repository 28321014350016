import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import { Layout, ConfigProvider } from "antd";
import withRouter from "./withRouter";
import AppHeader from "./components/common/app-header";
import AppFooter from "./components/common/app-footer";
import CommonList from "./screens/common/common-list";
import Customers from "./screens/customers/customers";
import CustomerAdd from "./screens/customers/customer-add";
import CustomerEdit from "./screens/customers/customer-edit";
import CustomerDetails from "./screens/customers/customer-details";
import Inventories from "./screens/inventories/inventories";
import InventoryAdd from "./screens/inventories/inventory-add";
import PointOfContacts from "./screens/customers/pointofcontacts";
import InventoryDetails from "./screens/inventories/inventory-details";
import InventoryEdit from "./screens/inventories/inventory-edit";
import InventoryStatus from "./screens/inventories/inventory-status";
import InventoriesTracking from "./screens/inventories/inventories-tracking";
import InventoriesContract from "./screens/inventories/inventories-contract";
import inventoriesMap from "./screens/inventories/inventories-map";
import InventoriesMaster from "./screens/inventories/inventories-master";
import Plans from "./screens/plan/plans";
import PlanDetails from "./screens/plan/plan-details";
import InventoryBookingStatus from "./screens/inventories/inventory-booking-status";
import PlanView from "./screens/plan/plan-view";
import InventoriesDeleted from "./screens/inventories/inventories-deleted";
import { LoginAuthorization } from "./iam/components/admin-helpers";
import Campaigns from "./screens/campaign/campaigns";
import CampaignDetails from "./screens/campaign/campaign-details";
import UserSettings from "./iam/components/user-settings";
import OperationList from "./screens/operations/operation-list";
import InventoriesImport from "./screens/inventories/inventories-import";
import AppHeaderPublic from "./components/common/app-header-public";
import AppFooterPublic from "./components/common/app-footer-public";
import BranchManagement from "./screens/operations/branch-management";
import BranchAdd from "./screens/operations/branch-add";
import SalesEstimate from "./screens/sales-estimate/sales-estimate-list";
import InvoiceList from "./screens/sales-estimate/invoice-list";
import SalesEstimateDetails from "./screens/sales-estimate/sales-estimate-details";
import SalesEstimateEdit from "./screens/sales-estimate/sales-estimate-edit";
import SalesEstimateAdd from "./screens/sales-estimate/sales-estimate-add";
import ApproveRejectInvoices from "./screens/sales-estimate/approve-reject-invoices";
import InvoiceDateNumber from "./screens/sales-estimate/invoice-date-number";
import PendingInvoices from "./screens/sales-estimate/pending-invoices";
import CreditNotes from "./screens/sales-estimate/credit-notes";
import JournalVoucher from "./screens/sales-estimate/journal-voucher";
import InvoiceView from "./screens/sales-estimate/invoice-view";
import CreditNoteAddEdit from "./screens/sales-estimate/credit-note-add-edit";
import JournalVoucherAddEdit from "./screens/sales-estimate/journal-voucher-add-edit";
import ManualInvoiceAdd from "./screens/sales-estimate/manual-invoice-add";
import CityUpdate from "./screens/operations/city-update";
import ProformaInvoices from "./screens/sales-estimate/proforma-invoices";
import Masters from "./screens/masters/masters";
import MastersImport from "./screens/masters/masters-import";
import PhotoBucket from "./screens/photobucket/photobucket";
import PhotoBucketReviewer from "./screens/photobucket/photobucket-reviewer";
import PayeeList from "./screens/purchase/payee-list";
import PayoutEstimate from "./screens/purchase/payout-estimate";
import PayeeAddEdit from "./screens/purchase/payee-add-edit";
import SisterCompanyList from "./screens/purchase/sister-company-list";
import EstimateTypeList from "./screens/purchase/estimate-type-list";
import PayoutEstimateAddEdit from "./screens/purchase/payout-estimate-add-edit";
import ScheduledTransactions from "./screens/purchase/scheduled-transactions";
import OneTimeTransactions from "./screens/purchase/one-time-transactions";
import TransactionsAddEdit from "./screens/purchase/transactions-add-edit";
import ManualTransactionsAddEdit from "./screens/purchase/manual-transactions-add-edit";
import VerifyEmail from "./screens/purchase/verify-email";
import SalesDashboard from "./screens/dashboard/sales-dashboard";
import OccupancyDashboard from "./screens/dashboard/occupancy-dashboard";
import SalesDashboardDetails from "./screens/dashboard/sales-dashboard-details";

const RouterWrapper = (props) => {
  const MComponent = withRouter(props.Component ?? props.component);
  return <MComponent {...props} />;
};

class App extends Component {
  state = {
    theme: "dark",
    loginStatus: JSON.parse(localStorage.getItem("loginStatus")),
  };

  handleTheme = () => {
    this.setState({ theme: this.state.theme === "dark" ? "light" : "dark" });
  };

  handleLogin = () => {
    this.setState({ loginStatus: true });
  };

  render() {
    const { Content } = Layout;
    const { theme } = this.state;
    let isPublic = false;
    if (
      this.props.location.pathname.includes("/plans/plan-view") ||
      this.props.location.pathname.includes("/campaigns/campaign-view") ||
      this.props.location.pathname.includes("/verify-email")
    ) {
      isPublic = true;
    }

    return (
      <ConfigProvider>
        <React.Fragment>
          {!isPublic && (
            <LoginAuthorization>
              <Layout className={"layout " + (theme + "-theme")}>
                <AppHeader theme={theme} {...this.state} {...this.props} />

                <Content>
                  <div className="site-layout-content">
                    <Routes>
                      <Route
                        exact
                        path="/"
                        element={<RouterWrapper component={Customers} />}
                      />
                      <Route
                        path="/customers/customers"
                        element={<RouterWrapper component={Customers} />}
                      />
                      <Route
                        path="/customers/customer-add"
                        element={<RouterWrapper component={CustomerAdd} />}
                      />
                      <Route
                        path="/customers/customer-edit/:id"
                        element={<RouterWrapper component={CustomerEdit} />}
                      />
                      <Route
                        path="/customers/customer-details/:id"
                        element={<RouterWrapper component={CustomerDetails} />}
                      />
                      <Route
                        path="/customers/pointofcontacts"
                        element={<RouterWrapper component={PointOfContacts} />}
                      />
                      <Route
                        path="/inventories/inventories"
                        element={
                          <RouterWrapper
                            component={Inventories}
                            key="ha-inventories"
                          />
                        }
                      />
                      <Route
                        path="/inventories/ta-inventories"
                        element={
                          <RouterWrapper
                            component={Inventories}
                            key="ta-inventories"
                          />
                        }
                      />
                      <Route
                        path="/inventories/inventory-add"
                        element={<RouterWrapper component={InventoryAdd} />}
                      />
                      <Route
                        path="/inventories/ta-inventory-add"
                        element={<RouterWrapper component={InventoryAdd} />}
                      />
                      <Route
                        path="/inventories/inventory-add-group"
                        element={<RouterWrapper component={InventoryAdd} />}
                      />
                      <Route
                        path="/inventories/ta-inventory-add-group"
                        element={<RouterWrapper component={InventoryAdd} />}
                      />
                      <Route
                        path="/inventories/inventory-edit/:id"
                        element={<RouterWrapper component={InventoryEdit} />}
                      />
                      <Route
                        path="/inventories/ta-inventory-edit/:id"
                        element={<RouterWrapper component={InventoryEdit} />}
                      />
                      <Route
                        path="/inventories/inventory-group-edit/:id"
                        element={<RouterWrapper component={InventoryEdit} />}
                      />
                      <Route
                        path="/inventories/ta-inventory-group-edit/:id"
                        element={<RouterWrapper component={InventoryEdit} />}
                      />
                      <Route
                        path="/inventories/inventory-details/:type/:id"
                        element={<RouterWrapper component={InventoryDetails} />}
                      />
                      <Route
                        path="/inventories/inventory-status/:id/:title"
                        element={<RouterWrapper component={InventoryStatus} />}
                      />
                      <Route
                        path="/inventories/inventory-booking-status/:id/:title"
                        element={
                          <RouterWrapper component={InventoryBookingStatus} />
                        }
                      />
                      <Route
                        path="/inventories/inventories-tracking"
                        element={
                          <RouterWrapper component={InventoriesTracking} />
                        }
                      />
                      <Route
                        path="/inventories/inventories-contract"
                        element={
                          <RouterWrapper component={InventoriesContract} />
                        }
                      />
                      <Route
                        path="/inventories/inventories-map"
                        element={<RouterWrapper component={inventoriesMap} />}
                      />
                      <Route
                        path="/inventories/inventories-master"
                        element={
                          <RouterWrapper
                            component={InventoriesMaster}
                            key="ha-inventories-master"
                          />
                        }
                      />
                      <Route
                        path="/inventories/ta-inventories-master"
                        element={
                          <RouterWrapper
                            component={InventoriesMaster}
                            key="ta-inventories-master"
                          />
                        }
                      />
                      <Route
                        path="/inventories/inventories-deleted"
                        element={
                          <RouterWrapper component={InventoriesDeleted} />
                        }
                      />
                      <Route
                        path="/plans/plans"
                        element={<RouterWrapper component={Plans} />}
                      />
                      <Route
                        path="/plans/plan-details/:id"
                        element={<RouterWrapper component={PlanDetails} />}
                      />
                      <Route
                        path="/common-list"
                        element={<RouterWrapper component={CommonList} />}
                      />
                      <Route
                        path="/campaigns/campaigns"
                        element={<RouterWrapper component={Campaigns} />}
                      />
                      <Route
                        path="/campaigns/campaign-details/:id"
                        element={<RouterWrapper component={CampaignDetails} />}
                      />
                      <Route
                        path="/operations/waiting-for-flex"
                        element={
                          <RouterWrapper
                            component={OperationList}
                            key="waiting-for-flex"
                          />
                        }
                      />
                      <Route
                        path="/operations/change-of-site"
                        element={
                          <RouterWrapper
                            component={OperationList}
                            key="change-of-site"
                          />
                        }
                      />
                      <Route
                        path="/operations/pending-mounting"
                        element={
                          <RouterWrapper
                            component={OperationList}
                            key="pending-mounting"
                          />
                        }
                      />
                      <Route
                        path="/operations/monitoring"
                        element={
                          <RouterWrapper
                            component={OperationList}
                            key="monitoring"
                          />
                        }
                      />
                      <Route
                        path="/operations/pending-unmounting-photo"
                        element={
                          <RouterWrapper
                            component={OperationList}
                            key="pending-unmounting-photo"
                          />
                        }
                      />
                      <Route
                        path="/operations/pending-unmounting"
                        element={
                          <RouterWrapper
                            component={OperationList}
                            key="pending-unmounting"
                          />
                        }
                      />
                      <Route
                        path="/operations/stock-management"
                        element={
                          <RouterWrapper
                            component={OperationList}
                            key="stock-management"
                          />
                        }
                      />
                      <Route
                        path="/operations/mounting-unmounting"
                        element={
                          <RouterWrapper
                            component={OperationList}
                            key="mounting-unmounting"
                          />
                        }
                      />
                      <Route
                        path="/operations/campaign-operations/:id"
                        element={
                          <RouterWrapper
                            component={OperationList}
                            key="campaign-operations"
                          />
                        }
                      />
                      <Route
                        path="/inventories/branch-management"
                        element={<RouterWrapper component={BranchManagement} />}
                      />
                      <Route
                        path="/inventories/branch-add"
                        element={<RouterWrapper component={BranchAdd} />}
                      />
                      <Route
                        path="/inventories/branch-edit/:id"
                        element={<RouterWrapper component={BranchAdd} />}
                      />
                      <Route
                        path="/inventories/inventories-import"
                        element={
                          <RouterWrapper
                            component={InventoriesImport}
                            key="ha-inventories-import"
                          />
                        }
                      />
                      <Route
                        path="/inventories/ta-inventories-import"
                        element={
                          <RouterWrapper
                            component={InventoriesImport}
                            key="ta-inventories-import"
                          />
                        }
                      />
                      <Route
                        path="/sales-estimate/sales-estimate-list"
                        element={<RouterWrapper component={SalesEstimate} />}
                      />
                      <Route
                        path="/sales-estimate/sales-estimate-details/:id/:title"
                        element={
                          <RouterWrapper component={SalesEstimateDetails} />
                        }
                      />
                      <Route
                        path="/sales-estimate/sales-estimate-add/:id"
                        element={<RouterWrapper component={SalesEstimateAdd} />}
                      />
                      <Route
                        path="/sales-estimate/sales-estimate-edit/:id"
                        element={<RouterWrapper component={SalesEstimateAdd} />}
                      />
                      <Route
                        path="/sales-estimate/invoice/:action/:id"
                        element={<RouterWrapper component={InvoiceView} />}
                      />
                      <Route
                        path="/sales-estimate/proforma-invoices"
                        element={<RouterWrapper component={ProformaInvoices} />}
                      />
                      <Route
                        path="/sales-estimate/approve-reject-invoices"
                        element={
                          <RouterWrapper component={ApproveRejectInvoices} />
                        }
                      />
                      <Route
                        path="/sales-estimate/invoice-date-number"
                        element={
                          <RouterWrapper component={InvoiceDateNumber} />
                        }
                      />
                      <Route
                        path="/sales-estimate/invoice-list"
                        element={<RouterWrapper component={InvoiceList} />}
                      />
                      <Route
                        path="/sales-estimate/pending-invoices"
                        element={<RouterWrapper component={PendingInvoices} />}
                      />
                      <Route
                        path="/sales-estimate/credit-notes"
                        element={<RouterWrapper component={CreditNotes} />}
                      />
                      <Route
                        path="/sales-estimate/credit-note-add"
                        element={
                          <RouterWrapper component={CreditNoteAddEdit} />
                        }
                      />
                      <Route
                        path="/sales-estimate/credit-note-edit/:id"
                        element={
                          <RouterWrapper component={CreditNoteAddEdit} />
                        }
                      />
                      <Route
                        path="/sales-estimate/journal-voucher"
                        element={<RouterWrapper component={JournalVoucher} />}
                      />
                      <Route
                        path="/sales-estimate/journal-voucher-add"
                        element={
                          <RouterWrapper component={JournalVoucherAddEdit} />
                        }
                      />
                      <Route
                        path="/sales-estimate/journal-voucher-edit/:id"
                        element={
                          <RouterWrapper component={JournalVoucherAddEdit} />
                        }
                      />
                      <Route
                        path="/sales-estimate/manual-invoice-add"
                        element={<RouterWrapper component={ManualInvoiceAdd} />}
                      />
                      <Route
                        path="/inventories/city-update"
                        element={<RouterWrapper component={CityUpdate} />}
                      />
                      <Route
                        path={"/settings"}
                        element={<RouterWrapper component={UserSettings} />}
                      />
                      <Route
                        path={"/masters"}
                        element={<RouterWrapper component={Masters} />}
                      />
                      <Route
                        path={"/masters-import"}
                        element={<RouterWrapper component={MastersImport} />}
                      />
                      <Route
                        path="/photobucket-list"
                        element={<RouterWrapper component={PhotoBucket} />}
                      />
                      <Route
                        path="/reviewer-list"
                        element={
                          <RouterWrapper component={PhotoBucketReviewer} />
                        }
                      />
                      <Route
                        path="/purchase/payee-list"
                        element={<RouterWrapper component={PayeeList} />}
                      />
                      <Route
                        path="/purchase/payee-add"
                        element={<RouterWrapper component={PayeeAddEdit} />}
                      />
                      <Route
                        path="/purchase/payee-edit/:id"
                        element={<RouterWrapper component={PayeeAddEdit} />}
                      />
                      <Route
                        path="/purchase/payout-estimate"
                        element={<RouterWrapper component={PayoutEstimate} />}
                      />
                      <Route
                        path="/purchase/payout-estimate-add"
                        element={
                          <RouterWrapper component={PayoutEstimateAddEdit} />
                        }
                      />
                      <Route
                        path="/purchase/payout-estimate-edit/:id"
                        element={
                          <RouterWrapper component={PayoutEstimateAddEdit} />
                        }
                      />
                      <Route
                        path="/purchase/sister-company-list"
                        element={
                          <RouterWrapper component={SisterCompanyList} />
                        }
                      />
                      <Route
                        path="/purchase/estimate-type-list"
                        element={<RouterWrapper component={EstimateTypeList} />}
                      />
                      <Route
                        path="/purchase/transactions"
                        element={
                          <RouterWrapper
                            component={ScheduledTransactions}
                            key="transactions"
                          />
                        }
                      />
                      <Route
                        path="/purchase/transactions-approval"
                        element={
                          <RouterWrapper
                            component={ScheduledTransactions}
                            key="transactions-approval"
                          />
                        }
                      />
                      <Route
                        path="/purchase/one-time-transactions"
                        element={
                          <RouterWrapper component={OneTimeTransactions} />
                        }
                      />
                      <Route
                        path="/purchase/transactions-add/:id"
                        element={
                          <RouterWrapper component={TransactionsAddEdit} />
                        }
                      />
                      <Route
                        path="/purchase/transactions-edit/:id"
                        element={
                          <RouterWrapper component={TransactionsAddEdit} />
                        }
                      />
                      <Route
                        path="/purchase/manual-transactions-add"
                        element={
                          <RouterWrapper
                            component={ManualTransactionsAddEdit}
                          />
                        }
                      />
                      <Route
                        path="/purchase/manual-transactions-edit/:id"
                        element={
                          <RouterWrapper
                            component={ManualTransactionsAddEdit}
                          />
                        }
                      />
                      <Route
                        path="/dashboard/sales"
                        element={<RouterWrapper component={SalesDashboard} />}
                      />
                      <Route
                        path="/dashboard/sales-details/:type/:month"
                        element={
                          <RouterWrapper component={SalesDashboardDetails} />
                        }
                      />
                      <Route
                        path="/dashboard/sales-details/:type/:month/:value"
                        element={
                          <RouterWrapper component={SalesDashboardDetails} />
                        }
                      />
                      <Route
                        path="/dashboard/occupancy"
                        element={
                          <RouterWrapper component={OccupancyDashboard} />
                        }
                      />
                    </Routes>
                  </div>
                </Content>

                <AppFooter handleTheme={this.handleTheme} />
              </Layout>
            </LoginAuthorization>
          )}

          {isPublic && (
            <Layout className={"layout " + (theme + "-theme")}>
              <AppHeaderPublic />

              <Content>
                <div className="site-layout-content">
                  <Routes>
                    <Route
                      path="/plans/plan-view/:id"
                      element={<RouterWrapper component={PlanView} />}
                    />
                    <Route
                      path="/campaigns/campaign-view/:id"
                      element={<RouterWrapper component={PlanView} />}
                    />
                    <Route
                      path="/verify-email"
                      element={<RouterWrapper component={VerifyEmail} />}
                    />
                  </Routes>
                </div>
              </Content>

              <AppFooterPublic />
            </Layout>
          )}
        </React.Fragment>
      </ConfigProvider>
    );
  }
}

export default withRouter(App);
