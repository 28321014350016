import React, { Component } from "react";
import { ajaxRequester } from "../../services/ajax-requester";
import { Upload, message, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";

class UploadFiles extends Component {
  state = {
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    fileList: [],
    image_quality: "",
  };

  getBase64Upload = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  handleChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      this.setState({ loading: false });
      return;
    }

    let fileList = info.fileList;
    this.setState({ fileList });
  };

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  onRemove = (img) => {
    var reqUrl =
      this.props.deletePath +
      "?inventoryId=" +
      this.props.id +
      "&mediaId=" +
      img.uid;

    var reqObj = "";
    ajaxRequester(
      reqUrl,
      reqObj,
      () => {
        let fileList = this.state.fileList;
        fileList = fileList.filter((x) => x.uid !== img.uid);
        this.setState({ fileList });
      },
      "DELETE"
    );
  };

  onRemoveLocal = (img) => {
    let fileList = this.state.fileList;
    fileList = fileList.filter((x) => x.uid !== img.uid);
    this.setState({ fileList });

    let uploadImages = fileList.map((x) => {
      return {
        is_public: true,
        tags: [x.name.split(".")[0]],
        data_extension: "." + [...x.name.split(".")].pop(),
        type: x.type,
        data: x.url.split(",")[1],
      };
    });

    this.props.handleUploadImages(uploadImages);
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handleUpload = (file, imageUrl) => {
    let reqUrl = this.props.uploadPath;
    let reqObj = {
      request: {
        reference_id: this.props.id,
        is_public: true,
        tags: [file.name.split(".")[0]],
        data_extension: "." + [...file.name.split(".")].pop(),
        type: file.type,
        data: imageUrl.split(",")[1],
        optimization: {
          resize: true,
          quality: this?.state?.image_quality,
          image_quality: this?.state?.image_quality,
        },
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      let fileList = this.state.fileList;

      fileList.push({
        uid: data.media_id,
        name: "image.png",
        status: "done",
        url: data.data_url,
      });

      this.setState({ fileList });

      if (data) {
        message.success(`${file.name} file uploaded successfully`);
      } else {
        message.error(`${file.name} file upload failed.`);
      }
    });
  };

  handleUploadLocal = (file, imageUrl) => {
    let fileList = this.state.fileList;

    fileList.push({
      uid: file.uid,
      name: file.name,
      status: "done",
      url: imageUrl,
      type: file.type,
    });

    this.setState({ fileList });

    let uploadImages = fileList.map((x) => {
      return {
        is_public: true,
        tags: [x.name.split(".")[0]],
        data_extension: "." + [...x.name.split(".")].pop(),
        type: x.type,
        data: x.url.split(",")[1],
        optimization: {
          resize: true,
          quality: this?.state?.image_quality,
          image_quality: this?.state?.image_quality,
        },
      };
    });

    this.props.handleUploadImages(uploadImages);
  };

  uploadImage = ({ file }) => {
    this.getBase64Upload(file, (image) => {
      this.props?.mode
        ? this.handleUpload(file, image)
        : this.handleUploadLocal(file, image);
    });
  };

  // Gettng Seller Details
  getConfigurationDetails = () => {
    let reqUrl = "configuration/detail";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          image_quality: data?.configuration_details?.image_quality,
        });
      },
      "GET"
    );
  };

  componentDidMount() {
    this.getConfigurationDetails();
    let fileList = [];
    const photos = this.props.photos;

    photos &&
      photos.map((img) =>
        fileList.push({
          uid: img.media_id,
          name: "image.png",
          status: "done",
          url: img.data_url,
        })
      );

    this.setState({ fileList });
  }

  render() {
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    const { mode } = this.props;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );

    return (
      <React.Fragment>
        <Upload
          listType="picture-card"
          fileList={fileList}
          customRequest={this.uploadImage}
          onChange={mode && this.handleChange}
          onPreview={this.handlePreview}
          onRemove={mode ? this.onRemove : this.onRemoveLocal}
          multiple={true}
        >
          {uploadButton}
        </Upload>
        <Modal
          open={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </React.Fragment>
    );
  }
}

export default UploadFiles;
