import React, { Component } from "react";
import { Column } from "@ant-design/charts";
import CommonListBase from "../../base/common-list-base";

class SalesMonthlyChart extends CommonListBase {
  state = {
    listReq:
      "dashboard/sales?resultgroup=month&durationGroup=" +
      this?.props?.year +
      "&",
    delReq: "sistercompany/delete?payout_estimate_id=",
    dtlReq: "sistercompany",
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    sorting: [],
    screen: "dashboard-city-list",
    isDrawer: false,
    record: "",
    isEditMode: false,
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.getResults({ pagination });
  }

  dataFormating = (data) => {
    let output = data.flatMap((item) => [
      {
        month: `${item.month_name} ${String(item.year).slice(-2)}`,
        type: "Billed",
        amount: parseInt(item.total_real_sales),
      },
      {
        month: `${item.month_name} ${String(item.year).slice(-2)}`,
        type: "Unbilled",
        amount: parseInt(item.total_unreal_sales),
      },
    ]);

    return output;
  };

  render() {
    const { data } = this.state;

    return (
      <Column
        {...{
          data: this.dataFormating(data),
          group: true,
          xField: "month",
          yField: "amount",
          seriesField: "type",
          colorField: "type",
        }}
      />
    );
  }
}

export default SalesMonthlyChart;
