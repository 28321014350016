import React, { Component } from "react";
import { ajaxRequester } from "../../services/ajax-requester";
import { Upload, Button, message } from "antd";
import {
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { downloadFile } from "../../helpers/download-helper";

class UploadConfigFile extends Component {
  state = { loading: false };

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }

    return isJpgOrPng && isLt2M;
  };

  handleChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      this.setState({ loading: false });
      return;
    }
  };

  handleUpload = (file, imageUrl) => {
    let reqUrl = this.props.uploadPath;

    let reqObj = {
      request: {
        reference_id: this.props.id,
        name: file.name,
        is_public: true,
        tags: [file.name.split(".")[0]],
        data_extension: "." + [...file.name.split(".")].pop(),
        type: file.type,
        document_type: String(this?.props?.type),
        data: imageUrl.split(",")[1],
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data?.success || data) {
        message.success(`${file?.name} file uploaded successfully`);
        this.setState({
          imageUrl,
          loading: false,
          docName: file?.name,
          docPath: data?.data_url,
          uploadedId: data?.id,
        });
      } else {
        message.error(`${file.name} file upload failed.`);
      }
    });
  };

  uploadImage = ({ file }) => {
    this.getBase64(file, (image) => {
      this.handleDelete(file, image);
    });
  };

  handleDelete = (file, imageUrl) => {
    let docType = "";

    if (this?.props?.type === 1) {
      docType = "logo";
    }
    if (this?.props?.type === 2) {
      docType = "cancelled_cheque";
    }
    if (this?.props?.type === 3) {
      docType = "gst_certificate";
    }

    const existingImage =
      this?.props?.documents?.length > 0 && this?.props?.documents[docType];

    if (existingImage?.length > 0) {
      let reqUrl =
        this?.props?.deletePath +
        "?configId=" +
        this?.props?.id +
        "&documenttype=" +
        this?.props?.type +
        "&documentid=" +
        existingImage[0]?.id;
      var reqObj = "";
      ajaxRequester(
        reqUrl,
        reqObj,
        (data) => {
          this.handleUpload(file, imageUrl);
        },
        "DELETE"
      );
    } else {
      this.handleUpload(file, imageUrl);
    }
  };

  render() {
    const { loading, imageUrl, docName, docPath } = this.state;
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );

    return (
      <React.Fragment>
        {this.props?.type === 1 && (
          <Upload
            listType="picture-card"
            beforeUpload={this.beforeUpload}
            customRequest={this.uploadImage}
            onChange={this.handleChange}
            showUploadList={false}
          >
            {imageUrl || this.props?.photoPath ? (
              <img
                src={imageUrl || this.props?.photoPath}
                alt="logo"
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        )}

        {this.props?.type !== 1 && (
          <React.Fragment>
            <Upload customRequest={this.uploadImage} showUploadList={false}>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>

            {(docName || this.props?.docPath) && (
              <div style={{ display: "inline-block", marginLeft: "24px" }}>
                <Button
                  type="link"
                  className="ant-btn-reset"
                  onClick={() =>
                    downloadFile(
                      docName || this.props?.docName,
                      docPath || this.props?.docPath
                    )
                  }
                >
                  {docName || this.props?.docName}
                </Button>
              </div>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default UploadConfigFile;
