import React from "react";
import { Row, Col, Spin } from "antd";
import PageTitleDetails from "../../components/common/page-title-detail";
import { ajaxRequester } from "../../services/ajax-requester";
import InventoryAddForm from "../../components/inventories/inventory-add-form";
import InventoriesBase from "../../base/inventories-base";

class InventoryEdit extends InventoriesBase {
  state = {
    isSuccess: false,
    inventoryKey: 1,
    mediaTypeList: "",
    results: "",
    isLoading: true,
    mode: "edit",
    isGroup:
      this.props.match.path.split("/")[2] === "inventory-group-edit" ||
      this.props.match.path.split("/")[2] === "ta-inventory-group-edit",
    inventoryType: this.props.match.path.includes("ta-")
      ? "tainventory"
      : "hainventory",
  };

  // Get Inventory Details
  getInventoryDetails = () => {
    const inventoryId = this.props.match.params.id;

    var reqUrl =
      (this.state.isGroup ? "hagroupinventory" : this.state.inventoryType) +
      "/detail?inventoryId=" +
      Number(inventoryId);
    var reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ results: data.response, isLoading: false });
      },
      "GET"
    );
  };

  componentDidMount() {
    this.getInventoryDetails();
    this.getLookupData();
    this.getSupplierList();
  }

  render() {
    const { inventoryKey, isLoading, isGroup, inventoryType } = this.state;
    return (
      <Row>
        <Col span={24}>
          <PageTitleDetails
            titleText={
              "Edit " +
              (inventoryType === "tainventory" ? "TA " : "HA ") +
              (isGroup ? "Grouped Inventory" : "Inventory")
            }
            isBackBtn="true"
            handleBack={this.handleBack}
          />

          {!isLoading && (
            <InventoryAddForm
              {...this.state}
              key={inventoryKey}
              handleAddInventory={this.handleUpdateInventory}
              handleBack={this.handleBack}
            />
          )}

          {isLoading && (
            <div className="list-loader">
              <Spin />
            </div>
          )}
        </Col>
      </Row>
    );
  }
}

export default InventoryEdit;
