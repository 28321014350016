import React, { Component } from "react";
import { ajaxRequester } from "../../services/ajax-requester";
import { Button, Form, Input, Select, Switch, Row, Col } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

class AddPayee extends Component {
  state = { stateList: [], districtList: [], cityList: [], details: "" };

  formRef = React.createRef();

  // Get Payee Details
  getDetails = () => {
    const { record } = this?.props;
    let reqUrl = "payee/detail?payeeid=" + record?.payee_id;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ details: data.response }, () => this.setDefaultData());
      },
      "GET"
    );
  };

  // Get State List from Lookup
  getStateList = () => {
    let reqUrl = "lookup/state";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ stateList: data.response });
      },
      "GET"
    );
  };

  // Get District List from Lookup
  getDistrictList = (state) => {
    let reqUrl = "lookup/district?state=" + state;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ districtList: data.response });
      },
      "GET"
    );
  };

  // Get City List from Lookup
  getCityList = (state, district) => {
    let reqUrl = "lookup/city?state=" + state + "&district=" + district;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ cityList: data.response });
      },
      "GET"
    );
  };

  // Handle Select Change
  handleTerritoryChange = (item, key) => {
    let state = this.formRef.current.getFieldValue("state");
    let district = this.formRef.current.getFieldValue("district");
    let city = this.formRef.current.getFieldValue("city");

    switch (item) {
      case "state":
        district = null;
        city = null;
        this.setState({ districtList: [], cityList: [], areaList: [] });
        break;

      case "district":
        city = null;
        this.setState({ cityList: [] });
        break;

      default:
        break;
    }
    this.formRef.current.setFieldsValue({
      state: state,
      district: district,
      city: city,
    });
  };

  // Handle Select Focus
  handleTerritorySelect = (item, key) => {
    let state = this.formRef.current.getFieldValue("state");
    let district = this.formRef.current.getFieldValue("district");

    switch (item) {
      case "district":
        state && this.getDistrictList(state);
        break;

      case "city":
        state && district && this.getCityList(state, district);
        break;

      default:
        break;
    }
  };

  setDefaultData = () => {
    const { details } = this?.state;

    this.formRef.current.setFieldsValue({
      is_active: details?.is_active,
      account_name: details?.account_name,
      payee_number: details?.payee_number,
      payee_email: details?.payee_email,
      address: details?.address,
      pan_no: details?.pan_no,
      gst_no: details?.gst_no,
      bank_name: details?.bank_name,
      bank_acct_no: details?.bank_acct_no,
      bank_ifsc_code: details?.bank_ifsc_code,
      online_payment_url: details?.online_payment_url,
      state: details?.state_name,
      district: details?.district_name,
      city: details?.city_name,
    });
  };

  ValuesChange = (value, values) => {};

  onFinish = (values) => {
    const { cityList } = this.state;
    const { isEditMode, record } = this.props;

    isEditMode && (values.payee_id = record?.payee_id);

    console.log(
      values.city,
      cityList.find((x) => x.city === values.city).city_id
    );

    values.city_id = cityList.find((x) => x.city === values.city).city_id;
    values.gst_no = values.gst_no || " ";

    console.log(values);

    this.props.handleAddEdit(values);
  };

  componentDidMount() {
    const { isEditMode } = this.props;
    isEditMode && this.getDetails();
    this.getStateList();
  }

  render() {
    const { isEditMode } = this?.props;
    const { stateList, districtList, cityList } = this?.state;

    const { Option } = Select;

    const layout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 15 },
    };

    const layout2 = {
      labelCol: { span: 4 },
      wrapperCol: { span: 19 },
    };
    const layout3 = {
      labelCol: { span: 12 },
      wrapperCol: { span: 11 },
    };
    const layout4 = {
      wrapperCol: { span: 19 },
    };

    const { record } = this.props;

    return (
      <Form
        name="payee_add"
        className="payee_add"
        {...layout}
        onFinish={this.onFinish}
        onValuesChange={this.ValuesChange}
        ref={this.formRef}
        initialValues={{
          is_active: true,
        }}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              label="Payee Status"
              name="is_active"
              valuePropName="checked"
            >
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </Col>
          <Col span={12}></Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item
              label="Account Name"
              name="account_name"
              rules={[
                {
                  required: true,
                  message: "Please Input Account Name !",
                },
              ]}
            >
              <Input placeholder="Account Name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Payee Number" name="payee_number">
              <Input placeholder="Payee Number" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item label="Payee Email" name="payee_email">
              <Input placeholder="Payee Email" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Address" name="address">
              <Input placeholder="Address" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={7}>
            <Form.Item label="State/District" name="state" {...layout3}>
              <Select
                placeholder="State"
                allowClear={true}
                showSearch={true}
                onChange={() => this.handleTerritoryChange("state")}
              >
                {stateList.map((x) => (
                  <Option key={x.state} value={x.state}>
                    {x.state}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={5}>
            <Form.Item name="district" {...layout4}>
              <Select
                placeholder="District"
                allowClear={true}
                showSearch={true}
                onChange={() => this.handleTerritoryChange("district")}
                onFocus={() => this.handleTerritorySelect("district")}
              >
                {districtList.map((x) => (
                  <Option key={x.district} value={x.district}>
                    {x.district}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="City"
              name="city"
              rules={[
                {
                  required: true,
                  message: "Please Select City !",
                },
              ]}
            >
              <Select
                placeholder="City"
                showSearch={true}
                allowClear={true}
                onChange={() => this.handleTerritoryChange("city")}
                onFocus={() => this.handleTerritorySelect("city")}
              >
                {cityList.map((x) => (
                  <Option key={x.city} value={x.city}>
                    {x.city}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item label="PAN Number" name="pan_no">
              <Input placeholder="PAN Number" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="GST Number" name="gst_no">
              <Input placeholder="GST Number" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item label="Bank Name" name="bank_name">
              <Input placeholder="Bank Name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Account Number" name="bank_acct_no">
              <Input placeholder="Account Number" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item label="IFSC Code" name="bank_ifsc_code">
              <Input placeholder="IFSC Code" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Payment URL" name="online_payment_url">
              <Input placeholder="Online Payment URL" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item wrapperCol={{ offset: 7 }} className="model-btns">
              <Button type="primary" htmlType="submit">
                {isEditMode ? "Update Payee" : "Add Payee"}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default AddPayee;
