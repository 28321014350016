import React from "react";
import { Row, Col, Table, Button, Popconfirm } from "antd";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
} from "@ant-design/icons";
import PageTitle from "../../components/common/page-title";
import ListFilters from "../../components/common/list-filters";
import CommonListBase from "../../base/common-list-base";
import {
  currencyFormatter,
  dateFormatter,
} from "../../components/common/common-formatter";
import PortalUser from "../../iam/components/portal-user";
import { Link } from "react-router-dom";
import { RolesPermissions } from "../../helpers/roles-permissions";

class CreditNotes extends CommonListBase {
  state = {
    listReq: "salesestimate/creditnotelist?employeeid=1&",
    delReq: "salesestimate/creditnote/delete?creditnoteid=",
    dtlReq: "/sales-estimate/credit-note-details/",
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    sorting: [],
    screen: "credit-notes",
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "Credit Note Number",
        dataIndex: "credit_note_no",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) => (
          <Link
            style={{ padding: "0px", whiteSpace: "normal", textAlign: "left" }}
            type="link"
            to={`/sales-estimate/credit-note-edit/${record.credit_note_id}`}
          >
            {text}
          </Link>
        ),
      },
      {
        title: "Credit Note Date",
        dataIndex: "credit_note_date",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => dateFormatter(text),
      },
      {
        title: "Customer Code",
        dataIndex: "customer_code",
      },
      {
        title: "Company Name",
        dataIndex: "company_name",
      },
      {
        title: "Employee",
        dataIndex: "employee_id",
        render: (text) => <PortalUser user={text} />,
        width: 140,
      },
      {
        title: "Type",
        dataIndex: "credit_note_type",
        render: (text) =>
          text === "G"
            ? "General"
            : text === "C"
            ? "CD"
            : text === "V"
            ? "VD"
            : "",
      },
      {
        title: "Mapped Invoices",
        dataIndex: "mapped_invoices",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Amount",
        dataIndex: "credit_note_amount",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: "Amount with GST",
        dataIndex: "total_amount",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: "Download",
        dataIndex: "download",
        align: "center",
        render: (record) => (
          <Button icon={<DownloadOutlined />} className="ant-btn-reset" />
        ),
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "x",
        render: (record) => (
          <React.Fragment>
            <Button
              icon={<EditOutlined />}
              className="ant-btn-reset"
              onClick={() => this.editCreditNote(record.credit_note_id)}
            />
            <Popconfirm
              title="Are you sure want to delete?"
              placement="left"
              okText="Yes"
              cancelText="No"
              onConfirm={() => this.deleteItem(record.credit_note_id)}
            >
              <Button
                icon={<DeleteOutlined />}
                className="ant-btn-reset"
                style={{ marginLeft: "12px" }}
              />
            </Popconfirm>
          </React.Fragment>
        ),
      },
    ];

    const filterColumns = [
      {
        columnName: "credit_note_no",
        displayName: "Credit Note Number",
        default: true,
      },
      {
        columnName: "credit_note_date",
        displayName: "Credit Note Date",
        hideFilter: true,
      },
      {
        columnName: "customer_code",
        displayName: "Customer Code",
        hideFilter: true,
      },
      {
        columnName: "company_name",
        displayName: "Company Name",
      },
      { columnName: "employee_id", displayName: "Employee" },
      { columnName: "remarks", displayName: "Description" },
      {
        columnName: "mapped_invoices",
        displayName: "Mapped Invoices",
        hideFilter: true,
      },
      {
        columnName: "credit_note_amount",
        displayName: "Amount",
        hideFilter: true,
      },
      {
        columnName: "total_amount",
        displayName: "Amount with GST",
        hideFilter: true,
      },
      { columnName: "download", displayName: "Download", hideFilter: true },
    ];

    this.setState({ columnsDefault: columns, filterColumns }, () =>
      this.setDefaultColumns(columns)
    );
  };

  // Redirect to credit note add form
  addCreditNote = () => {
    this.props.history.push(`/sales-estimate/credit-note-add`);
  };

  componentDidMount() {
    // Get List
    const { pagination } = this.state;
    this.getResults({ pagination });
    this.setColumns();
  }

  render() {
    const { data, columns, pagination, loading, filterColumns, screen } =
      this.state;

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageTitle
              titleText="Credit Notes"
              isAddBtn={
                (RolesPermissions("ad") || RolesPermissions("ac")) &&
                "Add Credit Note"
              }
              handleAdd={this.addCreditNote}
            />

            <ListFilters
              items={filterColumns}
              handleSettings={this.handleSettings}
              handleFilters={this.handleFilters}
              key={filterColumns}
              screen={screen}
            />

            <Table
              columns={columns}
              rowKey={(record) => record.credit_note_id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              bordered
              scroll={{ x: 400 }}
              size="small"
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default CreditNotes;
