import React from "react";
import PageTitleDetails from "../../components/common/page-title-detail";
import {
  Form,
  Select,
  Button,
  Input,
  Row,
  Col,
  Divider,
  Space,
  DatePicker,
  InputNumber,
} from "antd";
import {
  FileAddOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import SalesEstimateBase from "../../base/sales-estimate-base";
import UserPickerDropDown from "../../iam/components/user-picker-dropdown";

class ManualInvoiceAdd extends SalesEstimateBase {
  state = {
    page: "creditnote",
    loading: false,
    customer: "",
    customersList: [],
    companyList: [],
    gstList: [],
    addressList: [],
    mode: this.props.match.path.includes("add") ? "Add" : "Edit",
  };

  formRef = React.createRef();

  componentDidMount() {
    this.getCustomers();
    this.getSallerDetails();
    this.getInvoiceFormat();
    this.getPlaceOfSupply();
    this.setColumns();
    this.state.mode === "Edit" && this.getDetails();
    this.formRef.current.setFieldsValue({ manual_inventory_details: [""] });
  }

  render() {
    const {
      mode,
      loading,
      customersList,
      companyList,
      gstList,
      addressList,
      formatList,
      placeOfSupplyList,
    } = this.state;

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    const layout2 = {
      labelCol: { span: 6 },
      wrapperCol: { span: 17 },
    };

    const layoutInline = {
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
    };

    const { Option } = Select;

    const gstTypeList = [
      { label: "IGST", value: "I" },
      { label: "CGST SGST", value: "C" },
    ];

    return (
      <React.Fragment>
        <Row>
          <Col span={24} xs={24} xl={24}>
            <PageTitleDetails
              titleText={mode + " Manual Invoice"}
              isBackBtn="true"
              handleBack={this.handleBack}
            />

            <Form
              name="add_edit_manual_invoice"
              {...layout}
              onFinish={this.addManualInvoice}
              className="add-edit-manual-invoice"
              onValuesChange={this.ValuesChange}
              ref={this.formRef}
            >
              <Row>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item
                    label="Customer"
                    name="customer_id"
                    rules={[{ required: true, message: "Select Customer" }]}
                  >
                    <Select placeholder="Select Customer" allowClear={true}>
                      {customersList.map((x) => (
                        <Option key={x.customer_id} value={x.customer_id}>
                          {x.customer_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={8} xs={24} xl={8}>
                  <Form.Item
                    label="Company Name"
                    name="company_id"
                    rules={[{ required: true, message: "Select Company Name" }]}
                  >
                    <Select placeholder="Select Company Name" allowClear={true}>
                      {companyList.map((x) => (
                        <Option key={x.company_id} value={x.company_id}>
                          {x.company_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={8} xs={24} xl={8}>
                  <Form.Item
                    label="GSTIN"
                    name="gstin"
                    rules={[{ required: true, message: "Select GSTIN" }]}
                  >
                    <Select placeholder="Select GSTIN" allowClear={true}>
                      {gstList.map((x) => (
                        <Option key={x.gstin} value={x.gstin}>
                          {x.gstin}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item
                    label="Address"
                    name="address"
                    rules={[{ required: true, message: "Select Address" }]}
                  >
                    <Select placeholder="Select Address" allowClear={true}>
                      {addressList.map((x) => (
                        <Option key={x.address} value={x.address}>
                          {x.address}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={8} xs={24} xl={8}>
                  <Form.Item
                    label="Place of Supply"
                    name="place_of_supply_id"
                    rules={[
                      { required: true, message: "Select Place of Supply" },
                    ]}
                  >
                    {placeOfSupplyList && (
                      <Select
                        placeholder="Select Place of Supply"
                        allowClear={true}
                      >
                        {placeOfSupplyList.map((x) => (
                          <Option
                            key={x.place_of_supply_id}
                            value={x.place_of_supply_id}
                          >
                            {x.place_of_supply}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>

                <Col span={8} xs={24} xl={8}>
                  <Form.Item
                    label="Invoice Format"
                    name="invoice_format_id"
                    rules={[{ required: true, message: "Invoice Format" }]}
                  >
                    {formatList && (
                      <Select
                        placeholder="Select Invoice Format"
                        allowClear={true}
                      >
                        {formatList.map((x) => (
                          <Option
                            key={x.invoice_format_id}
                            value={x.invoice_format_id}
                          >
                            {x.invoice_format}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={16} xs={24} xl={16}>
                  <Row>
                    <Col span={12} xs={24} xl={12}>
                      <Form.Item
                        label="Employee Name"
                        name="employee_id"
                        rules={[
                          { required: true, message: "Select Employee Name" },
                        ]}
                      >
                        <UserPickerDropDown />
                      </Form.Item>
                    </Col>

                    <Col span={12} xs={24} xl={12}>
                      <Form.Item
                        label="Display Name"
                        name="display_name"
                        rules={[
                          { required: true, message: "Input Display Name" },
                        ]}
                      >
                        <Input placeholder="Display Name" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12} xs={24} xl={12}>
                      <Form.Item label="PO">
                        <Form.Item
                          name="po_number"
                          style={{
                            display: "inline-block",
                            width: "calc(52% - 8px)",
                            margin: "0 8px 0 0px",
                          }}
                        >
                          <Input placeholder="PO Number" />
                        </Form.Item>

                        <Form.Item
                          name="po_date"
                          style={{
                            display: "inline-block",
                            width: "48%",
                          }}
                        >
                          <DatePicker
                            placeholder="PO Date"
                            inputReadOnly={true}
                          />
                        </Form.Item>
                      </Form.Item>
                    </Col>

                    <Col span={12} xs={24} xl={12}>
                      <Form.Item
                        name="gst_type"
                        label="Apply GST"
                        rules={[
                          {
                            required: true,
                            message: "Select Apply GST",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Apply GST"
                          allowClear={true}
                        >
                          {gstTypeList.map((x) => (
                            <Option key={x.value} value={x.value}>
                              {x.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col span={8} xs={24} xl={8}>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="notes" label="Notes">
                        <Input.TextArea
                          placeholder="Notes"
                          style={{ height: "88px" }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Divider style={{ marginTop: "8px" }} />

              <Row style={{ width: "100%" }}>
                <Form.List name="manual_inventory_details">
                  {(fields, { add, remove }) => {
                    return (
                      <Col span="22">
                        {fields.map((field, index) => (
                          <Space key={field.key}>
                            <Row>
                              <Col span="12" xs={24} xl={12}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "description"]}
                                  fieldKey={[field.fieldKey, "description"]}
                                  {...layout2}
                                  label={"Item " + Number(index + 1)}
                                >
                                  <Input placeholder="Description" />
                                </Form.Item>
                              </Col>

                              <Col span="3" xs={24} xl={3}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "start_date"]}
                                  fieldKey={[field.fieldKey, "start_date"]}
                                  {...layoutInline}
                                >
                                  <DatePicker
                                    placeholder="Start Date"
                                    inputReadOnly={true}
                                  />
                                </Form.Item>
                              </Col>

                              <Col span="3" xs={24} xl={3}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "end_date"]}
                                  fieldKey={[field.fieldKey, "end_date"]}
                                  {...layoutInline}
                                >
                                  <DatePicker
                                    placeholder="End Date"
                                    inputReadOnly={true}
                                  />
                                </Form.Item>
                              </Col>

                              <Col span="3" xs={24} xl={3}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "monthly_rate"]}
                                  fieldKey={[field.fieldKey, "monthly_rate"]}
                                  {...layoutInline}
                                >
                                  <InputNumber
                                    min={0}
                                    style={{ width: "100%" }}
                                    placeholder="Monthly Rate"
                                  />
                                </Form.Item>
                              </Col>

                              <Col span="3" xs={24} xl={3}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "manual_cost"]}
                                  fieldKey={[field.fieldKey, "manual_cost"]}
                                  {...layoutInline}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Input Manual Cost",
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    min={0}
                                    style={{ width: "100%" }}
                                    placeholder="Manual Cost *"
                                  />
                                </Form.Item>
                              </Col>
                            </Row>

                            {fields.length > 1 ? (
                              <MinusCircleOutlined
                                className="dynamic-delete-button"
                                onClick={() => {
                                  remove(field.name);
                                }}
                              />
                            ) : null}
                          </Space>
                        ))}

                        <PlusOutlined
                          className="dynamic-add-button"
                          onClick={() => {
                            add();
                          }}
                        />
                      </Col>
                    );
                  }}
                </Form.List>
              </Row>

              <Form.Item
                className="ant-text-center"
                style={{ marginTop: "24px" }}
                wrapperCol={{ span: 24 }}
              >
                <Button
                  type="primary"
                  size={"large"}
                  htmlType="submit"
                  icon={<FileAddOutlined />}
                  loading={loading}
                >
                  {mode} Manual Invoice
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default ManualInvoiceAdd;
