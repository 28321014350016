import React from "react";
import { Row, Col, Table, Button, Radio, Popconfirm } from "antd";
import {
  EditOutlined,
  DownloadOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import PageTitle from "../../components/common/page-title";
import ListFilters from "../../components/common/list-filters";
import CommonListBase from "../../base/common-list-base";
import {
  currencyFormatter,
  dateFormatter,
} from "../../components/common/common-formatter";
import PortalUser from "../../iam/components/portal-user";
import { Link } from "react-router-dom";
import { RolesPermissions } from "../../helpers/roles-permissions";

class InvoiceList extends CommonListBase {
  state = {
    listReq: "salesestimate/estimateinvoicelist?employeeid=1&",
    delReq: "salesestimate/invoice/cancel?invoiceid=",
    dtlReq: "/sales-estimate/invoice/edit/",
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    sorting: [],
    options: [],
    value: "Estimate Invoices",
    screen: "invoice-list",
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "Project Id",
        dataIndex: "project_id",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 100,
      },
      {
        title: "Type",
        dataIndex: "invoice_type",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => (text === "E" ? "From Estimate" : "Manual"),
        width: 120,
      },
      {
        title: "Invoice Number",
        dataIndex: "invoice_no",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) => (
          <Link
            style={{ padding: "0px", whiteSpace: "normal", textAlign: "left" }}
            type="link"
            to={this.state.dtlReq + record.invoice_id}
          >
            {text}
          </Link>
        ),
        width: 136,
      },

      {
        title: "Employee",
        dataIndex: "employee_id",
        render: (text) => <PortalUser user={text} />,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 140,
      },
      {
        title: "Customer Name",
        dataIndex: "customer_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 140,
      },
      {
        title: "Display",
        dataIndex: "display_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 118,
      },
      {
        title: "Invoice Date",
        dataIndex: "invoice_date",
        render: (text) => dateFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "From",
        dataIndex: "duration_from",
        render: (text) => dateFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "To",
        dataIndex: "duration_to",
        render: (text) => dateFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Status",
        dataIndex: "invoice_status",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Rent Amount",
        dataIndex: "total_display_cost",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: "Print Amount",
        dataIndex: "total_printing_cost",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: "Installation Amount",
        dataIndex: "total_installing_cost",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: "Total Without Tax",
        dataIndex: "invoice_amount",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => currencyFormatter(parseInt(text)),
        width: 118,
      },
      {
        title: "Total With Tax",
        dataIndex: "total_amount",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => currencyFormatter(parseInt(text)),
        width: 112,
      },
      {
        title: "Campaign Difference",
        dataIndex: "campaign_difference",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => (
          <span className={text > 0 ? "ant-color-green" : "ant-color-red"}>
            {currencyFormatter(parseInt(text))}
          </span>
        ),
      },
      {
        title: "HA Markup",
        dataIndex: "ha_markup",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) => (
          <span className={text > 0 ? "ant-color-green" : "ant-color-red"}>
            {currencyFormatter(parseInt(text))} ({record.ha_markup_per}%)
          </span>
        ),
      },
      {
        title: "TA Markup",
        dataIndex: "ta_markup",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) => (
          <span className={text > 0 ? "ant-color-green" : "ant-color-red"}>
            {currencyFormatter(parseInt(text))} ({record.ta_markup_per}%)
          </span>
        ),
      },
      {
        title: "QoS",
        dataIndex: "qos",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) =>
          text && (
            <span className={text > 0 ? "ant-color-green" : "ant-color-red"}>
              {currencyFormatter(parseInt(text))} ({record.qos_per}%)
            </span>
          ),
      },
      {
        title: "Entry in Accounting Software",
        dataIndex: "entry_in_accounting_software",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 168,
      },
      {
        title: " Invoice Printed",
        dataIndex: " invoice_printed",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Download PO",
        dataIndex: "po_url",
        render: (text) => (
          <Button
            icon={<DownloadOutlined />}
            className="ant-btn-reset"
            disabled={text ? false : true}
            onClick={() => window.open(text)}
          />
        ),
        align: "center",
      },
      // {
      //   title: "Download Invoice",
      //   dataIndex: "download_invoice",
      //   render: (record) => (
      //     <Button icon={<DownloadOutlined />} className="ant-btn-reset" />
      //   ),
      //   align: "center",
      // },
      {
        title: "Actions",
        dataIndex: "",
        key: "x",
        render: (record) =>
          RolesPermissions("ad") && (
            <Button
              icon={<EditOutlined />}
              className="ant-btn-reset"
              onClick={() => this.editInvoice(record.invoice_id)}
            />
          ),
        align: "center",
      },
    ];

    let filterColumns = [
      { columnName: "project_id", displayName: "Project Id" },
      { columnName: "invoice_type", displayName: "Type", hideFilter: true },
      { columnName: "invoice_no", displayName: "Invoice Number" },
      { columnName: "employee_id", displayName: "Employee" },
      {
        columnName: "customer_name",
        displayName: "Customer Name",
      },
      { columnName: "display_name", displayName: "Display", default: true },
      {
        columnName: "invoice_date",
        displayName: "Invoice Date",
        hideFilter: true,
      },
      { columnName: "duration_from", displayName: "From", hideFilter: true },
      { columnName: "duration_to", displayName: "To", hideFilter: true },
      { columnName: "invoice_status", displayName: "Status", hideFilter: true },
      {
        columnName: "total_display_cost",
        displayName: "Rent Amount",
        hideFilter: true,
      },
      {
        columnName: "total_printing_cost",
        displayName: "Print Amount",
        hideFilter: true,
      },
      {
        columnName: "total_installing_cost",
        displayName: "Installation Amount",
        hideFilter: true,
      },
      {
        columnName: "invoice_amount",
        displayName: "Total Without Tax",
        hideFilter: true,
      },
      {
        columnName: "total_amount",
        displayName: "Total With Tax",
        hideFilter: true,
      },
      {
        columnName: "campaign_difference",
        displayName: "Campaign Difference",
        hideFilter: true,
      },
      { columnName: "ta_markup", displayName: "HA Markup", hideFilter: true },
      { columnName: "ta_markup", displayName: "TA Markup", hideFilter: true },
      { columnName: "base_rate_diff", displayName: "QoS", hideFilter: true },
      {
        columnName: "entry_in_accounting_software",
        displayName: "Entry in Accounting Software",
        hideFilter: true,
      },
      {
        columnName: "invoice_printed",
        displayName: "Invoice Printed",
        hideFilter: true,
      },
      {
        columnName: "po_url",
        displayName: "Download PO",
        hideFilter: true,
      },
      // {
      //   columnName: "download_invoice",
      //   displayName: "Download Invoice",
      //   hideFilter: true,
      // },
    ];

    if (this.state.value === "Manual Invoices") {
      columns = [
        {
          title: "Employee",
          dataIndex: "employee_id",
          render: (text) => <PortalUser user={text} />,
          sorter: true,
          sortDirections: ["ascend", "descend", "ascend"],
          width: 140,
        },
        {
          title: "Customer Name",
          dataIndex: "customer_name",
          width: 142,
        },
        {
          title: "Display",
          dataIndex: "display_name",
        },
        {
          title: "PO Number",
          dataIndex: "po_number",
        },
        {
          title: "PO Date",
          dataIndex: "po_date",
          render: (text) => dateFormatter(text),
        },
        {
          title: "Total Invoice Amount",
          dataIndex: "total_amount",
          render: (text) => currencyFormatter(parseInt(text)),
          sorter: true,
          sortDirections: ["ascend", "descend", "ascend"],
        },
        {
          title: "Invoice Number",
          dataIndex: "invoice_no",
          sorter: true,
          sortDirections: ["ascend", "descend", "ascend"],
          render: (text, record) => (
            <Link
              style={{
                padding: "0px",
                whiteSpace: "normal",
                textAlign: "left",
              }}
              type="link"
              to={this.state.dtlReq + record.invoice_id}
            >
              {text}
            </Link>
          ),
        },
        {
          title: "Invoice Date",
          dataIndex: "invoice_date",
          render: (text) => dateFormatter(text),
          sorter: true,
          sortDirections: ["ascend", "descend", "ascend"],
        },
        {
          title: "Entry Done by",
          dataIndex: "created_by_id",
          render: (text) => <PortalUser user={text} />,
        },
        {
          title: "Actions",
          dataIndex: "",
          key: "x",
          render: (record) => (
            <Popconfirm
              title="Are you sure want to cancel?"
              placement="left"
              okText="Yes"
              cancelText="No"
              onConfirm={() => this.deleteItem(record.invoice_id)}
            >
              <Button icon={<DeleteOutlined />} className="ant-btn-reset" />
            </Popconfirm>
          ),
          align: "center",
        },
      ];

      filterColumns = [
        {
          columnName: "employee_id",
          displayName: "Employee",
        },
        {
          columnName: "customer_name",
          displayName: "Customer Name",
        },
        {
          columnName: "display_name",
          displayName: "Display",
          default: true,
        },
        { columnName: "po_number", displayName: "Po Number", hideFilter: true },
        { columnName: "po_date", displayName: "Po Date", hideFilter: true },
        {
          columnName: "total_amount",
          displayName: "Total Invoice Amount",
          hideFilter: true,
        },
        {
          columnName: "invoice_date",
          displayName: "Invoice Date",
          hideFilter: true,
        },
        {
          columnName: "invoice_no",
          displayName: "Invoice Number",
        },
        {
          columnName: "created_by_id",
          displayName: "Entry Done by",
          hideFilter: true,
        },
      ];
    }

    if (this.state.value === "Cancelled Invoices") {
      columns = columns.slice(0, -3);
      filterColumns = filterColumns.slice(0, -3);

      columns.splice(2, 1, {
        title: "Invoice Number",
        dataIndex: "invoice_no",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 136,
      });
    }

    this.setState({ columnsDefault: columns, filterColumns }, () =>
      this.setDefaultColumns(columns)
    );
  };

  // Set Tabs
  setTabs = () => {
    const options = [
      { label: "Estimate Invoices", value: "Estimate Invoices" },
      { label: "Manual Invoices", value: "Manual Invoices" },
      { label: "Cancelled Invoices", value: "Cancelled Invoices" },
    ];

    this.setState({ options });
  };

  // Tab Change
  onTabChange = (e) => {
    let activeTab = e.target.value;

    let id = this.props.match.params.id;

    let pagination = {
      current: 1,
      pageSize: 20,
    };

    let listReq = "salesestimate/";

    if (activeTab === "Estimate Invoices") {
      listReq = listReq + "estimateinvoicelist?employeeid=1&";
    }
    if (activeTab === "Manual Invoices") {
      listReq = listReq + "manualinvoicelist?employeeid=1&";
    }
    if (activeTab === "Cancelled Invoices") {
      listReq = listReq + "cancelledinvoicelist?employeeid=1&";
    }

    this.setState({ value: activeTab }, () => this.setColumns());
    this.setState({ listReq }, () => this.getResults({ pagination }));
  };

  addManualInvoice = () => {
    this.props.history.push("/sales-estimate/manual-invoice-add");
  };

  // Redirect to Invoice edit Flow
  editInvoice = (id) => {
    this.props.history.push(`/sales-estimate/sales-estimate-edit/${id}`);
  };

  componentDidMount() {
    // Get List
    const { pagination } = this.state;
    this.getResults({ pagination });
    this.setColumns();
    this.setTabs();
  }

  render() {
    const {
      data,
      columns,
      pagination,
      loading,
      filterColumns,
      options,
      value,
      screen,
    } = this.state;

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageTitle titleText={"Invoice List - " + value} />

            <Radio.Group
              options={options}
              onChange={this.onTabChange}
              value={options.find((x) => x.value === value) ? value : ""}
              optionType="button"
              buttonStyle="solid"
              loading={true}
              style={{ marginBottom: "16px" }}
            />

            {value === "Manual Invoices" &&
              (RolesPermissions("ad") || RolesPermissions("ac")) && (
                <div className="page-title-custom-btn invoicelist-btns">
                  <Button
                    type="primary"
                    onClick={this.addManualInvoice}
                    icon={<PlusOutlined />}
                    className="icon-btn"
                  >
                    Add Manual Invoice
                  </Button>
                </div>
              )}

            <ListFilters
              items={filterColumns}
              handleSettings={this.handleSettings}
              handleFilters={this.handleFilters}
              key={filterColumns}
              screen={screen}
            />

            <Table
              columns={columns}
              rowKey={(record) => record.invoice_id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              bordered
              scroll={{ x: 400 }}
              size="small"
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default InvoiceList;
