import React from "react";
import { Row, Col } from "antd";
import PageTitleDetails from "../../components/common/page-title-detail";
import InventoryAddForm from "../../components/inventories/inventory-add-form";
import InventoriesBase from "../../base/inventories-base";

class InventoryAdd extends InventoriesBase {
  state = {
    isSuccess: false,
    inventoryKey: 1,
    mediaTypeList: "",
    isGroup:
      this.props.match.path.split("/")[2] === "inventory-add-group" ||
      this.props.match.path.split("/")[2] === "ta-inventory-add-group",
    inventoryType: this.props.match.path.includes("ta-")
      ? "tainventory"
      : "hainventory",
  };

  componentDidMount() {
    this.getLookupData();
    this.getSupplierList();
  }

  render() {
    const { inventoryKey, isGroup, inventoryType } = this.state;

    return (
      <Row>
        <Col span={24}>
          <PageTitleDetails
            titleText={
              "Add " +
              (inventoryType === "tainventory" ? "TA " : "HA ") +
              (isGroup ? "Grouped Inventory" : "Inventory")
            }
            isBackBtn="true"
            handleBack={this.handleBack}
          />
          <InventoryAddForm
            {...this.state}
            key={inventoryKey}
            handleAddInventory={this.handleAddInventory}
            handleBack={this.handleBack}
            handleUploadImages={this.handleUploadImages}
          />
        </Col>
      </Row>
    );
  }
}

export default InventoryAdd;
