import React, { Component } from "react";
import { Form, Button, DatePicker, Input, Radio, Select } from "antd";
import { ajaxRequester } from "../../services/ajax-requester";

class ReceiveUnmountedFlex extends Component {
  state = { branchList: [], loading: false };

  formRef = React.createRef();

  onFinish = (values) => {
    const { branchList } = this.state;

    values.branch_id = branchList?.find(
      (x) => x?.branch_name === values?.branch_id
    )?.branch_id;

    this.props.handleReceiveUnmountedFlex(values);
  };

  getBranchList = () => {
    let reqUrl = "branch/list";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          branchList: data.response,
        });
      },
      "GET"
    );
  };

  disabledDate = (current) => {
    // let start_date = this?.props?.selectedRows[0]?.start_date;
    // return current && current < dayjs(start_date, "YYYY-MM-DD");
  };

  setData = () => {
    // let data = this?.props?.editRecord;
    // let mounting_date =
    //   this?.props?.modalAction === "edit-mounting"
    //     ? data?.mounting_date
    //     : data?.unmounting_date;
    // this.formRef.current.setFieldsValue({
    //   mounting_date: mounting_date && dayjs(mounting_date),
    // });
  };

  componentDidMount() {
    // (this?.props?.modalAction === "edit-mounting" ||
    //   this?.props?.modalAction === "edit-unmounting-photo") &&
    //   this.setData();
    this.getBranchList();
  }

  render() {
    const { modalTitle } = this.props;

    const { loading, branchList } = this.state;

    const { Option } = Select;

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    return (
      <Form
        name="operation-modal"
        {...layout}
        onFinish={this.onFinish}
        ref={this.formRef}
      >
        <Form.Item
          name="flex_received_date"
          label="Flex Receive Date"
          rules={[{ required: true, message: "Please Select Date" }]}
        >
          <DatePicker disabledDate={this.disabledDate} inputReadOnly={true} />
        </Form.Item>

        <Form.Item name="flex_received_status" label="Flex Received Status">
          <Radio.Group defaultValue="O">
            <Radio value="O">Ok</Radio>
            <Radio value="S">Mark As Stolen</Radio>
            <Radio value="T">Mark As Torn</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="branch_id"
          label="Branch"
          rules={[{ required: true, message: "Please Select Branch" }]}
        >
          <Select placeholder="Branch" allowClear showSearch>
            {this.state.branchList.map((x) => (
              <Option key={x.branch_name} value={x.branch_name}>
                {x.branch_name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="remarks" label="Remarks">
          <Input.TextArea placeholder="Remarks" />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8 }} className="model-btns">
          <Button type="primary" htmlType="submit">
            {modalTitle}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default ReceiveUnmountedFlex;
