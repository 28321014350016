import React, { Component } from "react";
import { Form, Button, Select } from "antd";
import { ajaxRequester } from "../../services/ajax-requester";
import AdminApi from "../../iam/api/admin-api";

class AssignMounter extends Component {
  state = { mountersList: [] };

  onFinish = (values) => {
    let { page } = this?.props;
    if (page !== "mounting-unmounting") {
      this.props.handleAssignMounter(values.assigned_mounter_id);
    } else {
      this.props.handleAssignMounterUnmounter(values.assigned_mounter_id);
    }
  };

  // Get Mounter List
  getMounters = () => {
    let { selectedRows, page } = this?.props;

    if (page !== "mounting-unmounting") {
      selectedRows = selectedRows.map(
        (x) => x?.ha_inventory_id || x?.ha_inventory_id
      );
    } else {
      let selectedRowsUnmount = selectedRows.map(
        (x) => x?.unmount_ha_inventory_id
      );
      let selectedRowsMount = selectedRows.map((x) => x?.mount_ha_inventory_id);
      selectedRows = [...selectedRowsMount, ...selectedRowsUnmount];
    }

    let reqUrl = "operation/mounter/list";
    let reqObj = {
      request: selectedRows,
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      this.setState(
        {
          mountersList: data?.response || [],
        },
        () => this.setMountersList()
      );
    });
  };

  setMountersList = () => {
    let { mountersList } = this.state;

    AdminApi.queryUsers()
      .then((mounters) => {
        let result = mounters?.result.filter((o) =>
          mountersList.some((id) => o.id === id)
        );
        this.setState({ mountersList: result || [] });
      })
      .catch((nothing) => {});
  };

  componentDidMount() {
    this.getMounters();
  }

  render() {
    const { modalTitle, page } = this.props;
    const { mountersList } = this.state;
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };
    const { Option } = Select;

    return (
      <Form name="operation-modal" {...layout} onFinish={this.onFinish}>
        <Form.Item
          name="assigned_mounter_id"
          label={
            page === "pending-unmounting-photo"
              ? "Select Unmounter"
              : "Select Mounter"
          }
        >
          <Select
            placeholder={
              page === "pending-unmounting-photo"
                ? "Select Unmounter"
                : "Select Mounter"
            }
          >
            {mountersList.map((x) => (
              <Option key={x.id} value={x.id}>
                {x.firstName + " " + x.lastName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8 }} className="model-btns">
          <Button type="primary" htmlType="submit">
            {modalTitle}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default AssignMounter;
