import React, { Component } from "react";
import { ajaxRequester } from "../../services/ajax-requester";
import { Button, Form, Input, Select, Switch } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

class MasterAdd extends Component {
  state = { stateList: [], districtList: [], cityList: [], areaList: [] };

  formRef = React.createRef();

  setDefaultData = () => {
    const { record } = this?.props;

    this.formRef.current.setFieldsValue({
      state_name: record?.state_name,
      district_name: record?.district_name,
      city_name: record?.city_name,
      area_name: record?.area_name,
      supplier_name: record?.supplier_name,
      is_active: record?.is_active,
    });
  };

  // Get State List from Lookup
  getStateList = () => {
    let reqUrl = "lookup/state";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ stateList: data.response });
      },
      "GET"
    );
  };

  // Get District List from Lookup
  getDistrictList = (state) => {
    this.setState({ districtList: [] });
    let reqUrl = "lookup/district?state=" + state;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {        
        this.setState({ districtList: data.response });
      },
      "GET"
    );
  };

  // Get City List from Lookup
  getCityList = (state, district) => {
    let reqUrl = "lookup/city?state=" + state + "&district=" + district;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ cityList: data.response });
      },
      "GET"
    );
  };

  ValuesChange = (value, values) => {
    if (value["state_name"]) {
      this.formRef.current.setFieldsValue({
        district_name: undefined,
      });
      this.formRef.current.setFieldsValue({
        city_name: undefined,
      });
      this.getDistrictList(value["state_name"]);
    }

    if (value["district_name"]) {
      this.formRef.current.setFieldsValue({
        city_name: undefined,
      });
      this.getCityList(values["state_name"], value["district_name"]);
    }
  };

  onFinish = (values) => {
    const { isEditMode, record } = this.props;
    const { stateList, districtList, cityList } = this.state;

    values.state_id = stateList.find(
      (x) => x?.state === values?.state_name
    )?.state_id;

    values.district_id = districtList.find(
      (x) => x?.district === values?.district_name
    )?.district_id;

    values.city_id = cityList.find(
      (x) => x?.city === values?.city_name
    )?.city_id;

    if (isEditMode) {
      values.state_id = record?.state_id;
      values.district_id = record?.district_id;
      values.city_id = record?.city_id;
      values.area_id = record?.area_id;
      values.supplier_id = record?.supplier_id;
    }

    this.props.handleAddEdit(values);
  };

  componentDidMount() {
    const { isEditMode } = this.props;
    this.getStateList();
    isEditMode && this.setDefaultData();
  }

  render() {
    const { stateList, districtList, cityList, areaList } = this.state;
    const { isEditMode } = this?.props;

    const { Option } = Select;

    const layout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };

    const { record, activeTab } = this.props;

    return (
      <Form
        name="master_add"
        className="master_add"
        {...layout}
        onFinish={this.onFinish}
        onValuesChange={this.ValuesChange}
        ref={this.formRef}
      >
        {activeTab === "State" && (
          <Form.Item
            label="State Name"
            name="state_name"
            rules={[
              {
                required: true,
                message: "Please Input State Name !",
              },
            ]}
          >
            <Input placeholder="State Name" />
          </Form.Item>
        )}

        {(activeTab === "District" ||
          activeTab === "City" ||
          activeTab === "Area") && (
          <Form.Item
            label="State Name"
            name="state_name"
            rules={[
              {
                required: true,
                message: "Please Input State Name !",
              },
            ]}
          >
            <Select
              disabled={isEditMode ? true : false}
              placeholder="State"
              allowClear={true}
              showSearch={true}
            >
              {stateList.map((x) => (
                <Option key={x.state} value={x.state}>
                  {x.state}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {(activeTab === "City" || activeTab === "Area") && (
          <Form.Item
            label="District Name"
            name="district_name"
            rules={[
              {
                required: true,
                message: "Please Input District Name !",
              },
            ]}
          >
            <Select
              placeholder="District"
              allowClear={true}
              showSearch={true}
              disabled={isEditMode ? true : false}
            >
              {districtList.map((x) => (
                <Option key={x.district} value={x.district}>
                  {x.district}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {activeTab === "Area" && (
          <Form.Item
            label="City Name"
            name="city_name"
            rules={[
              {
                required: true,
                message: "Please Input City Name !",
              },
            ]}
          >
            <Select
              placeholder="City"
              allowClear={true}
              showSearch={true}
              disabled={isEditMode ? true : false}
            >
              {cityList.map((x) => (
                <Option key={x.city} value={x.city}>
                  {x.city}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {activeTab === "District" && (
          <Form.Item
            label="District Name"
            name="district_name"
            rules={[
              {
                required: true,
                message: "Please Input District Name !",
              },
            ]}
          >
            <Input placeholder="District Name" />
          </Form.Item>
        )}

        {activeTab === "City" && (
          <Form.Item
            label="City Name"
            name="city_name"
            rules={[
              {
                required: true,
                message: "Please Input City Name !",
              },
            ]}
          >
            <Input placeholder="City Name" />
          </Form.Item>
        )}

        {activeTab === "Area" && (
          <Form.Item
            label="Area Name"
            name="area_name"
            rules={[
              {
                required: true,
                message: "Please Input Area Name !",
              },
            ]}
          >
            <Input placeholder="Area Name" />
          </Form.Item>
        )}

        {activeTab === "Supplier" && (
          <Form.Item
            label="Supplier Name"
            name="supplier_name"
            rules={[
              {
                required: true,
                message: "Please Input Supplier Name !",
              },
            ]}
          >
            <Input placeholder="Supplier Name" />
          </Form.Item>
        )}

        {isEditMode && (
          <Form.Item label="Status" name="is_active">
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              defaultChecked={record?.is_active}
            />
          </Form.Item>
        )}

        <Form.Item wrapperCol={{ offset: 6 }} className="model-btns">
          <Button type="primary" htmlType="submit">
            {isEditMode ? "Update " : "Add "} {activeTab}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default MasterAdd;
