import React, { Component } from "react";
import { Button, DatePicker } from "antd";

class CampaignChangeofsites extends Component {
  state = {};

  onChange = (date, dateString) => {
    this.setState({ dateString });
  };

  handleChangeOfSiteDate = (type) => {
    const { dateString } = this.state;
    this.props.handleChangeOfSiteDate(dateString, type);
  };

  render() {
    const { dateString } = this.state;
    return (
      <div>
        <div>
          <label>COS Date: </label>
          <DatePicker onChange={this.onChange} inputReadOnly={true} />
        </div>
        <div style={{ marginTop: "16px" }}>
          <Button
            type="primary"
            disabled={dateString ? false : true}
            onClick={() => this.handleChangeOfSiteDate("ha")}
          >
            Select New HA
          </Button>
          <Button
            type="primary"
            disabled={dateString ? false : true}
            style={{ marginLeft: "16px" }}
            onClick={() => this.handleChangeOfSiteDate("ta")}
          >
            Select New TA
          </Button>
        </div>
      </div>
    );
  }
}

export default CampaignChangeofsites;
