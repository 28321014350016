import React, { Component } from "react";
import { Row, Col, Spin, Radio, Checkbox, Space, Button, message } from "antd";
import { ajaxRequester } from "../../services/ajax-requester";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

class PhotoBucketUpdate extends Component {
  state = {
    record: "",
    images: [],
    records: [],
    currentIndex: 0,
    isBtnLoading: false,
    radioIndex: 0,
  };

  _onSlide(index) {
    const { images, radioIndex } = this.state;
    this.setState({ currentIndex: index, radioIndex: radioIndex + 1 });
  }

  onChangeRadio = (e) => {
    let { records, currentIndex, radioIndex } = this.state;
    let currentId = records[currentIndex]?.operation_media_id;

    if (e.target.name === "photo_category") {
      records = records.map((x) => ({
        ...x,
        photo_category:
          x.operation_media_id === currentId
            ? e.target.value
            : x.photo_category,
      }));
    }

    if (e.target.name === "monitoring_type") {
      records = records.map((x) => ({
        ...x,
        monitoring_type:
          x.operation_media_id === currentId
            ? e.target.value
            : x.monitoring_type,
      }));
    }

    this.setState({ records, radioIndex: radioIndex + 1 });
  };

  onChangeCheckBox = (e) => {
    let { records, currentIndex } = this.state;
    let currentId = records[currentIndex]?.operation_media_id;
    let currentType = records[currentIndex]?.photo_category;

    if (e.target.name === "primary") {
      records = records.map((x) => ({
        ...x,
        is_primary:
          x.operation_media_id === currentId
            ? e.target.checked
            : x.photo_category === currentType
            ? false
            : x.is_primary,
      }));
    }

    if (e.target.name === "master") {
      records = records.map((x) => ({
        ...x,
        replace_inventory_photo:
          x.operation_media_id === currentId
            ? e.target.checked
            : x.photo_category === currentType
            ? false
            : x.replace_inventory_photo,
      }));
    }

    this.setState({ records });
  };

  getTitle = (index) => {
    const { images, currentIndex } = this.state;
    return "Review Photo " + (currentIndex + 1) + " out of " + images.length;
  };

  updatePhotos = () => {
    const { records } = this.state;
    this.setState({ isBtnLoading: true });

    let reqUrl = "photolibrary/reviewphoto/update";
    let reqObj = {};

    records.map(
      (x, key) => (
        (reqObj = {
          request: {
            operation_media_id: x?.operation_media_id,
            photo_category: x?.photo_category,
            monitoring_type: x?.monitoring_type,
            is_primary: x?.is_primary,
            replace_inventory_photo: x?.replace_inventory_photo,
          },
        }),
        ajaxRequester(reqUrl, reqObj, (data) => {
          if (data.success) {
            if (key + 1 === records.length) {
              this.props.updateRecords();
              this.setState({ isBtnLoading: false });
            }
          } else {
            message.error(data);
            this.setState({ isBtnLoading: false });
          }
        })
      )
    );
  };

  componentDidMount() {
    let photos = this?.props?.opeartion_photos || [];

    let images = [];
    let records = [];
    let photo_category = { news: [], long: [], short: [] };

    photos &&
      photos.map(
        (x) => (
          images.push({
            ...x,
            original: x.data_url,
            thumbnail: x.thumb_url,
            title: x.data_url.split("/").pop(),
          }),
          records.push({
            ...x,
            is_primary: false,
            replace_inventory_photo: false,
          }),
          x?.photo_category === "N" &&
            photo_category.news.push(x.operation_media_id),
          x?.photo_category === "L" &&
            photo_category.long.push(x.operation_media_id),
          x?.photo_category === "S" &&
            photo_category.short.push(x.operation_media_id)
        )
      );

    if (photo_category.news > 0) {
      records.find(
        (x) => x.operation_media_id === photo_category.news[0]
      ).is_primary = true;
    }

    if (photo_category.long > 0) {
      records.find(
        (x) => x.operation_media_id === photo_category.long[0]
      ).is_primary = true;
    }

    if (photo_category.short > 0) {
      records.find(
        (x) => x.operation_media_id === photo_category.short[0]
      ).is_primary = true;
    }

    this.setState({ images, records });
  }

  render() {
    const { currentIndex, images, records, loaded, isBtnLoading, radioIndex } =
      this.state;

    const { operation_photo_type } = this.props;

    return (
      <Row gutter={24}>
        <Col span={16} xs={24} xl={16}>
          {images && loaded && (
            <ImageGallery
              items={images}
              onSlide={this._onSlide.bind(this)}
              lazyLoad={true}
              showThumbnails={false}
              startIndex={this?.props?.startIndex || 0}
            />
          )}

          {images && !loaded && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "200px",
              }}
            >
              <Spin />
            </div>
          )}

          {images && images.length > 0 && (
            <img
              src={images[0].original}
              onLoad={() => this.setState({ loaded: true })}
              style={{ display: "none" }}
            />
          )}
        </Col>
        <Col span={8} xs={24} xl={8}>
          <Row>
            <Col span={24} xs={24} xl={24}>
              <h3
                style={{
                  borderBottom: "1px solid #f0f0f0",
                  marginBottom: "16px",
                  paddingBottom: "16px",
                }}
              >
                {this.getTitle()}
              </h3>
            </Col>
            <Col span={24} xs={24} xl={24}>
              <Radio.Group
                name="photo_category"
                value={records[currentIndex]?.photo_category}
                onChange={this.onChangeRadio}
                style={{ marginBottom: "24px" }}
                key={radioIndex}
              >
                <Space direction="vertical">
                  <Radio value="N">Newspaper</Radio>
                  <Radio value="L">Long</Radio>
                  <Radio value="S">Short</Radio>
                </Space>
              </Radio.Group>
            </Col>

            <Col span={24} xs={24} xl={24}>
              {operation_photo_type === "O" && (
                <Radio.Group
                  name="monitoring_type"
                  value={records[currentIndex]?.monitoring_type}
                  onChange={this.onChangeRadio}
                  style={{ marginBottom: "24px" }}
                >
                  <Space direction="vertical">
                    <Radio value="Day">Day</Radio>
                    <Radio value="Night">Night</Radio>
                  </Space>
                </Radio.Group>
              )}
            </Col>

            <Col span={24} xs={24} xl={24}>
              <Space direction="vertical">
                <Checkbox
                  checked={records[currentIndex]?.is_primary}
                  name="primary"
                  onChange={this.onChangeCheckBox}
                >
                  Set As Primary Photo
                </Checkbox>
                <Checkbox
                  checked={records[currentIndex]?.replace_inventory_photo}
                  name="master"
                  onChange={this.onChangeCheckBox}
                >
                  Replace Inventory Master Photo
                </Checkbox>
              </Space>
            </Col>
            <Col span={24} xs={24} xl={24}>
              <Button
                style={{ marginTop: "24px" }}
                type="primary"
                onClick={this.updatePhotos}
                disabled={images && images?.length !== currentIndex + 1}
                loading={isBtnLoading}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default PhotoBucketUpdate;
