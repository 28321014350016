import React from "react";
import { Row, Col, Table, Switch, Button, Popconfirm } from "antd";
import PageTitle from "../../components/common/page-title";
import ListFilters from "../../components/common/list-filters";
import CommonListBase from "../../base/common-list-base";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import PortalUser from "../../iam/components/portal-user";
import {
  currencyFormatter,
  dateFormatter,
} from "../../components/common/common-formatter";
import { RolesPermissions } from "../../helpers/roles-permissions";

class OneTimeTransactions extends CommonListBase {
  state = {
    listReq: "onetimetransaction/list?",
    delReq: "onetimetransaction/delete?pendingEstimateId=",
    dtlReq: "operation",
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    sorting: [],
    screen: "one-time-transaction",
    isDrawer: false,
    record: "",
    isEditMode: false,
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "Type",
        dataIndex: "estimate_type",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "District",
        dataIndex: "district_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "City",
        dataIndex: "city_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Authority ID",
        dataIndex: "authority_id",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 120,
      },
      {
        title: "Payout Estimate Name",
        dataIndex: "payout_estimate_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 178,
      },
      {
        title: "Payout From",
        dataIndex: "payout_from_account_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 140,
      },
      {
        title: "Account Name",
        dataIndex: "payout_from_account_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 140,
      },
      {
        title: "From",
        dataIndex: "billing_duration_from",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => dateFormatter(text),
      },
      {
        title: "To",
        dataIndex: "billing_duration_to",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => dateFormatter(text),
      },
      {
        title: "Amount",
        dataIndex: "payout_amount",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: "Mapped SQFT",
        dataIndex: "mapped_sqft",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Amount/SQFT/Month",
        dataIndex: "amount_sqft_month",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "x",
        render: (record) => (
          <React.Fragment>
            <Button
              icon={<EditOutlined />}
              className="ant-btn-reset"
              onClick={() => this.handleEdit(record.pending_estimate_id)}
            />

            {RolesPermissions("su") && (
              <Popconfirm
                title="Are you sure want to delete?"
                placement="left"
                okText="Yes"
                cancelText="No"
                onConfirm={() => this.deleteItem(record.pending_estimate_id)}
              >
                <Button
                  icon={<DeleteOutlined />}
                  className="ant-btn-reset"
                  style={{ marginLeft: "12px" }}
                />
              </Popconfirm>
            )}
          </React.Fragment>
        ),
        align: "center",
      },
    ];

    const filterColumns = [
      {
        columnName: "estimate_type",
        displayName: "Type",
      },
      { columnName: "district_name", displayName: "District" },
      { columnName: "city_name", displayName: "City" },
      { columnName: "authority_id", displayName: "Authority ID" },
      {
        columnName: "payout_estimate_name",
        displayName: "Payout Estimate Name",
        default: true,
      },
      {
        columnName: "payout_from_account_name",
        displayName: "Payout From",
      },
      {
        columnName: "payout_from_account_name",
        displayName: "Account Name",
      },
      {
        columnName: "billing_duration_from",
        displayName: "From",
        hideFilter: true,
      },
      {
        columnName: "billing_duration_to",
        displayName: "To",
        hideFilter: true,
      },
      {
        columnName: "payout_amount",
        displayName: "Amount",
        hideFilter: true,
      },
      {
        columnName: "mapped_sqft",
        displayName: "Mapped SQFT",
      },
      {
        columnName: "amount_sqft_month",
        displayName: "Amount/SQFT/Month",
      },
    ];

    this.setState({ columnsDefault: columns, filterColumns }, () =>
      this.setDefaultColumns(columns)
    );
  };

  //Add Payout Estimate
  handleAdd = () => {
    this.props.history.push("/purchase/manual-transactions-add");
  };

  //Edit Payout Estimate
  handleEdit = (pending_estimate_id) => {
    this.props.history.push(
      "/purchase/manual-transactions-edit/" + pending_estimate_id
    );
  };

  handleActiveToggle = (e) => {
    const { pagination } = this.state;

    this.setState(
      {
        listReq: e
          ? "scheduledtransaction/history?"
          : "scheduledtransaction/list?",
      },
      () => this.getResults({ pagination }, this.setColumns())
    );
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.getResults({ pagination });
    this.setColumns();
  }
  render() {
    const {
      data,
      columns,
      pagination,
      loading,
      filterColumns,
      screen,
      isDrawer,
    } = this.state;

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageTitle
              titleText="One Time Transactions"
              isAddBtn={"Add"}
              handleAdd={this.handleAdd}
            />

            <ListFilters
              items={filterColumns}
              handleSettings={this.handleSettings}
              handleFilters={this.handleFilters}
              key={filterColumns}
              screen={screen}
            />

            <Table
              columns={columns}
              rowKey={(record) => record?.payout_estimate_id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              bordered
              scroll={{ x: 400 }}
              size="small"
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default OneTimeTransactions;
