import React from "react";
import { Row, Col, Table } from "antd";
import PageTitle from "../../components/common/page-title";
import ListFilters from "../../components/common/list-filters";
import InventoryNav from "../../components/inventories/inventory-nav";
import InventoriesListBase from "../../base/inventories-list-base";
import PhotoGallery from "../../components/common/photo-gallery";
class Inventories extends InventoriesListBase {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: {
        current: 1,
        pageSize: 20,
      },
      loading: false,
      columns: [],
      columnsDefault: [],
      filterColumns: [],
      inventoryType: this.props.match.path.includes("ta-")
        ? "tainventory"
        : "hainventory",
      navKey: 1,
      filters: "",
      screen: "inventories",
    };
  }
  

  componentDidMount() {
    // Get Inventories List
    const { pagination } = this.state;
    this.getInventories({ pagination });
    this.setColumns();
  }

  render() {
    const {
      data,
      columns,
      pagination,
      loading,
      inventoryType,
      navKey,
      filterColumns,
      isShowPhotos,
      photos,
      screen,
      photosTitle,
    } = this.state;

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            {inventoryType === "hainventory" && (
              <PageTitle
                titleText="HA Inventory List"
                isAddBtn={"Add HA Inventory"}
                handleAdd={this.addInventory}
              />
            )}

            {inventoryType === "tainventory" && (
              <PageTitle
                titleText="TA Inventory List"
                isAddBtn={"Add TA Inventory"}
                handleAdd={this.addInventory}
              />
            )}

            {inventoryType === "hagroupinventory" && (
              <PageTitle
                titleText="HA Grouped Inventory List"
                isAddBtn={"Add HA Grouped Inventory"}
                handleAdd={this.addInventory}
              />
            )}

            {inventoryType === "tagroupinventory" && (
              <PageTitle
                titleText="TA Grouped Inventory List"
                isAddBtn={"Add TA Grouped Inventory"}
                handleAdd={this.addInventory}
              />
            )}

            <ListFilters
              items={filterColumns}
              handleSettings={this.handleSettings}
              handleFilters={this.handleFilters}
              key={filterColumns}
              screen={screen}
            />

            <InventoryNav
              {...this.state}
              handleToggle={this.handleToggle}
              handleNav={this.handleNav}
              key={navKey}
            />

            {(inventoryType === "hainventory" ||
              inventoryType === "tainventory") && (
              <Table
                columns={columns}
                rowKey={(record) => record.ha_inventory_id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={this.handleTableChange}
                bordered
                scroll={{ x: 400 }}
                size="small"
                expandable={{
                  expandedRowRender: (record) => this.getMultiDetails(record),
                  rowExpandable: (record) =>
                    record.ha_base_inventories &&
                    record.ha_base_inventories.length > 0,
                }}
              />
            )}

            {(inventoryType === "hagroupinventory" ||
              inventoryType === "tagroupinventory") && (
              <Table
                columns={columns}
                rowKey={(record) => record.ha_inventory_id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={this.handleTableChange}
                bordered
                scroll={{ x: 400 }}
                size="small"
                expandable={{
                  expandedRowRender: (record) => this.getGroupDetails(record),
                }}
              />
            )}

            {isShowPhotos && (
              <PhotoGallery
                photos={photos}
                photosTitle={photosTitle}
                handlePhotos={this.handlePhotos}
              />
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default Inventories;
