import React, { Component } from "react";
import { Form, Button, Input, Space, Select, Row, Col } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { ajaxRequester } from "../../services/ajax-requester";

class PoSettings extends Component {
  state = { companyList: [], gstList: [] };
  formRef = React.createRef();

  onFinish = (values) => {
    const { companyList } = this.state;

    let print_company_name = companyList.find(
      (x) => x?.company_id === values?.company_id
    )?.company_name;

    let print_gstin = values.gstin;

    let poTerms = values?.po_terms;

    this.props.handleDownload(poTerms, print_company_name, print_gstin);
  };

  getCustomerDetails = () => {
    const customer_id = this?.props?.results?.customer_id;

    var reqUrl = "customer/detail?customerid=" + Number(customer_id);
    var reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ companyList: data?.response?.companies || [] });
        this.formRef.current.setFieldsValue({
          company_id: data?.response?.companies[0]?.company_id,
          gstin: data?.response?.companies[0]?.gst_detail[0]?.gstin,
        });
      },
      "GET"
    );
  };

  ValuesChange = (value, values) => {
    const { companyList } = this.state;

    if (value["company_id"]) {
      let gst_detail = companyList.find(
        (x) => x.company_id === value["company_id"]
      ).gst_detail;
      this.setState({ gstList: gst_detail });
      this.formRef.current.setFieldsValue({ gstin: null });
    }
  };

  setDefaultData = () => {
    const terms_and_conditions =
      this?.props?.configurationDetails?.configuration_details?.plan_settings
        ?.terms_and_conditions || [];

    let poTerms = terms_and_conditions.map((x) => x);

    this.formRef.current.setFieldsValue({
      po_terms: poTerms,
    });
  };

  componentDidMount() {
    this.setDefaultData();
    this.getCustomerDetails();
  }

  render() {
    const { companyList, gstList } = this.state;

    const { Option } = Select;

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };
    return (
      <div>
        <Form
          name="po_settings"
          onFinish={this.onFinish}
          className="edit-configuration po_settings"
          ref={this.formRef}
          onValuesChange={this.ValuesChange}
        >
          <Row>
            <Col span={12} xs={24} xl={12}>
              <Form.Item
                label="Company Name"
                name="company_id"
                rules={[{ required: true, message: "Select Company Name" }]}
                {...layout}
              >
                <Select placeholder="Select Customer Name" allowClear={true}>
                  {companyList.map((x) => (
                    <Option key={x.company_id} value={x.company_id}>
                      {x.company_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12} xs={24} xl={12}>
              <Form.Item
                label="GSTIN"
                name="gstin"
                tooltip="Clear Company Name to get updated GSTIN"
                {...layout}
              >
                <Select placeholder="Select GSTIN">
                  {gstList.map((x) => (
                    <Option key={x.gstin} value={x.gstin}>
                      {x.gstin}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <h4 style={{ marginTop: "0px" }}>Terms and Conditions</h4>

          <Form.List name="po_terms">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <Space key={field.key}>
                      <Form.Item {...field}>
                        <Input placeholder="Terms and Conditions" />
                      </Form.Item>

                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      ) : null}
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                    >
                      <PlusOutlined /> Add
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>

          <Form.Item wrapperCol={{ span: 24 }}>
            <Button type="primary" htmlType="submit">
              Download PO
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default PoSettings;
