import React, { Component } from "react";
import PageTitleDetails from "../../components/common/page-title-detail";
import { ajaxRequester } from "../../services/ajax-requester";
import { Button, Form, Input, Select, Switch, Row, Col, message } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  FileAddOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import UploadFileLocal from "../../components/common/upload-file-local";

class PayeeAddEdit extends Component {
  state = {
    page: "payee-add",
    loading: false,
    stateList: [],
    districtList: [],
    cityList: [],
    mode: this.props.match.path.includes("add") ? "Add" : "Edit",
    results: "",
    files: [],
    currentFileKey: 0,
  };

  formRef = React.createRef();

  // Get Payee Details
  getDetails = () => {
    const { id } = this.props.match.params;
    let reqUrl = "payee/detail?payeeid=" + id;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ details: data.response }, () => this.setDefaultData());
      },
      "GET"
    );
  };

  // Redirect to Payee List
  handleBack = () => {
    this.props.history.goBack();
  };

  // Get State List from Lookup
  getStateList = () => {
    let reqUrl = "lookup/state";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ stateList: data.response });
      },
      "GET"
    );
  };

  // Get District List from Lookup
  getDistrictList = (state) => {
    let reqUrl = "lookup/district?state=" + state;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ districtList: data.response });
      },
      "GET"
    );
  };

  // Get City List from Lookup
  getCityList = (state, district) => {
    let reqUrl = "lookup/city?state=" + state + "&district=" + district;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ cityList: data.response });
      },
      "GET"
    );
  };

  // Handle Select Change
  handleTerritoryChange = (item, key) => {
    let state = this.formRef.current.getFieldValue("state");
    let district = this.formRef.current.getFieldValue("district");
    let city = this.formRef.current.getFieldValue("city");

    switch (item) {
      case "state":
        district = null;
        city = null;
        this.setState({ districtList: [], cityList: [], areaList: [] });
        break;

      case "district":
        city = null;
        this.setState({ cityList: [] });
        break;

      default:
        break;
    }
    this.formRef.current.setFieldsValue({
      state: state,
      district: district,
      city: city,
    });
  };

  // Handle Select Focus
  handleTerritorySelect = (item, key) => {
    let state = this.formRef.current.getFieldValue("state");
    let district = this.formRef.current.getFieldValue("district");

    switch (item) {
      case "district":
        state && this.getDistrictList(state);
        break;

      case "city":
        state && district && this.getCityList(state, district);
        break;

      default:
        break;
    }
  };

  setDefaultData = () => {
    const { details } = this?.state;

    this.formRef.current.setFieldsValue({
      is_active: details?.is_active,
      account_name: details?.account_name,
      payee_number: details?.payee_number,
      payee_email: details?.payee_email,
      address: details?.address,
      pan_no: details?.pan_no,
      gst_no: details?.gst_no,
      bank_name: details?.bank_name,
      bank_acct_no: details?.bank_acct_no,
      bank_ifsc_code: details?.bank_ifsc_code,
      online_payment_url: details?.online_payment_url,
      state: details?.state_name,
      district: details?.district_name,
      city: details?.city_name,
    });
  };

  ValuesChange = (value, values) => {};

  onFinish = (values) => {
    const { mode, details, cityList, files } = this.state;

    values.gst_no = values?.gst_no || " ";

    if (mode === "Edit") {
      values.payee_id = details?.payee_id;
      values.city_id =
        cityList.find((x) => x?.city === values?.city)?.city_id ||
        details?.city_id;
    } else {
      values.city_id = cityList.find((x) => x?.city === values?.city)?.city_id;
    }

    let reqUrl = "payee/" + (mode === "Edit" ? "update" : "add");
    let reqObj = {
      request: {
        ...values,
      },
    };
    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data?.payee_id || data.success) {
        message.success(
          "Payee has been " +
            (mode === "Edit" ? "updated" : "added") +
            " successfully"
        );
        files && files.length > 0
          ? this.handleMultiUpload(data?.payee_id)
          : this.handleBack();
      } else {
        message.error(
          data?.detail ||
            data?.title ||
            "An error occured while processing your request."
        );
      }
    });
  };

  handleDocument = (file, document_type) => {
    let { files } = this.state || [];

    if (file) {
      files = files.filter((x) => x?.document_type !== file.document_type);
      files.push(file);
    } else {
      files = files.filter((x) => x?.document_type !== document_type);
    }

    this.setState({ files });
  };

  handleMultiUpload = (ref_id) => {
    this.setState({ loading: true });
    let { files, currentFileKey } = this.state;
    if (currentFileKey < files?.length) {
      this.handleUpload(ref_id, files[currentFileKey]);
    } else {
      this.handleBack();
      this.setState({ loading: false });
    }
  };

  handleUpload = (ref_id, file) => {
    const { id } = this?.props?.match?.params;
    let { currentFileKey } = this.state;

    let reqUrl = "payee/document/upload";
    let request = file;
    request.reference_id = Number(ref_id || id);

    let reqObj = {
      request,
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data) {
        this.setState({ currentFileKey: currentFileKey + 1 }, () =>
          this.handleMultiUpload(ref_id)
        );
      } else {
        message.error("Document Upload Failed");
      }
    });
  };

  // Verify Email
  handleVerifyEmail = () => {
    const { id } = this.props.match.params;
    let reqUrl = "payee/resend/email?payeeid=" + id;
    let reqObj = "";
    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        if (data?.success) {
          message.success("Email has been successfully sent.");
        } else {
          message.error(
            data?.detail ||
              data?.title ||
              "An error occured while processing your request."
          );
        }
      },
      "GET"
    );
  };

  componentDidMount() {
    this.state.mode === "Edit" && this.getDetails();
    this.getStateList();
  }

  render() {
    const { mode, loading, stateList, districtList, cityList, details } =
      this?.state;

    const { Option } = Select;

    const layout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 15 },
    };

    const layout3 = {
      labelCol: { span: 12 },
      wrapperCol: { span: 11 },
    };

    const layout4 = {
      wrapperCol: { span: 19 },
    };

    return (
      <React.Fragment>
        <Row>
          <Col span={24} xs={24} xl={24}>
            <PageTitleDetails
              titleText={mode + " Payee"}
              isBackBtn="true"
              handleBack={this.handleBack}
            />
            <Form
              name="payee_add"
              className="payee_add"
              {...layout}
              onFinish={this.onFinish}
              onValuesChange={this.ValuesChange}
              ref={this.formRef}
              initialValues={{
                is_active: true,
              }}
            >
              <Row>
                <Col span={12} xs={24} xl={12}>
                  <Form.Item
                    label="Payee Status"
                    name="is_active"
                    valuePropName="checked"
                  >
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} xs={24} xl={12}></Col>
              </Row>

              <Row>
                <Col span={12} xs={24} xl={12}>
                  <Form.Item
                    label="Account Name"
                    name="account_name"
                    rules={[
                      {
                        required: true,
                        message: "Please Input Account Name !",
                      },
                    ]}
                  >
                    <Input placeholder="Account Name" />
                  </Form.Item>
                </Col>
                <Col span={12} xs={24} xl={12}>
                  <Form.Item label="Payee Number" name="payee_number">
                    <Input
                      placeholder="Payee Number"
                      minLength={10}
                      maxLength={10}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={12} xs={24} xl={12}>
                  <Form.Item label="Payee Email" name="payee_email">
                    <Input placeholder="Payee Email" />
                  </Form.Item>
                  <div
                    style={{ position: "absolute", right: "82px", top: "6px" }}
                  >
                    {details?.is_payee_email_verified ? (
                      <CheckCircleFilled
                        style={{ fontSize: "18px", color: "#0b8235" }}
                      />
                    ) : (
                      <div>
                        <Button
                          type="link"
                          className="ant-btn-reset"
                          style={{ top: "-2px", marginRight: "12px" }}
                          onClick={() => this.handleVerifyEmail()}
                        >
                          Resend Email
                        </Button>
                        <CloseCircleFilled
                          style={{ fontSize: "18px", color: "#ff4d4f" }}
                        />
                      </div>
                    )}
                  </div>
                </Col>
                <Col span={12} xs={24} xl={12}>
                  <Form.Item label="Address" name="address">
                    <Input placeholder="Address" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={7} xs={24} xl={7}>
                  <Form.Item label="State/District" name="state" {...layout3}>
                    <Select
                      placeholder="State"
                      allowClear={true}
                      showSearch={true}
                      onChange={() => this.handleTerritoryChange("state")}
                    >
                      {stateList.map((x) => (
                        <Option key={x.state} value={x.state}>
                          {x.state}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={5} xs={24} xl={5}>
                  <Form.Item name="district" {...layout4}>
                    <Select
                      placeholder="District"
                      allowClear={true}
                      showSearch={true}
                      onChange={() => this.handleTerritoryChange("district")}
                      onFocus={() => this.handleTerritorySelect("district")}
                    >
                      {districtList.map((x) => (
                        <Option key={x.district} value={x.district}>
                          {x.district}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={12} xs={24} xl={12}>
                  <Form.Item
                    label="City"
                    name="city"
                    rules={[
                      {
                        required: true,
                        message: "Please Select City !",
                      },
                    ]}
                  >
                    <Select
                      placeholder="City"
                      showSearch={true}
                      allowClear={true}
                      onChange={() => this.handleTerritoryChange("city")}
                      onFocus={() => this.handleTerritorySelect("city")}
                    >
                      {cityList.map((x) => (
                        <Option key={x.city} value={x.city}>
                          {x.city}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={12} xs={24} xl={12}>
                  <Form.Item label="PAN Number" name="pan_no">
                    <Input
                      placeholder="PAN Number"
                      minLength={10}
                      maxLength={10}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} xs={24} xl={12}>
                  <Form.Item label="GST Number" name="gst_no">
                    <Input placeholder="GST Number" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={12} xs={24} xl={12}>
                  <Form.Item label="Bank Name" name="bank_name">
                    <Input placeholder="Bank Name" />
                  </Form.Item>
                </Col>
                <Col span={12} xs={24} xl={12}>
                  <Form.Item label="Account Number" name="bank_acct_no">
                    <Input placeholder="Account Number" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={12} xs={24} xl={12}>
                  <Form.Item label="IFSC Code" name="bank_ifsc_code">
                    <Input
                      placeholder="IFSC Code"
                      minLength={11}
                      maxLength={11}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} xs={24} xl={12}>
                  <Form.Item label="Payment URL" name="online_payment_url">
                    <Input placeholder="Online Payment URL" />
                  </Form.Item>
                </Col>
              </Row>

              {(mode === "Add" || details) && (
                <React.Fragment>
                  <Row>
                    <Col span={12} xs={24} xl={12}>
                      <Form.Item label="Aadhar/Address Proof" name="aadhar">
                        <UploadFileLocal
                          handleUpload={(file) =>
                            this.handleDocument(file, "1")
                          }
                          document_type={"1"}
                          mode={mode}
                          existingFile={details?.documents?.aadhar}
                          deletePath={
                            "payee/document/delete?payeeId=" + details?.payee_id
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} xs={24} xl={12}>
                      <Form.Item label="PAN Card" name="pan_card">
                        <UploadFileLocal
                          handleUpload={(file) =>
                            this.handleDocument(file, "2")
                          }
                          document_type={"2"}
                          mode={mode}
                          existingFile={details?.documents?.pan_card}
                          deletePath={
                            "payee/document/delete?payeeId=" + details?.payee_id
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12} xs={24} xl={12}>
                      <Form.Item label="GST Certificate" name="gst_certificate">
                        <UploadFileLocal
                          handleUpload={(file) =>
                            this.handleDocument(file, "3")
                          }
                          document_type={"3"}
                          mode={mode}
                          existingFile={details?.documents?.gst_certificate}
                          deletePath={
                            "payee/document/delete?payeeId=" + details?.payee_id
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} xs={24} xl={12}>
                      <Form.Item
                        label="Cancelled Cheque"
                        name="cancelled_cheque"
                      >
                        <UploadFileLocal
                          handleUpload={(file) =>
                            this.handleDocument(file, "4")
                          }
                          document_type={"4"}
                          mode={mode}
                          existingFile={details?.documents?.cancelled_cheque}
                          deletePath={
                            "payee/document/delete?payeeId=" + details?.payee_id
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </React.Fragment>
              )}

              <Row>
                <Col span={24}>
                  <Form.Item
                    className="ant-text-center"
                    style={{ marginTop: "24px" }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Button
                      type="primary"
                      size={"large"}
                      icon={<FileAddOutlined />}
                      htmlType="submit"
                      loading={loading}
                    >
                      {mode} Payee
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default PayeeAddEdit;
