import React from "react";
import { Table, Button } from "antd";
import { FileImageOutlined, BulbTwoTone } from "@ant-design/icons";
import ListFilters from "../../components/common/list-filters";
import InventoryNav from "../../components/inventories/inventory-nav";
import InventoriesListBase from "../../base/inventories-list-base";
import PhotoGallery from "../../components/common/photo-gallery";

class InventorySelectInventory extends InventoriesListBase {
  state = {
    listReq: "hainventory/list?",
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    sorting: [],
    selectedRowKeys: [],
    selectedRows: [],
    navKey: 1,
    inventoryType: "hainventory",
    screen: "inventory-select-inventory",
  };

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys, selectedRows });
  };

  handleSelection = () => {
    this.props.addSelectedInventory(this.state.selectedRows);
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "Media",
        dataIndex: "media_type",
        render: (text) => <span style={{ whiteSpace: "nowrap" }}>{text}</span>,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Qty",
        dataIndex: "quantity",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "IID",
        dataIndex: "inventory_code",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => <span style={{ whiteSpace: "nowrap" }}>{text}</span>,
      },
      {
        title: "City",
        dataIndex: "city",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Area",
        dataIndex: "area",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Location",
        dataIndex: "location",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Size",
        dataIndex: "size",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "SQFT",
        dataIndex: "sqft",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Light",
        dataIndex: "light_type",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Photo",
        dataIndex: "images",
        render: (text, record) => (
          <Button
            className="ant-btn-reset"
            disabled={record?.ha_images?.length > 0 ? false : true}
            icon={<FileImageOutlined />}
            onClick={() => this.handlePhotos(record)}
          />
        ),
      },
      {
        title: "Card Rate",
        dataIndex: "card_rate",
        render: (text) => this.currencyFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Log",
        dataIndex: "is_inventory_status_read",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) =>
          text ? (
            <span style={{ whiteSpace: "nowrap" }}>
              <BulbTwoTone twoToneColor="#cccccc" /> Read
            </span>
          ) : (
            <span style={{ whiteSpace: "nowrap" }}>
              <BulbTwoTone twoToneColor="#fadb14" /> Unread
            </span>
          ),
      },
      {
        title: "Booking Status",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        dataIndex: "booking_status",
        render: (text) =>
          text === "A" ? "Available" : text === "B" ? "Blocked" : "NA",
      },
    ];

    const filterColumns = [
      { columnName: "media_type", displayName: "Media" },
      { columnName: "quantity", displayName: "Qty" },
      { columnName: "inventory_code", displayName: "IID" },
      { columnName: "city", displayName: "City" },
      { columnName: "area", displayName: "Area" },
      { columnName: "location", displayName: "Location", default: true },
      { columnName: "size", displayName: "Size" },
      { columnName: "sqft", displayName: "SQFT" },
      { columnName: "light_type", displayName: "Light" },
      { columnName: "images", displayName: "Photo" },
      { columnName: "card_rate", displayName: "Card Rate" },
      { columnName: "is_inventory_status_read", displayName: "Log" },
      { columnName: "booking_status", displayName: "Booking Status" },
      { columnName: "campaign_start_date", displayName: "Campaign Start Date" },
      { columnName: "campaign_end_date", displayName: "Campaign End Date" },
    ];

    this.setState({ columnsDefault: columns, filterColumns }, () =>
      this.setDefaultColumns(columns)
    );
  };

  componentDidMount() {
    // Get Inventories List
    const { pagination } = this.state;
    this.getInventories({ pagination });
    this.setColumns();
  }

  render() {
    const {
      data,
      columns,
      loading,
      selectedRowKeys,
      pagination,
      navKey,
      filterColumns,
      screen,
      isShowPhotos,
      photos,
      photosTitle,
    } = this.state;

    const rowSelection = {
      selectedRowKeys,
      preserveSelectedRowKeys: true,
      onChange: this.onSelectChange,

      getCheckboxProps: (record) => ({
        disabled: record.is_read,
        is_read: record.is_read,
      }),
    };

    const hasSelected = selectedRowKeys.length > 0;

    return (
      <div>
        <ListFilters
          items={filterColumns}
          handleSettings={this.handleSettings}
          handleFilters={this.handleFilters}
          key={filterColumns}
          screen={screen}
        />

        <InventoryNav
          {...this.state}
          handleToggle={this.handleToggle}
          handleNav={this.handleNav}
          key={navKey}
        />

        <Table
          columns={columns}
          rowKey={(record) => record.ha_inventory_id}
          dataSource={data}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
          bordered
          scroll={{ x: 400 }}
          size="small"
          rowSelection={rowSelection}
        />

        {isShowPhotos && (
          <PhotoGallery
            photos={photos}
            photosTitle={photosTitle}
            handlePhotos={this.handlePhotos}
          />
        )}

        <Button
          type="primary"
          onClick={this.handleSelection}
          disabled={!hasSelected}
          loading={loading}
          style={{ top: "-42px" }}
        >
          Add Inventories
        </Button>
      </div>
    );
  }
}

export default InventorySelectInventory;
