import React, { Component } from "react";
import { Row, Col } from "antd";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import {
  dateFormatter,
  currencyFormatter,
} from "../../components/common/common-formatter";
import PortalUser from "../../iam/components/portal-user";
import InvoiceFormat from "./invoice-format";

class InvoicePrint extends Component {
  state = {};

  handleDownload = () => {
    html2canvas(document.querySelector("#divToPrint"), {
      imageTimeout: 150000,
      logging: false,
      useCORS: true,
      width: 1366,
      windowWidth: 1366,
      scale: 1,
    }).then((canvas) => {
      var imgData = canvas.toDataURL("image/png");
      var imgWidth = 210;
      var pageHeight = 297;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF("p", "mm");
      var position = 0; // give some top padding to first page

      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position += heightLeft - imgHeight; // top padding for other pages
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save("Invoice-" + this.props?.display_name + ".pdf");
    });
  };

  render() {
    const results = this.props;
    const { sallerDetails } = results;
    const invoiceLogo = sallerDetails?.documents?.logo?.at(-1)?.data_url || "";

    return (
      <React.Fragment>
        <div className="invoice-print-title">
          <Row>
            <Col span={12}>
              <h2>
                ADVERTISING SERVICES
                <h4>PROFORMA INVOICE</h4>
              </h2>
            </Col>
            <Col span={12}>
              <div className="invoice-print-logo">
                {invoiceLogo && <img src={invoiceLogo} alt="" />}
              </div>
            </Col>
          </Row>
        </div>

        <div
          style={{
            borderBottom: "solid 1px #999",
            paddingLeft: "10px",
            paddingBottom: "12px",
            marginTop: "12px",
          }}
        >
          <Row gutter="16">
            <Col span={14}>
              {sallerDetails && (
                <Row>
                  <Col span={24}>
                    <div>Seller,</div>
                    <div>
                      <b>{sallerDetails?.provider_name}</b>
                    </div>
                    <div>
                      {sallerDetails?.address1 +
                        " " +
                        sallerDetails?.address2 +
                        ", "}
                    </div>
                    <div>
                      {sallerDetails?.city_name +
                        " - " +
                        sallerDetails?.pin_code}
                    </div>
                    <div>GSTIN : {sallerDetails?.gst_number}</div>
                    <div>State Name : {sallerDetails?.state_name}</div>
                    <div>PAN : {sallerDetails?.pan}</div>
                  </Col>
                </Row>
              )}

              <Row style={{ marginTop: "12px" }}>
                <Col span={24}>
                  <div>Buyer,</div>
                  <div>
                    <b>{results?.company_name}</b>
                  </div>
                  <div>{results?.address}</div>
                  <div>GSTIN : {results?.gstin}</div>
                  <div>Place of Supply : {results?.place_of_supply}</div>
                  <div>
                    State Name : {results?.place_of_supply}, State Code :{" "}
                    {results?.state_code}
                  </div>
                  <div>PAN : {results?.pan}</div>
                </Col>
              </Row>
            </Col>

            <Col span={10}>
              <Row>
                <Col span={24}>
                  <div>Proforma Invoice Details,</div>
                  <div>
                    <b>Customer Code :</b> {results?.customer_code}
                  </div>
                  <div>
                    <b>Project Id :</b> {results?.project_id}
                  </div>
                  <div>
                    <b>Proforma Invoice No :</b> {results?.proforma_invoice_no}
                  </div>
                  <div>
                    <b>Proforma Invoice Date :</b>{" "}
                    {results?.invoice_date &&
                      dateFormatter(results?.invoice_date)}
                  </div>
                </Col>
              </Row>

              <Row style={{ marginTop: "12px" }}>
                <Col span={24}>
                  <div>
                    <b>Display Name :</b> {results?.display_name}
                  </div>
                  <div>
                    <b>PO No :</b> {results?.po_number}
                  </div>
                  <div>
                    <b>PO Date :</b>{" "}
                    {results?.po_date &&
                      results?.po_date !== "0001-01-01T00:00:00" &&
                      dateFormatter(results?.po_date)}
                  </div>
                  <div>
                    <b>From :</b>{" "}
                    {results?.duration_from &&
                      dateFormatter(results?.duration_from)}
                  </div>
                  <div>
                    <b>To :</b>{" "}
                    {results?.duration_to &&
                      dateFormatter(results?.duration_to)}
                  </div>
                  <div>
                    <b>Business By :</b>{" "}
                    <span style={{ display: "inline-block" }}>
                      <PortalUser user={results?.employee_id} />
                    </span>
                  </div>

                  {this.props?.is_client_approved && (
                    <React.Fragment>
                      <div>
                        <b>Campaign Difference :</b>{" "}
                        <span
                          style={{
                            display: "inline-block",
                          }}
                        >
                          {currencyFormatter(
                            parseInt(results?.campaign_difference)
                          )}
                        </span>
                      </div>
                      <div>
                        <b>HA Markup :</b>{" "}
                        <span
                          style={{
                            display: "inline-block",
                          }}
                        >
                          {currencyFormatter(parseInt(results?.ha_markup))}
                          {(results?.ha_markup_per ||
                            results?.ha_markup_per !== 0) &&
                            " (" +
                              (parseInt(results?.ha_markup_per) || 0) +
                              "%)"}
                        </span>
                      </div>
                      <div>
                        <b>TA Markup :</b>{" "}
                        <span
                          style={{
                            display: "inline-block",
                          }}
                        >
                          {currencyFormatter(parseInt(results?.ta_markup))}
                          {(results?.ta_markup_per ||
                            results?.ta_markup_per !== 0) &&
                            " (" +
                              (parseInt(results?.ta_markup_per) || 0) +
                              "%)"}
                        </span>
                      </div>
                    </React.Fragment>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <div style={{ marginTop: "12px" }}>
          <h2 style={{ marginBottom: "12px" }}>SUMMARY OF CHARGES</h2>
          <InvoiceFormat {...this.props} />
        </div>

        <div style={{ marginTop: "8px", marginBottom: "-8px" }}>
          <b>SAC : {results?.sac_code}</b>
        </div>

        <Row>
          <Col span={16}></Col>
          <Col span={8}>
            <div
              style={{
                marginBottom: "5px",
                paddingBottom: "5px",
                borderBottom: "solid 1px #999",
              }}
            >
              <b style={{ display: "inline-block", width: "50%" }}>
                Sub Total in INR :
              </b>
              <b
                style={{
                  display: "inline-block",
                  width: "50%",
                  textAlign: "right",
                }}
              >
                {currencyFormatter(parseInt(results?.invoice_amount))}
              </b>
            </div>

            {results?.igst_amount !== 0 && (
              <div style={{ marginBottom: "5px" }}>
                <span style={{ display: "inline-block", width: "50%" }}>
                  IGST @ 18%
                </span>
                <span
                  style={{
                    display: "inline-block",
                    width: "50%",
                    textAlign: "right",
                  }}
                >
                  {currencyFormatter(parseInt(results?.igst_amount))}
                </span>
              </div>
            )}

            {results?.igst_amount === 0 && (
              <div style={{ marginBottom: "5px" }}>
                <span style={{ display: "inline-block", width: "50%" }}>
                  CGST @ 9%
                </span>
                <span
                  style={{
                    display: "inline-block",
                    width: "50%",
                    textAlign: "right",
                  }}
                >
                  {currencyFormatter(parseInt(results?.cgst_amount))}
                </span>
              </div>
            )}

            {results?.igst_amount === 0 && (
              <div style={{ marginBottom: "5px" }}>
                <span style={{ display: "inline-block", width: "50%" }}>
                  SGST @ 9%
                </span>
                <span
                  style={{
                    display: "inline-block",
                    width: "50%",
                    textAlign: "right",
                  }}
                >
                  {currencyFormatter(parseInt(results?.sgst_amount))}
                </span>
              </div>
            )}

            <div
              className="ant-border-t"
              style={{
                paddingTop: "5px",
                paddingBottom: "5px",
                borderTop: "solid 1px #999",
                borderBottom: "solid 1px #999",
              }}
            >
              <b style={{ display: "inline-block", width: "50%" }}>
                Total in INR :
              </b>
              <b
                style={{
                  display: "inline-block",
                  width: "50%",
                  textAlign: "right",
                }}
              >
                {currencyFormatter(parseInt(results?.total_amount))}
              </b>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default InvoicePrint;
