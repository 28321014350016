import React, { Component } from "react";
import { ajaxRequester } from "../../services/ajax-requester";
import { Row, Col, Button } from "antd";
import PageTitle from "../../components/common/page-title";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "../../assets/css/leaflet.css";
import { currencyFormatter } from "../../components/common/common-formatter";
import MapSearchBox from "../../components/common/map-searchbox";
import { CloseOutlined } from "@ant-design/icons";

class inventoriesMap extends Component {
  constructor(props) {
    super(props);
    this.popup = React.createRef();
    this.state = {
      data: [],
      pagination: {
        current: 1,
        pageSize: 2000,
      },
      inventoryType: "hainventory",
      defaultPosition: {
        zoom: 8,
        center: {
          lat: 22.8587,
          lng: 72.3924,
        },
      },
    };
  }

  // Get Inventories List
  getInventories = (params = {}) => {
    this.setState({ loading: true });
    let reqUrl = this.state.inventoryType + "/list";

    let reqObj = { request: { page_no: 1, page_size: 5000 } };

    ajaxRequester(reqUrl, reqObj, (data) => {
      this.setState({
        loading: false,
        data: data.response,
        pagination: {
          ...params.pagination,
          total: data.page_info.total_records,
        },
        mediaTypeList: data.mediaTypeList,
        navKey: this.state.navKey + 1,
        filters: params.filters,
      });
    });
  };

  handleClosePopup = () => {
    this.popup.current.leafletElement.options.leaflet.map.closePopup();
  };

  componentDidMount() {
    // Get Inventories List
    const { pagination } = this.state;
    this.getInventories({ pagination });
  }

  render() {
    const { defaultPosition, data } = this.state;

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageTitle titleText="Inventories on Map" />

            <div
              style={{
                width: "100%",
                height: "600px",
                position: "relative",
                overflow: "hidden",
              }}
            >
              {data && (
                <Map
                  center={defaultPosition.center}
                  zoom={defaultPosition.zoom}
                >
                  <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                  {data.map(
                    (item, key) =>
                      item.lat_long.x &&
                      item.lat_long.y && (
                        <Marker
                          key={key}
                          position={[item.lat_long.x, item.lat_long.y]}
                        >
                          <Popup closeButton={false} ref={this.popup}>
                            <div className="leaflet-map-popup">
                              <b>
                                {item.inventory_code} - {item.media_type}
                              </b>

                              <div>{item.location}</div>
                              <div>{item.inventory_location}</div>
                              <div>
                                Size : {item.size}{" "}
                                {item.quantity > 1 &&
                                  ", Quantity : " + item.quantity}
                              </div>
                              <div>
                                Card Rate : {currencyFormatter(item.card_rate)}
                              </div>

                              <Button
                                icon={<CloseOutlined />}
                                onClick={this.handleClosePopup}
                                style={{
                                  position: "absolute",
                                  right: "4px",
                                  top: "4px",
                                  border: "0px none",
                                  background: "none",
                                }}
                              ></Button>
                            </div>
                          </Popup>
                        </Marker>
                      )
                  )}

                  <MapSearchBox />
                </Map>
              )}
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default inventoriesMap;
