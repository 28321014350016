import {
    useLocation,
    useNavigate,
    useParams,
  } from "react-router-dom";
  
  function withRouter(Component) {

    function ComponentWithRouterProp(props) {
      let location = useLocation();
      let navigate = useNavigate();
      let params = useParams();

      let history = {
        goBack: () => {
          navigate(-1);
        },
        push: (path) => {
          navigate(path);
        },
        location: location
      }

      let path = (props.key ?? location.pathname);

      if (params) {
        Object.keys(params).forEach(key => {
          let value = params[key];
          path = path.replace(`/${value}`, `/:${key}`);
        });
      }

      return (
        <Component
          {...props}
          location={location}
          navigate={navigate}
          params={params}
          history={history}
          match={{
            params: params,
            path: path
          }}
        />
      );
    }
  
    return ComponentWithRouterProp;
  }
  
  export default withRouter;