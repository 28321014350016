import React from "react";
import { Row, Col, Table, Button, Popconfirm } from "antd";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
} from "@ant-design/icons";
import PageTitle from "../../components/common/page-title";
import ListFilters from "../../components/common/list-filters";
import CommonListBase from "../../base/common-list-base";
import {
  currencyFormatter,
  dateFormatter,
} from "../../components/common/common-formatter";
import PortalUser from "../../iam/components/portal-user";
import { Link } from "react-router-dom";
import { RolesPermissions } from "../../helpers/roles-permissions";

class JournalVoucher extends CommonListBase {
  state = {
    listReq: "salesestimate/journalvoucherlist?employeeid=1&",
    delReq: "salesestimate/journalvoucher/delete?journalvoucherid=",
    dtlReq: "/sales-estimate/journal-voucher-details/",
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    sorting: [],
    screen: "journal-voucher",
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "JV Number",
        dataIndex: "journal_voucher_no",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) => (
          <Link
            style={{ padding: "0px", whiteSpace: "normal", textAlign: "left" }}
            type="link"
            to={`/sales-estimate/journal-voucher-edit/${record.journal_voucher_id}`}
          >
            {text}
          </Link>
        ),
      },
      {
        title: "JV Date",
        dataIndex: "journal_voucher_date",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => dateFormatter(text),
      },
      {
        title: "Customer Code",
        dataIndex: "customer_code",
      },
      {
        title: "Company Name",
        dataIndex: "company_name",
      },
      {
        title: "Employee",
        dataIndex: "employee_id",
        render: (text) => <PortalUser user={text} />,
        width: 140,
      },
      {
        title: "Purchase Invoice Number",
        dataIndex: "purchase_invoice_no",
      },
      {
        title: "Adjusted Invoices",
        dataIndex: "mapped_invoices",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Amount",
        dataIndex: "journal_voucher_amount",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: "Amount with GST",
        dataIndex: "total_amount",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: "Download",
        dataIndex: "download",
        align: "center",
        render: (record) => (
          <Button icon={<DownloadOutlined />} className="ant-btn-reset" />
        ),
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "x",
        render: (record) => (
          <React.Fragment>
            <Button
              icon={<EditOutlined />}
              className="ant-btn-reset"
              onClick={() => this.editJournalVoucher(record.journal_voucher_id)}
            />
            <Popconfirm
              title="Are you sure want to delete?"
              placement="left"
              okText="Yes"
              cancelText="No"
              onConfirm={() => this.deleteItem(record.journal_voucher_id)}
            >
              <Button
                icon={<DeleteOutlined />}
                className="ant-btn-reset"
                style={{ marginLeft: "12px" }}
              />
            </Popconfirm>
          </React.Fragment>
        ),
      },
    ];

    const filterColumns = [
      {
        columnName: "journal_voucher_no",
        displayName: "JV Number",
        default: true,
      },
      {
        columnName: "journal_voucher_date",
        displayName: "JV Date",
        hideFilter: true,
      },
      {
        columnName: "customer_code",
        displayName: "Customer Code",
      },
      {
        columnName: "company_name",
        displayName: "Company Name",
      },
      { columnName: "employee_id", displayName: "Employee" },
      {
        columnName: "purchase_invoice_no",
        displayName: "Purchase Invoice Number",
        hideFilter: true,
      },
      {
        columnName: "mapped_invoices",
        displayName: "Adjusted Invoices",
        hideFilter: true,
      },
      {
        columnName: "journal_voucher_amount",
        displayName: "Amount",
        hideFilter: true,
      },
      {
        columnName: "total_amount",
        displayName: "Amount with GST",
        hideFilter: true,
      },
      { columnName: "download", displayName: "Download", hideFilter: true },
    ];

    this.setState({ columnsDefault: columns, filterColumns }, () =>
      this.setDefaultColumns(columns)
    );
  };

  addJournalVoucher = () => {
    this.props.history.push(`/sales-estimate/journal-voucher-add`);
  };

  componentDidMount() {
    // Get List
    const { pagination } = this.state;
    this.getResults({ pagination });
    this.setColumns();
  }

  render() {
    const { data, columns, pagination, loading, filterColumns, screen } =
      this.state;

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageTitle
              titleText="Journal Voucher (JD)"
              isAddBtn={
                (RolesPermissions("ad") || RolesPermissions("ac")) &&
                "Add Journal Voucher (JD)"
              }
              handleAdd={this.addJournalVoucher}
            />

            <ListFilters
              items={filterColumns}
              handleSettings={this.handleSettings}
              handleFilters={this.handleFilters}
              key={filterColumns}
              screen={screen}
            />

            <Table
              columns={columns}
              rowKey={(record) => record.journal_voucher_id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              bordered
              scroll={{ x: 400 }}
              size="small"
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default JournalVoucher;
