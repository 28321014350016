import React, { Component } from "react";
import { Input, InputNumber } from "antd";
import { DatePicker } from "antd";
import * as dayjs from "dayjs";
import { UndoOutlined } from "@ant-design/icons";
import { dateFormatter } from "../../components/common/common-formatter";

class PlanInventoryEdit extends Component {
  state = {
    value: this.props.value,
    defaultValue: this.props.value,
    column: this.props.column,
    isDateDisabled: false,
  };

  onFinish = () => {
    this.props.handleInventoryEdit(
      this.state.value,
      this.props.record,
      this.props.column
    );
  };

  onChange = (value) => {
    this.setState({ value });
  };

  onDateChange = (value) => {
    let date = value;
    this.setState({ value: date }, () => this.onFinish());
  };

  setDateDisabled = () => {
    let isDateDisabled = false;

    const { column, record } = this.props;

    if (
      column === "start_date" &&
      (record?.campaign_status === "Running" ||
        record?.campaign_status === "Completed" ||
        record?.campaign_status === "Expired")
    ) {
      isDateDisabled = true;
    }
    if (
      column === "end_date" &&
      (record?.campaign_status === "Completed" ||
        record?.campaign_status === "Expired")
    ) {
      isDateDisabled = true;
    }

    this.setState({ isDateDisabled });
  };

  handleBaseRateReset = () => {
    this.setState({ value: this.props.record.inv_base_rate }, () =>
      this.onFinish()
    );
  };

  disabledDate = (current) => {
    return current && current < dayjs().startOf("day");
  };

  // disabledEndDate = (current) => {
  //   return current && this.state.column === "end_date"
  //     ? this?.props?.record?.inv_end_date
  //       ? current < dayjs(this?.props?.record?.inv_end_date)
  //       : current && current < dayjs().startOf("day")
  //     : current < dayjs().startOf("day");
  // };

  disabledEndDate = (current) => {
    return current && this.state.column === "end_date"
      ? this?.props?.record?.inv_end_date
        ? dayjs().diff(dayjs(this?.props?.record?.inv_end_date)) >= 0
          ? current < dayjs().startOf("day")
          : current < dayjs(this?.props?.record?.inv_end_date)
        : current && current < dayjs().startOf("day")
      : current < dayjs().startOf("day");
  };

  onChangeRemarks = (e) => {
    let value = e?.target?.value || "";
    this.setState({ value });
  };

  componentDidMount() {
    this.setDateDisabled();
  }

  render() {
    const { value, defaultValue, column, isDateDisabled } = this.state;

    const customFormat = () => `Available`;

    return (
      <React.Fragment>
        {(column === "start_date" || column === "end_date") && (
          <DatePicker
            defaultValue={dayjs(value, "YYYY-MM-DD")}
            format="DDMMMYY"
            bordered={false}
            allowClear={false}
            onChange={this.onDateChange}
            disabled={isDateDisabled}
            style={{
              margin: "0px",
              padding: "0px",
            }}
            disabledDate={this.disabledEndDate}
            inputReadOnly={true}
          />
        )}

        {column === "available_from" && (
          <DatePicker
            defaultValue={dayjs(value, "YYYY-MM-DD")}
            format={
              dateFormatter(value) === dateFormatter(new Date())
                ? customFormat
                : "DDMMMYY"
            }
            bordered={false}
            allowClear={false}
            onChange={this.onDateChange}
            disabled={isDateDisabled}
            style={{
              margin: "0px",
              padding: "0px",
            }}
            disabledDate={this.disabledDate}
            inputReadOnly={true}
          />
        )}

        {column !== "start_date" &&
          column !== "end_date" &&
          column !== "available_from" &&
          column !== "remarks" && (
            <React.Fragment>
              <InputNumber
                name="input"
                value={parseInt(value) || parseInt(defaultValue) || 0}
                onChange={this.onChange}
                onBlur={this.onFinish}
              />
              {column === "base_rate" && (
                <UndoOutlined
                  className="input-reset-icon"
                  onClick={this.handleBaseRateReset}
                  title="Reset"
                />
              )}
            </React.Fragment>
          )}

        {column === "remarks" && (
          <Input
            name="inputnew"
            value={value || defaultValue || ""}
            onChange={this.onChangeRemarks}
            onBlur={this.onFinish}
            style={{ width: "100px" }}
          />
        )}
      </React.Fragment>
    );
  }
}

export default PlanInventoryEdit;
