import React, { Component } from "react";
import { ajaxRequester } from "../../services/ajax-requester";
import { Upload, message, Button } from "antd";
import {
  DeleteOutlined,
  UploadOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { downloadFile } from "../../helpers/download-helper";

class UploadFileLocal extends Component {
  state = {
    loading: false,
    mode: this.props.mode,
    fileName: this?.props?.existingFile?.name || "",
    fileUrl: this?.props?.existingFile?.data_url || "",
  };

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  handleChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      this.setState({ loading: false });
      return;
    }
  };

  handleUpload = (file, fileUrl) => {
    let reqUrl = this.props.uploadPath;

    let reqObj = {
      request: {
        reference_id: this.props.id,
        name: file.name,
        is_public: true,
        tags: [file.name.split(".")[0]],
        data_extension: "." + [...file.name.split(".")].pop(),
        type: file.type,
        document_type: "1",
        data: fileUrl.split(",")[1],
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data?.success || data) {
        message.success(`${file.name} file uploaded successfully`);
        this.setState({
          fileUrl,
          loading: false,
        });
      } else {
        message.error(`${file.name} file upload failed.`);
      }
    });
  };

  handleUploadLocal = (file, fileUrl) => {
    const { mode } = this.props;

    let fileRequest = {
      is_public: true,
      name: file.name,
      tags: [file.name.split(".")[0]],
      data_extension: "." + [...file.name.split(".")].pop(),
      type: file.type,
      document_type: this?.props?.document_type || "1",
      data: fileUrl.split(",")[1],
    };

    this.setState({
      fileName: file?.name,
      loading: false,
      fileUrl: "",
      fileRequest,
    });

    this.props.handleUpload(fileRequest);

    (mode === "Edit" || mode === "update") &&
      this?.props?.existingFile?.id &&
      this.handleDelete();
  };

  uploadImage = ({ file }) => {
    this.getBase64(file, (image) => {
      this.handleUploadLocal(file, image);
    });
  };

  handleLocalDelete = () => {
    const { mode } = this.props;

    let fileRequest = "";
    this.setState({
      fileName: "",
      fileUrl: "",
    });
    this.props.handleUpload(fileRequest);
    (mode === "Edit" || mode === "update") &&
      this?.props?.existingFile?.id &&
      this.handleDelete();
  };

  handleDelete = () => {
    let url = this?.props?.deletePath;
    let id = this?.props?.existingFile?.id;
    let document_type = this?.props?.document_type;

    let reqUrl = url + "&documenttype=" + document_type + "&documentid=" + id;
    let reqObj = "";

    ajaxRequester(reqUrl, reqObj, (data) => {}, "DELETE");
  };

  viewDocument = () => {
    const { fileName, fileUrl } = this.state;

    downloadFile(fileName, fileUrl);
  };

  render() {
    const { loading, fileName, fileUrl } = this.state;

    return (
      <React.Fragment>
        <Upload
          customRequest={this.uploadImage}
          onChange={this.handleChange}
          showUploadList={false}
        >
          <Button loading={loading} icon={<UploadOutlined />}>
            Click to Upload
          </Button>
        </Upload>

        <span style={{ margin: "0px 8px 0px 16px" }}>{fileName}</span>

        {fileUrl && (
          <Button
            size="small"
            className="ant-btn-reset"
            icon={<DownloadOutlined />}
            onClick={() => this.viewDocument()}
            style={{ marginRight: "8px" }}
          />
        )}

        {fileName && (
          <Button
            size="small"
            className="ant-btn-reset"
            icon={<DeleteOutlined />}
            onClick={this.handleLocalDelete}
          />
        )}
      </React.Fragment>
    );
  }
}

export default UploadFileLocal;
