import React from "react";
import { Row, Col, Table, Button } from "antd";
import ListFilters from "../../components/common/list-filters";
import CommonListBase from "../../base/common-list-base";

class SelectPayee extends CommonListBase {
  state = {
    listReq: "payee/list?showActive=true&",
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    sorting: [],
    selectedRowKeys: [],
    selectedRows: [],
    screen: "payee-list-select",
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "PID",
        dataIndex: "pid",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Account Name",
        dataIndex: "account_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "City",
        dataIndex: "city_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Payee Number",
        dataIndex: "payee_number",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Payee Email",
        dataIndex: "payee_email",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "PAN Number",
        dataIndex: "pan_no",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Status",
        dataIndex: "is_active",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => (text ? "Active" : "Inactive"),
      },
    ];

    const filterColumns = [
      { columnName: "pid", displayName: "PID" },
      {
        columnName: "account_name",
        displayName: "Account Name",
        default: true,
      },
      { columnName: "city_name", displayName: "City" },
      { columnName: "payee_number", displayName: "Payee Number" },
      { columnName: "payee_email", displayName: "Payee Email" },
      { columnName: "pan_no", displayName: "PAN Number" },
      { columnName: "is_active", displayName: "Status", hideFilter: true },
    ];

    this.setState({ columnsDefault: columns, filterColumns }, () =>
      this.setDefaultColumns(columns)
    );
  };

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys, selectedRows });
  };

  setDisabled = (record) => {
    return this.state.existingPayee.includes(record.payee_id) && "disabled-row";
  };

  handleSelection = () => {
    let { selectedRows } = this.state;
    this.props.handleAddPayee(selectedRows);
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.getResults({ pagination });
    this.setColumns();

    let { payout_estimate_payee_list } = this.props || [];

    let existingPayee = [];

    payout_estimate_payee_list.map((x) => existingPayee.push(x.payee_id));

    this.setState({ existingPayee });
  }

  render() {
    const {
      data,
      columns,
      pagination,
      loading,
      filterColumns,
      screen,
      selectedRowKeys,
    } = this.state;

    const rowSelection = {
      selectedRowKeys,
      preserveSelectedRowKeys: true,
      onChange: this.onSelectChange,
      getCheckboxProps: (record) => ({
        disabled: this.state.existingPayee.includes(record.payee_id),
      }),
    };

    const hasSelected = selectedRowKeys.length > 0;

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <ListFilters
              items={filterColumns}
              handleSettings={this.handleSettings}
              handleFilters={this.handleFilters}
              key={filterColumns}
              screen={screen}
            />

            <Button
              type="primary"
              onClick={this.handleSelection}
              disabled={!hasSelected}
              loading={loading}
              style={{
                float: "right",
                position: "relative",
                top: "-48px",
                right: "48px",
                marginBottom: "-48px",
              }}
              className="mobile-hide"
            >
              Add Payee
            </Button>

            <Table
              columns={columns}
              rowKey={(record) => record.payee_id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              bordered
              scroll={{ x: 400 }}
              size="small"
              rowSelection={{
                ...rowSelection,
              }}
              rowClassName={(record) => this.setDisabled(record)}
            />

            <Button
              type="primary"
              onClick={this.handleSelection}
              disabled={!hasSelected}
              loading={loading}
              style={{ top: "-42px" }}
            >
              Add Payee
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default SelectPayee;
