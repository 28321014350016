import React, { Component } from "react";
import { Row, Col, Table, Button } from "antd";
import { ajaxRequester } from "../../services/ajax-requester";
import PageTitleDetails from "../../components/common/page-title-detail";

class InventoryStatus extends Component {
  state = {
    data: [],
    loading: true,
    columns: [],
    columnsDefault: [],
    selectedRowKeys: [],
  };

  // Get Inventory Status
  getInventoryStatus = (params = {}) => {
    const inventoryId = this.props.match.params.id;

    let reqUrl =
      "hainventory/inventorystatus/activitylog?inventoryid=" +
      Number(inventoryId);

    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          loading: false,
          data: data.response,
        });
      },
      "GET"
    );
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "Employee Name",
        dataIndex: "updated_by",
      },
      {
        title: "Activity",
        dataIndex: "message",
      },
      {
        title: "Remarks",
        dataIndex: "remarks",
      },
      {
        title: "Inventory Status",
        dataIndex: "inventory_status",
      },
      {
        title: "Date & Time",
        dataIndex: "updated_date",
      },
      {
        title: "Auto Active On",
        dataIndex: "auto_active_on",
      },
    ];

    this.setState({ columns, columnsDefault: columns });
  };

  setReadUnread = (record) => {
    return record.is_read ? "status-record-read" : "status-record-unread";
  };

  // Redirect to Inventory List
  handleBack = () => {
    this.props.history.goBack();
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  handleSelection = () => {
    this.setState({ loading: true });
    let reqUrl = "hainventory/inventorystatus/activitylog/markasread";
    let reqObj = {
      request: {
        activity_log_id: this.state.selectedRowKeys,
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      this.setState({ selectedRowKeys: [] });
      this.getInventoryStatus({});
    });
  };

  componentDidMount() {
    // Get Inventory Status
    this.getInventoryStatus({});
    this.setColumns();
  }

  render() {
    const { data, columns, loading, selectedRowKeys } = this.state;

    const inventoryTitle = this.props.match.params.title;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,

      getCheckboxProps: (record) => ({
        disabled: record.is_read,
        is_read: record.is_read,
      }),
    };

    const hasSelected = selectedRowKeys.length > 0;

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageTitleDetails
              titleText={"Inventory Status - " + inventoryTitle}
              isBackBtn="true"
              handleBack={this.handleBack}
            />

            <Button
              type="primary"
              onClick={this.handleSelection}
              disabled={!hasSelected}
              loading={loading}
              style={{ marginBottom: "16px" }}
            >
              Mark as Read
            </Button>

            <Table
              columns={columns}
              rowKey={(record) => record.activity_log_id}
              dataSource={data}
              pagination={false}
              loading={loading}
              bordered
              scroll={{ x: 400 }}
              rowSelection={rowSelection}
              rowClassName={(record) => this.setReadUnread(record)}
              size="small"
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default InventoryStatus;
