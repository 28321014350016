import React from "react";
import { Layout } from "antd";
import { Link } from "react-router-dom";
import { AntDesignOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import AppMainMenu from "./app-main-menu";
import EazyLogo from "../../assets/images/EazyLogoWhite.svg";

const AppHeader = (props) => {
  const { Header } = Layout;
  const { Title } = Typography;

  return (
    <Header>
      <div className="logo">
        <Link to="/">
        <img src={EazyLogo} alt="EasyOutdoor" />
        </Link>
      </div>
      <AppMainMenu {...props} />
    </Header>
  );
};

export default AppHeader;
