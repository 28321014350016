import React, { Component } from "react";
import { ajaxRequester } from "../../services/ajax-requester";
import { Table, Button, Descriptions, Typography, message } from "antd";
import {
  currencyFormatter,
  dateFormatter,
} from "../../components/common/common-formatter";
import PortalUser from "../../iam/components/portal-user";

class PlansMerge extends Component {
  state = { data: "", isDisabled: false };

  getMergeDetails = () => {
    const { selectedRowKeys, mergePlan } = this.props;

    let reqUrl = "plan/merge/details";
    let reqObj = {
      request: {
        plan_ids: selectedRowKeys,
        customer_id: Number(mergePlan.customer_id),
        segment_id: Number(mergePlan.segment_id),
        display_name: mergePlan.display_name,
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data.response) {
        this.setState({ data: data.response });
      } else {
        message.error(data.detail);
        this.props.handleActions();
      }
    });
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "Project Id",
        dataIndex: "project_id",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 100,
      },
      {
        title: "Employee",
        dataIndex: "employee_id",
        render: (text) => <PortalUser user={text} />,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 140,
      },
      {
        title: "Customer Name",
        dataIndex: "customer_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 140,
      },
      {
        title: "Display",
        dataIndex: "display_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "From",
        dataIndex: "start_date",
        render: (text) => dateFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "To",
        dataIndex: "end_date",
        render: (text) => dateFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Days",
        dataIndex: "no_of_days",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "SQFT",
        dataIndex: "total_sqft",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Amount",
        dataIndex: "total_amount",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: "QoS",
        dataIndex: "base_rate_diff",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => (
          <span className={text > 0 ? "ant-color-green" : "ant-color-red"}>
            {text}
          </span>
        ),
      },
      {
        title: "Status",
        dataIndex: "plan_status",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => text,
      },
    ];

    let columnsInventory = [
      {
        title: "Type",
        dataIndex: "ha_inventory_id",
        render: (text) => (text ? "HA" : "TA"),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Media",
        dataIndex: "media_type",
        render: (text) => <span style={{ whiteSpace: "nowrap" }}>{text}</span>,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Qty",
        dataIndex: "quantity",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "IID",
        dataIndex: "inventory_code",
        width: 110,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "City",
        dataIndex: "city",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Area",
        dataIndex: "area",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Location",
        dataIndex: "location",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Size",
        dataIndex: "size",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "SQFT",
        dataIndex: "sqft",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Light",
        dataIndex: "light_type",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Plan Status",
        dataIndex: "plan_status",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      // {
      //   title: "Available From",
      //   dataIndex: "available_from",
      //   render: (text) => dateFormatter(text),
      // },
      {
        title: "Start Date",
        dataIndex: "start_date",
        render: (text) => dateFormatter(text),
        width: 100,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "End Date",
        dataIndex: "end_date",
        render: (text) => dateFormatter(text),
        width: 100,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      // {
      //   title: "Base Rate",
      //   dataIndex: "base_rate",
      //   sorter: true,
      //   sortDirections: ["ascend", "descend", "ascend"],
      //   render: (text) => currencyFormatter(parseInt(text)),
      // },
      {
        title: "Monthly Cost",
        dataIndex: "monthly_rate",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: "Cost",
        dataIndex: "rental_cost",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => currencyFormatter(parseInt(text)),
      },
    ];

    this.setState({ columns, columnsInventory });
  };

  mergePlans = () => {
    this.setState({ isDisabled: true });
    const { selectedRowKeys } = this.props;

    let reqUrl = "plan/merge/save";
    let reqObj = {
      request: {
        plan_ids: selectedRowKeys,
        merge_plan: this.state.data,
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      this.props.handleActions();
    });
  };

  componentDidMount() {
    this.getMergeDetails();
    this.setColumns();
  }

  render() {
    const { data, loading, columns, columnsInventory, isDisabled } = this.state;
    const { selectedRows } = this.props;
    const { Title } = Typography;

    return (
      <div>
        {data && (
          <Descriptions column={3}>
            <Descriptions.Item label="New Customer Name">
              <b>{data.customer_name}</b>
            </Descriptions.Item>

            <Descriptions.Item label="New Display Name" span={2}>
              <b>{data.display_name}</b>
            </Descriptions.Item>

            <Descriptions.Item label="Start Date">
              <b>{dateFormatter(data.start_date)}</b>
            </Descriptions.Item>

            <Descriptions.Item label="End Date">
              <b>{dateFormatter(data.end_date)}</b>
            </Descriptions.Item>

            <Descriptions.Item label="Amount">
              <b>{currencyFormatter(parseInt(data.total_amount))}</b>
            </Descriptions.Item>
          </Descriptions>
        )}

        <Title style={{ marginTop: "16px" }} level={5}>
          Selected Plans
        </Title>

        <Table
          columns={columns}
          rowKey={(record) => record.plan_id}
          dataSource={selectedRows}
          pagination={false}
          loading={loading}
          bordered
          scroll={{ x: 400 }}
          size="small"
        />

        <Title style={{ marginTop: "24px" }} level={5}>
          Inventories
        </Title>

        {data && (
          <Table
            columns={columnsInventory}
            rowKey={(record) => record.ha_inventory_id}
            dataSource={data.plan_inventory_details}
            pagination={false}
            loading={loading}
            bordered
            scroll={{ x: 400 }}
            size="small"
          />
        )}

        <Button
          type="primary"
          style={{ marginTop: "24px" }}
          onClick={this.mergePlans}
          disabled={isDisabled}
        >
          Save
        </Button>
      </div>
    );
  }
}

export default PlansMerge;
