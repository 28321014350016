import React, { Component } from "react";
import { Modal, Table } from "antd";
import { ajaxRequester } from "../../services/ajax-requester";
import * as dayjs from "dayjs";
import { currencyFormatter } from "../../components/common/common-formatter";

class SalesEstimateStatistics extends Component {
  state = {
    fromPage: this.props.fromPage,
    results: "",
    data: [],
    columns: [],
    loading: true,
  };

  setColumns = () => {
    let columns = [];

    if (this.props.fromPage === "list") {
      columns = [
        {
          title: "Sales Estimate",
          dataIndex: "total_sales_amt",
          render: (text) => (text ? currencyFormatter(parseInt(text)) : ""),
        },
        {
          title: "Billed Amount",
          dataIndex: "billed_amount",
          render: (text) => (text ? currencyFormatter(parseInt(text)) : ""),
        },
        {
          title: "Unbilled Amount ",
          dataIndex: "unbilled_amount",
          render: (text) => (text ? currencyFormatter(parseInt(text)) : ""),
        },
      ];
    }

    if (this.props.fromPage === "details") {
      columns = [
        {
          title: "Description",
          dataIndex: "description",
        },
        {
          title: "Sales Estimate",
          dataIndex: "sales_estimate",
          render: (text) => (text ? currencyFormatter(parseInt(text)) : ""),
        },
        {
          title: "Billed",
          dataIndex: "billed_amount",
          render: (text) => (text ? currencyFormatter(parseInt(text)) : ""),
        },
        {
          title: "Unbilled",
          dataIndex: "unbilled_amount",
          render: (text) => (text ? currencyFormatter(parseInt(text)) : ""),
        },
      ];
    }

    this.setState({ columns });
  };

  // Get Statistics
  getStatistics = () => {
    let reqUrl = "salesestimate/billedsummary";
    let reqObj = {
      request: {
        period: "ttm",
        from_date: dayjs().startOf("month").format("YYYY-MM-DD"),
        to_date: dayjs().endOf("month").format("YYYY-MM-DD"),
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      this.setState({ data: data?.response || [], loading: false });
    });
  };

  getStatisticsDetails = () => {
    let reqUrl = "salesestimate/detail?campaignid=" + this.props?.id;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          data:
            data?.response?.billed_unbilled_list?.billed_unbilled_details || [],
          loading: false,
        });
      },
      "GET"
    );
  };

  componentDidMount() {
    this.props.fromPage === "list" && this.getStatistics();
    this.props.fromPage === "details" && this.getStatisticsDetails();
    this.setColumns();
  }

  render() {
    const { data, columns, loading, fromPage } = this.state;

    const details =
      dayjs.months(data[0]?.campmonth - 1) + " - " + data[0]?.campyear;

    return (
      <Modal
        centered
        title="Statistics"
        open={true}
        onCancel={this.props.handleStatistics}
        footer={false}
      >
        {fromPage === "list" && <h3>{details}</h3>}
        <Table
          columns={columns}
          rowKey={(record) => record.description}
          dataSource={data}
          pagination={false}
          loading={loading}
          bordered
          size="small"
        />
      </Modal>
    );
  }
}

export default SalesEstimateStatistics;
