import React, { Component } from "react";
import { Radio } from "antd";

class CampaignOperationTabs extends Component {
  state = { options: [], value: "" };

  onChange = (e) => {
    this.setState(
      {
        value: e.target.value,
      },
      () => this.props.handleTabEvent(e.target.value)
    );
  };

  componentDidMount() {
    const options = [
      { label: "Waiting For Flex", value: "waitingforflex" },
      { label: "Mounting", value: "mounting" },
      { label: "Monitoring", value: "monitoring" },
      { label: "Unmounting", value: "unmounting" },
    ];

    this.setState({
      options,
      value: this?.props?.activeTab || "waitingforflex",
    });
  }

  render() {
    const { options, value } = this.state;

    return (
      <div className="operation-tabs">
        <Radio.Group
          options={options}
          onChange={this.onChange}
          value={options.find((x) => x.value === value) ? value : ""}
          optionType="button"
          buttonStyle="solid"
          //loading={true}
        />
      </div>
    );
  }
}

export default CampaignOperationTabs;
