import React from "react";
import { Row, Col, Table, Modal, Drawer, Button, Switch } from "antd";
import PageTitle from "../../components/common/page-title";
import ListFilters from "../../components/common/list-filters";
import InventoryNav from "../../components/inventories/inventory-nav";
import InventoriesListBase from "../../base/inventories-list-base";
import PlanAdd from "../../components/plan/plan-add";
import InventoryAdvancedFilters from "../../components/inventories/inventory-advanced-filters";
import { ajaxRequester } from "../../services/ajax-requester";
import PhotoGallery from "../../components/common/photo-gallery";
import { RollbackOutlined } from "@ant-design/icons";

class InventoriesMaster extends InventoriesListBase {
  state = {
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    inventoryType: "hainventory",
    navKey: 1,
    filters: "",
    isModel: false,
    isDrawer: false,
    selectedRowKeys: [],
    selectedRows: [],
    pageName: "deletedlist",
    screen: "inventories-deleted",
  };

  callBack = () => {
    let columns = [...this.state.columns];
    let filterColumns = [...this.state.filterColumns];
    columns = columns.filter(
      (x) =>
        x.title !== "Actions" &&
        x.title !== "Log" &&
        x.title !== "SQFT" &&
        x.title !== "Booking Status"
    );

    filterColumns = filterColumns.filter(
      (x) =>
        x.displayName !== "Actions" &&
        x.displayName !== "Log" &&
        x.displayName !== "SQFT" &&
        x.displayName !== "Booking Status"
    );

    this.setState({ columns, filterColumns });
  };

  // Check Box Selection
  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys, selectedRows });
  };

  // Revive
  handleRevive = () => {
    this.setState({ loading: true });
    let ids = this.state.selectedRowKeys[0];
    var reqUrl = this.state.inventoryType + "/revive?inventoryId=" + ids;
    var reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      () => {
        const { pagination } = this.state;
        this.getInventories({ pagination });
        this.setState({ selectedRowKeys: [], selectedRow: [] });
      },
      "GET"
    );
  };

  // Handle Popup
  handleModal = () => {
    this.setState({ isModel: !this.state.isModel });
  };

  handleInventoryType = () => {
    let inventoryType =
      this.state.inventoryType === "hainventory" ||
      this.state.inventoryType === "hagroupinventory"
        ? "tainventory"
        : "hainventory";

    this.setState({ inventoryType }, this.handleToggle());
  };

  componentDidMount() {
    // Get Inventories List
    const { pagination } = this.state;
    this.getInventories({ pagination });
    this.setColumns();
  }

  render() {
    const {
      data,
      columns,
      pagination,
      loading,
      inventoryType,
      navKey,
      filterColumns,
      selectedRowKeys,
      isModel,
      isDrawer,
      screen,
      isShowPhotos,
      photos,
      photosTitle,
    } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,

      getCheckboxProps: (record) => ({
        disabled: record.is_read,
        is_read: record.is_read,
      }),
    };

    const hasSelected = selectedRowKeys.length > 0;

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            {inventoryType === "hainventory" && (
              <PageTitle titleText="Deleted HA Inventory List" />
            )}

            {inventoryType === "tainventory" && (
              <PageTitle titleText="Deleted TA Inventory List" />
            )}

            {inventoryType === "hagroupinventory" && (
              <PageTitle titleText="Deleted HA Grouped Inventory List" />
            )}

            {inventoryType === "tagroupinventory" && (
              <PageTitle titleText="Deleted TA Grouped Inventory List" />
            )}

            <ListFilters
              items={filterColumns}
              handleSettings={this.handleSettings}
              handleFilters={this.handleFilters}
              key={filterColumns}
              screen={screen}
            />

            <div
              style={{
                top: "-110px",
                float: "right",
                right: "0px",
                marginBottom: "-48px",
                position: "relative",
              }}
              className="title-btns"
            >
              <Button
                type="primary"
                disabled={!hasSelected}
                loading={loading}
                onClick={this.handleRevive}
                icon={<RollbackOutlined />}
                className="icon-btn"
              >
                Shift to Inventory Master
              </Button>
            </div>

            <InventoryNav
              {...this.state}
              handleToggle={this.handleToggle}
              handleNav={this.handleNav}
              key={navKey}
            />

            <div
              style={{
                top: "-44px",
                float: "right",
                right: "122px",
                marginBottom: "-44px",
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
              className="inventory-del-switch"
            >
              <Switch size="small" onChange={this.handleInventoryType}></Switch>
              <label style={{ marginLeft: "8px", marginRight: "16px" }}>
                TA<span className="mobile-hide"> Inventories</span>
              </label>
            </div>

            {(inventoryType === "hainventory" ||
              inventoryType === "tainventory") && (
              <Table
                columns={columns}
                rowKey={(record) => record.ha_inventory_id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={this.handleTableChange}
                bordered
                scroll={{ x: 400 }}
                size="small"
                expandable={{
                  expandedRowRender: (record) => this.getMultiDetails(record),
                  rowExpandable: (record) =>
                    record.ha_base_inventories &&
                    record.ha_base_inventories.length > 0,
                }}
                rowSelection={rowSelection}
              />
            )}

            {(inventoryType === "hagroupinventory" ||
              inventoryType === "tagroupinventory") && (
              <Table
                columns={columns}
                rowKey={(record) => record.ha_inventory_id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={this.handleTableChange}
                bordered
                scroll={{ x: 400 }}
                size="small"
                expandable={{
                  expandedRowRender: (record) => this.getGroupDetails(record),
                }}
                rowSelection={rowSelection}
              />
            )}
          </Col>
        </Row>

        <Modal
          centered
          title="Add to Plan"
          open={isModel}
          onCancel={this.handleModal}
          footer={null}
        >
          <PlanAdd key={isModel} addToPlan={this.addToPlan} />
        </Modal>

        <Drawer
          title={"Advanced Filters"}
          placement="right"
          open={isDrawer}
          onClose={this.handleDrawer}
          width={"64%"}
        >
          <InventoryAdvancedFilters
            handleAdvancedFilters={this.handleAdvancedFilters}
            key={isDrawer}
          />
        </Drawer>

        {isShowPhotos && (
          <PhotoGallery
            photos={photos}
            photosTitle={photosTitle}
            handlePhotos={this.handlePhotos}
          />
        )}
      </React.Fragment>
    );
  }
}

export default InventoriesMaster;
