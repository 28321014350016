import React, { Component } from "react";
import { ajaxRequester } from "../../services/ajax-requester";
import PageTitleDetails from "../../components/common/page-title-detail";
import { Row, Col, Spin, Descriptions, Table, Button, Image } from "antd";
import { FileImageOutlined } from "@ant-design/icons";
import PhotoGallery from "../../components/common/photo-gallery";

class InventoryDetails extends Component {
  state = { results: "", isLoading: true };

  // Get Inventory Details
  getInventoryDetails = () => {
    const inventoryType = this.props.match.params.type;
    const inventoryId = this.props.match.params.id;

    var reqUrl = inventoryType + "/detail?inventoryId=" + Number(inventoryId);
    var reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ results: data.response, isLoading: false });
      },
      "GET"
    );
  };

  // Redirect to Inventories List
  handleBack = () => {
    this.props.history.goBack();
  };

  // Currency Formatter
  currencyFormatter = (currency) => {
    let x = currency;
    x = x.toString();
    let lastThree = x.substring(x.length - 3);
    let otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers !== "") lastThree = "," + lastThree;
    let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res;
  };

  // Handle Photos SlideShoe
  handlePhotos = (record) => {
    this.setState({
      isShowPhotos: !this.state.isShowPhotos,
      photos: record ? record?.ha_images : "",
      photosTitle: record ? record : "",
    });
  };

  // on component load get details based on id from url
  componentDidMount() {
    this.getInventoryDetails();
  }

  render() {
    const { results, isLoading, isShowPhotos, photos, photosTitle } =
      this.state;

    let ha_base_inventoriesColumns = [
      {
        title: "IID",
        dataIndex: "inventory_code",
      },
      {
        title: "Side Type",
        dataIndex: "side_type",
        render: (text) => (
          <span style={{ textTransform: "capitalize" }}>{text}</span>
        ),
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
      },
      {
        title: "Description",
        dataIndex: "description",
      },
      {
        title: "Width",
        dataIndex: "width",
      },
      {
        title: "Height",
        dataIndex: "height",
      },
      {
        title: "SQFT",
        dataIndex: "sqft",
      },
      {
        title: "Light Type",
        dataIndex: "light_type",
      },
      {
        title: "Base Rate",
        dataIndex: "base_rate",
        render: (text) => this.currencyFormatter(text),
      },
      {
        title: "Card Rate",
        dataIndex: "card_rate",
        render: (text) => this.currencyFormatter(text),
      },
    ];

    let ha_group_inventoriesColumns = [
      {
        title: "Media",
        dataIndex: "media_type",
        render: (text) => <span style={{ whiteSpace: "nowrap" }}>{text}</span>,
      },
      {
        title: "Qty",
        dataIndex: "quantity",
      },
      {
        title: "IID",
        dataIndex: "inventory_code",
      },
      {
        title: "City",
        dataIndex: "city",
      },
      {
        title: "Area",
        dataIndex: "area",
      },
      {
        title: "Location",
        dataIndex: "location",
      },
      {
        title: "Size",
        render: (text) => text.width + "x" + text.height,
      },
      {
        title: "Light",
        dataIndex: "light_type",
      },
      {
        title: "Photo",
        dataIndex: "images",
        render: (text, record) => (
          <Button
            className="ant-btn-reset"
            disabled={record?.ha_images?.length > 0 ? false : true}
            icon={<FileImageOutlined />}
            onClick={() => this.handlePhotos(record)}
          />
        ),
      },
      {
        title: "Card Rate",
        dataIndex: "card_rate",
        render: (text) => this.currencyFormatter(text),
      },
      {
        title: "Inventory Status",
        dataIndex: "booking_status",
        render: (text) => (text === "A" ? <span>Active</span> : "Inactive"),
      },
      {
        title: "Booking Status",
        dataIndex: "booking_status",
        render: (text) => (text === "A" ? "Available" : "Not Available"),
      },
    ];

    return (
      <Row className="customer-details">
        <Col span={24} xs={24} xl={24}>
          {!isLoading && (
            <React.Fragment>
              <PageTitleDetails
                titleText={"Inventory Details - " + results.inventory_code}
                isBackBtn="true"
                handleBack={this.handleBack}
              />

              <Descriptions column={{ xl: 4, xs: 1 }}>
                <Descriptions.Item label="Media">
                  <b>{results.media_type}</b>
                </Descriptions.Item>

                <Descriptions.Item label="Structure">
                  <b>
                    {results.structure === "S"
                      ? "Single Display"
                      : "Multi Display"}
                  </b>
                </Descriptions.Item>

                <Descriptions.Item label="Status">
                  <b>{results.is_active === true ? "Active" : "Inactive"}</b>
                </Descriptions.Item>

                <Descriptions.Item label="City">
                  <b>{results.city}</b>
                </Descriptions.Item>

                <Descriptions.Item label="District">
                  <b>{results.district}</b>
                </Descriptions.Item>

                <Descriptions.Item label="State">
                  <b>{results.state}</b>
                </Descriptions.Item>

                <Descriptions.Item label="Area">
                  <b>{results.area}</b>
                </Descriptions.Item>

                <Descriptions.Item label="Location">
                  <b>{results.location}</b>
                </Descriptions.Item>

                <Descriptions.Item label="Qty">
                  <b>{results.quantity}</b>
                </Descriptions.Item>

                <Descriptions.Item label="Width">
                  <b>{results.width}</b>
                </Descriptions.Item>

                <Descriptions.Item label="Height">
                  <b>{results.height}</b>
                </Descriptions.Item>

                <Descriptions.Item label="SQFT">
                  <b>{results.sqft}</b>
                </Descriptions.Item>

                <Descriptions.Item label="Base Rate">
                  <b>{this.currencyFormatter(results.base_rate)}</b>
                </Descriptions.Item>

                <Descriptions.Item label="Card Rate">
                  <b>{this.currencyFormatter(results.card_rate)}</b>
                </Descriptions.Item>

                <Descriptions.Item label="Side Type">
                  <b style={{ textTransform: "capitalize" }}>
                    {results.side_type}
                  </b>
                </Descriptions.Item>

                <Descriptions.Item label="Light">
                  <b>{results.light_type}</b>
                </Descriptions.Item>

                <Descriptions.Item label="Traffic From">
                  <b>{results.traffic_facing_from}</b>
                </Descriptions.Item>

                <Descriptions.Item label="Traffic To">
                  <b>{results.traffic_going_to}</b>
                </Descriptions.Item>

                <Descriptions.Item label="Inventory Status">
                  <b>
                    {results.inventory_status === "A"
                      ? "Active"
                      : results.inventory_status}
                  </b>
                </Descriptions.Item>

                <Descriptions.Item label="Booking Status">
                  <b>
                    {results.booking_status === "A" ? "Active" : "Inactive"}
                  </b>
                </Descriptions.Item>

                <Descriptions.Item label="Campaign Start Date">
                  <b>{results.campaign_start_date}</b>
                </Descriptions.Item>

                <Descriptions.Item label="Campaign End Date">
                  <b>{results.campaign_end_date}</b>
                </Descriptions.Item>

                <Descriptions.Item label="Latitude">
                  <b>{results.lat_long?.x}</b>
                </Descriptions.Item>

                <Descriptions.Item label="Longitude">
                  <b>{results.lat_long?.y}</b>
                </Descriptions.Item>

                <Descriptions.Item label="Images">
                  <b>{results.images && results?.images[0]?.image_name}</b>
                </Descriptions.Item>
              </Descriptions>

              <div className="custom-images-display">
                {results.ha_images &&
                  results.ha_images.map((img, key) => {
                    return (
                      <Image
                        key={key}
                        width={200}
                        height={142}
                        src={img.data_url}
                      />
                    );
                  })}
              </div>

              {results.ha_base_inventories &&
                results.ha_base_inventories.length > 0 && (
                  <React.Fragment>
                    <h3>Base Inventories :</h3>
                    <Table
                      columns={ha_base_inventoriesColumns}
                      rowKey={(record) => record.ha_inventory_id}
                      dataSource={results.ha_base_inventories}
                      pagination={false}
                      scroll={{ x: 400 }}
                      size="small"
                      bordered
                    />
                  </React.Fragment>
                )}

              {results.ha_group_inventories &&
                results.ha_group_inventories.length > 0 && (
                  <React.Fragment>
                    <h3>Group Inventories :</h3>
                    <Table
                      columns={ha_group_inventoriesColumns}
                      rowKey={(record) => record.ha_inventory_id}
                      dataSource={results.ha_group_inventories}
                      pagination={false}
                      scroll={{ x: 400 }}
                      size="small"
                      bordered
                    />
                  </React.Fragment>
                )}

              {isShowPhotos && (
                <PhotoGallery
                  photos={photos}
                  photosTitle={photosTitle}
                  handlePhotos={this.handlePhotos}
                />
              )}
            </React.Fragment>
          )}

          {isLoading && (
            <div className="list-loader">
              <Spin />
            </div>
          )}
        </Col>
      </Row>
    );
  }
}

export default InventoryDetails;
