import React from "react";
import { Table, Button } from "antd";
import ListFilters from "../../components/common/list-filters";
import CommonListBase from "../../base/common-list-base";
import {
  currencyFormatter,
  dateFormatter,
} from "../../components/common/common-formatter";
import PortalUser from "../../iam/components/portal-user";

class SelectCustomerWiseInvoices extends CommonListBase {
  state = {
    listReq:
      "salesestimate/invoicelistofcustomer?customerId=" +
      this.props.customer.customer_id +
      "&",
    data: [],
    pagination: {
      current: 1,
      pageSize: 100,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    sorting: [],
    options: [],
    selectedRowKeys: [],
    selectedRows: [],
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "Project Id",
        dataIndex: "project_id",
        width: 100,
      },
      {
        title: "Invoice Number",
        dataIndex: "invoice_no",
      },

      {
        title: "Employee",
        dataIndex: "employee_id",
        render: (text) => <PortalUser user={text} />,
        width: 140,
      },
      {
        title: "Display",
        dataIndex: "display_name",
        width: 118,
      },
      {
        title: "Invoice Date",
        dataIndex: "invoice_date",
        render: (text) => dateFormatter(text),
      },
      {
        title: "Status",
        dataIndex: "invoice_status",
      },
      {
        title: "Total Without Tax",
        dataIndex: "invoice_amount",
        render: (text) => currencyFormatter(parseInt(text)),
        width: 118,
      },
      {
        title: "Total With Tax",
        dataIndex: "total_amount",
        render: (text) => currencyFormatter(parseInt(text)),
        width: 112,
      },
    ];

    let filterColumns = [
      { columnName: "project_id", displayName: "Project Id" },
      { columnName: "invoice_no", displayName: "Invoice Number" },
      { columnName: "employee_id", displayName: "Employee" },
      { columnName: "display_name", displayName: "Display", default: true },
      { columnName: "invoice_date", displayName: "Invoice Date" },
      { columnName: "invoice_status", displayName: "Status" },
      { columnName: "invoice_amount", displayName: "Total Without Tax" },
      { columnName: "total_amount", displayName: "Total With Tax" },
    ];

    if (this.state.value === "Manual Invoices") {
      columns = [
        {
          title: "Employee",
          dataIndex: "employee_id",
          render: (text) => <PortalUser user={text} />,
          sorter: true,
          sortDirections: ["ascend", "descend", "ascend"],
          width: 140,
        },
        {
          title: "Customer Name",
          dataIndex: "customer_name",
          sorter: true,
          sortDirections: ["ascend", "descend", "ascend"],
          width: 142,
        },
        {
          title: "Display",
          dataIndex: "display_name",
          sorter: true,
          sortDirections: ["ascend", "descend", "ascend"],
        },
        {
          title: "PO Number",
          dataIndex: "po_number",
          sorter: true,
          sortDirections: ["ascend", "descend", "ascend"],
        },
        {
          title: "PO Date",
          dataIndex: "po_date",
          render: (text) => dateFormatter(text),
          sorter: true,
          sortDirections: ["ascend", "descend", "ascend"],
        },
        {
          title: "Total Invoice Amount",
          dataIndex: "total_amount",
          render: (text) => currencyFormatter(parseInt(text)),
          sorter: true,
          sortDirections: ["ascend", "descend", "ascend"],
        },
        {
          title: "Invoice Number",
          dataIndex: "invoice_no",
          sorter: true,
          sortDirections: ["ascend", "descend", "ascend"],
        },
        {
          title: "Invoice Date",
          dataIndex: "invoice_date",
          render: (text) => dateFormatter(text),
          sorter: true,
          sortDirections: ["ascend", "descend", "ascend"],
        },
        {
          title: "Entry Done by",
          dataIndex: "created_by_id",
          render: (text) => <PortalUser user={text} />,
          sorter: true,
          sortDirections: ["ascend", "descend", "ascend"],
        },
      ];

      filterColumns = [
        { columnName: "employee_id", displayName: "Employee" },
        {
          columnName: "customer_name",
          displayName: "Customer Name",
        },
        { columnName: "display_name", displayName: "Display", default: true },
        { columnName: "po_number", displayName: "Po Number" },
        { columnName: "po_date", displayName: "Po Date" },
        { columnName: "total_amount", displayName: "Total Invoice Amount" },
        { columnName: "invoice_date", displayName: "Invoice Date" },
        { columnName: "invoice_no", displayName: "Invoice Number" },
        { columnName: "created_by_id", displayName: "Entry Done by" },
      ];
    }

    this.setState({ columns, columnsDefault: columns, filterColumns });
  };

  // Check Box Selection
  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys, selectedRows });
  };

  handleSelection = () => {
    this.props.handleSelection(this.state.selectedRows);
  };

  setDisabled = (record) => {
    return (
      this.state.selectedInvoices.includes(record.invoice_id) && "disabled-row"
    );
  };

  componentDidMount() {
    // Get List
    const { pagination } = this.state;
    this.getResults({ pagination });
    this.setColumns();

    let selectedInvoices = [];

    if (this.props.selectedInvoices) {
      this.props.selectedInvoices.map((x) =>
        selectedInvoices.push(x.invoice_id)
      );
    }

    this.setState({ selectedInvoices });
  }

  render() {
    const {
      data,
      columns,
      pagination,
      loading,
      filterColumns,
      selectedRowKeys,
    } = this.state;

    const rowSelection = {
      selectedRowKeys,
      preserveSelectedRowKeys: true,
      onChange: this.onSelectChange,
      getCheckboxProps: (record) => ({
        disabled: this.state.selectedInvoices.includes(record.invoice_id),
      }),
    };

    const hasSelected = selectedRowKeys.length > 0;

    return (
      <React.Fragment>
        {/* <ListFilters
          items={filterColumns}
          handleSettings={this.handleSettings}
          handleFilters={this.handleFilters}
          key={filterColumns}
        /> */}

        {/* <Button
          type="primary"
          onClick={this.handleSelection}
          disabled={!hasSelected}
          loading={loading}
          style={{
            float: "right",
            position: "relative",
            top: "-48px",
            right: "48px",
            marginBottom: "-48px",
          }}
        >
          Add Invoices
        </Button> */}

        <Table
          columns={columns}
          rowKey={(record) => record.invoice_id}
          dataSource={data}
          pagination={false}
          loading={loading}
          onChange={this.handleTableChange}
          bordered
          scroll={{ x: 400 }}
          size="small"
          rowSelection={{
            ...rowSelection,
          }}
          rowClassName={(record) => this.setDisabled(record)}
        />

        <Button
          type="primary"
          onClick={this.handleSelection}
          disabled={!hasSelected}
          loading={loading}
          style={{ top: "16px" }}
        >
          Add Invoices
        </Button>
      </React.Fragment>
    );
  }
}

export default SelectCustomerWiseInvoices;
