import React, { Component } from "react";
import { Row, Col, Button, Table, Typography } from "antd";
import { ajaxRequester } from "../../services/ajax-requester";
import { dateFormatter } from "../../components/common/common-formatter";
import CommonListBase from "../../base/common-list-base";

class EstimateChangeLog extends CommonListBase {
  state = {
    listReq:
      "scheduledtransaction/inventory/changelog?payoutEstimateId=" +
      this?.props?.payout_estimate_id +
      "&",
    delReq: "",
    dtlReq: "",
    editReq: "",
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    sorting: [],
    screen: "transaction-changelog-paging",
    selectedRowKeys: [],
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "Site Description",
        dataIndex: "inventory_code",
        render: (text, record) =>
          record?.inventory_code +
          " - " +
          record?.city +
          " - " +
          record?.location +
          " - " +
          record?.size,
      },
      {
        title: "Activity",
        dataIndex: "message",
      },
      {
        title: "Remarks",
        dataIndex: "remarks",
      },
      {
        title: "Date",
        dataIndex: "updated_date",
        render: (text) => dateFormatter(text),
      },
      {
        title: "Read",
        dataIndex: "is_read",
        render: (text) => (text ? "Read" : <b>Unread</b>),
      },
    ];

    this.setState({ columns });
  };

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys, selectedRows });
  };

  setReadUnread = (record) => {
    return record.is_read ? "status-record-read" : "status-record-unread";
  };

  handleSelection = () => {
    this.setState({ loading: true });

    let reqUrl = "hainventory/inventorystatus/activitylog/markasread";
    let reqObj = {
      request: {
        activity_log_id: this.state.selectedRowKeys,
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      this.setState({ selectedRowKeys: [], loading: false });
      const { pagination } = this.state;
      this.getResults({ pagination });
    });
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.getResults({ pagination });
    this.setColumns();
  }

  render() {
    const {
      data,
      columns,
      pagination,
      loading,
      filterColumns,
      screen,
      selectedRowKeys,
    } = this.state;
    const { Title } = Typography;

    const rowSelection = {
      selectedRowKeys,
      preserveSelectedRowKeys: true,
      onChange: this.onSelectChange,

      getCheckboxProps: (record) => ({
        disabled: record.is_read,
        is_read: record.is_read,
      }),
    };

    const hasSelected = selectedRowKeys.length > 0;

    return (
      <Row style={{ marginTop: "24px" }}>
        <Col span={24}>
          <Title level={5} style={{ marginBottom: "16px" }}>
            Change Log
            <Button
              type="primary"
              onClick={this.handleSelection}
              disabled={!hasSelected}
              loading={loading}
              style={{ float: "right" }}
              // size="small"
            >
              Mark as Read
            </Button>
          </Title>

          <Table
            columns={columns}
            rowKey={(record) => record?.activity_log_id}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            onChange={this.handleTableChange}
            bordered
            scroll={{ x: 400 }}
            size="small"
            rowSelection={rowSelection}
            // rowClassName={(record) => this.setColors(record)}
          />
        </Col>
      </Row>
    );
  }
}

export default EstimateChangeLog;
