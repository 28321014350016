import React, { Component } from "react";
import { Row, Col, Divider, Table, Typography } from "antd";
import { ajaxRequester } from "../../services/ajax-requester";
import {
  currencyFormatter,
  dateFormatter,
} from "../../components/common/common-formatter";

class TransactionsHistoryList extends Component {
  state = {
    listReq: "scheduledtransaction/log?payoutEstimateId=1",
    data: [],
    loading: false,
    columns: [],
    screen: "trans-history-list",
  };

  getResults = () => {
    const { payout_estimate_id } = this.props;
    let reqUrl =
      "scheduledtransaction/log?payoutEstimateId=" + payout_estimate_id;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ data: data.response });
      },
      "GET"
    );
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "PID",
        dataIndex: "pid",
      },
      {
        title: "Account Name",
        dataIndex: "payee_account_name",
      },
      {
        title: "From",
        dataIndex: "billing_duration_from",
        render: (text) => dateFormatter(text),
      },
      {
        title: "To",
        dataIndex: "billing_duration_to",
        render: (text) => dateFormatter(text),
      },
      {
        title: "Estimated Amount",
        dataIndex: "payout_amount",
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: "Amount Paid",
        dataIndex: "payment_amount",
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: "Difference",
        dataIndex: "amount_difference",
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: "Payment Date",
        dataIndex: "payment_date",
        render: (text) => dateFormatter(text),
      },
      {
        title: "Remarks",
        dataIndex: "remarks",
      },
    ];

    this.setState({ columns });
  };

  componentDidMount() {
    this.getResults();
    this.setColumns();
  }

  render() {
    const { data, columns, loading } = this.state;
    const { Title } = Typography;
    return (
      data &&
      data.length > 0 && (
        <Row>
          <Col span={24}>
            <Title level={5} style={{ marginBottom: "16px" }}>
              Transaction History
            </Title>

            <Table
              columns={columns}
              rowKey={(record) => record?.payout_estimate_id}
              dataSource={data}
              pagination={false}
              loading={loading}
              bordered
              scroll={{ x: 400 }}
              size="small"
            />
          </Col>
        </Row>
      )
    );
  }
}

export default TransactionsHistoryList;
