import {useState} from "react";
import {Layout, Menu} from "antd";
import {
    LaptopOutlined,
    NotificationOutlined,
    SettingOutlined,
    UsergroupAddOutlined,
    UserOutlined
} from "@ant-design/icons";
import EditProfile from "./edit-profile";
import ManagerUsers from "./manage-users";
import EditSettings from "./edit-settings";
import {RoleAuthorization} from "./admin-helpers";
import AdminApi from "../api/admin-api";

const {Sider, Content} = Layout;
const {SubMenu} = Menu;

const UserSettings = (props) => {

    const [selectedMenu, setSelectedMenu] = useState({
        key: '1'
    });

    return (
        <div>
            <Layout className="site-layout-background" style={{padding: '24px 0', background: "none"}}>
                <RoleAuthorization roles={[AdminApi.roles.Admin, AdminApi.roles.SuperAdmin]}>
                    <Menu theme="light" defaultSelectedKeys={['1']} mode="horizontal" onSelect={setSelectedMenu}>
                        <Menu.Item key={1} icon={<UserOutlined/>}>
                            Profile
                        </Menu.Item>
                        <Menu.Item key={2} icon={<UsergroupAddOutlined/>}>
                            Users
                        </Menu.Item>
                        <Menu.Item key={3} icon={<SettingOutlined/>}>
                            Configuration
                        </Menu.Item>
                    </Menu>
                </RoleAuthorization>
                <RoleAuthorization roles={[AdminApi.roles.Accounts, AdminApi.roles.BranchManager, AdminApi.roles.Employee, AdminApi.roles.Mounter, AdminApi.roles.Photographer, AdminApi.roles.PurchaseManager]}>
                    <Menu theme="light" defaultSelectedKeys={['1']} mode="horizontal" onSelect={setSelectedMenu}>
                        <Menu.Item key={1} icon={<UserOutlined/>}>
                            Profile
                        </Menu.Item>
                    </Menu>
                </RoleAuthorization>
                <Content style={{padding: '0 24px', minHeight: 280}}>
                    {selectedMenu.key === '1' && <EditProfile/>}
                    {selectedMenu.key === '2' && <ManagerUsers/>}
                    {selectedMenu.key === '3' && <EditSettings/>}
                </Content>
            </Layout>
        </div>
    )
}

export default UserSettings;
