import { dateFormatter } from "../../components/common/common-formatter";

const ExcelJS = require("exceljs");

export const exportCampaignToXlsx = (
  reportName,
  inventory_details,
  configuration,
  settings
) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet(reportName);
  let inventories = inventory_details || [];

  inventories = inventories.map((x) => ({
    ...x,
    sr: x.sr,
    start_date: dateFormatter(x?.start_date),
    end_date: dateFormatter(x?.end_date),
    flex_received_date: dateFormatter(x?.flex_received_date),
    installation_date: dateFormatter(x?.installation_date),
    mounting_date: dateFormatter(x?.mounting_date),
    monitoring_date: dateFormatter(x?.monitoring_date),
    monitoring_performed_date:
      x?.monitoring_performed_date !== "0001-01-01T00:00:00" &&
      dateFormatter(x?.monitoring_performed_date),
    unmounting_photo_performed_date:
      x?.unmounting_photo_performed_date &&
      x?.unmounting_photo_performed_date !== "0001-01-01T00:00:00"
        ? dateFormatter(x?.unmounting_photo_performed_date)
        : "-",
    campaign_status:
      x.campaign_status === "Running" || x.campaign_status === "Completed"
        ? dateFormatter(x?.start_date)
        : "-",
    display_qty: x?.quantity * (x?.side_type === "double" ? 2 : 1),
    location:
      x?.location +
      (x?.quantity > 1
        ? " (Qty : " +
          x?.quantity +
          " / Display : " +
          (x?.side_type === "double" ? x?.quantity * 2 : x?.quantity) +
          ")"
        : ""),
  }));

  let columns = [
    { title: "Sr", columnName: "sr", width: 4, align: "center" },
    {
      title: "Media Type",
      columnName: "media_type",
      width: 16,
      align: "left",
    },
    { title: "IID", columnName: "inventory_code", width: 10, align: "left" },
    { title: "State", columnName: "state", width: 12, align: "left" },
    { title: "District", columnName: "district", width: 12, align: "left" },
    { title: "City", columnName: "city", width: 12, align: "left" },
    { title: "Area", columnName: "area", width: 12, align: "left" },
    { title: "Location", columnName: "location", width: 24, align: "left" },
    { title: "W", columnName: "width", width: 8, align: "center" },
    { title: "H", columnName: "height", width: 8, align: "center" },
    { title: "SQFT", columnName: "sqft", width: 8, align: "center" },
    { title: "Light", columnName: "light_type", width: 8, align: "center" },
    { title: "Qty", columnName: "quantity", width: 10, align: "center" },
    {
      title: "Display Qty",
      columnName: "display_qty",
      width: 16,
      align: "center",
    },
    {
      title: "Start Date",
      columnName: "start_date",
      width: 10,
      align: "center",
    },
    {
      title: "Flex Receive Date",
      columnName: "flex_received_date",
      width: 18,
      align: "center",
    },
    {
      title: "Installation Date",
      columnName: "installation_date",
      width: 16,
      align: "center",
    },
    {
      title: "Mounting Date",
      columnName: "mounting_date",
      width: 14,
      align: "center",
    },
    {
      title: "Monitoring Date",
      columnName: "monitoring_date",
      width: 16,
      align: "center",
    },
    {
      title: "Monitoring Name",
      columnName: "monitoring_name",
      width: 24,
    },
    {
      title: "Monitoring Performed On",
      columnName: "monitoring_performed_date",
      width: 24,
      align: "center",
    },
    { title: "End Date", columnName: "end_date", width: 10, align: "center" },
    {
      title: "Unmounting Photo Date",
      columnName: "unmounting_photo_performed_date",
      width: 22,
      align: "center",
    },
    {
      title: "Date of Installation",
      columnName: "campaign_status",
      width: 18,
      align: "center",
    },
    {
      title: "Creative Name",
      columnName: "creative_name",
      width: 18,
    },
  ];

  settings?.length > 0 &&
    (columns = columns.filter((x) => settings.includes(x.title)));

  const getColAddress = (add) => {
    let address = add.replace(/[0-9]/g, "");
    return address;
  };

  // Header Rows
  sheet.addRow([reportName]);
  sheet.getCell("A1").font = {
    bold: true,
    size: 14,
  };

  // Adding Colums Title
  let titleRowNum = 2;
  sheet.addRow(columns.map((x) => x.title));
  sheet.getRow(titleRowNum).eachCell((cell, index) => {
    cell.font = {
      bold: true,
    };
    cell.border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    cell.alignment = { horizontal: columns[index - 1]?.align };
    sheet.getColumn(getColAddress(cell.address)).width =
      columns[index - 1].width || 0;
  });

  // Adding Sites
  const numFmt = "#,##0";
  let total = 0;
  let total_printing_cost = 0;
  let total_installing_cost = 0;
  let total_monthly_rate = 0;
  let total_amount = 0;
  let gst_amount = 0;
  let grand_total = 0;
  let numRows = 0;
  let siteRows = [];

  inventories.forEach((inventory) => {
    total += inventory.rental_cost;
    total_printing_cost += inventory.printing_cost;
    total_installing_cost += inventory.installing_cost;
    total_monthly_rate += inventory.monthly_rate;
    total_amount += inventory.total_amount;
    gst_amount += inventory.gst_amount;
    grand_total += inventory.grand_total;
    numRows += 1;

    sheet.addRow(columns.map((x) => inventory[x.columnName] || ""));

    if (inventory.ha_base_inventories?.length > 0) {
      siteRows.push("subrow");
      numRows += 1;
      inventory.ha_base_inventories.forEach((groupInventory, gIndex) => {
        sheet.addRow(columns.map((x) => groupInventory[x.columnName] || ""));
        siteRows.push("subrow");
      });
    } else {
      siteRows.push("row");
    }
  });

  siteRows.forEach((row, count) => {
    sheet.getRow(titleRowNum).eachCell((cell, index) => {
      let newcell = sheet.getCell(
        getColAddress(cell.address) + (titleRowNum + count + 1)
      );
      newcell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      newcell.alignment = { horizontal: columns[index - 1]?.align };
      columns[index - 1]?.numberFormat && (newcell.numFmt = numFmt);
      row === "subrow" &&
        (newcell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "F2F2F2" },
        });
    });
  });

  // Exporting File
  workbook.xlsx.writeBuffer().then((buffer) => {
    let blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    let a = document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.style.display = "none";
    a.download = configuration?.exportName ?? "export.xlsx";
    document.body.appendChild(a);
    a.click();
  });
};
