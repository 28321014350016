import React, { Component } from "react";
import { Table, Row, Col, Form, Button, InputNumber, Select } from "antd";
import { ajaxRequester } from "../../services/ajax-requester";
import PlanInventoryEdit from "../../components/plan/plan-inventory-edit";
import { FileImageOutlined } from "@ant-design/icons";

class AdditionalPrintingMounting extends Component {
  state = {
    mediaTypeList: [],
    columns: [],
    inventory_details: [],
    loading: true,
  };

  formRef = React.createRef();

  // Get Media Type List from Lookup
  getMediaTypeList = () => {
    let reqUrl = "lookup/mediatype";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        let mediaTypeList = data?.response || [];
        let invMediaTypeList = [];

        this.props?.data &&
          this.props?.data.map((x) => invMediaTypeList.push(x.media_type));

        mediaTypeList = mediaTypeList.filter((x) =>
          invMediaTypeList.includes(x.media_type)
        );

        this.setState({ mediaTypeList });
      },
      "GET"
    );
  };

  onFinish = (values) => {
    const { inventory_details } = this.state;
    this.props.handleAdditionalPrintingMounting(inventory_details);
  };

  handleInventoryEdit = (value, record, column) => {
    let inventory_details = [...this.state.inventory_details];

    inventory_details = inventory_details.map((x) => ({
      ...x,
      printing_cost:
        x.operation_id === record.operation_id
          ? column === "printing_cost"
            ? value
            : x.printing_cost
          : x.printing_cost,
      installing_cost:
        x.operation_id === record.operation_id
          ? column === "installing_cost"
            ? value
            : x.installing_cost
          : x.installing_cost,
    }));

    this.setState({
      inventory_details,
    });
  };

  handleBulkEdit = (value, values) => {
    let key = Object.keys(value)[0];
    let val = value[key];

    let inventory_details = [...this.state.inventory_details];

    if (key === "media_type" || key === "rate_type") {
      this.clearBlukEditValue();
    } else {
      inventory_details = inventory_details.map((x) => ({
        ...x,
        printing_cost: this.getCalculatedPrintRate(x, key, val, values),
        installing_cost: this.getCalculatedMountRate(x, key, val, values),
      }));
      this.clearBlukEditValue(key);
    }

    this.setState({ inventory_details });
  };

  getCalculatedPrintRate = (x, key, val, values) => {
    let rate = "";

    if (x.media_type === values["media_type"]) {
      if (values["rate_type"] === "Rate/SQFT") {
        if (key === "print_fl") {
          if (x.light_type === "FL" || x.light_type === "NL") {
            rate = Number(x.sqft * val);
          } else {
            rate = x.printing_cost;
          }
        } else if (key === "print_bl") {
          if (x.light_type === "BL") {
            rate = Number(x.sqft * val);
          } else {
            rate = x.printing_cost;
          }
        } else if (key === "print_all") {
          rate = Number(x.sqft * val);
        } else {
          rate = x.printing_cost;
        }
      } else {
        if (key === "print_fl") {
          if (x.light_type === "FL" || x.light_type === "NL") {
            rate = Number(x.quantity * val);
          } else {
            rate = x.printing_cost;
          }
        } else if (key === "print_bl") {
          if (x.light_type === "BL") {
            rate = Number(x.quantity * val);
          } else {
            rate = x.printing_cost;
          }
        } else if (key === "print_all") {
          rate = Number(x.quantity * val);
        } else {
          rate = x.printing_cost;
        }
      }
    } else {
      rate = x.printing_cost;
    }

    return rate;
  };

  getCalculatedMountRate = (x, key, val, values) => {
    let rate = "";

    if (x.media_type === values["media_type"]) {
      if (values["rate_type"] === "Rate/SQFT") {
        if (key === "mount_fl") {
          if (x.light_type === "FL" || x.light_type === "NL") {
            rate = Number(x.sqft * val);
          } else {
            rate = x.installing_cost;
          }
        } else if (key === "mount_bl") {
          if (x.light_type === "BL") {
            rate = Number(x.sqft * val);
          } else {
            rate = x.installing_cost;
          }
        } else if (key === "mount_all") {
          rate = Number(x.sqft * val);
        } else {
          rate = x.installing_cost;
        }
      } else {
        if (key === "mount_fl") {
          if (x.light_type === "FL" || x.light_type === "NL") {
            rate = Number(x.quantity * val);
          } else {
            rate = x.installing_cost;
          }
        } else if (key === "mount_bl") {
          if (x.light_type === "BL") {
            rate = Number(x.quantity * val);
          } else {
            rate = x.installing_cost;
          }
        } else if (key === "mount_all") {
          rate = Number(x.quantity * val);
        } else {
          rate = x.installing_cost;
        }
      }
    } else {
      rate = x.installing_cost;
    }

    return rate;
  };

  clearBlukEditValue = (selected) => {
    let clear = {
      print_fl: "",
      print_bl: "",
      print_all: "",
      mount_fl: "",
      mount_bl: "",
      mount_all: "",
    };

    if (selected === "print_all") {
      clear = {
        print_fl: "",
        print_bl: "",
      };
    }

    if (selected === "mount_all") {
      clear = {
        mount_fl: "",
        mount_bl: "",
      };
    }

    if (selected === "print_fl" || selected === "print_bl") {
      clear = {
        print_all: "",
      };
    }

    if (selected === "mount_fl" || selected === "mount_bl") {
      clear = {
        mount_all: "",
      };
    }

    this.formRef.current.setFieldsValue(clear);
  };

  // Get Multi Inventory Details when expanded
  getMultiDetails = (record) => {
    const inventories = record.ha_base_inventories;

    let columns = [
      {
        title: "Type",
        dataIndex: "inventory_type",
      },
      {
        title: "Qty",
        dataIndex: "quantity",
      },
      {
        title: "IID",
        dataIndex: "inventory_code",
      },
      {
        title: "Size",
        dataIndex: "size",
      },
      {
        title: "SQFT",
        dataIndex: "sqft",
      },
      {
        title: "Light",
        dataIndex: "light_type",
      },
      {
        title: "Photo",
        dataIndex: "images",
        render: (text, record) => (
          <Button
            className="ant-btn-reset"
            disabled={record?.ha_images?.length > 0 ? false : true}
            icon={<FileImageOutlined />}
            onClick={() => this.handlePhotos(record)}
          />
        ),
      },
      {
        title: "Booking Status",
        dataIndex: "booking_status",
        render: (text) => (text === "B" ? "Blocked" : "-"),
      },
    ];

    let data = inventories;

    return (
      <Table
        columns={columns}
        rowKey={(record) => record.ha_inventory_id}
        dataSource={data}
        pagination={false}
      />
    );
  };

  getResults = (campaign_id) => {
    var reqUrl =
      "campaign/mounting/list?campaign_id=" +
      Number(campaign_id) +
      "&pageno=1&pagesize=10000";
    var reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        let inventory_details = data?.response || [];

        inventory_details = inventory_details.filter((x) => x?.creative_name);

        this.setState({ inventory_details, loading: false });
      },
      "GET"
    );
  };

  componentDidMount() {
    let campaign_id = this?.props?.data[0]?.campaign_id;

    campaign_id
      ? this.getResults(campaign_id)
      : this.setState({ loading: false });

    this.getMediaTypeList();

    let columns = [
      {
        title: "IID",
        dataIndex: "inventory_code",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "City",
        dataIndex: "city",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Area",
        dataIndex: "area",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Location",
        dataIndex: "location",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Size",
        dataIndex: "size",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "SQFT",
        dataIndex: "sqft",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Light",
        dataIndex: "light_type",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Creative Name",
        dataIndex: "creative_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Printing",
        dataIndex: "printing_cost",
        render: (text, record) => (
          <PlanInventoryEdit
            key={text}
            value={text}
            record={record}
            column={"printing_cost"}
            handleInventoryEdit={this.handleInventoryEdit}
          />
        ),
      },
      {
        title: "Mounting",
        dataIndex: "installing_cost",
        render: (text, record) => (
          <PlanInventoryEdit
            key={text}
            value={text}
            record={record}
            column={"installing_cost"}
            handleInventoryEdit={this.handleInventoryEdit}
          />
        ),
      },
    ];

    this.setState({ columns });
  }

  render() {
    const { mediaTypeList, inventory_details, columns, loading } = this.state;

    const { Option } = Select;

    const layout = {
      wrapperCol: { span: 24 },
    };

    return (
      <Form
        name="plan_advanced"
        {...layout}
        ref={this.formRef}
        onFinish={this.onFinish}
        className="plan_advanced"
        onValuesChange={this.handleBulkEdit}
        initialValues={{ rate_type: "Rate/SQFT" }}
      >
        <Button
          type="primary"
          htmlType="submit"
          style={{
            float: "right",
            position: "absolute",
            top: "10px",
            right: "48px",
            marginBottom: "-48px",
          }}
          className="mobile-hide"
        >
          Apply Printing & Mounting Charges
        </Button>

        <React.Fragment>
          <Row
            gutter="16"
            style={{ marginBottom: "8px" }}
            className="mobile-hide"
          >
            <Col span="5">Media Type</Col>
            <Col span="3">Rate Type</Col>
            <Col span="8">Printing</Col>
            <Col span="8">Installation</Col>
          </Row>

          <Row gutter="16">
            <Col span="5" xs={24} xl={5}>
              <Form.Item name="media_type">
                <Select placeholder="Select Media Type">
                  {mediaTypeList &&
                    mediaTypeList.map((x) => (
                      <Option key={x.media_type} value={x.media_type}>
                        {x.media_type}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span="3" xs={24} xl={3}>
              <Form.Item name="rate_type">
                <Select placeholder="Rate Type">
                  <Option key="Rate/SQFT" value="Rate/SQFT">
                    Rate/SQFT
                  </Option>
                  <Option key="Rate/Unit" value="Rate/Unit">
                    Rate/Unit
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span="8" xs={24} xl={8}>
              <Row gutter="16">
                <Col span={"8"}>
                  <Form.Item name="print_fl">
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      placeholder="FL"
                    />
                  </Form.Item>
                </Col>
                <Col span={"8"}>
                  <Form.Item name="print_bl">
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      placeholder="BL"
                    />
                  </Form.Item>
                </Col>
                <Col span={"8"}>
                  <Form.Item name="print_all">
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      placeholder="All"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span="8" xs={24} xl={8}>
              <Row gutter="16">
                <Col span={"8"}>
                  <Form.Item name="mount_fl">
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      placeholder="FL"
                    />
                  </Form.Item>
                </Col>
                <Col span={"8"}>
                  <Form.Item name="mount_bl">
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      placeholder="BL"
                    />
                  </Form.Item>
                </Col>
                <Col span={"8"}>
                  <Form.Item name="mount_all">
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      placeholder="All"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Table
            columns={columns}
            rowKey={(record) => record.operation_id}
            dataSource={inventory_details}
            onChange={this.props.handleTableChange}
            bordered
            scroll={{ x: 400 }}
            size="small"
            pagination={false}
            loading={loading}
          />
        </React.Fragment>

        <Button type="primary" htmlType="submit" style={{ marginTop: "16px" }}>
          Apply Printing & Mounting Charges
        </Button>
      </Form>
    );
  }
}

export default AdditionalPrintingMounting;
