import { Component } from "react";
import { ajaxRequester } from "../services/ajax-requester";
import { message } from "antd";

class CustomersBase extends Component {
  state = {};

  // Add Customer
  handleAddCustomers = (data) => {
    let reqUrl = "customer/create";
    let reqObj = {
      request: {
        customer_name: data.customer_name,
        image_name: data.image_name || "logo.png",
        companies: this.getCompanyDetails(data),
        point_of_contact: this.getPointOfContact(data),
        assigned_employee: this.getAssignedEmployee(data),
        email_notifications: this.getEmailNotifications(data),
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data?.customer_id) {
        this.setState({
          isSuccess: true,
          customerKey: this.state.customerKey + 1,
        });
        this.handleUploadLogo(data);
      } else {
        this.errorCustomerAdd(data);
      }
    });
  };

  // Show Erroes if Customer Added
  errorCustomerAdd = (data) => {
    let msg = data.detail;
    message.error(msg);
    this.setState({ isError: true, customerKey: this.state.customerKey + 1 });
  };

  // Update Customer
  handleUpdateCustomers = (data) => {
    let reqUrl = "customer/update";
    let reqObj = {
      request: {
        customer_id: Number(this.props.match.params.id),
        customer_name: data.customer_name,
        image_name: data.image_name || "logo.png",
        created_by: "admin",
        companies: this.getCompanyDetails(data),
        point_of_contact: this.getPointOfContact(data),
        assigned_employee: this.getAssignedEmployee(data),
        email_notifications: this.getEmailNotifications(data),
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data.success) {
        this.setState({
          isSuccess: true,
          customerKey: this.state.customerKey + 1,
        });
      } else {
        this.errorCustomerAdd(data);
      }
    });
  };

  // Get Company Details Request
  getCompanyDetails = (data) => {
    let companies = data.companies
      ?.filter((x) => x.company_name)
      .map((x) => {
        return {
          company_name: x.company_name,
          address: x.address,
          office_phone: x.office_phone,
          office_email: x.office_email,
          segment_id: x.segment_id,
          credit_days: Number(x.credit_days),
          pan: x.pan,
          gst_detail: this.getGstDetails(x) || [],
          bank_acct_no: x.bank_acct_no,
          bank_ifsc_code: x.bank_ifsc_code,
          company_id: x.company_id,
          customer_id: x.customer_id,
          isMain: true,
        };
      });

    return companies;
  };

  // Get GST Details Request
  getGstDetails = (data) => {
    let gst_detail = data.gst_detail
      ?.filter((x) => x.gstin)
      .map((x) => {
        return {
          gstin: x.gstin,
          addresses: x.addresses
            ?.filter((x) => x.address)
            .map((y) => {
              return { address: y.address };
            }),
        };
      });

    return gst_detail;
  };

  // Get Point Of Contact Request
  getPointOfContact = (data) => {
    let point_of_contact = data.point_of_contact
      ?.filter((x) => x.contact_name)
      .map((x) => {
        return {
          contact_name: x.contact_name,
          phone_no: x.phone_no,
          email: x.email,
          designation_id: x.designation_id,
          customer_id: x.customer_id,
          customer_contact_id: x.customer_contact_id,
        };
      });

    return point_of_contact;
  };

  // Get Assigned Employee Request
  getAssignedEmployee = (data) => {
    let assigned_employee = data.assigned_employee
      ?.filter((x) => x.employee_id)
      .map((x) => {
        return {
          employee_id: x.employee_id,
          assigned_employee_id: x.assigned_employee_id,
        };
      });

    return assigned_employee;
  };

  // Get Notification Request
  getEmailNotifications = (data) => {
    let email_notifications = [
      {
        id: 1,
        code: "BER",
        value: data.email_notifications[0]["BERInput"],
        selected: data.email_notifications[0]["BER"],
      },
      {
        id: 2,
        code: "MMUP",
        value: data.email_notifications[0]["MMUPInput"],
        selected: data.email_notifications[0]["MMUP"],
      },
      {
        id: 3,
        code: "CER",
        value: data.email_notifications[0]["CERInput"],
        selected: data.email_notifications[0]["CER"],
      },
      {
        id: 4,
        code: "SIC",
        value: "",
        selected: data.email_notifications[0]["SIC"],
      },
      {
        id: 5,
        code: "SCNC",
        value: "",
        selected: data.email_notifications[0]["SCNC"],
      },
      {
        id: 6,
        code: "SOR",
        value: data.email_notifications[0]["SORInput"],
        selected: data.email_notifications[0]["SOR"],
      },
      {
        id: 7,
        code: "SPR",
        value: "",
        selected: data.email_notifications[0]["SPR"],
      },
      {
        id: 8,
        code: "GACR",
        value: data.email_notifications[0]["GACRInput"],
        selected: data.email_notifications[0]["GACR"],
      },
    ];

    return email_notifications;
  };

  // Get Sagment List from Lookup
  getSegmentList = () => {
    let reqUrl = "lookup/segment";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ segmentList: data.response });
      },
      "GET"
    );
  };

  // Get Designation List from Lookup
  getDesignationList = () => {
    let reqUrl = "lookup/designation";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ designationList: data.response });
      },
      "GET"
    );
  };

  // Get Notification List from Lookup
  getNotificationList = () => {
    let reqUrl = "lookup/emailnotifications";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ emailnotificationsList: data.response });
      },
      "GET"
    );
  };

  // Get All Lookup Data
  getLookupData = () => {
    this.getSegmentList();
    this.getDesignationList();
    this.getNotificationList();
  };

  // Redirect to Customer List
  handleBack = () => {
    this.props.history.push(`/customers/customers`);
  };

  // Set Logo Request in State in case of New Record
  handleUploadLogoReq = (logoReq) => {
    this.setState({ logoReq });
  };

  handleUploadLogo = (ref_id) => {
    let reqUrl = "customer/photo/upload";
    let uploadReq = this.state?.logoReq;

    if (uploadReq) {
      uploadReq.reference_id = ref_id?.customer_id || "";
    }

    let reqObj = {
      request: uploadReq,
    };

    if (uploadReq) {
      ajaxRequester(reqUrl, reqObj, (data) => {
        if (!data?.success) {
          message.error(`Logo file upload failed.`);
        }
      });
    }
  };
}

export default CustomersBase;
