import {useState, useEffect} from "react";
import LoginForm from "./login-form";
import AdminApi from "../api/admin-api";
import {Spin} from "antd";
import { AuthListner } from "../api/auth-listener";

export const LoginAuthorization = (props) => {

    const [loginStatus, setLoginStatus] = useState(-1);

    const reloadStatus = async () => {
        if (await AdminApi.isLoggedIn()) {
            setLoginStatus(1);
        } else {
            setLoginStatus(0);
        }
    }

    useEffect(()=> {
        if (loginStatus === 1) {
            AdminApi.getAuthToken().then((token) => {
                AuthListner.connect(AdminApi.url, token);
            });
        } else if (loginStatus === 0) {
            AuthListner.disconnect();
        }
    }, [loginStatus])

    useEffect( ()=> {
        reloadStatus().then();
    }, [])

    return (
        <>
            {(loginStatus === 0 || loginStatus === -1) && <LoginForm isLoading={loginStatus === -1} onLoginSucces={reloadStatus} />}
            {loginStatus === 1 && props.children}
        </>
    )
};

export const RoleAuthorization = (props) => {

    const [user, setUser] = useState(AdminApi.getCurrentUser());

    return (
        <>
            {user.Roles?.some(x => props.roles.includes(x)) && props.children}
        </>
    )
}
