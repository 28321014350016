import React, { Component } from "react";
import { Form, Input, Button, DatePicker } from "antd";
import * as dayjs from "dayjs";

class InvoiceAddNumberDate extends Component {
  state = {};

  onFinish = (values) => {
    this.props.handleAddNumberDate(values);
  };

  disabledDate = (current) => {
    // let invoice_date = dayjs();
    // return current && current < dayjs(invoice_date, "YYYY-MM-DD");
  };

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    const { action } = this.props;

    return (
      <Form
        name="invoice_add_date_number"
        {...layout}
        onFinish={this.onFinish}
        className="invoice_add_date_number"
      >
        {action === "Add Invoice Number" && (
          <Form.Item
            name="invoice_no"
            label="Invoice Number"
            rules={[
              { required: true, message: "Please Enter Invoice Number !" },
            ]}
          >
            <Input placeholder="Invoice Number" />
          </Form.Item>
        )}

        {action === "Set Date" && (
          <Form.Item
            name="invoice_date"
            label="Invoices Date"
            rules={[
              { required: true, message: "Please Enter Invoices Date !" },
            ]}
          >
            <DatePicker disabledDate={this.disabledDate} inputReadOnly={true} />
          </Form.Item>
        )}

        <Form.Item wrapperCol={{ offset: 8 }}>
          <Button type="primary" htmlType="submit">
            {action}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default InvoiceAddNumberDate;
