import React, { Component } from "react";
import { Table } from "antd";
import {
  dateFormatter,
  currencyFormatter,
} from "../../components/common/common-formatter";

class InvoiceFormat extends Component {
  state = {
    gridData: [],
    loading: false,
    columns: [],
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "Description",
        dataIndex: "description",
        render: (text, record) =>
          text
            ? text
            : record.inventory_code +
              " - " +
              record.city +
              ", " +
              record.area +
              ", " +
              record.location +
              " - " +
              record.size +
              " " +
              (record.light_type ? record.light_type : "") +
              (record?.notes ? " - " + "(Notes: " + record?.notes + ")" : "") +
              (record?.quantity && record?.quantity > 1
                ? " (Qty : " +
                  record?.quantity +
                  " / Display : " +
                  (record?.side_type === "double"
                    ? record?.quantity * 2
                    : record?.quantity) +
                  ")"
                : ""),
      },
      {
        title: "Start Date",
        dataIndex: "start_date",
        render: (text) => (text ? dateFormatter(text) : ""),
        width: 124,
      },
      {
        title: "End Date",
        dataIndex: "end_date",
        render: (text) => (text ? dateFormatter(text) : ""),
        width: 124,
      },
      {
        title: "Days",
        dataIndex: "no_of_days",
        width: 92,
      },
      {
        title: "Monthly Rate",
        dataIndex: "monthly_rate",
        render: (text) => (text ? currencyFormatter(parseInt(text)) : ""),
        width: 168,
        align: "right",
      },
      {
        title: "Cost",
        dataIndex: "rental_cost",
        align: "right",
        width: 168,
        render: (text, record) =>
          text
            ? currencyFormatter(parseInt(text))
            : record?.manual_cost
            ? currencyFormatter(parseInt(record?.manual_cost))
            : "-",
      },
    ];

    let invoiceFormatType = "";
    switch (this.props.invoice_format_id) {
      case 1:
        invoiceFormatType = "Full Detail";
        break;

      case 2:
        columns.splice(1, 4);
        invoiceFormatType = "Less Detail";
        break;

      case 3:
        columns.splice(1, 4);
        invoiceFormatType = "Package Rate";
        break;

      case 4:
        columns.splice(1, 4);
        invoiceFormatType = "As Per Attachment";
        break;

      case 5:
        columns.splice(1, 4);
        invoiceFormatType = "As Per Attachment with separate Printing Mounting";
        break;

      default:
        invoiceFormatType = "Full Detail";
        break;
    }

    this.setState({ columns, invoiceFormatType });
  };

  // Setting Table Data
  setData = () => {
    const {
      rental_inventory_details,
      printing_inventory_details,
      installing_inventory_details,
      total_display_cost,
      total_printing_cost,
      total_installing_cost,
      invoice_amount,
      total_amount,
      invoice_type,
      manual_inventory_details,
    } = this.props;

    let gridData = rental_inventory_details.filter((x) => !x.isRemove);

    let total_printing_sqft = 0;
    let total_installing_sqft = 0;

    printing_inventory_details.map(
      (x) => (total_printing_sqft = total_printing_sqft + x?.sqft)
    );

    installing_inventory_details.map(
      (x) => (total_installing_sqft = total_installing_sqft + x?.sqft)
    );

    // Calculate SQFT wise Breakup
    let printing_inventory_cost = [];
    printing_inventory_details.reduce((res, value) => {
      if (!res[value.printing_cost / value.sqft]) {
        res[value.printing_cost / value.sqft] = {
          printing_cost: value.printing_cost / value.sqft,
          sqft: 0,
        };
        printing_inventory_cost.push(res[value.printing_cost / value.sqft]);
      }
      res[value.printing_cost / value.sqft].sqft += value.sqft;
      return res;
    }, {});

    let installing_inventory_cost = [];
    installing_inventory_details.reduce((res, value) => {
      if (!res[value.installing_cost / value.sqft]) {
        res[value.installing_cost / value.sqft] = {
          installing_cost: value.installing_cost / value.sqft,
          sqft: 0,
        };
        installing_inventory_cost.push(res[value.installing_cost / value.sqft]);
      }
      res[value.installing_cost / value.sqft].sqft += value.sqft;
      return res;
    }, {});

    if (total_printing_cost) {
      gridData.push({
        ha_inventory_id: "total_printing_cost",

        description:
          printing_inventory_cost &&
          printing_inventory_cost.map((x) => (
            <div>
              {"Printing " +
                x.sqft +
                " SQFT @ " +
                currencyFormatter(parseInt(x.printing_cost)) +
                "RS"}
            </div>
          )),
        rental_cost: total_printing_cost,
        isRemove: true,
      });
    }

    if (total_installing_cost) {
      gridData.push({
        ha_inventory_id: "total_installing_cost",
        description:
          installing_inventory_cost &&
          installing_inventory_cost.map((x) => (
            <div>
              {"Mounting " +
                x.sqft +
                " SQFT @ " +
                currencyFormatter(parseInt(x.installing_cost)) +
                "RS"}
            </div>
          )),
        rental_cost: total_installing_cost,
        isRemove: true,
      });
    }

    if (invoice_type === "M") {
      gridData = manual_inventory_details;
    }

    if (this.props.invoice_format_id === 3) {
      gridData = gridData.map((x, key) => ({
        ...x,
        rental_cost:
          key === 0 && !x.isRemove
            ? total_display_cost
            : x.ha_inventory_id !== "total_printing_cost" &&
              x.ha_inventory_id !== "total_installing_cost"
            ? 0
            : x.rental_cost,
      }));
    }

    if (this.props.invoice_format_id === 4) {
      gridData = [
        {
          ha_inventory_id: "as_per_Attachment",
          description: "As Per Attachment",
          rental_cost: invoice_amount,
        },
      ];
    }

    if (this.props.invoice_format_id === 5) {
      gridData = [
        {
          ha_inventory_id: "display_charges_as_per_attachment",
          description: "Display Charges as Per Attachment",
          rental_cost: total_display_cost,
        },
        {
          ha_inventory_id: "printing_as_per_attachment",
          description: "Printing as Per Attachment",
          rental_cost: total_printing_cost,
        },
        {
          ha_inventory_id: "mounting_as_per_attachment",
          description: "Mounting as Per Attachment",
          rental_cost: total_installing_cost,
        },
      ];
    }

    this.setState({ gridData });
  };

  componentDidMount() {
    this.setData();
    this.setColumns();
  }

  render() {
    const { gridData, columns, loading, invoiceFormatType } = this.state;

    return (
      <React.Fragment>
        <Table
          columns={columns}
          rowKey={(record) => record.ha_inventory_id}
          dataSource={gridData}
          pagination={false}
          loading={loading}
          bordered
          scroll={{ x: 400 }}
          size="small"
          title={() => <h3>{invoiceFormatType || ""}</h3>}
        />
      </React.Fragment>
    );
  }
}

export default InvoiceFormat;
