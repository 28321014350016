import React, { Component } from "react";
import { Row, Col, Form, Button, InputNumber, Select, Tabs } from "antd";
import { ajaxRequester } from "../../services/ajax-requester";

class PlanAdvanced extends Component {
  state = {
    activeTab: "discounting",
    mediaTypeList: "",
  };

  // Get Media Type List from Lookup
  getMediaTypeList = () => {
    let reqUrl = "lookup/mediatype";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ mediaTypeList: data.response });
      },
      "GET"
    );
  };

  tabChange = (key) => {
    this.setState({ activeTab: key });
  };

  onFinish = (values) => {
    let plan_inventory_details = this.props.plan_inventory_details_default;
    let displayValues = this.props.displayValues;
    let currentValues = this.props.currentValues;

    let print_fl_rate = 0;
    let print_bl_rate = 0;
    let install_fl_rate = 0;
    let install_bl_rate = 0;

    if (values.n4_1) {
      if (values.n4_2) {
        plan_inventory_details.map(
          (x) =>
            x.media_type_id === values.n4_1 &&
            x.light_type === "FL" &&
            (print_fl_rate = print_fl_rate + x.sqft * values.n4_2)
        );
      }

      if (values.n4_3) {
        plan_inventory_details.map(
          (x) =>
            x.media_type_id === values.n4_1 &&
            x.light_type === "BL" &&
            (print_bl_rate = print_bl_rate + x.sqft * values.n4_3)
        );
      }

      if (values.n4_4) {
        plan_inventory_details.map(
          (x) =>
            x.media_type_id === values.n4_1 &&
            x.light_type === "FL" &&
            (install_fl_rate = install_fl_rate + x.sqft * values.n4_4)
        );
      }

      if (values.n4_5) {
        plan_inventory_details.map(
          (x) =>
            x.media_type_id === values.n4_1 &&
            x.light_type === "BL" &&
            (install_bl_rate = install_bl_rate + x.sqft * values.n4_5)
        );
      }
    }

    displayValues.total_printing_cost = print_fl_rate + print_bl_rate;
    displayValues.total_installing_cost = install_fl_rate + install_bl_rate;
    currentValues.print_fl_rate = values.n4_2 ? values.n4_2 : 0;
    currentValues.print_bl_rate = values.n4_3 ? values.n4_3 : 0;
    currentValues.install_fl_rate = values.n4_4 ? values.n4_4 : 0;
    currentValues.install_bl_rate = values.n4_5 ? values.n4_5 : 0;

    this.props.handleAdvancedPrinting(displayValues, currentValues);
  };

  componentDidMount() {
    this.getMediaTypeList();
  }

  render() {
    const { activeTab, mediaTypeList } = this.state;

    const { Option } = Select;

    const { TabPane } = Tabs;

    const layout = {
      wrapperCol: { span: 24 },
    };

    return (
      <Form
        name="plan_advanced"
        {...layout}
        ref={this.formRef}
        onFinish={this.onFinish}
        className="plan_advanced"
        // onValuesChange={this.onFinish}
      >
        <Tabs defaultActiveKey={activeTab} onChange={this.tabChange}>
          <TabPane tab="Discounting on Rent" key="discounting"></TabPane>
          <TabPane
            tab="Printing & Installation Charges"
            key="printing"
          ></TabPane>
        </Tabs>

        {activeTab === "discounting" && (
          <React.Fragment>
            <Row gutter="16" style={{ marginBottom: "8px" }}>
              <Col span="15"></Col>
              <Col span="5">%</Col>
              <Col span="4">SQFT</Col>
            </Row>

            <Row gutter="16">
              <Col span="20">
                <Row gutter="16">
                  <Col span="9">
                    <Form.Item name="d1-1">
                      <Select placeholder="State">
                        <Option value={""}>State</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span="9">
                    <Form.Item name="d1-2">
                      <Select placeholder="Gujarat">
                        <Option value={"Gujarat"}>Gujarat</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span="6">
                    <Form.Item name="d1-3">
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        placeholder=""
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span="4">
                <Form.Item name="d1-4">
                  <InputNumber
                    style={{ width: "100%" }}
                    min={0}
                    placeholder=""
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter="16">
              <Col span="20">
                <Row gutter="16">
                  <Col span="9">
                    <Form.Item name="d2-1">
                      <Select placeholder="District">
                        <Option value={""}>District</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span="9">
                    <Form.Item name="d2-2">
                      <Select placeholder="Ahmedabad">
                        <Option value={""}>Ahmedabad</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span="6">
                    <Form.Item name="d2-3">
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        placeholder=""
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span="4">
                <Form.Item name="d2-4">
                  <InputNumber
                    style={{ width: "100%" }}
                    min={0}
                    placeholder=""
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter="16">
              <Col span="20">
                <Row gutter="16">
                  <Col span="9">
                    <Form.Item name="d3-1">
                      <Select placeholder="City">
                        <Option value={""}>City</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span="9">
                    <Form.Item name="d3-2">
                      <Select placeholder="Bareja">
                        <Option value={""}>Bareja</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span="6">
                    <Form.Item name="d3-3">
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        placeholder=""
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span="4">
                <Form.Item name="d3-4">
                  <InputNumber
                    style={{ width: "100%" }}
                    min={0}
                    placeholder=""
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter="16">
              <Col span="20">
                <Row gutter="16">
                  <Col span="9">
                    <Form.Item name="d4-1">
                      <Select placeholder="Media Type">
                        {mediaTypeList &&
                          mediaTypeList.map((x) => (
                            <Option
                              key={x.media_type_id}
                              value={x.media_type_id}
                            >
                              {x.media_type}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span="9">
                    <Form.Item name="d4-2">
                      <Select placeholder="Hoardings">
                        <Option value={""}>Hoardings</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span="6">
                    <Form.Item name="d4-3">
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        placeholder=""
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span="4">
                <Form.Item name="d4-4">
                  <InputNumber
                    style={{ width: "100%" }}
                    min={0}
                    placeholder=""
                  />
                </Form.Item>
              </Col>
            </Row>
          </React.Fragment>
        )}

        {activeTab === "printing" && (
          <React.Fragment>
            <Row gutter="16" style={{ marginBottom: "8px" }}>
              <Col span="8"></Col>
              <Col span="8">Printing</Col>
              <Col span="8">Installation</Col>
            </Row>

            <Row gutter="16">
              <Col span="16">
                <Row gutter="16">
                  <Col span="12">
                    <Form.Item name="n1_1">
                      <Select placeholder="State">
                        <Option value={""}>State</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span="6">
                    <Form.Item name="n1_2">
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        placeholder="FL"
                      />
                    </Form.Item>
                  </Col>

                  <Col span="6">
                    <Form.Item name="n1_3">
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        placeholder="BL"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span="8">
                <Row gutter="16">
                  <Col span="12">
                    <Form.Item name="n1_4">
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        placeholder="FL"
                      />
                    </Form.Item>
                  </Col>

                  <Col span="12">
                    <Form.Item name="n1_5">
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        placeholder="BL"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter="16">
              <Col span="16">
                <Row gutter="16">
                  <Col span="12">
                    <Form.Item name="n2_1">
                      <Select placeholder="District">
                        <Option value={""}>District</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span="6">
                    <Form.Item name="n2_2">
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        placeholder="FL"
                      />
                    </Form.Item>
                  </Col>

                  <Col span="6">
                    <Form.Item name="n2_3">
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        placeholder="BL"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span="8">
                <Row gutter="16">
                  <Col span="12">
                    <Form.Item name="n2_4">
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        placeholder="FL"
                      />
                    </Form.Item>
                  </Col>

                  <Col span="12">
                    <Form.Item name="n2_5">
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        placeholder="BL"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter="16">
              <Col span="16">
                <Row gutter="16">
                  <Col span="12">
                    <Form.Item name="n3_1">
                      <Select placeholder="City">
                        <Option value={""}>City</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span="6">
                    <Form.Item name="n3_2">
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        placeholder="FL"
                      />
                    </Form.Item>
                  </Col>

                  <Col span="6">
                    <Form.Item name="n3_3">
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        placeholder="BL"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span="8">
                <Row gutter="16">
                  <Col span="12">
                    <Form.Item name="n3_4">
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        placeholder="FL"
                      />
                    </Form.Item>
                  </Col>

                  <Col span="12">
                    <Form.Item name="n3_5">
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        placeholder="BL"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter="16">
              <Col span="16">
                <Row gutter="16">
                  <Col span="12">
                    <Form.Item name="n4_1">
                      <Select placeholder="Media Type">
                        {mediaTypeList &&
                          mediaTypeList.map((x) => (
                            <Option
                              key={x.media_type_id}
                              value={x.media_type_id}
                            >
                              {x.media_type}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span="6">
                    <Form.Item name="n4_2">
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        placeholder="FL"
                      />
                    </Form.Item>
                  </Col>

                  <Col span="6">
                    <Form.Item name="n4_3">
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        placeholder="BL"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span="8">
                <Row gutter="16">
                  <Col span="12">
                    <Form.Item name="n4_4">
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        placeholder="FL"
                      />
                    </Form.Item>
                  </Col>

                  <Col span="12">
                    <Form.Item name="n4_5">
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        placeholder="BL"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </React.Fragment>
        )}

        <Button type="primary" htmlType="submit">
          {activeTab === "discounting" && "Apply Discounting on Rent"}
          {activeTab === "printing" && "Apply Printing & Installation Charges"}
        </Button>
      </Form>
    );
  }
}

export default PlanAdvanced;
