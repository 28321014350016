import React from "react";
import CommonListBase from "../../base/common-list-base";
import ListFilters from "../../components/common/list-filters";
import PageTitle from "../../components/common/page-title";
import { Table, Button, Drawer, Radio, message } from "antd";
import { PlusOutlined, EditOutlined, ImportOutlined } from "@ant-design/icons";
import { ajaxRequester } from "../../services/ajax-requester";
import MasterAdd from "../../components/masters/master-add";

class Masters extends CommonListBase {
  state = {
    listReq: "state/list?",
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    sorting: [],
    screen: "State-Master",
    isDrawer: false,
    options: [],
    activeTab: "State",
    stateList: [],
    districtList: [],
    cityList: [],
    record: "",
    isEditMode: false,
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "State",
        dataIndex: "state_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Status",
        dataIndex: "is_active",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) =>
          text ? (
            <span>Active</span>
          ) : (
            <span style={{ opacity: ".5" }}>Inactive</span>
          ),
        width: "8%",
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "x",
        render: (record) => (
          <Button
            onClick={() => this.handleEdit(record)}
            icon={<EditOutlined />}
            className="ant-btn-reset"
          />
        ),
        width: "8%",
      },
    ];

    let filterColumns = [
      {
        columnName: "state_name",
        displayName: "State",
        default: this.state.activeTab === "State" ? true : false,
      },
      {
        columnName: "is_active",
        displayName: "Status",
      },
    ];

    if (this.state.activeTab === "District") {
      columns.splice(1, 0, {
        title: "District",
        dataIndex: "district_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      });

      filterColumns.splice(1, 0, {
        columnName: "district_name",
        displayName: "District",
        default: true,
      });
    }

    if (this.state.activeTab === "City") {
      columns.splice(1, 0, {
        title: "District",
        dataIndex: "district_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      });
      columns.splice(2, 0, {
        title: "City",
        dataIndex: "city_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      });

      filterColumns.splice(1, 0, {
        columnName: "district_name",
        displayName: "District",
      });

      filterColumns.splice(2, 0, {
        columnName: "city_name",
        displayName: "City",
        default: true,
      });
    }

    if (this.state.activeTab === "Area") {
      columns.splice(1, 0, {
        title: "District",
        dataIndex: "district_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      });
      columns.splice(2, 0, {
        title: "City",
        dataIndex: "city_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      });
      columns.splice(3, 0, {
        title: "Area",
        dataIndex: "area_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      });

      filterColumns.splice(1, 0, {
        columnName: "district_name",
        displayName: "District",
      });

      filterColumns.splice(2, 0, {
        columnName: "city_name",
        displayName: "City",
      });

      filterColumns.splice(3, 0, {
        columnName: "area_name",
        displayName: "Area",
        default: true,
      });
    }

    if (this.state.activeTab === "Supplier") {
      columns.splice(0, 1, {
        title: "Supplier",
        dataIndex: "supplier_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      });

      filterColumns.splice(0, 2, {
        columnName: "supplier_name",
        displayName: "Supplier",
        default: true,
      });

      filterColumns.push({
        columnName: "is_active",
        displayName: "Status",
      });
    }

    this.setState({ columnsDefault: columns, filterColumns }, () =>
      this.setDefaultColumns(columns)
    );
  };

  handleAddEdit = (data) => {
    const { activeTab, isEditMode } = this.state;

    let reqUrl =
      activeTab.toLocaleLowerCase() + (isEditMode ? "/update" : "/add");
    let reqObj = {
      request: {
        ...data,
        state_name:
          data?.state_name && data?.state_name?.replace(/\s+/g, " ").trim(),
        district_name:
          data?.district_name &&
          data?.district_name?.replace(/\s+/g, " ").trim(),
        city_name:
          data?.city_name && data?.city_name?.replace(/\s+/g, " ").trim(),
        area_name:
          data?.area_name && data?.area_name?.replace(/\s+/g, " ").trim(),
        supplier_name:
          data?.supplier_name &&
          data?.supplier_name?.replace(/\s+/g, " ").trim(),
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data.success) {
        message.success(
          activeTab +
            " has been " +
            (isEditMode ? "updated" : "added") +
            " successfully"
        );
        this.handleDrawer();
        const { pagination } = this.state;
        this.getResults({ pagination });
      } else {
        message.error(
          data?.detail || "An error occured while processing your request."
        );
      }
    });
  };

  handleAdd = () => {
    this.setState({ record: "", isEditMode: false }, () => this.handleDrawer());
  };

  handleEdit = (record) => {
    this.setState({ record, isEditMode: true }, () => this.handleDrawer());
  };

  // Handle Drawer
  handleDrawer = () => {
    this.setState({ isDrawer: !this.state.isDrawer });
  };

  // Set Tabs
  setTabs = () => {
    const options = ["State", "District", "City", "Area", "Supplier"];
    this.setState({ options });
  };

  // Tab Change
  onTabChange = (e) => {
    let activeTab = e.target.value;
    let listReq = activeTab.toLocaleLowerCase() + "/list?";
    let pagination = {
      current: 1,
      pageSize: 20,
    };

    let screen = activeTab + "-Master";
    this.setState({ activeTab, screen }, () => this.setColumns());
    this.setState({ listReq, data: [] }, () => this.getResults({ pagination }));
  };

  handleImport = () => {
    this.props.history.push("/masters-import");
  };

  componentDidMount() {
    // Get List
    const { pagination } = this.state;
    this.getResults({ pagination });
    this.setColumns();
    this.setTabs();
  }
  render() {
    const {
      data,
      columns,
      pagination,
      loading,
      isDrawer,
      options,
      activeTab,
      isEditMode,
      filterColumns,
      screen,
    } = this.state;

    return (
      <React.Fragment>
        <PageTitle titleText={"Masters - " + activeTab} />

        <div className="page-title-custom-btn master-import-btns">
          <Button
            type="primary"
            onClick={this.handleImport}
            style={{ marginRight: "16px" }}
            icon={<ImportOutlined />}
            className="icon-btn"
          >
            Import Masters
          </Button>

          <Button
            type="primary"
            onClick={this.handleAdd}
            icon={<PlusOutlined />}
            className="icon-btn"
          >
            {"Add " + activeTab}
          </Button>
        </div>

        <Radio.Group
          options={options}
          onChange={this.onTabChange}
          value={activeTab}
          optionType="button"
          buttonStyle="solid"
          loading={true}
          style={{ marginBottom: "16px" }}
        />

        <ListFilters
          items={filterColumns}
          handleSettings={this.handleSettings}
          handleFilters={this.handleFilters}
          key={filterColumns}
          screen={screen}
        />

        <Table
          columns={columns}
          rowKey={(record) =>
            activeTab === "State"
              ? record.state_id
              : activeTab === "District"
              ? record.district_id
              : activeTab === "City"
              ? record.city_id
              : record.area_id
          }
          dataSource={data}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
          bordered
          scroll={{ x: 400 }}
          size="small"
          key={activeTab}
        />

        <Drawer
          title={(isEditMode ? "Update " : "Add ") + activeTab}
          placement="right"
          open={isDrawer}
          onClose={this.handleDrawer}
          width={"478px"}
          key={isDrawer}
        >
          <MasterAdd
            key={isDrawer}
            handleAddEdit={this.handleAddEdit}
            {...this.state}
          />
        </Drawer>
      </React.Fragment>
    );
  }
}

export default Masters;
