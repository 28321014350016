import React, { Component } from "react";
import { Table, Button } from "antd";
import { ajaxRequester } from "../../services/ajax-requester";
import ListFilters from "../common/list-filters";
import AdminApi from "../../iam/api/admin-api";

class AssignTerritory extends Component {
  state = {
    data: [],
    data_default: [],
    pagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    sorting: [],
    record: "",
    selectedRowKeys: [],
    selectedRows: [],
    existingTerritory: [],
    screen: "assign-territory",
  };

  getResults = (params = {}) => {
    this.setState({ loading: true });
    let reqUrl = "lookup/territory?";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          loading: false,
          data: data.response,
          data_default: data.response,
          pagination: {
            ...params.pagination,
          },
          navKey: this.state.navKey + 1,
          filters: params.filters ? params.filters : this.state.filters,
          sortField: params.sortField ? params.sortField : this.state.sortField,
          sortOrder: params.sortOrder ? params.sortOrder : this.state.sortOrder,
        });
      },
      "GET"
    );
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "State",
        dataIndex: "state_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "District",
        dataIndex: "district_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "City",
        dataIndex: "city_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Area",
        dataIndex: "area_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
    ];

    const filterColumns = [
      { columnName: "state_name", displayName: "State", default: true },
      { columnName: "district_name", displayName: "District" },
      { columnName: "city_name", displayName: "City" },
      { columnName: "area_name", displayName: "Area" },
    ];

    this.setState({ columnsDefault: columns, filterColumns }, () =>
      this.setDefaultColumns(columns)
    );
  };

  // Change Pagging Sorting and Filters
  handleTableChange = (pagination, filters, sorter) => {
    let data = [...this.state.data];
    data.sort((a, b) =>
      sorter.order === "ascend"
        ? typeof a[sorter.field] !== "number"
          ? a[sorter.field]?.localeCompare(b[sorter.field]?.toLowerCase())
          : a[sorter.field] - b[sorter.field]
        : typeof b[sorter.field] !== "number"
        ? b[sorter.field]?.localeCompare(a[sorter.field]?.toLowerCase())
        : b[sorter.field] - a[sorter.field]
    );
    this.setState({ data });
  };

  // Handle Global Filters
  handleFilters = (filterColumn, filterValue) => {
    let data = [...this.state.data_default];

    data = filterValue
      ? data.filter((x) =>
          x[filterColumn.columnName]
            .toLowerCase()
            .includes(filterValue.toLowerCase())
        )
      : data;
    this.setState({ data });
  };

  // Handle Setting show hide columns
  handleSettings = (values) => {
    let columnsDefault = this.state.columnsDefault;
    let columns = this.state.columns;
    columns = columnsDefault.filter(({ title }) =>
      values.some((exclude) => exclude.title === title)
    );
    this.setState({ columns });
  };

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys, selectedRows });
  };

  setDisabled = (record) => {
    let branch_territories = [];
    this.props?.branch_territories &&
      this.props.branch_territories.length > 0 &&
      this.props.branch_territories.map((x) =>
        branch_territories.push(x.area_id)
      );
    return branch_territories.includes(record.area_id) && "disabled-row";
  };

  handleAssignTerritory = () => {
    let selectedRows = this.state.selectedRows;
    this.props.handleAssignTerritory(selectedRows);
  };

  // Set Default Columns
  setDefaultColumns = (columns) => {
    AdminApi.syncColumsSettings()
      .then((columnsSettings) => {
        this.setState({ columnsSettings: columnsSettings || {} }, () =>
          this.applyDefaultColums(columns)
        );
      })
      .catch((nothing) => {});
  };

  // Apply Default Columns
  applyDefaultColums = (columns) => {
    let { columnsSettings, columnsDefault, screen } = this.state;
    if (screen && columnsSettings) {
      let colConfig = columnsSettings[screen];

      if (colConfig && colConfig.length > 1) {
        columns = columnsDefault.filter(({ title }) =>
          colConfig.some((exclude) => exclude.title === title)
        );
      }
      this.setState({ columns });
    }
  };

  componentDidMount() {
    // Get List
    const { pagination } = this.state;
    this.getResults({ pagination });
    this.setColumns();
  }

  render() {
    const { columns, data, loading, filterColumns, selectedRowKeys, screen } =
      this.state;

    const rowSelection = {
      selectedRowKeys,
      preserveSelectedRowKeys: true,
      onChange: this.onSelectChange,
      getCheckboxProps: (record) => ({
        disabled: this.state.data.includes(record.area_id),
      }),
    };

    const hasSelected = selectedRowKeys.length > 0;

    return (
      <React.Fragment>
        <ListFilters
          items={(filterColumns, filterColumns)}
          handleSettings={this.handleSettings}
          handleFilters={this.handleFilters}
          key={filterColumns}
          screen={screen}
        />

        <Button
          onClick={this.handleAssignTerritory}
          disabled={!hasSelected}
          type="primary"
          style={{
            float: "right",
            position: "relative",
            top: "-48px",
            right: "48px",
            marginBottom: "-48px",
          }}
          className="mobile-hide"
        >
          Assign Territory
        </Button>

        <Table
          columns={columns}
          rowKey={(record) => record.area_id}
          dataSource={data}
          // pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
          bordered
          scroll={{ x: 400 }}
          size="small"
          rowSelection={{
            ...rowSelection,
          }}
          rowClassName={(record) => this.setDisabled(record)}
        />

        <Button
          onClick={this.handleAssignTerritory}
          disabled={!hasSelected}
          type="primary"
          style={{ top: "-42px" }}
        >
          Assign Territory
        </Button>
      </React.Fragment>
    );
  }
}

export default AssignTerritory;
