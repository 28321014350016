import React from "react";
import { Row, Col, Table, Button, Switch } from "antd";
import { LineChartOutlined } from "@ant-design/icons";

import PageTitle from "../../components/common/page-title";
import ListFilters from "../../components/common/list-filters";
import CommonListBase from "../../base/common-list-base";
import {
  currencyFormatter,
  dateFormatter,
} from "../../components/common/common-formatter";
import PortalUser from "../../iam/components/portal-user";
import { Link } from "react-router-dom";
import SalesEstimateStatistics from "../../components/sales-estimate/sales-estimate-statistics";

class SalesEstimate extends CommonListBase {
  state = {
    listReq: "salesestimate/list?employeeid=1&",
    delReq: "salesestimate/delete?campaignId=",
    dtlReq: "/sales-estimate/sales-estimate-details/",
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    sorting: [],
    isStatistics: false,
    screen: "sales-estimate-list",
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "Project Id",
        dataIndex: "project_id",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 100,
      },
      {
        title: "Employee",
        dataIndex: "employee_id",
        render: (text) => <PortalUser user={text} />,
        width: 140,
      },
      {
        title: "Customer Name",
        dataIndex: "customer_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 140,
      },
      {
        title: "Display",
        dataIndex: "display_name",
        render: (text, record) => (
          <Link
            style={{ padding: "0px", whiteSpace: "normal", textAlign: "left" }}
            type="link"
            to={
              this.state.dtlReq +
              record.campaign_id +
              "/" +
              record.project_id +
              " - " +
              encodeURI(record?.display_name) +
              " - " +
              encodeURI(record.customer_name)
            }
          >
            {text}
          </Link>
        ),
        width: 118,
      },
      {
        title: "From",
        dataIndex: "start_date",
        render: (text) => dateFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "To",
        dataIndex: "end_date",
        render: (text) => dateFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Campaign Status",
        dataIndex: "campaign_status",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Amount",
        dataIndex: "total_amount",
        render: (text) => currencyFormatter(parseInt(text)),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "HA Markup",
        dataIndex: "ha_base_rate_diff_amt",
        render: (text, record) => (
          <span className={text > 0 ? "ant-color-green" : "ant-color-red"}>
            {currencyFormatter(parseInt(text))} ({record?.ha_base_rate_diff}%)
          </span>
        ),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "TA Markup",
        dataIndex: "ta_base_rate_diff_amt",
        render: (text, record) => (
          <span className={text > 0 ? "ant-color-green" : "ant-color-red"}>
            {currencyFormatter(parseInt(text))} ({record?.ta_base_rate_diff}%)
          </span>
        ),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "QoS",
        dataIndex: "qos",
        render: (text, record) => (
          <span className={text > 0 ? "ant-color-green" : "ant-color-red"}>
            {currencyFormatter(parseInt(text))} ({record.qos_per}%)
          </span>
        ),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Rent Billed %",
        dataIndex: "billed_per",
        render: (text) => <span>{text}%</span>,
        width: 78,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Print Billed %",
        dataIndex: "printing_billed_per",
        render: (text) => <span>{text}%</span>,
        width: 78,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Installation Billed %",
        dataIndex: "mounting_billed_per",
        render: (text) => <span>{text}%</span>,
        width: 92,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
    ];

    const filterColumns = [
      { columnName: "project_id", displayName: "Project Id" },
      { columnName: "employee_id", displayName: "Employee", hideFilter: true },
      {
        columnName: "customer_name",
        displayName: "Customer Name",
      },
      { columnName: "display_name", displayName: "Display", default: true },
      { columnName: "start_date", displayName: "From", hideFilter: true },
      { columnName: "end_date", displayName: "To", hideFilter: true },
      {
        columnName: "campaign_status",
        displayName: "Campaign Status",
        hideFilter: true,
      },
      { columnName: "total_amount", displayName: "Amount", hideFilter: true },
      {
        columnName: "ha_base_rate_diff_amt",
        displayName: "HA Markup",
        hideFilter: true,
      },
      {
        columnName: "ta_base_rate_diff_amt",
        displayName: "TA Markup",
        hideFilter: true,
      },
      { columnName: "qos", displayName: "QoS", hideFilter: true },
      {
        columnName: "billed_per",
        displayName: "Rent Billed %",
        hideFilter: true,
      },
      {
        columnName: "printing_billed_per",
        displayName: "Print Billed %",
        hideFilter: true,
      },
      {
        columnName: "mounting_billed_per",
        displayName: "Installation Billed %",
        hideFilter: true,
      },
    ];

    this.setState({ columnsDefault: columns, filterColumns }, () =>
      this.setDefaultColumns(columns)
    );
  };

  // Handle Closed Switch
  handleClosedToggle = (e) => {
    const { pagination } = this.state;
    let listReq = this.state.listReq.includes("showClosed=true&")
      ? this.state.listReq.replace("showClosed=true&", "")
      : this.state.listReq + "showClosed=true&";
    this.setState({ listReq }, () => this.getResults({ pagination }));
  };

  // Handle Statistics
  handleStatistics = () => {
    this.setState({ isStatistics: !this.state.isStatistics });
  };

  componentDidMount() {
    // Get List
    const { pagination } = this.state;
    this.getResults({ pagination });
    this.setColumns();
  }

  render() {
    const {
      data,
      columns,
      pagination,
      loading,
      filterColumns,
      isStatistics,
      screen,
    } = this.state;

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageTitle titleText="Sales Estimate List" />

            {/* <div className="page-title-custom-btn">
              <Button
                type="primary"
                icon={<LineChartOutlined />}
                onClick={this.handleStatistics}
              >
                Statistics
              </Button>
            </div> */}

            <ListFilters
              items={filterColumns}
              handleSettings={this.handleSettings}
              handleFilters={this.handleFilters}
              key={filterColumns}
              screen={screen}
            />

            <div
              style={{
                top: "-44px",
                float: "right",
                right: "42px",
                marginBottom: "-44px",
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
              class="title-btns"
            >
              <Switch size="small" onChange={this.handleClosedToggle}></Switch>
              <label style={{ marginLeft: "8px", marginRight: "16px" }}>
                Closed
              </label>
            </div>

            <Table
              columns={columns}
              rowKey={(record) => record.project_id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              bordered
              scroll={{ x: 400 }}
              size="small"
            />
          </Col>
        </Row>

        {isStatistics && (
          <SalesEstimateStatistics
            key={isStatistics}
            handleStatistics={this.handleStatistics}
            fromPage="list"
          />
        )}
      </React.Fragment>
    );
  }
}

export default SalesEstimate;
