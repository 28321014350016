import React from "react";
import { Row, Col, Table, Button, Drawer, Switch, message } from "antd";
import PageTitle from "../../components/common/page-title";
import ListFilters from "../../components/common/list-filters";
import CommonListBase from "../../base/common-list-base";
import { EditOutlined } from "@ant-design/icons";
import AddPayee from "../../components/purchase/add-payee";
import { ajaxRequester } from "../../services/ajax-requester";

class PayeeList extends CommonListBase {
  state = {
    listReq: "payee/list?showActive=true&",
    delReq: "payee/delete?payeeId=",
    dtlReq: "payee/detail?payeeid=",
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    sorting: [],
    screen: "payee-list",
    isDrawer: false,
    record: "",
    isEditMode: false,
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "PID",
        dataIndex: "pid",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Account Name",
        dataIndex: "account_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "City",
        dataIndex: "city_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Payee Number",
        dataIndex: "payee_number",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Payee Email",
        dataIndex: "payee_email",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "PAN Number",
        dataIndex: "pan_no",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Status",
        dataIndex: "is_active",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => (text ? "Active" : "Inactive"),
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "x",
        render: (record) => (
          <React.Fragment>
            <Button
              icon={<EditOutlined />}
              className="ant-btn-reset"
              onClick={() => this.handleEdit(record?.payee_id)}
            />
          </React.Fragment>
        ),
        align: "center",
      },
    ];

    const filterColumns = [
      { columnName: "pid", displayName: "PID" },
      {
        columnName: "account_name",
        displayName: "Account Name",
        default: true,
      },
      { columnName: "city_name", displayName: "City" },
      { columnName: "payee_number", displayName: "Payee Number" },
      { columnName: "payee_email", displayName: "Payee Email" },
      { columnName: "pan_no", displayName: "PAN Number" },
      { columnName: "is_active", displayName: "Status", hideFilter: true },
    ];

    this.setState({ columnsDefault: columns, filterColumns }, () =>
      this.setDefaultColumns(columns)
    );
  };

  // Handle Drawer
  handleDrawer = () => {
    this.setState({ isDrawer: !this.state.isDrawer });
  };

  handleAdd = () => {
    this.props.history.push("/purchase/payee-add");
  };

  handleEdit = (payee_id) => {
    this.props.history.push("/purchase/payee-edit/" + payee_id);
  };

  handleActiveToggle = (e) => {
    const { pagination } = this.state;
    let listReq = this.state.listReq.includes("showActive=true&")
      ? this.state.listReq.replace("showActive=true&", "showActive=false&")
      : this.state.listReq.replace("showActive=false&", "showActive=true&");
    this.setState({ listReq }, () => this.getResults({ pagination }));
  };

  handleAddEdit = (data) => {
    const { isEditMode } = this.state;
    let reqUrl = "payee/" + (isEditMode ? "update" : "add");
    let reqObj = {
      request: {
        ...data,
      },
    };
    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data.success) {
        message.success(
          "Payee has been " +
            (isEditMode ? "updated" : "added") +
            " successfully"
        );
        this.handleDrawer();
        const { pagination } = this.state;
        this.getResults({ pagination });
      } else {
        message.error(
          data?.detail ||
            data ||
            "An error occured while processing your request."
        );
      }
    });
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.getResults({ pagination });
    this.setColumns();
  }
  render() {
    const {
      data,
      columns,
      pagination,
      loading,
      filterColumns,
      screen,
      isDrawer,
      isEditMode,
    } = this.state;

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageTitle
              titleText="Payee List"
              isAddBtn={"Add Payee"}
              handleAdd={this.handleAdd}
            />

            <ListFilters
              items={filterColumns}
              handleSettings={this.handleSettings}
              handleFilters={this.handleFilters}
              key={filterColumns}
              screen={screen}
            />

            <div
              style={{
                top: "-44px",
                float: "right",
                right: "42px",
                marginBottom: "-44px",
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
              class="title-btns title-btns-purchase"
            >
              <Switch size="small" onChange={this.handleActiveToggle}></Switch>
              <label style={{ marginLeft: "8px", marginRight: "16px" }}>
                Inactive
              </label>
            </div>

            <Table
              columns={columns}
              rowKey={(record) => record.payee_id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              bordered
              scroll={{ x: 400 }}
              size="small"
            />

            <Drawer
              title={isEditMode ? "Update Payee" : "Add Payee"}
              placement="right"
              open={isDrawer}
              onClose={this.handleDrawer}
              width={"924px"}
              key={isDrawer}
            >
              <AddPayee
                key={isDrawer}
                {...this.state}
                handleAddEdit={this.handleAddEdit}
              />
            </Drawer>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default PayeeList;
