import React, { Component } from "react";
import { Button, Row, Col, Table, InputNumber } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import EstimatePayeeListInput from "./estimate-payee-list-input";

class EstimatePayeeList extends Component {
  state = {
    loading: false,
    payout_estimate_payee_list: [],
    key: 1,
    columns: [],
  };

  setColumns = () => {
    let columns = [
      {
        title: "Account Name",
        dataIndex: "account_name",
      },
      {
        title: "Per %",
        dataIndex: "payee_per",
        render: (text, record) => (
          <EstimatePayeeListInput
            key={text}
            value={text}
            record={record}
            column={"payee_per"}
            handlePayeeInput={this.handlePayeeInput}
          />
        ),
      },
      {
        title: "Amount",
        dataIndex: "payee_annual_amount",
        render: (text, record) => (
          <EstimatePayeeListInput
            key={text}
            value={text}
            record={record}
            column={"payee_annual_amount"}
            handlePayeeInput={this.handlePayeeInput}
          />
        ),
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "x",
        render: (record) => (
          <React.Fragment>
            <Button
              icon={<DeleteOutlined />}
              className="ant-btn-reset"
              style={{ marginLeft: "12px" }}
              onClick={() => this.props.deletePayee(record)}
            />
          </React.Fragment>
        ),
        align: "center",
      },
    ];

    this.setState({ columns });
  };

  handlePayeeInput = (value, record, column) => {
    let { payout_estimate_payee_list, key } = this.state;

    payout_estimate_payee_list = payout_estimate_payee_list.map((x) => ({
      ...x,
      payee_annual_amount:
        x.payee_id === record.payee_id
          ? column === "payee_per"
            ? parseInt((record.payee_annual_amount / record?.payee_per) * value)
            : parseInt(value)
          : x.payee_annual_amount,
      payee_per:
        x.payee_id === record.payee_id
          ? column === "payee_annual_amount"
            ? Math.round(
                ((value * record?.payee_per) / record.payee_annual_amount) * 100
              ) / 100
            : Math.round(value * 100) / 100
          : x.payee_per,
    }));

    this.setState({ payout_estimate_payee_list, key: key + 1 });

    this.props.handlePayeeListChange(payout_estimate_payee_list);
  };

  componentDidMount() {
    this.setColumns();
    const { payout_estimate_payee_list } = this.props || [];
    this.setState({ payout_estimate_payee_list });
  }

  render() {
    const { loading, payout_estimate_payee_list, columns, key } = this.state;

    return (
      <Table
        columns={columns}
        rowKey={(record) => record?.payout_estimate_id}
        dataSource={payout_estimate_payee_list}
        pagination={false}
        loading={loading}
        onChange={this.handleTableChange}
        bordered
        scroll={{ x: 400 }}
        size="small"
        key={key}
      />
    );
  }
}

export default EstimatePayeeList;
