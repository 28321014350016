import React from "react";
import { createRoot } from 'react-dom/client';
import { HashRouter } from "react-router-dom";
import { Buffer } from 'buffer';
import App from "./App";
import "./assets/css/common.css";
import "./assets/css/style.css";
import "./assets/css/theme.css";
import { init as initApm } from '@elastic/apm-rum'
import * as dayjs from 'dayjs';
import * as advancedFormat from 'dayjs/plugin/advancedFormat'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as localeData from 'dayjs/plugin/localeData'
import * as weekday from 'dayjs/plugin/weekday'
import * as weekOfYear from 'dayjs/plugin/weekOfYear'
import * as weekYear from 'dayjs/plugin/weekYear'

import * as serviceWorker from "./serviceWorker";
serviceWorker.unregister();

const JsonPlugin = (option, dayjsClass, dayjsFactory) => {
  // overriding existing API
  dayjsClass.prototype.toJSON = function() {
      return this.format()
   }
}

dayjs.extend(JsonPlugin);
dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

// @ts-ignore
window.Buffer = Buffer;

if (!window.location.host.includes("localhost") && !window.location.host.includes("127.0.0.1")) {
    try {
        var apm = initApm({
            serviceName: 'mis-ui',
            serverUrl: 'https://eapm.ooh.systems',
            serviceVersion: '',
            environment: window.location.host.split('.')[0]
        })
    } catch (e) {
        console.warn(`Unable to initiate APM for host : ${window.location.host}`)
    }
}
const domNode = document.getElementById('root');
const root = createRoot(domNode);



root.render(
  <HashRouter>
    <App />
  </HashRouter>
);
