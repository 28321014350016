import React from "react";
import { Row, Col, Table, Button } from "antd";
import ListFilters from "../../components/common/list-filters";
import CommonListBase from "../../base/common-list-base";
import { perFormatter } from "../../components/common/common-formatter";

class OccupancyMonthlyList extends CommonListBase {
  state = {
    listReq:
      "dashboard/occupancy?resultgroup=month&durationGroup=current_finyear&",
    delReq: "sistercompany/delete?payout_estimate_id=",
    dtlReq: "sistercompany",
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    sorting: [],
    screen: "dashboard-city-list",
    isDrawer: false,
    record: "",
    isEditMode: false,
  };

  // Setting Columns
  getColumns = (type) => {
    let columns = [
      {
        title: type,
        dataIndex: "type",
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("apr")}
            >
              April {this.getYear()}
            </div>
          );
        },
        dataIndex: "apr",
        render: (text) => perFormatter(parseInt(text)),
      },
      {
        title: () => {
          return (
            <div className="link-hdr" onClick={() => this.perFormatter("may")}>
              May {this.getYear()}
            </div>
          );
        },
        dataIndex: "may",
        render: (text) => perFormatter(parseInt(text)),
      },
      {
        title: () => {
          return (
            <div className="link-hdr" onClick={() => this.perFormatter("jun")}>
              June {this.getYear()}
            </div>
          );
        },
        dataIndex: "jun",
        render: (text) => perFormatter(parseInt(text)),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("jul")}
            >
              July {this.getYear()}
            </div>
          );
        },
        dataIndex: "jul",
        render: (text) => perFormatter(parseInt(text)),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("aug")}
            >
              August {this.getYear()}
            </div>
          );
        },
        dataIndex: "aug",
        render: (text) => perFormatter(parseInt(text)),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("sep")}
            >
              September {this.getYear()}
            </div>
          );
        },
        dataIndex: "sep",
        render: (text) => perFormatter(parseInt(text)),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("oct")}
            >
              October {this.getYear()}
            </div>
          );
        },
        dataIndex: "oct",
        render: (text) => perFormatter(parseInt(text)),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("nov")}
            >
              November {this.getYear()}
            </div>
          );
        },
        dataIndex: "nov",
        render: (text) => perFormatter(parseInt(text)),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("dec")}
            >
              December {this.getYear()}
            </div>
          );
        },
        dataIndex: "dec",
        render: (text) => perFormatter(parseInt(text)),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("jan")}
            >
              January {Number(this.getYear()) + 1}
            </div>
          );
        },
        dataIndex: "jan",
        render: (text) => perFormatter(parseInt(text)),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("feb")}
            >
              February {Number(this.getYear()) + 1}
            </div>
          );
        },
        dataIndex: "feb",
        render: (text) => perFormatter(parseInt(text)),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("mar")}
            >
              March {Number(this.getYear()) + 1}
            </div>
          );
        },
        dataIndex: "mar",
        render: (text) => perFormatter(parseInt(text)),
      },
    ];

    return columns;
  };

  dataFormating = (data) => {
    const transformedData = data.reduce((acc, curr) => {
      if (!acc[0]) acc[0] = {};
      acc[0][curr.month_name.toLowerCase()] = curr.occupancy_per;
      return acc;
    }, []);

    return transformedData;
  };

  getYear = () => {
    const now = new Date();
    const year = now.getFullYear();
    const financialYear = now.getMonth() >= 3 ? year : year - 1;
    return financialYear.toString().slice(-2);
  };

  handleMonthClick = (month) => {
    this.props.handleDetails(month);
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.getResults({ pagination });
  }

  render() {
    const { data, pagination, loading } = this.state;

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <Table
              columns={this.getColumns("Monthly Occupancy")}
              rowKey={(record) => record?.id}
              dataSource={this.dataFormating(data)}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              bordered
              scroll={{ x: 400 }}
              size="small"
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default OccupancyMonthlyList;
