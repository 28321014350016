import React, { Component } from "react";
import { Spin } from "antd";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { ajaxRequesterPublic } from "../../services/ajax-requester-public";

class VerifyEmail extends Component {
  state = { isLoading: true, isSuccess: false, isError: "" };

  // Verify Email
  handleVerifyEmail = () => {
    let code = window.location.href;
    code = code.split("?")[1];

    let reqUrl = "payee/verify/email?" + code;
    let reqObj = "";

    ajaxRequesterPublic(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ isLoading: false });
        data?.success
          ? this.setState({ isSuccess: true })
          : this.setState({ isSuccess: false, isError: data });
      },
      "GET"
    );
  };

  componentDidMount() {
    this.handleVerifyEmail();
  }
  render() {
    const { isLoading, isSuccess, isError } = this.state;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "calc(100vh - 200px)",
        }}
      >
        {isLoading && (
          <div style={{ textAlign: "center" }}>
            <Spin size="large" />
            <h2 style={{ textAlign: "center", marginTop: "24px" }}>
              Verifying your email address...
            </h2>
          </div>
        )}

        {!isLoading && (
          <React.Fragment>
            {isSuccess && (
              <div style={{ textAlign: "center" }}>
                <CheckCircleFilled
                  style={{ fontSize: "48px", color: "#0b8235" }}
                />
                <h2 style={{ textAlign: "center", marginTop: "24px" }}>
                  Your email address verified successfully.
                </h2>
              </div>
            )}

            {!isSuccess && (
              <div style={{ textAlign: "center" }}>
                <CloseCircleFilled
                  style={{ fontSize: "48px", color: "#ff4d4f" }}
                />
                <h2 style={{ textAlign: "center", marginTop: "24px" }}>
                  {isError?.detail}
                </h2>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default VerifyEmail;
