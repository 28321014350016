import React, { Component } from "react";
import { Descriptions } from "antd";
import {
  currencyFormatter,
  dateFormatter,
} from "../../components/common/common-formatter";
import PortalUser from "../../iam/components/portal-user";

class TransactionsDetails extends Component {
  state = {};
  render() {
    const { results } = this.props;

    return (
      <Descriptions column={{ xl: 3, xs: 1 }} size="small" bordered>
        <Descriptions.Item label="Payout From">
          <b>{results?.payout_from_account_name}</b>
        </Descriptions.Item>
        <Descriptions.Item label="Payout Estimate Name">
          <b>{results?.payout_estimate_name}</b>
        </Descriptions.Item>
        <Descriptions.Item label="Account Name">
          <b>{results?.payee_account_name}</b>
        </Descriptions.Item>
        <Descriptions.Item label="Payout Type">
          <b>{results?.estimate_type}</b>
        </Descriptions.Item>
        <Descriptions.Item label="Authority ID">
          <b>{results?.authority_id}</b>
        </Descriptions.Item>
        <Descriptions.Item label="PID">
          <b>{results?.pid}</b>
        </Descriptions.Item>
        <Descriptions.Item label="From">
          <b>{dateFormatter(results?.billing_duration_from)}</b>
        </Descriptions.Item>
        <Descriptions.Item label="To">
          <b>{dateFormatter(results?.billing_duration_to)}</b>
        </Descriptions.Item>
        <Descriptions.Item label="Payment Due Date">
          <b>{dateFormatter(results?.payment_due_date)}</b>
        </Descriptions.Item>
        <Descriptions.Item label="Estimated Amount">
          <b>{currencyFormatter(parseInt(results?.payout_amount))}</b>
        </Descriptions.Item>
        {results?.approved_by_id && (
          <Descriptions.Item label="Payment Approval From">
            <b>
              <span style={{ display: "inline-block" }}>
                <PortalUser user={results?.approved_by_id} />
              </span>
            </b>
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Remarks">
          <b>{results?.remarks}</b>
        </Descriptions.Item>
      </Descriptions>
    );
  }
}

export default TransactionsDetails;
