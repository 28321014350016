import React, { Component } from "react";
import { ajaxRequester } from "../../services/ajax-requester";
import PageTitleDetails from "../../components/common/page-title-detail";
import {
  Row,
  Col,
  Spin,
  Descriptions,
  Card,
  Button,
  Typography,
  Drawer,
  Menu,
  Dropdown,
  Modal,
  message,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  CopyOutlined,
  ShareAltOutlined,
  UnorderedListOutlined,
  StopOutlined,
  CheckCircleOutlined,
  EllipsisOutlined,
  ExportOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import PlanBusiness from "../../components/plan/plan-business";
import PlanInventories from "../../components/plan/plan-inventories";
import PlanActions from "../../components/plan/plan-actions";
import {
  currencyFormatter,
  dateFormatter,
  timeFormatter,
} from "../../components/common/common-formatter";
import PlanDocuments from "../../components/plan/plan-documents";
import PortalUser from "../../iam/components/portal-user";
import * as dayjs from "dayjs";
import { exportPlanToZip } from "./plan-to-zip";
import ExportSettings from "../plan/export-settings";
import POPrint from "../../components/campaign/po-print";
import PoSettings from "../../components/campaign/po-settings";

class PlanDetails extends Component {
  state = {
    results: "",
    isLoading: true,
    isDrawer: false,
    customersList: [],
    editDrawer: "",
    isEditMode: false,
    isModal: false,
    record: "",
    page: "plan",
    isExportDrawer: false,
    isPoDownload: false,
    isUpdateKey: 1,
    poTerms: [],
    print_company_name: "",
    print_gstin: "",
  };

  // Get Plan Details
  getPlanDetails = () => {
    const planid = this.props.match.params.id;
    var reqUrl = "plan/detail?planid=" + Number(planid);
    var reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          results: data.response,
          isLoading: false,
          isBusinessLoading: false,
          isInventoryLoading: false,
          isUpdateKey: this.state.isUpdateKey + 1,
          is_rotational: data?.response?.is_rotational,
        });
      },
      "GET"
    );
  };

  // Get Customer List from Lookup
  getCustomers = () => {
    let reqUrl = "customer/lookup";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          customersList: data.response,
        });
      },
      "GET"
    );
  };

  // Redirect to Plan List
  handleBack = () => {
    this.props.history.push(`/plans/plans`);
  };

  // Handle Drawer
  handleDrawer = (editDrawer) => {
    this.setState({
      isDrawer: !this.state.isDrawer,
      editDrawer,
    });
  };

  // Handle Business Edit
  handleBusiness = (data) => {
    const discounting_per =
      data?.discounting_criteria?.discount_criteria?.rate_percentage || 0;

    data.plan_inventory_details = data.plan_inventory_details.map(
      (inventory) => ({
        ...inventory,
        start_date: data.start_date,
        end_date: data.end_date,
        no_of_days: data.no_of_days,
        monthly_rate: discounting_per
          ? inventory.card_rate - (inventory.card_rate * discounting_per) / 100
          : inventory.monthly_rate,
        rental_cost: discounting_per
          ? parseInt((inventory.card_rate / 30) * data.no_of_days) -
            parseInt(
              (inventory.card_rate / 30) * data.no_of_days * discounting_per
            ) /
              100
          : (inventory.monthly_rate / 30) * data.no_of_days,
      })
    );

    let total_display_cost = 0;
    data.plan_inventory_details.map(
      (x) => (total_display_cost = total_display_cost + x.rental_cost)
    );
    data.total_display_cost = total_display_cost;

    data.total_without_tax =
      data.total_display_cost +
      data.total_printing_cost +
      data.total_installing_cost;

    data.gst_amount =
      (data.total_display_cost * data.gst_per) / 100 +
      (data.total_printing_cost * data.gst_per) / 100 +
      (data.total_installing_cost * data.gst_per) / 100;

    data.total_amount = data.total_without_tax + data.gst_amount;

    this.setState(
      {
        isDrawer: !this.state.isDrawer,
        isBusinessLoading: true,
      },
      () => this.updatePlan(data)
    );
  };

  // Handle Inventory Update
  handleInventory = (data) => {
    this.setState({
      // isEditMode: !this.state.isEditMode,
      isInventoryLoading: true,
    });
    this.updatePlan(data);
  };

  // Update to Plan
  updatePlan = (data) => {
    var reqUrl = "plan/update";
    var reqObj = {
      request: {
        ...data,
        plan_status: "",
        created_by: "varun",
        auto_adjust_site_start_dates: this.state?.auto_adjust_site_start_dates,
        plan_key: this.state?.results?.plan_key,
        is_rotational: this.state?.is_rotational,
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data.success) {
        message.success("Plan has been updated successfully");
        this.getPlanDetails();
      } else {
        message.error(
          data?.detail || "An error occured while processing your request."
        );
        this.getPlanDetails();
      }
    });
  };

  // handle Edit Mode of Inventories
  handleEdit = (isChanged) => {
    const { confirm } = Modal;
    if (this.state.isEditMode && isChanged) {
      confirm({
        title: "Your all unsaved data will be deleted.",
        onOk: () => {
          this.setState({ isEditMode: !this.state.isEditMode });
        },
      });
    } else {
      this.setState({ isEditMode: !this.state.isEditMode });
    }
  };

  // handle Action Menu
  handleMenuClick = ({ key }) => {
    let record = this.state.results;
    record.action = key;

    switch (key) {
      case "delete":
        record.title = "Delete Plan";
        break;

      case "block":
        record.title = "Block Plan";
        break;

      case "unblock":
        record.title = "Unblock Plan";
        break;

      case "copy":
        record.title = "Copy Plan";
        break;

      case "share":
        record.title = "Share Plan";
        break;

      case "activity":
        record.title = "Plan Activities";
        this.getActivityLog(record.plan_id);
        break;

      case "publiclink":
        window.open("/#/plans/plan-view/" + record.publish_id, "_blank");
        break;

      case "download":
        this.setState({ isExportDrawer: true, exportType: "ppt" });
        break;

      case "downloadXlsx":
        this.setState({ isExportDrawer: true, exportType: "excel" });
        break;

      case "downloadZip":
        exportPlanToZip(this.state.results, {
          exportName: `${this.state.results.display_name} - ${dayjs().format(
            "Do MMMM YYYY"
          )}.zip`,
        });
        break;

      case "downloadPO":
        this.setState({ isPoDownload: true });
        break;

      default:
        break;
    }

    key !== "download" &&
      key !== "publiclink" &&
      key !== "downloadZip" &&
      key !== "downloadXlsx" &&
      key !== "downloadPO" &&
      this.setState({ record }, () => this.handleModal());
  };

  // Handle Popup
  handleModal = () => {
    this.setState({ isModal: !this.state.isModal });
  };

  // Handle Popup Actions
  handleActions = (data) => {
    this.handleModal();
    const { action, plan_id } = this.state.record;

    switch (action) {
      case "delete":
        this.deleteItem(plan_id);
        break;

      case "block":
        this.blockUnblockItem(action, plan_id);
        break;

      case "unblock":
        this.blockUnblockItem(action, plan_id);
        break;

      case "copy":
        this.planActions(action, plan_id, data);
        break;

      case "share":
        this.planActions(action, plan_id, data);
        break;

      default:
        break;
    }
  };

  // Plan Actions
  planActions = (action, plan_id, data) => {
    var reqUrl = "plan/" + action;
    var reqObj = {
      request: {
        plan_id: plan_id,
        display_name: data.display_name,
        shared_with_employee_id: data.employee_id,
        created_by: "varun",
      },
    };

    ajaxRequester(reqUrl, reqObj, () => {
      this.handleBack();
    });
  };

  // Plan Block Unblock
  blockUnblockItem = (action, plan_id) => {
    var reqUrl = "plan/" + action + "?planid=" + plan_id;
    var reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      () => {
        this.getPlanDetails();
      },
      "GET"
    );
  };

  // Get Plan Activities
  getActivityLog = (plan_id) => {
    this.setState({ activityLog: "" });

    var reqUrl = "plan/activitylog?planid=" + plan_id;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ activityLog: data.response });
      },
      "GET"
    );
  };

  // Delete Item from List based on Id
  deleteItem = (plan_id) => {
    var reqUrl = "plan/delete?planId=" + plan_id;
    var reqObj = "";
    ajaxRequester(
      reqUrl,
      reqObj,
      () => {
        this.handleBack();
      },
      "DELETE"
    );
  };

  // Convert Plan To Campaign
  convertToCampaign = () => {
    const planid = this.props.match.params.id;
    var reqUrl = "campaign/create";
    var reqObj = {
      request: {
        plan_id: Number(planid),
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data.success) {
        this.props.history.push("/campaigns/campaigns");
        message.success("Plan has been converted to campaign successfully");
      } else if (data.response) {
        let detail = data?.response || [];

        if (detail.length > 0) {
          message.success(
            <React.Fragment>
              The start dates of following sites have been changed according to
              the available dates of sites.
              <ol style={{ textAlign: "left" }}>
                {detail.map((x) => (
                  <li style={{ textAlign: "left" }}>{x}</li>
                ))}
              </ol>
            </React.Fragment>
          );
        } else {
          message.success("Plan has been converted to campaign successfully");
        }

        this.props.history.push("/campaigns/campaigns");
      } else {
        message.error(data.detail);
      }
    });
  };

  // Adjust PlanDates Toggle
  handleAdjustPlanDatesToggle = (e) => {
    this.setState({ auto_adjust_site_start_dates: e });
  };

  handleRotational = (e) => {
    this.setState({ is_rotational: e });
  };

  // Gettng Seller Details
  getConfigurationDetails = () => {
    let reqUrl = "configuration/detail";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ configurationDetails: data });
      },
      "GET"
    );
  };

  downloadPO = (poTerms, print_company_name, print_gstin) => {
    this.setState(
      { isPoDownload: false, poTerms, print_company_name, print_gstin },
      () => window.print()
    );
  };

  // on component load get details based on id from url
  componentDidMount() {
    this.getPlanDetails();
    this.getCustomers();
    this.getConfigurationDetails();
  }

  render() {
    const {
      results,
      isLoading,
      isDrawer,
      isBusinessLoading,
      isInventoryLoading,
      editDrawer,
      isEditMode,
      isModal,
      record,
      isExportDrawer,
      isPoDownload,
      isUpdateKey,
      exportType,
    } = this.state;
    const { Title } = Typography;

    return (
      <React.Fragment>
        <Row className="plan-details print-hide">
          <Col span={24}>
            {!isLoading && (
              <React.Fragment>
                {!isEditMode && (
                  <React.Fragment>
                    <PageTitleDetails
                      titleText={
                        results?.project_id +
                        " - " +
                        (results.customer_name
                          ? results.customer_name + " - "
                          : "") +
                        results.display_name +
                        (results.plan_status === "B"
                          ? " - Blocked till " +
                            dateFormatter(results.blocking_expiry_date) +
                            " " +
                            timeFormatter(results.blocking_expiry_date)
                          : "")
                      }
                      isBackBtn="true"
                      handleBack={this.handleBack}
                      is_rotational={results?.is_rotational}
                    />

                    <div className="page-title-custom-btn plan-dtn-title-btn">
                      {!results.converted_to_campaign &&
                        results.can_convert_to_campaign && (
                          <Button
                            type="primary"
                            style={{ marginRight: "16px" }}
                            onClick={this.convertToCampaign}
                          >
                            Convert to Campaign
                          </Button>
                        )}

                      <Dropdown
                        menu={{
                          onClick: (key) => this.handleMenuClick(key),
                          items: [
                            {
                              key: "delete",
                              icon: <DeleteOutlined />,
                              label: "Delete",
                            },
                            ...(this?.state?.configurationDetails
                              ?.configuration_details?.plan_settings
                              ?.enable_blocking
                              ? [
                                  ...(results.plan_status !== "B"
                                    ? [
                                        {
                                          key: "block",
                                          icon: <StopOutlined />,
                                          label: "Block",
                                        },
                                      ]
                                    : []),
                                  ...(results.plan_status === "B"
                                    ? [
                                        {
                                          key: "unblock",
                                          icon: <CheckCircleOutlined />,
                                          label: "Unblock",
                                        },
                                      ]
                                    : []),
                                ]
                              : []),
                            {
                              key: "copy",
                              icon: <CopyOutlined />,
                              label: "Copy",
                            },
                            {
                              key: "share",
                              icon: <ShareAltOutlined />,
                              label: "Share",
                            },
                            {
                              key: "activity",
                              icon: <UnorderedListOutlined />,
                              label: "Activity",
                            },
                            {
                              key: "publiclink",
                              icon: <ExportOutlined />,
                              label: "Public Link",
                            },
                            {
                              key: "download",
                              icon: <DownloadOutlined />,
                              label: "Download PPTx",
                            },
                            {
                              key: "downloadZip",
                              icon: <DownloadOutlined />,
                              label: "Download Photos",
                            },
                            {
                              key: "downloadXlsx",
                              icon: <DownloadOutlined />,
                              label: "Download Excel",
                            },
                            {
                              key: "downloadPO",
                              icon: <DownloadOutlined />,
                              label: "Download PO",
                            },
                          ],
                        }}
                        // overlay={
                        //   <Menu onClick={(key) => this.handleMenuClick(key)}>
                        //     <Menu.Item key="delete" icon={<DeleteOutlined />}>
                        //       Delete
                        //     </Menu.Item>
                        //     {this?.state?.configurationDetails
                        //       ?.configuration_details?.plan_settings
                        //       ?.enable_blocking && (
                        //       <React.Fragment>
                        //         {results.plan_status !== "B" && (
                        //           <Menu.Item key="block" icon={<StopOutlined />}>
                        //             Block
                        //           </Menu.Item>
                        //         )}
                        //         {results.plan_status === "B" && (
                        //           <Menu.Item
                        //             key="unblock"
                        //             icon={<CheckCircleOutlined />}
                        //           >
                        //             Unblock
                        //           </Menu.Item>
                        //         )}
                        //       </React.Fragment>
                        //     )}

                        //     <Menu.Item key="copy" icon={<CopyOutlined />}>
                        //       Copy
                        //     </Menu.Item>
                        //     <Menu.Item key="share" icon={<ShareAltOutlined />}>
                        //       Share
                        //     </Menu.Item>
                        //     <Menu.Item
                        //       key="activity"
                        //       icon={<UnorderedListOutlined />}
                        //     >
                        //       Activity
                        //     </Menu.Item>
                        //     <Menu.Item key="publiclink" icon={<ExportOutlined />}>
                        //       Public Link
                        //     </Menu.Item>
                        //     <Menu.Item
                        //       key={"download"}
                        //       icon={<DownloadOutlined />}
                        //     >
                        //       Download PPTx
                        //     </Menu.Item>
                        //     <Menu.Item
                        //       key={"downloadZip"}
                        //       icon={<DownloadOutlined />}
                        //     >
                        //       Download Photos
                        //     </Menu.Item>
                        //     <Menu.Item
                        //       key={"downloadXlsx"}
                        //       icon={<DownloadOutlined />}
                        //     >
                        //       Download Excel
                        //     </Menu.Item>
                        //   </Menu>
                        // }
                        placement="bottomRight"
                        trigger={["click"]}
                      >
                        <Button
                          style={{ paddingLeft: "8px", paddingRight: "8px" }}
                        >
                          <EllipsisOutlined />
                        </Button>
                      </Dropdown>
                    </div>

                    {!results.converted_to_campaign &&
                      results.can_convert_to_campaign && (
                        <div
                          style={{
                            float: "right",
                            position: "relative",
                            marginTop: "-63px",
                            marginRight: "98px",
                          }}
                        ></div>
                      )}

                    <Row gutter={16} className="plan-dtl-boxes">
                      <Col span={8} xs={24} xl={8}>
                        <Card
                          title={<Title level={5}>Business</Title>}
                          extra={
                            <Button
                              style={{ margin: "-8px" }}
                              icon={<EditOutlined />}
                              onClick={() => this.handleDrawer("Business")}
                            />
                          }
                          loading={isBusinessLoading}
                          bodyStyle={{ paddingBottom: "0px" }}
                        >
                          <Descriptions column={1}>
                            <Descriptions.Item label="Employee">
                              <b>
                                <PortalUser user={results.employee_id} />
                              </b>
                            </Descriptions.Item>

                            <Descriptions.Item label="Start Date">
                              <b>{dateFormatter(results.start_date)}</b>
                            </Descriptions.Item>

                            <Descriptions.Item label="End Date">
                              <b>{dateFormatter(results.end_date)}</b>
                            </Descriptions.Item>

                            <Descriptions.Item label="Days">
                              <b>{results.no_of_days}</b>
                            </Descriptions.Item>
                          </Descriptions>
                        </Card>

                        <Card
                          title={<Title level={5}>Inventory</Title>}
                          extra={
                            <Button
                              style={{ margin: "-8px" }}
                              icon={<EditOutlined />}
                              onClick={this.handleEdit}
                            />
                          }
                          bodyStyle={{ paddingBottom: "0px" }}
                          style={{ marginTop: "16px" }}
                          loading={isInventoryLoading}
                        >
                          <Descriptions column={1}>
                            <Descriptions.Item label="Home Inventory">
                              <b>{results.total_ha_inventory}</b>
                            </Descriptions.Item>

                            <Descriptions.Item label="Traded Inventory">
                              <b>{results.total_ta_inventory}</b>
                            </Descriptions.Item>

                            <Descriptions.Item label="Total Sites">
                              <b>{results.total_sites}</b>
                            </Descriptions.Item>

                            <Descriptions.Item label="Price / SQFT">
                              <b>{results.rate_per_sqft}</b>
                            </Descriptions.Item>

                            <Descriptions.Item label="Price/SQFT/Month">
                              <b>{results.rate_per_sqft_month}</b>
                            </Descriptions.Item>

                            <Descriptions.Item label="Total SQFT">
                              <b>{results.total_sqft}</b>
                            </Descriptions.Item>
                          </Descriptions>
                        </Card>
                      </Col>

                      <Col span={8} xs={24} xl={8}>
                        <Card
                          title={<Title level={5}>Statistics</Title>}
                          bodyStyle={{ paddingBottom: "0px" }}
                          loading={isInventoryLoading}
                        >
                          <Descriptions column={1}>
                            <Descriptions.Item
                              label="HA Markup"
                              className="ant-text-right"
                            >
                              <b
                                className={
                                  results.base_rate_diff > 0
                                    ? "ant-color-green"
                                    : "ant-color-red"
                                }
                              >
                                {results.base_rate_diff_amt
                                  ? currencyFormatter(
                                      parseInt(results.base_rate_diff_amt)
                                    )
                                  : 0}{" "}
                                ({results.base_rate_diff}%)
                              </b>
                            </Descriptions.Item>

                            <Descriptions.Item label="Occupancy Wise">
                              <b>{}</b>
                            </Descriptions.Item>

                            <Descriptions.Item
                              label="TA Markup"
                              className="ant-text-right"
                            >
                              <b
                                className={
                                  results?.ta_base_rate_diff > 0
                                    ? "ant-color-green"
                                    : "ant-color-red"
                                }
                              >
                                {results?.ta_base_rate_diff_amt
                                  ? currencyFormatter(
                                      parseInt(results?.ta_base_rate_diff_amt)
                                    )
                                  : 0}{" "}
                                ({results?.ta_base_rate_diff}%)
                              </b>
                            </Descriptions.Item>

                            <Descriptions.Item label="ROI on Expense">
                              <b>{}</b>
                            </Descriptions.Item>
                          </Descriptions>
                        </Card>

                        <Card
                          title={<Title level={5}>Client Grade A</Title>}
                          bodyStyle={{ paddingBottom: "0px" }}
                          style={{ marginTop: "16px" }}
                        >
                          <Descriptions column={1}>
                            <Descriptions.Item
                              label="Unbilled Sales"
                              style={{ textAlign: "right" }}
                            >
                              <b>{}</b>
                            </Descriptions.Item>

                            <Descriptions.Item
                              label="Effective Sales"
                              style={{ textAlign: "right" }}
                            >
                              <b>{}</b>
                            </Descriptions.Item>

                            <Descriptions.Item
                              label="Payment Received"
                              style={{ textAlign: "right" }}
                            >
                              <b>{}</b>
                            </Descriptions.Item>

                            <Descriptions.Item
                              label="Outstanding"
                              style={{ textAlign: "right" }}
                            >
                              <b>{}</b>
                            </Descriptions.Item>
                          </Descriptions>
                        </Card>
                      </Col>

                      <Col span={8} xs={24} xl={8}>
                        <Card
                          title={<Title level={5}>Summary</Title>}
                          bodyStyle={{ paddingBottom: "0px" }}
                          loading={isInventoryLoading}
                        >
                          <Descriptions column={1}>
                            <Descriptions.Item
                              label="Display Cost"
                              className="ant-text-right"
                            >
                              <b>
                                {currencyFormatter(
                                  parseInt(results.total_display_cost)
                                )}
                              </b>
                            </Descriptions.Item>

                            <Descriptions.Item
                              label="Printing Cost"
                              className="ant-text-right"
                            >
                              <b>
                                {currencyFormatter(
                                  parseInt(results.total_printing_cost)
                                )}
                              </b>
                            </Descriptions.Item>

                            <Descriptions.Item
                              label="Installation Cost"
                              className="ant-text-right"
                            >
                              <b>
                                {currencyFormatter(
                                  parseInt(results.total_installing_cost)
                                )}
                              </b>
                            </Descriptions.Item>

                            <Descriptions.Item
                              label="Total Without Tax"
                              className="ant-text-right"
                            >
                              <b>
                                {currencyFormatter(
                                  parseInt(
                                    results.total_display_cost +
                                      results.total_printing_cost +
                                      results.total_installing_cost
                                  )
                                )}
                              </b>
                            </Descriptions.Item>

                            <Descriptions.Item
                              label={"GST (" + results.gst_per + "%)"}
                              className="ant-text-right"
                            >
                              <b>
                                {currencyFormatter(
                                  parseInt(results.gst_amount)
                                )}
                              </b>
                            </Descriptions.Item>

                            <Descriptions.Item
                              label={<b>Grand Total</b>}
                              className="ant-text-right"
                              style={{
                                borderTop: "solid 1px #f0f0f0",
                                paddingTop: "12px",
                              }}
                            >
                              <b>
                                {currencyFormatter(
                                  parseInt(results.total_amount)
                                )}
                              </b>
                            </Descriptions.Item>
                          </Descriptions>
                        </Card>

                        <Card
                          title={<Title level={5}>Documents</Title>}
                          extra={
                            <Button
                              style={{ margin: "-8px" }}
                              icon={<EditOutlined />}
                              onClick={() => this.handleDrawer("Documents")}
                            />
                          }
                          bodyStyle={{ paddingBottom: "0px" }}
                          style={{ marginTop: "16px" }}
                        >
                          <Descriptions column={1}>
                            <Descriptions.Item label="Email Confirmation">
                              <b>
                                {results.documents &&
                                  results.documents["email_confirmation"]
                                    ?.length}
                              </b>
                            </Descriptions.Item>

                            <Descriptions.Item label="Purchase Orders">
                              <b>
                                {results.documents &&
                                  results.documents["purchase_order"]?.length}
                              </b>
                            </Descriptions.Item>

                            <Descriptions.Item label="Others">
                              <b>
                                {results.documents &&
                                  results.documents["others"]?.length}
                              </b>
                            </Descriptions.Item>
                          </Descriptions>
                        </Card>
                      </Col>
                    </Row>

                    <Drawer
                      title={editDrawer}
                      placement="right"
                      open={isDrawer}
                      onClose={() => this.handleDrawer("")}
                      width={"40%"}
                    >
                      {editDrawer === "Business" && (
                        <PlanBusiness
                          {...this.state}
                          handleBusiness={this.handleBusiness}
                          key={isDrawer}
                        />
                      )}

                      {editDrawer === "Documents" && (
                        <PlanDocuments {...this.state} />
                      )}
                    </Drawer>
                  </React.Fragment>
                )}

                {isEditMode && (
                  <PlanInventories
                    {...this.state}
                    handleInventories={this.handleInventories}
                    key={isUpdateKey}
                    handleInventory={this.handleInventory}
                    handleBack={this.handleEdit}
                    handleAdjustPlanDatesToggle={
                      this.handleAdjustPlanDatesToggle
                    }
                    handleRotational={this.handleRotational}
                  />
                )}

                <Modal
                  centered
                  title={record.title}
                  open={isModal}
                  onCancel={this.handleModal}
                  width={record.action === "delete" ? 280 : 400}
                  footer={false}
                >
                  <PlanActions
                    key={isModal}
                    {...record}
                    {...this.state}
                    handleActions={this.handleActions}
                  />
                </Modal>

                <Drawer
                  title={"Export"}
                  placement="right"
                  open={isExportDrawer}
                  onClose={() => this.setState({ isExportDrawer: false })}
                  width={"34%"}
                >
                  <ExportSettings {...this.state} key={exportType} />
                </Drawer>
              </React.Fragment>
            )}

            {isLoading && (
              <div className="list-loader">
                <Spin />
              </div>
            )}
          </Col>
        </Row>

        {!isLoading && (
          <React.Fragment>
            <div className="invoice-print-view" style={{ display: "none" }}>
              <POPrint {...this.state} />
            </div>

            <Drawer
              title={"Purchase Order Settings"}
              placement="right"
              open={isPoDownload}
              onClose={() => this.setState({ isPoDownload: false })}
              width={"48%"}
            >
              <PoSettings {...this.state} handleDownload={this.downloadPO} />
            </Drawer>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default PlanDetails;
