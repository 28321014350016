import React from "react";
import { Row, Col, Table, Button, Popconfirm, message } from "antd";
import PageTitle from "../../components/common/page-title";
import ListFilters from "../../components/common/list-filters";
import CommonListBase from "../../base/common-list-base";
import {
  currencyFormatter,
  dateFormatter,
} from "../../components/common/common-formatter";
import PortalUser from "../../iam/components/portal-user";
import { Link } from "react-router-dom";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { ajaxRequester } from "../../services/ajax-requester";

class ProformaInvoices extends CommonListBase {
  state = {
    listReq:
      "salesestimate/invoice/pendingforapprovallist?employeeid=1&showClientApproved=false&",
    delReq: "salesestimate/invoice/pendingforapprovallist?campaignId=",
    dtlReq: "/sales-estimate/invoice/approval/",
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    sorting: [],
    screen: "proforma-invoices",
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "Project Id",
        dataIndex: "project_id",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 98,
      },
      {
        title: "Employee",
        dataIndex: "employee_id",
        render: (text) => <PortalUser user={text} />,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 140,
      },
      {
        title: "Customer Name",
        dataIndex: "customer_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 140,
      },
      {
        title: "Display",
        dataIndex: "display_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) => (
          <Link
            style={{
              padding: "0px",
              whiteSpace: "normal",
              textAlign: "left",
            }}
            type="link"
            to={this.state.dtlReq + record.invoice_id}
          >
            {text}
          </Link>
        ),
        width: 118,
      },
      {
        title: "Start Date",
        dataIndex: "duration_from",
        render: (text) => (text ? dateFormatter(text) : ""),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 110,
      },
      {
        title: "End Date",
        dataIndex: "duration_to",
        render: (text) => (text ? dateFormatter(text) : ""),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 98,
      },
      {
        title: "Proforma No.",
        dataIndex: "proforma_invoice_no",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 128,
      },
      {
        title: "Invoice Amount",
        dataIndex: "invoice_amount",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: "Invoice Amount with Tax",
        dataIndex: "total_amount",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => currencyFormatter(parseInt(text)),
        width: 142,
      },
      {
        title: "Campaign Difference",
        dataIndex: "campaign_difference",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => (
          <span className={text > 0 ? "ant-color-green" : "ant-color-red"}>
            {currencyFormatter(parseInt(text))}
          </span>
        ),
      },
      {
        title: "HA Markup",
        dataIndex: "ha_markup",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) => (
          <span className={text > 0 ? "ant-color-green" : "ant-color-red"}>
            {currencyFormatter(parseInt(text))} ({record.ha_markup_per}%)
          </span>
        ),
      },
      {
        title: "TA Markup",
        dataIndex: "ta_markup",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) => (
          <span className={text > 0 ? "ant-color-green" : "ant-color-red"}>
            {currencyFormatter(parseInt(text))} ({record.ta_markup_per}%)
          </span>
        ),
      },
      {
        title: "QoS",
        dataIndex: "qos",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) =>
          text && (
            <span className={text > 0 ? "ant-color-green" : "ant-color-red"}>
              {currencyFormatter(parseInt(text))} ({record.qos_per}%)
            </span>
          ),
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "x",
        render: (record) => (
          <React.Fragment>
            <Button
              icon={<EditOutlined />}
              className="ant-btn-reset"
              onClick={() => this.editInvoice(record.invoice_id)}
            />
            <Popconfirm
              title="Are you sure want to delete?"
              placement="left"
              okText="Yes"
              cancelText="No"
              onConfirm={() => this.deleteInvoice(record.invoice_id)}
            >
              <Button
                icon={<DeleteOutlined />}
                className="ant-btn-reset"
                style={{ marginLeft: "12px" }}
              />
            </Popconfirm>
          </React.Fragment>
        ),
        align: "center",
      },
    ];

    const filterColumns = [
      { columnName: "project_id", displayName: "Project Id" },
      { columnName: "employee_id", displayName: "Employee" },
      {
        columnName: "customer_name",
        displayName: "Customer Name",
      },
      { columnName: "display_name", displayName: "Display", default: true },
      { columnName: "start_date", displayName: "Start Date", hideFilter: true },
      { columnName: "end_date", displayName: "End Date", hideFilter: true },
      {
        columnName: "proforma_invoice_no",
        displayName: "Proforma No.",
        hideFilter: true,
      },
      {
        columnName: "invoice_amount",
        displayName: "Invoice Amount",
        hideFilter: true,
      },
      {
        columnName: "total_amount",
        displayName: "Invoice Amount with Tax",
        hideFilter: true,
      },
      {
        columnName: "campaign_difference",
        displayName: "Campaign Difference",
        hideFilter: true,
      },
      {
        columnName: "base_rate_diff",
        displayName: "HA Markup",
        hideFilter: true,
      },
      { columnName: "ha_markup", displayName: "TA Markup", hideFilter: true },
      { columnName: "ta_markup", displayName: "QoS", hideFilter: true },
    ];

    this.setState({ columns, columnsDefault: columns, filterColumns });
  };

  // Redirect to Invoice edit Flow
  editInvoice = (id) => {
    this.props.history.push(`/sales-estimate/sales-estimate-edit/${id}`);
  };

  deleteInvoice = (id) => {
    this.setState({ isLoading: true });
    var reqUrl = "salesestimate/invoice/reject";
    var reqObj = {
      request: { invoice_id: Number(id) },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data?.success) {
        message.success("Proforma Invoice deleted successfully");
        const { pagination } = this.state;
        this.getResults({ pagination });
      } else {
        message.error(
          data?.detail || "An error occured while processing your request."
        );
      }
    });
  };

  componentDidMount() {
    // Get List
    const { pagination } = this.state;
    this.getResults({ pagination });
    this.setColumns();
  }

  render() {
    const { data, columns, pagination, loading, filterColumns, screen } =
      this.state;

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageTitle titleText="Proforma Invoices" />

            <ListFilters
              items={filterColumns}
              handleSettings={this.handleSettings}
              handleFilters={this.handleFilters}
              key={filterColumns}
              screen={screen}
            />

            <Table
              columns={columns}
              rowKey={(record) => record.invoice_id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              bordered
              scroll={{ x: 400 }}
              size="small"
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default ProformaInvoices;
