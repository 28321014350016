import React from "react";
import { Button, Layout } from "antd";

const AppFooterPublic = (props) => {
  const { Footer } = Layout;
  return (
    <Footer>
      All Rights Reserved{" "}
      <Button
        type="link"
        onClick={() => window.open("https://www.easyoutdoor.in", "_blank")}
        style={{
          padding: "0px",
          margin: "0px",
          lineHeight: "normal",
          height: "auto",
        }}
      >
        EasyOutdoor
      </Button>
    </Footer>
  );
};

export default AppFooterPublic;
