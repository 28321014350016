import React, { Component } from "react";
import { Button, Checkbox } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { exportPlanToXlsx } from "../../screens/plan/plan-to-xlsx";
import { exportPlanToPPTx } from "../../screens/plan/plan-to-ppt";
import { globalVal } from "../../helpers/global";
import * as dayjs from "dayjs";
import AdminApi from "../../iam/api/admin-api";

class ExportSettings extends Component {
  state = {
    screen: this.props.page + "-export-" + this.props.exportType,
    items: [],
    checkedValues: [],
    settingsKey: 1,
    exportType: this.props.exportType,
    isLoading: false,
  };

  handleCheck = (checkedValues) => {
    this.setState({ checkedValues });
  };

  updateCheck = () => {
    let values = [];
    this.state.checkedValues.map((item) => values.push({ title: item }));
    let { columnsSettings } = this.state;
    columnsSettings[this.state.screen] = values;
    AdminApi.syncColumsSettings({
      data: columnsSettings,
      updatedDate: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
    });

    if (this.state.exportType === "excel") {
      exportPlanToXlsx(
        this.props.results,
        {
          ...this.props.configurationDetails,
          exportName: `${this.props.results.display_name} - ${dayjs().format(
            "Do MMMM YYYY"
          )}.xlsx`,
        },
        this.state.checkedValues
      );
    } else {
      this.setState({ isLoading: true });
      exportPlanToPPTx(
        this.props.results,
        {
          ...this.props.configurationDetails,
          exportName: `${this.props.results.display_name} - ${dayjs().format(
            "Do MMMM YYYY"
          )}.pptx`,
        },
        this.state.checkedValues,
        () => {
          this.setState({ isLoading: false });
        }
      );
    }
  };

  setData = () => {
    let items =
      this.state.exportType === "excel"
        ? [
            { title: "Sr" },
            { title: "Supplier" },
            { title: "Media Type" },
            { title: "Lat" },
            { title: "Long" },
            { title: "IID" },
            { title: "District" },
            { title: "City" },
            { title: "Area" },
            { title: "Location" },
            { title: "W" },
            { title: "H" },
            { title: "Size" },
            { title: "SQFT" },
            { title: "Traffic From" },
            { title: "Traffic To" },
            { title: "Light" },
            { title: "Qty" },
            { title: "Display Qty" },
            { title: "Available From" },
            { title: "Start Date" },
            { title: "End Date" },
            { title: "Days" },
            { title: "Card Rate Per Month" },
            { title: "Card Rate / Unit" },
            { title: "Discounted Monthly Rate" },
            { title: "Discounted Rate / Unit" },
            { title: "Display Cost" },
            { title: "Printing Cost" },
            { title: "Mounting Cost" },
            { title: "Total" },
            { title: "GST (" + globalVal?.gst_per + "%)" },
            { title: "Grand Total" },
            { title: "Current Display" },
            { title: "Employee" },
            { title: "Remarks" },
            { title: "Replace Blocked" },
          ]
        : [
            { title: "Card Rates" },
            { title: "Discounted Rates" },
            { title: "Available From" },
            { title: "Replace Blocked" },
          ];

    this.setState({ items, settingsKey: this.state.settingsKey + 1 });
  };

  componentDidMount() {
    this.setData();
    AdminApi.syncColumsSettings()
      .then((columnsSettings) => {
        this.setState({
          columnsSettings: columnsSettings || {},
          settingsKey: this.state.settingsKey + 1,
        });

        if (this.state.screen && columnsSettings) {
          let colConfig = columnsSettings[this.state.screen]?.map(
            (x) => x.title
          );
          colConfig?.length > 0 && this.setState({ checkedValues: colConfig });
        }
      })
      .catch((nothing) => {});
  }

  render() {
    const {
      items,
      screen,
      columnsSettings,
      settingsKey,
      exportType,
      isLoading,
    } = this.state;
    const settingItems = items.map((x) => x.title);
    let defaultChecked = items.map((x) => x.title);

    if (screen && columnsSettings) {
      let colConfig = columnsSettings[screen]?.map((x) => x.title);
      colConfig?.length > 0 && (defaultChecked = colConfig);
    }

    return (
      <React.Fragment>
        <Checkbox.Group
          options={settingItems}
          defaultValue={defaultChecked}
          onChange={this.handleCheck}
          className="export-checkbox"
          key={settingsKey}
        />
        <div style={{ textAlign: "center" }}>
          <Button
            type="primary"
            onClick={this.updateCheck}
            icon={<SaveOutlined />}
            style={{ marginTop: "24px" }}
            loading={isLoading}
          >
            Download
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

export default ExportSettings;
