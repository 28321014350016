import React, { Component } from "react";
import { Row, Col, Table, Button, Upload, message, Popover } from "antd";
import {
  UploadOutlined,
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
  DownloadOutlined,
  ImportOutlined,
} from "@ant-design/icons";
import PageTitleDetails from "../../components/common/page-title-detail";
import XLSX from "xlsx";
import { ajaxRequester } from "../../services/ajax-requester";
import SampleExcel from "../../assets/samples/import-inventory-sample.xlsx";
import SampleExcelTA from "../../assets/samples/import-ta-inventory-sample.xlsx";

class InventoriesImport extends Component {
  state = {
    count: 0,
    inventoryType: this.props.match.path.includes("ta-")
      ? "tainventory"
      : "hainventory",
  };

  handleUpload = ({ file }) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = (e) => {
      const bufferArray = e.target.result;
      const wb = XLSX.read(bufferArray, { type: "buffer" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const xlsdata = XLSX.utils.sheet_to_json(ws);
      this.validateData(xlsdata);
    };
  };

  validateData = (xlsdata) => {
    let data = xlsdata;

    data = data.map((x) => ({
      ...x,
      isValid: this.checkValid(x),
    }));

    this.setState({ data, isImport: true });
  };

  handleValidate = () => {
    this.setState({ btnLoading: true });
    let data = this.state.data;

    let reqData = data.map((x) => ({
      ...x,
      structure: "S",
      sqft:
        (x?.width || 1) *
        (x?.height || 1) *
        (x?.quantity || 1) *
        (x?.side_type === "double" ? 2 : 1),
      lat_long: { x: x.lat && Number(x.lat), y: x.long && Number(x.long) },
      state: x?.state?.trim(),
      district: x?.district?.trim(),
      city: x?.city?.trim(),
      area: x?.area?.trim(),
    }));

    var reqUrl = this.state.inventoryType + "/import/validate";
    var reqObj = { request: reqData };

    ajaxRequester(reqUrl, reqObj, (res) => {
      res &&
        res?.response.map(
          (x, key) =>
            x.value &&
            x.value.length > 0 &&
            ((data[key].isValid = false), (data[key].error = x.value))
        );

      this.setState({ data, isValidate: true, btnLoading: false });
    });
  };

  handleUploadInventories = () => {
    this.setState({ btnImportLoading: true });

    let data = this.state.data;

    data = data.map((x) => ({
      ...x,
      structure: "S",
      lat_long: { x: x.lat, y: x.long },
      sqft:
        (x?.width || 1) *
        (x?.height || 1) *
        (x?.quantity || 1) *
        (x?.side_type === "double" ? 2 : 1),
      state: x?.state?.trim(),
      district: x?.district?.trim(),
      city: x?.city?.trim(),
      area: x?.area?.trim(),
    }));

    data = data.filter((x) => x.isValid !== false);

    var reqUrl = this.state.inventoryType + "/import";
    var reqObj = { request: data };

    ajaxRequester(reqUrl, reqObj, (res) => {
      res && message.success("Inventories Uploaded Successfully");
      this.setState({
        isImport: false,
        isValidate: false,
        btnImportLoading: false,
      });
    });
  };

  handleUploadRequest = () => {
    let data = this.state.data;

    data = data.map((x) => ({
      ...x,
      inventory_location_id: x.city === "Ahmedabad" ? 1 : 2,
      media_type_id: x.media_type === "Hoarding" ? 1 : 2,
      structure: "S",
      sqft:
        (x?.width || 1) *
        (x?.height || 1) *
        (x?.quantity || 1) *
        (x?.side_type === "double" ? 2 : 1),
      is_active: true,
      lat_long: { x: x.lat, y: x.long },
      state: x?.state?.trim(),
      district: x?.district?.trim(),
      city: x?.city?.trim(),
      area: x?.area?.trim(),
    }));

    this.setState({ req: data, btnLoading: true }, () =>
      this.handleAddInventory()
    );
  };

  // Add Inventory
  handleAddInventory = () => {
    let req = this.state.req;
    let count = this.state.count;
    let length = req.length - 1;

    var reqUrl = this.state.inventoryType + "/create";
    var reqObj = {
      request: req[count],
    };

    if (reqObj.request.isValid) {
      ajaxRequester(reqUrl, reqObj, (res) => {
        let data = this.state.data;
        data.status = res ? true : false;
        count < length &&
          this.setState({ count: count + 1, data }, () =>
            this.handleAddInventory()
          );
        count = length && this.setState({ btnLoading: false });
        count = length && message.success("Inventories Imported Successfully");
      });
    } else {
      count < length &&
        this.setState({ count: count + 1 }, () => this.handleAddInventory());
    }
  };

  checkValid = (record) => {
    let isValid = true;

    if (
      !record.media_type ||
      !record.state ||
      !record.district ||
      !record.city ||
      !record.area ||
      !record.location ||
      !record.width ||
      !record.height ||
      !record.side_type ||
      !record.quantity ||
      !record.light_type ||
      (this.state.inventoryType !== "tainventory" && !record.base_rate) ||
      !record.card_rate
    ) {
      isValid = false;
    }

    return isValid;
  };

  render() {
    const {
      data,
      selectedRowKeys,
      btnLoading,
      btnImportLoading,
      isImport,
      isValidate,
      inventoryType,
    } = this.state;

    const columns = [
      {
        title: "Media Type",
        dataIndex: "media_type",
      },
      {
        title: "State",
        dataIndex: "state",
      },
      {
        title: "District",
        dataIndex: "district",
      },
      {
        title: "City",
        dataIndex: "city",
      },
      {
        title: "Area",
        dataIndex: "area",
      },
      {
        title: "Location",
        dataIndex: "location",
      },
      {
        title: "Width",
        dataIndex: "width",
      },

      {
        title: "Height",
        dataIndex: "height",
      },
      {
        title: "Sqft",
        dataIndex: "sqft",
        render: (text, record) =>
          (record?.width || 1) *
          (record?.height || 1) *
          (record?.quantity || 1) *
          (record?.side_type === "double" ? 2 : 1),
      },
      {
        title: "Side Type",
        dataIndex: "side_type",
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
      },
      {
        title: "Light Type",
        dataIndex: "light_type",
      },
      {
        title: "Base Rate",
        dataIndex: "base_rate",
      },
      {
        title: "Card Rate",
        dataIndex: "card_rate",
      },
      {
        title: "Status",
        dataIndex: "isValid",
        render: (text, record) =>
          text ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : (
            <Popover
              content={
                (record?.error &&
                  record?.error.length > 0 &&
                  record?.error.map((x) => {
                    return <div>{x}</div>;
                  })) ||
                "Required values can not be empty."
              }
              trigger="click"
              title="Error"
              placement="topRight"
            >
              <ExclamationCircleTwoTone twoToneColor="#ff4d4f" />
            </Popover>
          ),
      },
    ];

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageTitleDetails
              titleText={
                "Import" +
                (inventoryType === "tainventory" ? " TA" : " HA") +
                " Inventories"
              }
            />

            <Button
              type="primary"
              style={{
                top: "-62px",
                float: "right",
                marginBottom: "-62px",
                position: "relative",
              }}
              onClick={() =>
                inventoryType === "tainventory"
                  ? window.open(SampleExcelTA)
                  : window.open(SampleExcel)
              }
              icon={<DownloadOutlined />}
              className="icon-btn"
            >
              Download Sample
            </Button>

            <div style={{ display: "flex", marginBottom: "16px" }}>
              <Upload customRequest={this.handleUpload} fileList={[]}>
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>

              {isImport && (
                <Button
                  type="primary"
                  onClick={this.handleValidate}
                  style={{ marginLeft: "16px" }}
                  loading={btnLoading}
                >
                  Validate
                </Button>
              )}

              {isValidate && (
                <Button
                  type="primary"
                  onClick={this.handleUploadInventories}
                  style={{ marginLeft: "16px" }}
                  loading={btnImportLoading}
                  icon={<ImportOutlined />}
                  className="icon-btn"
                >
                  Import Inventories
                </Button>
              )}
            </div>

            <Table
              columns={columns}
              dataSource={data}
              bordered
              scroll={{ x: 400 }}
              size="small"
              pagination={false}
              rowClassName={(record) => !record.isValid && "imp-inv-error"}
              //rowSelection={rowSelection}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default InventoriesImport;
