import React, { Component } from "react";
import { Radio } from "antd";

class TransactionsTabs extends Component {
  state = { options: [], value: 0, estimate_type_list: [] };

  onChange = (e) => {
    this.setState(
      {
        value: Number(e.target.value),
      },
      () => this.props.handleTabs(e.target.value)
    );
  };

  setTabs = () => {
    const { estimate_type_list } = this.props;

    let totalCount = 0;
    estimate_type_list &&
      estimate_type_list.map((x) => (totalCount = x.total_count + totalCount));

    let options = [{ label: "All (" + totalCount + ")", value: 0 }];

    estimate_type_list &&
      estimate_type_list.map((x) =>
        options.push({
          label: x.estimate_type + " (" + x.total_count + ")",
          value: Number(x?.estimate_type_id),
        })
      );

    this.setState({ options });
  };

  componentDidMount() {
    const { activeTab } = this.props;
    this.setState({ value: activeTab || 0 }, () => this.setTabs());
  }

  render() {
    const { options, value } = this.state;

    return (
      <div className="operation-tabs">
        <Radio.Group
          options={options}
          onChange={this.onChange}
          value={value}
          optionType="button"
          buttonStyle="solid"
          loading={true}
        />
      </div>
    );
  }
}

export default TransactionsTabs;
