import React, { Component } from "react";
import { Modal } from "antd";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "../../assets/css/leaflet.css";
import MapSearchBox from "../../components/common/map-searchbox";

class MapLatLng extends Component {
  state = {
    defaultPosition: {
      zoom: 8,
      center: {
        lat: 22.8587,
        lng: 72.3924,
      },
    },
    visible: true,
    currentPos: null,
  };

  handleOk = () => {
    this.setState({ visible: false });
    this.props.setLatLong(this.state.currentPos);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleClick = (e) => {
    this.setState({ currentPos: e.latlng });
  };

  componentDidMount() {
    const { latlng } = this.props;

    latlng &&
      latlng.lat &&
      latlng.lng &&
      this.setState({
        currentPos: { lat: latlng.lat, lng: latlng.lng },
        defaultPosition: {
          zoom: 16,
          center: {
            lat: latlng.lat,
            lng: latlng.lng,
          },
        },
      });
  }

  render() {
    const { defaultPosition, visible, currentPos } = this.state;

    return (
      <Modal
        centered
        title="Select Latitude and Longitude"
        open={visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        width={920}
      >
        <div
          style={{
            width: "100%",
            height: "400px",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Map
            center={defaultPosition.center}
            zoom={defaultPosition.zoom}
            onclick={this.handleClick}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

            {currentPos && (
              <Marker position={currentPos}>
                <Popup>
                  <div>lat : {currentPos.lat}</div>
                  <div>lng : {currentPos.lng}</div>
                </Popup>
              </Marker>
            )}

            <MapSearchBox />
          </Map>
        </div>
      </Modal>
    );
  }
}

export default MapLatLng;
