import React, { Component } from "react";
import { ajaxRequester } from "../../services/ajax-requester";
import { Button, Form, Input, Select, Switch, Row, Col } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

class AddEstimateType extends Component {
  state = {};

  formRef = React.createRef();

  setDefaultData = () => {
    const { record } = this?.props;

    this.formRef.current.setFieldsValue({
      is_active: record?.is_active,
      estimate_type: record?.estimate_type,
    });
  };

  ValuesChange = (value, values) => {};

  onFinish = (values) => {
    const { isEditMode, record } = this.props;
    isEditMode && (values.estimate_type_id = record?.estimate_type_id);
    this.props.handleAddEdit(values);
  };

  componentDidMount() {
    const { isEditMode } = this.props;
    isEditMode && this.setDefaultData();
  }

  render() {
    const { isEditMode } = this?.props;

    const layout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 16 },
    };

    const { record } = this.props;

    return (
      <Form
        name="payee_add"
        className="payee_add"
        {...layout}
        onFinish={this.onFinish}
        onValuesChange={this.ValuesChange}
        ref={this.formRef}
        initialValues={{
          is_active: true,
        }}
      >
        <Form.Item
          label="Estimate Type Status"
          name="is_active"
          valuePropName="checked"
        >
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>

        <Form.Item
          label="Expense Type*"
          name="estimate_type"
          rules={[
            {
              required: true,
              message: "Please Input Expense Type* !",
            },
          ]}
        >
          <Input placeholder="Expense Type" />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 7 }} className="model-btns">
          <Button type="primary" htmlType="submit">
            {isEditMode ? "Update Estimate Type" : "Add Estimate Type"}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default AddEstimateType;
