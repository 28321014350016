import { Component } from "react";
import { ajaxRequester } from "../services/ajax-requester";
import { message } from "antd";

class InventoriesBase extends Component {
  state = {};

  // Add Inventory
  handleAddInventory = (data) => {
    var reqUrl = !this.state.isGroup
      ? this.state.inventoryType + "/create"
      : this.state.inventoryType !== "tainventory"
      ? "hagroupinventory/create"
      : "tagroupinventory/create";
    var reqObj = {
      request: {
        media_type_id: Number(data.media_type),
        media_type: this.getMediaType(data.media_type),
        structure: data.structure,
        inventory_location_id: data.inventory_location_id,
        location: data.location,
        traffic_facing_from: data.traffic_facing_from,
        traffic_going_to: data.traffic_going_to,
        description: data.description,
        width: data.width,
        height: data.height,
        sqft: data.sqft,
        side_type: data.side_type,
        quantity: data.quantity,
        light_type: data.light_type,
        base_rate: data.base_rate || 0,
        card_rate: data.card_rate,
        images: [
          {
            image_name: "test.jpg",
            image_type: "I",
          },
        ],
        is_active: data.is_active,
        lat_long: {
          x: data.lat_long_x && Number(data.lat_long_x),
          y: data.lat_long_y && Number(data.lat_long_y),
        },
        created_by: data.created_by,
        ha_base_inventories: data.ha_base_inventories,
        ha_group_inventories: data.ha_group_inventories,
        supplier_id: data?.supplier_id,
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data) {
        if (this.state?.uploadImages && this.state?.uploadImages.length > 0) {
          data?.inventory_id && this.inventoryUploadImages(data?.inventory_id);
        } else {
          this.setState({
            isSuccess: true,
            inventoryKey: this.state.inventoryKey + 1,
          });
        }
      } else {
        this.setState({ isSuccess: true });
      }
    });
  };

  // Edit Inventory
  handleUpdateInventory = (data) => {
    var reqUrl = !this.state.isGroup
      ? this.state.inventoryType + "/update"
      : this.state.inventoryType !== "tainventory"
      ? "hagroupinventory/update"
      : "tagroupinventory/update";

    var reqObj = {
      request: {
        ha_inventory_id: Number(this.props.match.params.id),
        media_type_id: this.getMediaTypeId(data.media_type),
        media_type: data.media_type,
        structure: data.structure,
        inventory_location_id: data.inventory_location_id,
        location: data.location,
        traffic_facing_from: data.traffic_facing_from,
        traffic_going_to: data.traffic_going_to,
        description: data.description,
        width: data.width || 0,
        height: data.height || 0,
        sqft: data.sqft,
        side_type: data.side_type,
        quantity: data.quantity,
        light_type: data.light_type,
        base_rate: data.base_rate || 0,
        card_rate: data.card_rate,
        images: [
          {
            image_name: "test.jpg",
            image_type: "I",
          },
        ],
        is_active: data.is_active,
        lat_long: {
          x: (data.lat_long_x && Number(data.lat_long_x)) || 0,
          y: (data.lat_long_y && Number(data.lat_long_y)) || 0,
        },
        created_by: data.created_by,
        ha_base_inventories: data.ha_base_inventories,
        ha_group_inventories: data.ha_group_inventories,
        supplier_id: data?.supplier_id,
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data?.success) {
        this.setState({
          isSuccess: true,
          inventoryKey: this.state.inventoryKey + 1,
        });
      } else {
        message.error(data?.detail || "Something went wrong.");
        this.setState({
          inventoryKey: this.state.inventoryKey + 1,
        });
      }
    });
  };

  // Get Media type id based on Media type
  getMediaType = (media_type_id) => {
    let mediaType = this.state.mediaTypeList.find(
      (x) => x.media_type_id === media_type_id
    ).media_type;

    return mediaType;
  };

  // Get Media type based on Media type id
  getMediaTypeId = (media_type) => {
    let mediaTypeId = this.state.mediaTypeList.find(
      (x) => x.media_type === media_type
    ).media_type_id;

    return mediaTypeId;
  };

  // Get Media Type List from Lookup
  getMediaTypeList = () => {
    let reqUrl = "lookup/mediatype";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ mediaTypeList: data.response });
      },
      "GET"
    );
  };

  // Get Supplier List from Lookup
  getSupplierList = () => {
    let reqUrl = "supplier/lookup";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ supplierList: data.response });
      },
      "GET"
    );
  };

  // Get All Lookup Data
  getLookupData = () => {
    this.getMediaTypeList();
  };

  // Redirect to Inventory List
  handleBack = () => {
    this.props.history.goBack();
  };

  handleUploadImages = (uploadImages) => {
    this.setState({ uploadImages });
  };

  inventoryUploadImages = (reference_id) => {
    let uploadImages = this.state.uploadImages;

    uploadImages = uploadImages.map((x) => ({
      request: {
        ...x,
        reference_id: reference_id,
      },
    }));

    let reqUrl = "hainventory/photo/upload";
    uploadImages.map((reqObj, key) =>
      ajaxRequester(reqUrl, reqObj, () => {
        if (key + 1 === uploadImages.length) {
          this.setState({
            isSuccess: true,
            inventoryKey: this.state.inventoryKey + 1,
          });
        }
      })
    );
  };
}

export default InventoriesBase;
