import React, { Component } from "react";
import { ajaxRequester } from "../../services/ajax-requester";
import { Button, Form, Input, Select, Switch, Row, Col } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

class AddSisterCompany extends Component {
  state = {};

  formRef = React.createRef();

  setDefaultData = () => {
    const { record } = this?.props;

    this.formRef.current.setFieldsValue({
      is_active: record?.is_active,
      sister_company_name: record?.sister_company_name,
      employee_name: record?.employee_name,
      phone: record?.phone,
      email: record?.email,
      pan_no: record?.pan_no,
      gst_no: record?.gst_no,
      address: record?.address,
    });
  };

  ValuesChange = (value, values) => {};

  onFinish = (values) => {
    const { isEditMode, record } = this.props;
    values.gst_no = values?.gst_no || " ";
    isEditMode && (values.sister_company_id = record?.sister_company_id);

    this.props.handleAddEdit(values);
  };

  componentDidMount() {
    const { isEditMode } = this.props;
    isEditMode && this.setDefaultData();
  }

  render() {
    const { isEditMode } = this?.props;
    const { Option } = Select;

    const layout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 16 },
    };

    const { record } = this.props;

    return (
      <Form
        name="payee_add"
        className="payee_add"
        {...layout}
        onFinish={this.onFinish}
        onValuesChange={this.ValuesChange}
        ref={this.formRef}
        initialValues={{
          is_active: true,
        }}
      >
        <Form.Item
          label="Company Status"
          name="is_active"
          valuePropName="checked"
        >
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>

        <Form.Item
          label="Company Name"
          name="sister_company_name"
          rules={[
            {
              required: true,
              message: "Please Input Company Name !",
            },
          ]}
        >
          <Input placeholder="Company Name" />
        </Form.Item>

        <Form.Item label="Accountant Name" name="employee_name">
          <Input placeholder="Accountant Name" />
        </Form.Item>

        <Form.Item label="Accountant Number" name="phone">
          <Input placeholder="Accountant Number" />
        </Form.Item>

        <Form.Item label="Accountant Email" name="email">
          <Input placeholder="Accountant Email" />
        </Form.Item>

        <Form.Item label="PAN Number" name="pan_no">
          <Input placeholder="PAN Number" minLength={10} maxLength={10} />
        </Form.Item>

        <Form.Item label="GST Number" name="gst_no">
          <Input placeholder="GST Number" />
        </Form.Item>

        <Form.Item label="Address" name="address">
          <Input placeholder="Address" />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 7 }} className="model-btns">
          <Button type="primary" htmlType="submit">
            {isEditMode ? "Update Sister Company" : "Add Sister Company"}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default AddSisterCompany;
