import React from "react";
import {
  Row,
  Col,
  Table,
  Button,
  Menu,
  Dropdown,
  Modal,
  Drawer,
  message,
} from "antd";
import {
  DeleteOutlined,
  CopyOutlined,
  ShareAltOutlined,
  EllipsisOutlined,
  UnorderedListOutlined,
  StopOutlined,
  CheckCircleOutlined,
  ExportOutlined,
  MergeCellsOutlined,
  TrademarkCircleTwoTone,
} from "@ant-design/icons";
import PageTitle from "../../components/common/page-title";
import ListFilters from "../../components/common/list-filters";
import CommonListBase from "../../base/common-list-base";
import PlanActions from "../../components/plan/plan-actions";
import { ajaxRequester } from "../../services/ajax-requester";
import {
  currencyFormatter,
  dateFormatter,
} from "../../components/common/common-formatter";
import PortalUser from "../../iam/components/portal-user";
import { Link } from "react-router-dom";
import { RolesPermissions } from "../../helpers/roles-permissions";
import PlansMerge from "../../components/plan/plans-merge";

class Plans extends CommonListBase {
  state = {
    listReq: "plan/list?employeeid=1&",
    delReq: "plan/delete?planId=",
    dtlReq: "../plans/plan-details/",
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    sorting: [],
    isModal: false,
    record: "",
    screen: "plans",
    isDrawer: false,
  };

  handleMenuClick = ({ key }, record) => {
    record.action = key;

    switch (key) {
      case "delete":
        record.title = "Delete Plan";
        break;

      case "block":
        record.title = "Block Plan";
        break;

      case "unblock":
        record.title = "Unblock Plan";
        break;

      case "copy":
        record.title = "Copy Plan";
        break;

      case "share":
        record.title = "Share Plan";
        break;

      case "activity":
        record.title = "Plan Activities";
        this.getActivityLog(record.plan_id);
        break;

      case "merge":
        record.title = "Merge Plans";
        break;

      case "publiclink":
        window.open("/#/plans/plan-view/" + record.publish_id, "_blank");
        break;

      default:
        break;
    }

    key !== "publiclink" && this.setState({ record }, () => this.handleModal());
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "Project Id",
        dataIndex: "project_id",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 100,
      },
      {
        title: "Employee",
        dataIndex: "employee_id",
        render: (text) => <PortalUser user={text} />,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 140,
      },
      {
        title: "Customer Name",
        dataIndex: "customer_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 140,
      },
      {
        title: "Display",
        dataIndex: "display_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) => (
          <Link
            style={{ padding: "0px", whiteSpace: "normal", textAlign: "left" }}
            type="link"
            to={this.state.dtlReq + record.plan_id}
          >
            {text}
            {record?.is_rotational && (
              <TrademarkCircleTwoTone
                twoToneColor="#52c41a"
                style={{ marginLeft: "8px" }}
              />
            )}
          </Link>
        ),
      },
      {
        title: "From",
        dataIndex: "start_date",
        render: (text) => dateFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "To",
        dataIndex: "end_date",
        render: (text) => dateFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Days",
        dataIndex: "no_of_days",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "SQFT",
        dataIndex: "total_sqft",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Amount",
        dataIndex: "total_amount",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: "QoS",
        dataIndex: "base_rate_diff",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => (
          <span className={text > 0 ? "ant-color-green" : "ant-color-red"}>
            {text}%
          </span>
        ),
      },
      {
        title: "Status",
        dataIndex: "plan_status",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => (text === "B" ? "Blocked" : "-"),
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "x",
        render: (record) => (
          <div style={{ textAlign: "center" }}>
            <Dropdown
            menu={
              {
                onClick: (key) => this.handleMenuClick(key, record),
                items: [
                  {
                    key: "delete",
                    icon: <DeleteOutlined />,
                    label: "Delete"
                  },
                  ...(this?.state?.configurationDetails?.configuration_details
                    ?.plan_settings?.enable_blocking
                    ? [
                        ...(record.plan_status !== "B"
                          ? [
                              {
                                key: "block",
                                icon: <StopOutlined />,
                                label: "Block"
                              }
                            ]
                          : []),
                        ...(record.plan_status === "B"
                          ? [
                              {
                                key: "unblock",
                                icon: <CheckCircleOutlined />,
                                label: "Unblock"
                              }
                            ]
                          : [])
                      ]
                    : []),
                  {
                    key: "copy",
                    icon: <CopyOutlined />,
                    label: "Copy"
                  },
                  {
                    key: "share",
                    icon: <ShareAltOutlined />,
                    label: "Share"
                  },
                  {
                    key: "activity",
                    icon: <UnorderedListOutlined />,
                    label: "Activity"
                  },
                  {
                    key: "publiclink",
                    icon: <ExportOutlined />,
                    label: "Public Link"
                  }
                ]
              }
            }
              // overlay={
              //   <Menu onClick={(key) => this.handleMenuClick(key, record)}>
              //     <Menu.Item key="delete" icon={<DeleteOutlined />}>
              //       Delete
              //     </Menu.Item>

              //     {this?.state?.configurationDetails?.configuration_details
              //       ?.plan_settings?.enable_blocking && (
              //       <React.Fragment>
              //         {record.plan_status !== "B" && (
              //           <Menu.Item key="block" icon={<StopOutlined />}>
              //             Block
              //           </Menu.Item>
              //         )}
              //         {record.plan_status === "B" && (
              //           <Menu.Item key="unblock" icon={<CheckCircleOutlined />}>
              //             Unblock
              //           </Menu.Item>
              //         )}
              //       </React.Fragment>
              //     )}

              //     <Menu.Item key="copy" icon={<CopyOutlined />}>
              //       Copy
              //     </Menu.Item>
              //     <Menu.Item key="share" icon={<ShareAltOutlined />}>
              //       Share
              //     </Menu.Item>
              //     <Menu.Item key="activity" icon={<UnorderedListOutlined />}>
              //       Activity
              //     </Menu.Item>
              //     <Menu.Item key="publiclink" icon={<ExportOutlined />}>
              //       Public Link
              //     </Menu.Item>
              //   </Menu>
              // }
              placement="bottomRight"
              trigger={["click"]}
            >
              <Button className="ant-btn-reset">
                <EllipsisOutlined />
              </Button>
            </Dropdown>
          </div>
        ),
      },
    ];

    const filterColumns = [
      { columnName: "project_id", displayName: "Project Id" },
      { columnName: "employee_id", displayName: "Employee" },
      {
        columnName: "customer_name",
        displayName: "Customer Name",
      },
      { columnName: "display_name", displayName: "Display", default: true },
      { columnName: "start_date", displayName: "From" },
      { columnName: "end_date", displayName: "To" },
      { columnName: "no_of_days", displayName: "Days" },
      { columnName: "total_sqft", displayName: "SQFT" },
      { columnName: "total_amount", displayName: "Amount" },
      { columnName: "base_rate_diff", displayName: "QoS" },
      { columnName: "plan_status", displayName: "Status" },
    ];

    this.setState({ columnsDefault: columns, filterColumns }, () =>
      this.setDefaultColumns(columns)
    );
  };

  // Handle Popup
  handleModal = () => {
    this.setState({ isModal: !this.state.isModal });
  };

  // Handle Popup Actions
  handleActions = (data) => {
    this.handleModal();
    const { action, plan_id } = this.state.record;
    action === "delete" && this.deleteItem(plan_id);
    action === "block" && this.blockUnblockItem(action, plan_id);
    action === "unblock" && this.blockUnblockItem(action, plan_id);
    action === "copy" && this.planActions(action, plan_id, data);
    action === "share" && this.planActions(action, plan_id, data);
    action === "merge" && this.handleDrawer(data);
  };

  // Plan Actions
  planActions = (action, plan_id, data) => {
    var reqUrl = "plan/" + action;
    var reqObj = {
      request: {
        plan_id: plan_id,
        display_name: data.display_name,
        shared_with_employee_id: data.employee_id,
        copy_documents: data.copy_documents,
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data?.success) {
        message.success(
          "Successfully " +
            (action === "copy"
              ? "Copied"
              : action === "share"
              ? "Shared"
              : "Merged")
        );
        const { pagination } = this.state;
        this.getResults({ pagination });
      } else {
        message.error(data?.detail || "Something went wrong.");
      }
    });
  };

  // Plan Block Unblock
  blockUnblockItem = (action, plan_id) => {
    var reqUrl = "plan/" + action + "?planid=" + plan_id;
    var reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      () => {
        const { pagination } = this.state;
        this.getResults({ pagination });
      },
      "GET"
    );
  };

  // Get Plan Activities
  getActivityLog = (plan_id) => {
    this.setState({ activityLog: "" });

    var reqUrl = "plan/activitylog?planid=" + plan_id;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ activityLog: data.response });
      },
      "GET"
    );
  };

  // Add Plan
  addPlan = () => {
    this.props.history.push(`/inventories/inventories-master`);
  };

  // Setup Background Colors
  setColors = (record) => {
    return record.plan_status === "B" && "row-bg-orange";
  };

  // Check Box Selection
  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys, selectedRows });
  };

  handleDrawer = (data) => {
    this.setState({ mergePlan: data, isDrawer: !this.state.isDrawer });
  };

  handleMergePlans = () => {
    this.handleDrawer();
    const { pagination } = this.state;
    this.getResults({ pagination });
  };

  // Gettng Seller Details
  getConfigurationDetails = () => {
    let reqUrl = "configuration/detail";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ configurationDetails: data });
      },
      "GET"
    );
  };

  componentDidMount() {
    // Get List
    const { pagination } = this.state;
    this.getResults({ pagination });
    this.setColumns();
    this.getConfigurationDetails();
  }

  render() {
    const {
      data,
      columns,
      pagination,
      loading,
      filterColumns,
      isModal,
      record,
      screen,
      selectedRowKeys,
      isDrawer,
    } = this.state;

    const rowSelection = {
      selectedRowKeys,
      preserveSelectedRowKeys: true,
      onChange: this.onSelectChange,

      getCheckboxProps: (record) => ({
        disabled: record.is_read,
        is_read: record.is_read,
      }),
    };

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageTitle
              titleText="Plan List"
              isAddBtn={
                (RolesPermissions("ad") || RolesPermissions("pl")) && "Add Plan"
              }
              handleAdd={this.addPlan}
            />

            <ListFilters
              items={filterColumns}
              handleSettings={this.handleSettings}
              handleFilters={this.handleFilters}
              key={filterColumns}
              screen={screen}
            />

            <div
              style={{
                top: "-48px",
                float: "right",
                right: "48px",
                marginBottom: "-48px",
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
              class="title-btns"
            >
              {this.state?.selectedRowKeys?.length > 1 && (
                <Button
                  onClick={() =>
                    this.handleMenuClick({ key: "merge" }, { action: "merge" })
                  }
                  icon={<MergeCellsOutlined />}
                >
                  Merge Plans
                </Button>
              )}
            </div>

            <Table
              columns={columns}
              rowKey={(record) => record.plan_id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              bordered
              scroll={{ x: 400 }}
              size="small"
              rowClassName={(record) => this.setColors(record)}
              rowSelection={rowSelection}
            />
          </Col>
        </Row>

        <Modal
          centered
          title={record.title}
          open={isModal}
          onCancel={this.handleModal}
          width={record.action === "delete" ? 280 : 400}
          footer={false}
        >
          <PlanActions
            key={isModal}
            {...record}
            {...this.state}
            handleActions={this.handleActions}
          />
        </Modal>

        <Drawer
          title={"Merge Plans"}
          placement="right"
          open={isDrawer}
          onClose={this.handleDrawer}
          width={"90%"}
          key={isDrawer}
        >
          <PlansMerge
            key={isDrawer}
            {...this.state}
            handleActions={this.handleMergePlans}
          />
        </Drawer>
      </React.Fragment>
    );
  }
}

export default Plans;
