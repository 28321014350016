import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  InputNumber,
  DatePicker,
  Card,
  Typography,
} from "antd";
import * as dayjs from "dayjs";
import UploadFileLocal from "../../components/common/upload-file-local";

class TransactionsPayment extends Component {
  state = {};

  formRef = React.createRef();

  ValuesChange = (value, values) => {
    const { results } = this.props;
    if (value["payment_amount"] || value["gst_amount"] || value["tds_amount"]) {
      let total =
        (values["payment_amount"] || 0) +
        (values["gst_amount"] || 0) -
        (values["tds_amount"] || 0);

      this.formRef.current.setFieldsValue({
        net_amount: total,
        amount_difference:
          values["payment_amount"] - (parseInt(results?.payout_amount) || 0),
      });
    }
  };

  setTransactionDetails = (data) => {
    const { results } = data;
    const { payment_detail, payout_amount } = results;

    this.formRef.current.setFieldsValue({
      payment_amount: payment_detail?.payment_amount || 0,
      gst_amount: payment_detail?.gst_amount || 0,
      tds_amount: payment_detail?.tds_amount || 0,
      net_amount: payment_detail?.net_amount || 0,
      amount_difference:
        parseInt(payout_amount - payment_detail?.payment_amount) || 0,
      cheque_no: payment_detail?.cheque_no || "",
      cheque_date: dayjs(payment_detail?.cheque_date),
      remarks: payment_detail?.remarks || "",
    });
  };

  handleDocument = (uploaded_file) => {
    this.props.handleUploadFile(uploaded_file);
  };

  componentDidMount() {
    this.props.mode === "update" && this.setTransactionDetails(this.props);
  }

  render() {
    const { results, mode } = this.props;
    const { Title } = Typography;
    const { TextArea } = Input;
    const layout = {
      labelCol: { span: 12 },
      wrapperCol: { span: 12 },
    };
    const layout1 = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };
    const layout2 = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };
    return (
      <Form
        name="transactions_add"
        className="transactions_add"
        {...layout}
        onFinish={this.props.handlePayment}
        onValuesChange={this.ValuesChange}
        ref={this.formRef}
        initialValues={{
          is_active: true,
          cheque_date: dayjs(),
        }}
        style={{ marginTop: "16px", marginBottom: "24px" }}
      >
        <Card
          title={
            <Title level={5} style={{ margin: "12px 0px" }}>
              Transaction Details
            </Title>
          }
          size="small"
          extra={
            <React.Fragment>
              <Button
                type="primary"
                style={{ marginRight: "16px" }}
                onClick={() => this.props.handleActivityLog()}
              >
                Activity Log
              </Button>
              <Button type="primary" htmlType="submit">
                {mode === "add" ? "Save" : "Update"}
              </Button>
            </React.Fragment>
          }
          bodyStyle={{ padding: "24px 24px 0px 24px" }}
        >
          <Row>
            <Col span={18} xs={24} xl={18}>
              <Row>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item label="Amount Paid" name="payment_amount">
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      placeholder="Amount Paid"
                    />
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item label="GST" name="gst_amount">
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      placeholder="GST"
                    />
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item label="TDS" name="tds_amount">
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      placeholder="TDS"
                    />
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item label="Net Payout" name="net_amount">
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      placeholder="Net Payout"
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item label="Difference" name="amount_difference">
                    <InputNumber
                      style={{ width: "100%" }}
                      placeholder="Difference"
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item label="Date" name="cheque_date">
                    <DatePicker placeholder="Date" />
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item label="Instrument Number" name="cheque_no">
                    <Input placeholder="Instrument Number" />
                  </Form.Item>
                </Col>
                <Col span={16} xs={24} xl={16}>
                  <Form.Item
                    label="Payout Invoice"
                    name="uploaded_file"
                    {...layout2}
                  >
                    {(mode === "add" || results) && (
                      <UploadFileLocal
                        handleUpload={this.handleDocument}
                        document_type={"1"}
                        mode={mode}
                        existingFile={results?.payment_detail?.payout_invoice}
                        deletePath={
                          "scheduledtransaction/payment/document/delete?paymentDetailId=" +
                          results?.payment_detail?.payment_detail_id
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={6} xs={24} xl={6}>
              <Form.Item label="Remarks" name="remarks" {...layout1}>
                <TextArea rows={3} placeholder="Remarks" />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    );
  }
}

export default TransactionsPayment;
