import React, { Component } from "react";
import {
  Form,
  Select,
  Button,
  Input,
  DatePicker,
  InputNumber,
  Row,
  Col,
} from "antd";
import * as dayjs from "dayjs";
import UserPickerDropDown from "../../iam/components/user-picker-dropdown";
import { ajaxRequester } from "../../services/ajax-requester";
import { RolesPermissions } from "../../helpers/roles-permissions";

class PlanBusiness extends Component {
  state = { loading: false, customersList: [], isDateDisabled: false };

  formRef = React.createRef();

  // Set Data if Edit Mode
  setData = () => {
    const data = this.props.results;
    let display_name = data.display_name;
    let dates = [
      dayjs(data.start_date, "YYYY/MM/DD"),
      dayjs(data.end_date, "YYYY/MM/DD"),
    ];
    let days = data.no_of_days;
    let employee_id = data.employee_id;
    let customer_id = data.customer_name || "";

    this.formRef.current.setFieldsValue({
      display_name,
      dates,
      days,
      employee_id,
      customer_id,
    });
  };

  onFinish = (values) => {
    const { customersList } = this.state;

    let data = this.props.results;
    data.display_name = values.display_name;
    data.employee_id = values.employee_id;
    data.customer_id =
      customersList.find((x) => x?.customer_name === values?.customer_id)
        ?.customer_id || 0;
    data.start_date = values.dates[0].format("YYYY-MM-DDTHH:mm:ss");
    data.end_date = values.dates[1].format("YYYY-MM-DDTHH:mm:ss");
    data.no_of_days = values.days;
    data.isDatesChanged = this.checkDatesChanged(values);
    this.setState({ loading: true });
    this.props.handleBusiness(data);
  };

  checkDatesChanged = (values) => {
    const dates = this.state.dates;
    if (
      dayjs(dates.start_date).format("YYYY-MM-DD") !==
      values.dates[0].format("YYYY-MM-DD")
    ) {
      return true;
    } else if (
      dayjs(dates.end_date).format("YYYY-MM-DD") !==
      values.dates[1].format("YYYY-MM-DD")
    ) {
      return true;
    } else {
      return false;
    }
  };

  ValuesChange = (value, values) => {
    if (value["dates"]) {
      let days = values["dates"][1].diff(values["dates"][0], "days") + 1;
      this.formRef.current.setFieldsValue({
        days,
      });
    }

    if (value["days"]) {
      this.formRef.current.setFieldsValue({
        dates: [
          dayjs(values["dates"][0], "YYYY/MM/DD"),
          dayjs(values["dates"][0], "YYYY/MM/DD").add(
            values["days"] - 1,
            "days"
          ),
        ],
      });
    }

    if (value["employee_id"]) {
      this.formRef.current.setFieldsValue({
        customer_id: "",
      });
      this.getCustomersByEmployee(values["employee_id"]);
    }
  };

  // Get Customer List
  getCustomersByEmployee = (employee_id) => {
    let reqUrl = "customer/lookup?employeeid=" + employee_id;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          customersList: data.response,
        });
      },
      "GET"
    );
  };

  checkDateDisabled = () => {
    let isDateDisabled = false;
    const { page, results } = this.props;

    if (page === "campaign") {
      let inventoryStatus = results.campaign_inventory_details.map(
        (x) => x.campaign_status
      );

      (inventoryStatus.includes("Running") ||
        inventoryStatus.includes("Completed")) &&
        (isDateDisabled = true);
    }

    this.setState({ isDateDisabled });
  };

  disabledDate = (current) => {
    return current && current < dayjs().startOf("day");
  };

  componentDidMount() {
    this.setData();
    const dates = {
      start_date: this?.props?.results?.start_date,
      end_date: this?.props?.results?.end_date,
    };
    this.getCustomersByEmployee(this?.props?.results?.employee_id);
    this.setState({ dates });
    this.checkDateDisabled();
  }

  render() {
    const { loading, customersList, isDateDisabled } = this.state;
    const { results, page } = this.props;
    const { employee_id } = results;

    const layout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
    };

    const { Option } = Select;

    const { RangePicker } = DatePicker;

    return (
      <Form
        name="plan_business"
        {...layout}
        onFinish={this.onFinish}
        className="plan_business"
        onValuesChange={this.ValuesChange}
        ref={this.formRef}
      >
        {/* {RolesPermissions("ad") && ( */}
        <Form.Item
          name="employee_id"
          label="Employee Name"
          style={{ display: RolesPermissions("ad") ? "" : "none" }}
        >
          <UserPickerDropDown user={employee_id} />
        </Form.Item>
        {/* )} */}

        <Form.Item name="customer_id" label="Customer Name">
          <Select
            placeholder="Customer Name"
            allowClear={page === "plan" ? true : false}
            showSearch={true}
          >
            {customersList.map((x) => (
              <Option key={x.customer_name} value={x.customer_name}>
                {x.customer_name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="display_name"
          label="Display Name"
          rules={[
            {
              required: true,
              pattern: new RegExp(/^[^/]*$/),
              message: "Please Input Display Name ! ( / not allowed)",
            },
          ]}
        >
          <Input placeholder="Display Name" />
        </Form.Item>

        <Row>
          <Col span="18">
            <Form.Item
              name="dates"
              label="Dates"
              rules={[{ required: true, message: "Please Input Dates !" }]}
              {...{
                labelCol: { span: 8 },
                wrapperCol: { span: 16 },
              }}
            >
              <RangePicker
                allowClear={false}
                disabled={isDateDisabled}
                disabledDate={this.disabledDate}
                inputReadOnly={true}
              />
            </Form.Item>
          </Col>

          <Col span="4">
            <Form.Item
              name="days"
              wrapperCol={{ offset: 2 }}
              className="without-label"
            >
              <InputNumber
                min={1}
                style={{ width: "100%" }}
                placeholder="Days"
                disabled={isDateDisabled}
              />
            </Form.Item>
          </Col>
        </Row>

        {isDateDisabled && (
          <Row>
            <Col span="18" offset="6" xs={24} xl={18}>
              <i
                style={{
                  display: "block",
                  marginTop: "-8px",
                  marginBottom: "16px",
                }}
              >
                You cannot change dates, once sites are running or completed
              </i>
            </Col>
          </Row>
        )}

        <Form.Item wrapperCol={{ offset: 6 }} className="model-btns">
          <Button type="primary" htmlType="submit" loading={loading}>
            Update Business
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default PlanBusiness;
