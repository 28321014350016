import React from "react";
import { Table, Radio } from "antd";
import { ajaxRequester } from "../../services/ajax-requester";
import ListFilters from "../../components/common/list-filters";
import CommonListBase from "../../base/common-list-base";
import {
  currencyFormatter,
  dateFormatter,
} from "../../components/common/common-formatter";

class InvoiceAddList extends CommonListBase {
  state = {
    listReq:
      "salesestimate/unbilleditems/rentallist?campaignid=" +
      this.props.id +
      "&",
    delReq: "salesestimate/delete?campaignid=",
    dtlReq: "/sales-estimate/sales-estimate-edit",
    data: [],
    pagination: {
      current: 1,
      pageSize: 100,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    sorting: [],
    options: [],
    active: "Rent",
    selectedRowKeys: [],
    selectedRows: [],
    screen: "sales-estimate-add",
  };

  // Setting Columns
  setColumns = () => {
    const { active } = this.state;

    let columns = [
      {
        title: "IID",
        dataIndex: "inventory_code",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 140,
      },
      {
        title: "District",
        dataIndex: "district",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "City",
        dataIndex: "city",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Location",
        dataIndex: "location",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) =>
          text +
          (record?.quantity && record?.quantity > 1
            ? " (Qty : " +
              record?.quantity +
              " / Display : " +
              (record?.side_type === "double"
                ? record?.quantity * 2
                : record?.quantity) +
              ")"
            : ""),
      },
      {
        title: "Size",
        dataIndex: "size",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Start Date",
        dataIndex: "start_date",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 102,
        render: (text) => dateFormatter(text),
      },
      {
        title: "End Date",
        dataIndex: "end_date",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 102,
        render: (text) => dateFormatter(text),
      },
      {
        title: "Days",
        dataIndex: "no_of_days",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 72,
      },
      {
        title: "Monthly Rate",
        dataIndex: "monthly_rate",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 124,
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: "Cost",
        dataIndex:
          active === "Rent"
            ? "rental_cost"
            : active === "Print"
            ? "printing_cost"
            : "installing_cost",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 124,
        render: (text) => currencyFormatter(parseInt(text)),
      },
    ];

    const filterColumns = [
      { columnName: "inventory_code", displayName: "IID" },
      { columnName: "district", displayName: "District" },
      { columnName: "city", displayName: "City" },
      { columnName: "location", displayName: "Location", default: true },
      { columnName: "size", displayName: "Size", hideFilter: true },
      { columnName: "start_date", displayName: "Start Date", hideFilter: true },
      { columnName: "end_date", displayName: "End Date", hideFilter: true },
      { columnName: "no_of_days", displayName: "Days", hideFilter: true },
      { columnName: "rental_cost", displayName: "Cost", hideFilter: true },
    ];

    if (active === "Print" || active === "Mount") {
      columns.splice(5, 0, {
        title: "Light",
        dataIndex: "light_type",
      });

      columns.splice(6, 0, {
        title: "Creative Name",
        dataIndex: "creative_name",
        width: 142,
      });

      columns.splice(7, 4, {
        title: "Price / SQFT",
        dataIndex: "rate_per_sqft",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 142,
        render: (text) => currencyFormatter(parseInt(text)),
      });

      filterColumns.splice(5, 0, {
        columnName: "light_type",
        displayName: "Light",
        hideFilter: true,
      });

      filterColumns.splice(6, 0, {
        columnName: "creative_name",
        displayName: "Creative Name",
        hideFilter: true,
      });

      filterColumns.splice(7, 4, {
        columnName: "rate_per_sqft",
        displayName: "Price / SQFT",
        hideFilter: true,
      });
    }

    this.setState({ columnsDefault: columns, filterColumns }, () =>
      this.setDefaultColumns(columns)
    );
  };

  // Check Box Selection
  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys, selectedRows });
    this.props.handleSelection(selectedRows, this.state.active);
  };

  // Set Tabs
  setTabs = () => {
    const options = [
      { label: "Rent (" + (this.state.rentCount || 0) + ")", value: "Rent" },
      { label: "Print (" + (this.state.printCount || 0) + ")", value: "Print" },
      { label: "Mount (" + (this.state.mountCount || 0) + ")", value: "Mount" },
    ];

    this.setState({ options });
  };

  // Tab Change
  onTabChange = (e) => {
    let active = e.target.value;
    let id = this.props.id;
    let pagination = {
      current: 1,
      pageSize: 100,
    };

    let listReq = "salesestimate/unbilleditems/";

    if (active === "Rent") {
      listReq = listReq + "rentallist?campaignid=" + id + "&";
    }
    if (active === "Print") {
      listReq = listReq + "printinglist?campaignid=" + id + "&";
    }
    if (active === "Mount") {
      listReq = listReq + "mountinglist?campaignid=" + id + "&";
    }

    this.setState({ active }, () => this.setColumns());
    this.setState({ listReq }, () => this.getResults({ pagination }));
  };

  updateResults = () => {
    const { active } = this.state;

    let data = this.state.data;

    data = data.map((x, key) => ({
      ...x,
      uniqueId: active + key + 1 + "-" + this.state.pagination.current,
    }));

    let inventory_details = [];

    active === "Rent" &&
      (inventory_details = this.props?.rental_inventory_details || []);

    active === "Print" &&
      (inventory_details = this.props?.printing_inventory_details || []);

    active === "Mount" &&
      (inventory_details = this.props?.installing_inventory_details || []);

    this.setState({
      data,
      selectedRowKeys: inventory_details.map((x) => x.uniqueId),
    });
  };

  getTabCount = () => {
    let reqUrl =
      "salesestimate/unbilleditems/rentallist?campaignid=" + this.props.id;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ rentCount: data?.page_info?.total_records || 0 }, () =>
          this.setTabs()
        );
      },
      "GET"
    );

    reqUrl =
      "salesestimate/unbilleditems/printinglist?campaignid=" + this.props.id;

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ printCount: data?.page_info?.total_records || 0 }, () =>
          this.setTabs()
        );
      },
      "GET"
    );

    reqUrl =
      "salesestimate/unbilleditems/mountinglist?campaignid=" + this.props.id;

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ mountCount: data?.page_info?.total_records || 0 }, () =>
          this.setTabs()
        );
      },
      "GET"
    );
  };

  componentDidMount() {
    // Get List
    const { pagination } = this.state;
    this.getResults({ pagination });
    this.setColumns();
    this.setTabs();
    this.getTabCount();
  }

  render() {
    const {
      data,
      columns,
      pagination,
      loading,
      filterColumns,
      selectedRowKeys,
      options,
      active,
      screen,
    } = this.state;

    const rowSelection = {
      selectedRowKeys,
      preserveSelectedRowKeys: true,
      onChange: this.onSelectChange,
    };

    return (
      <React.Fragment>
        <Radio.Group
          options={options}
          onChange={this.onTabChange}
          value={active}
          optionType="button"
          buttonStyle="solid"
          loading={true}
          style={{ marginBottom: "16px" }}
        />

        <ListFilters
          items={filterColumns}
          handleSettings={this.handleSettings}
          handleFilters={this.handleFilters}
          key={filterColumns}
          screen={screen}
        />

        <Table
          columns={columns}
          rowKey={(record) => record.uniqueId}
          dataSource={data}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
          bordered
          scroll={{ x: 400 }}
          size="small"
          rowSelection={rowSelection}
          key={active}
        />
      </React.Fragment>
    );
  }
}

export default InvoiceAddList;
