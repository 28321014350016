import React, { Component } from "react";
import { Row, Col, Table, Typography } from "antd";
import PageTitleDetails from "../../components/common/page-title-detail";
import { ajaxRequester } from "../../services/ajax-requester";
import {
  dateFormatter,
  timeFormatter,
} from "../../components/common/common-formatter";
import PortalUser from "../../iam/components/portal-user";
import { Link } from "react-router-dom";
import { RolesPermissions } from "../../helpers/roles-permissions";

class InventoryBookingStatus extends Component {
  state = {
    data: [],
    loading: true,
    columns: [],
    columnsDefault: [],
    history: [],
  };

  // Get Inventory Status
  getInventoryBookingStatus = () => {
    const inventoryId = this.props.match.params.id;

    let reqUrl =
      "hainventory/bookingstatus/list?inventoryid=" + Number(inventoryId);

    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        let bookingStatus = [];
        let history = [];
        data.response.map((x) =>
          x.is_active === true ? bookingStatus.push(x) : history.push(x)
        );
        this.setState({
          loading: false,
          data: bookingStatus,
          history,
        });
      },
      "GET"
    );
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "Description",
        dataIndex: "description",
        width: "26%",
      },
      {
        title: "Employee Name",
        dataIndex: "employee_id",
        render: (text) => <PortalUser user={text} />,
        width: "12%",
      },
      {
        title: "Company Name",
        dataIndex: "customer_name",
        width: "12%",
      },
      {
        title: "Display Name",
        dataIndex: "display_name",
        width: "15%",
        render: (text, record) =>
          record.booking_status === "N" ||
          record.booking_status === "B" ||
          record.booking_status === "R" ? (
            RolesPermissions("ad") ? (
              <Link
                style={{
                  padding: "0px",
                  whiteSpace: "normal",
                  textAlign: "left",
                }}
                type="link"
                to={
                  record.booking_status === "B"
                    ? "/plans/plan-details/" + record.plan_id
                    : "/campaigns/campaign-details/" + record.campaign_id
                }
              >
                {text}
              </Link>
            ) : (
              text
            )
          ) : (
            text
          ),
      },
      {
        title: "Site Start Date",
        dataIndex: "start_date",
        render: (text) => dateFormatter(text),
        width: "7%",
      },
      {
        title: "Site End Date",
        dataIndex: "end_date",
        render: (text) => dateFormatter(text),
        width: "7%",
      },
      {
        title: "Booking Status",
        dataIndex: "booking_status",
        render: (text, record) =>
          text === "A" ? (
            <b className="ant-color-green">Available</b>
          ) : text === "B" ? (
            <b className="ant-color-orange">
              Blocked till{" "}
              {dateFormatter(record.blocked_till_date) +
                " - " +
                timeFormatter(record.blocked_till_date)}
            </b>
          ) : text === "R" ? (
            <b className="ant-color-purple">Rotational</b>
          ) : (
            <b className="ant-color-red">Not Available</b>
          ),
      },
      {
        title: "Activity Date & Time",
        dataIndex: "created_date",
        render: (text) => dateFormatter(text) + " - " + timeFormatter(text),
        width: "8%",
      },
      {
        title: "Campaign Status",
        dataIndex: "campaign_status",
        width: "8%",
      },
    ];

    let historyColumns = [
      {
        title: "Description",
        dataIndex: "description",
        width: "26%",
      },
      {
        title: "Employee Name",
        dataIndex: "employee_id",
        render: (text) => <PortalUser user={text} />,
        width: "12%",
      },
      {
        title: "Company Name",
        dataIndex: "customer_name",
        width: "12%",
      },
      {
        title: "Display Name",
        dataIndex: "display_name",
        width: "15%",
      },
      {
        title: "Site Start Date",
        dataIndex: "start_date",
        render: (text) => dateFormatter(text),
        width: "7%",
      },
      {
        title: "Site End Date",
        dataIndex: "end_date",
        render: (text) => dateFormatter(text),
        width: "7%",
      },
      {
        title: "Activity",
        dataIndex: "activity",
        render: (text) => (
          <b
            className={
              text === "A"
                ? "ant-color-green"
                : text === "Blocked"
                ? "ant-color-orange"
                : "ant-color-red"
            }
          >
            {text}
          </b>
        ),
      },
      {
        title: "Activity Date & Time",
        dataIndex: "created_date",
        render: (text) => dateFormatter(text) + " - " + timeFormatter(text),
        width: "8%",
      },
      {
        title: "Campaign Status",
        dataIndex: "campaign_status",
        width: "8%",
      },
    ];

    this.setState({ columns, columnsDefault: columns, historyColumns });
  };

  // Redirect to Inventory List
  handleBack = () => {
    this.props.history.goBack();
  };

  componentDidMount() {
    // Get Inventory Status
    this.getInventoryBookingStatus({});
    this.setColumns();
  }

  render() {
    const { loading, history, historyColumns } = this.state;
    const { Title } = Typography;

    const inventoryTitle = this.props.match.params.title;

    let { data, columns } = this.state;

    data = data.sort((a, b) => {
      return new Date(a.created_date) - new Date(b.created_date);
    });

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageTitleDetails
              titleText={"Booking Status - " + inventoryTitle}
              // isBackBtn="true"
              handleBack={this.handleBack}
            />

            <Table
              columns={columns}
              rowKey={(record) => record.booking_status_id}
              dataSource={data}
              pagination={false}
              loading={loading}
              bordered
              scroll={{ x: 400 }}
              size="small"
            />

            <Title style={{ marginTop: "28px" }} level={4}>
              History
            </Title>

            <Table
              columns={historyColumns}
              rowKey={(record) => record.booking_status_id}
              dataSource={history}
              pagination={false}
              loading={loading}
              bordered
              scroll={{ x: 400 }}
              size="small"
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default InventoryBookingStatus;
