import { EventSourcePolyfill } from "event-source-polyfill";
import AdminApi from "./admin-api";
let eventSource = null;

const validateUser = () => {
  AdminApi.validateToken().then((res) => {
    var isValid = res.response;
    if (!isValid) {
      localStorage.clear();
      window.location.hash = "";
      window.location.reload();
    }
  });
};

export const AuthListner = {
  connect: (url, token) => {
    if (eventSource != null) {
      eventSource.disconnect();
      eventSource = null;
    }
    eventSource = new EventSourcePolyfill(`${url}/auth/subscribe`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    eventSource.onmessage = (ev) => {
      var data = JSON.parse(ev.data);
      console.log(data);
      switch (data.event) {
        case "reload":
          let id = data.id;
          if (id === AdminApi.getCurrentUser().Id) {
            validateUser();
          } else {
            AdminApi.updateUserCache(id);
          }
          break;
        case "full_reload":
          validateUser();
          break;
        default:
          break;
      }
    };
  },
  disconnect: () => {
    if (eventSource != null) {
      eventSource.disconnect();
      eventSource = null;
    }
  },
};
