import React, { Component } from "react";
import PageTitleDetails from "../../components/common/page-title-detail";
import { ajaxRequester } from "../../services/ajax-requester";
import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  message,
  DatePicker,
  InputNumber,
  Drawer,
  Tag,
} from "antd";
import { FileAddOutlined } from "@ant-design/icons";
import PlanSelectInventory from "../../components/plan/plan-select-inventories";
import * as dayjs from "dayjs";
import AdminApi from "../../iam/api/admin-api";
import UploadFileLocal from "../../components/common/upload-file-local";
import EstimateSiteList from "../../components/purchase/estimate-site-list";

class ManualTransactionsAddEdit extends Component {
  state = {
    page: "manual-transactions-add-edit",
    loading: false,
    stateList: [],
    districtList: [],
    cityList: [],
    estimateTypeList: [],
    sisterCompanyList: [],
    mode: this.props.match.path.includes("add") ? "Add" : "Edit",
    results: "",
    isDrawer: false,
    isPayeeDrawer: false,
    payout_estimate_site_list: [],
    inventory_details: [],
    payout_estimate_payee_list: [],
    payee_slab: [{ id: "", per: "", amount: "" }],
    annual_amount: 0,
    estimate_payee_key: 1,
    estimate_site_key: 1,
    payeeList: [],
  };

  formRef = React.createRef();

  // Get Payee Details
  getDetails = () => {
    const { id } = this.props.match.params;
    let reqUrl = "scheduledtransaction/detail?pendingEstimateId=" + id;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ details: data.response }, () => this.setDefaultData());
      },
      "GET"
    );
  };

  // Redirect to Payee List
  handleBack = () => {
    this.props.history.goBack();
  };

  // Get Estimate Type List from Lookup
  getEstimateTypeList = () => {
    let reqUrl = "estimatetype/list?showActive=true";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ estimateTypeList: data.response });
      },
      "GET"
    );
  };

  // Get Payee Type List from Lookup
  getPayeeList = () => {
    let reqUrl = "payee/list?showActive=true";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ payeeList: data.response });
      },
      "GET"
    );
  };

  // Get Sister Company List from Lookup
  getSisterCompanyList = () => {
    let reqUrl = "sistercompany/list?showActive=true";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ sisterCompanyList: data.response });
      },
      "GET"
    );
  };

  // Get State List from Lookup
  getStateList = () => {
    let reqUrl = "lookup/state";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ stateList: data.response });
      },
      "GET"
    );
  };

  // Get District List from Lookup
  getDistrictList = (state) => {
    let reqUrl = "lookup/district?state=" + state;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ districtList: data.response });
      },
      "GET"
    );
  };

  // Get City List from Lookup
  getCityList = (state, district) => {
    let reqUrl = "lookup/city?state=" + state + "&district=" + district;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ cityList: data.response });
      },
      "GET"
    );
  };

  // Handle Select Change
  handleTerritoryChange = (item, key) => {
    let state = this.formRef.current.getFieldValue("state");
    let district = this.formRef.current.getFieldValue("district");
    let city = this.formRef.current.getFieldValue("city");

    switch (item) {
      case "state":
        district = null;
        city = null;
        this.setState({ districtList: [], cityList: [], areaList: [] });
        break;

      case "district":
        city = null;
        this.setState({ cityList: [] });
        break;

      default:
        break;
    }
    this.formRef.current.setFieldsValue({
      state: state,
      district: district,
      city: city,
    });
  };

  // Handle Select Focus
  handleTerritorySelect = (item, key) => {
    let state = this.formRef.current.getFieldValue("state");
    let district = this.formRef.current.getFieldValue("district");

    switch (item) {
      case "district":
        state && this.getDistrictList(state);
        break;

      case "city":
        state && district && this.getCityList(state, district);
        break;

      default:
        break;
    }
  };

  setDefaultData = () => {
    const { details } = this?.state;

    this.formRef.current.setFieldsValue({
      estimate_type_id: details?.estimate_type,
      payout_estimate_name: details?.payout_estimate_name,
      payout_from_company_id: details?.payout_from_account_name,
      payee_id: details?.payout_estimate_payee_list?.find((x) => x.payee_id)
        ?.account_name,
      pid: details?.pid,
      authority_id: details?.authority_id,
      tenure_from: dayjs(details?.billing_duration_from),
      tenure_to: dayjs(details?.billing_duration_to),
      state: details?.state_name,
      district: details?.district_name,
      city: details?.city_name,
      mapped_sqft: details?.mapped_sqft,
      amount_sqft_month: details?.amount_sqft_month,
      payment_amount: details?.payment_detail?.payment_amount,
      gst_amount: details?.payment_detail?.gst_amount,
      tds_amount: details?.payment_detail?.tds_amount,
      net_amount: details?.payment_detail?.net_amount,
      cheque_no: details?.payment_detail?.cheque_no,
      cheque_date: dayjs(details?.payment_detail?.cheque_date),
      remarks: details?.payment_detail?.remarks,
    });

    this.setState({
      payout_estimate_site_list: details?.payout_estimate_site_list || [],
      inventory_details: details?.payout_estimate_site_list || [],
      annual_amount: details?.payment_detail?.payment_amount || 0,
    });
  };

  ValuesChange = (value, values) => {
    const { payout_estimate_payee_list, payeeList } = this.state;
    if (value["payment_amount"]) {
      this.setState({ annual_amount: value["payment_amount"] });

      if (values["mapped_sqft"]) {
        let amount_sqft_month = parseInt(
          value["payment_amount"] / values["mapped_sqft"] / 12 || 0
        );
        this.formRef.current.setFieldsValue({ amount_sqft_month });
      } else {
        this.formRef.current.setFieldsValue({ amount_sqft_month: 0 });
      }
    }

    if (value["payment_amount"] || value["gst_amount"] || value["tds_amount"]) {
      let total =
        (values["payment_amount"] || 0) +
        (values["gst_amount"] || 0) -
        (values["tds_amount"] || 0);

      this.formRef.current.setFieldsValue({
        net_amount: total,
      });
    }

    if (value["payee_id"]) {
      let pid = payeeList?.find(
        (x) => x?.account_name === values["payee_id"]
      )?.pid;

      this.formRef.current.setFieldsValue({
        pid: pid,
      });
    }
  };

  onFinish = (values) => {
    const { mode, details, cityList, file } = this.state;
    const { estimateTypeList, sisterCompanyList, payeeList } = this.state;
    let { payout_estimate_site_list } = this.state;

    values.payout_interval = "O";
    values.payout_type = "A";
    values.schedule_start_date = dayjs();
    values.annual_amount = values?.payment_amount;
    values.payout_amount = values?.payment_amount;

    let payment_detail = {
      payment_date: dayjs(),
      payment_amount: values?.payment_amount,
      gst_amount: values?.gst_amount,
      tds_amount: values?.tds_amount,
      cheque_no: values?.cheque_no,
      cheque_date: values?.cheque_date,
      remarks: values?.remarks,
    };

    if (mode === "Edit") {
      values.pending_estimate_id = details?.pending_estimate_id;
      values.payout_estimate_id = details?.payout_estimate_id;
      payment_detail.payment_detail_id =
        details?.payment_detail?.payment_detail_id;

      values.city_id =
        cityList.find((x) => x?.city === values?.city)?.city_id ||
        details?.city_id;
    } else {
      values.city_id = cityList.find((x) => x?.city === values?.city)?.city_id;
    }

    values.estimate_type_id = estimateTypeList?.find(
      (x) => x?.estimate_type === values?.estimate_type_id
    )?.estimate_type_id;

    values.payout_from_company_id = sisterCompanyList?.find(
      (x) => x?.sister_company_name === values?.payout_from_company_id
    )?.sister_company_id;

    payout_estimate_site_list = payout_estimate_site_list.map((x) => ({
      ha_inventory_id: x.ha_inventory_id,
    }));

    let payout_estimate_payee_list = [
      {
        payee_id: payeeList.find((x) => x?.account_name === values?.payee_id)
          ?.payee_id,
        payee_per: 100,
        payee_annual_amount: values?.payment_amount,
      },
    ];

    let reqUrl = "onetimetransaction/" + (mode === "Edit" ? "update" : "add");
    let reqObj = {
      request: {
        ...values,
        payout_estimate_site_list: payout_estimate_site_list || [],
        payout_estimate_payee_list: payout_estimate_payee_list || [],
        payment_detail,
        payout_approval_by_id: AdminApi?.getCurrentUser()?.Id,
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (mode === "Add" ? data?.payment_detail_id : data.success) {
        message.success(
          "Transaction has been " +
            (mode === "Edit" ? "updated" : "added") +
            " successfully"
        );
        file && this.handleUpload(data?.payment_detail_id);
        this.handleBack();
      } else {
        message.error(
          data?.detail ||
            data?.title ||
            "An error occured while processing your request."
        );
      }
    });
  };

  // Handle Drawer
  handleDrawer = () => {
    this.setState({
      isDrawer: !this.state.isDrawer,
    });
  };

  handlePayeeDrawer = () => {
    this.setState({
      isPayeeDrawer: !this.state.isPayeeDrawer,
    });
  };

  addSelectedInventory = (inventories) => {
    this.handleDrawer();

    let { payout_estimate_site_list, estimate_site_key } = this?.state;

    inventories && inventories.map((x) => payout_estimate_site_list.push(x));
    this.setState({
      payout_estimate_site_list,
      inventory_details: payout_estimate_site_list,
      estimate_site_key: estimate_site_key + 1,
    });

    this.calcMappedSqft(payout_estimate_site_list);
  };

  deleteInventory = (inventory) => {
    let { payout_estimate_site_list, estimate_site_key } = this?.state;

    payout_estimate_site_list = payout_estimate_site_list.filter(
      (x) => x.ha_inventory_id !== inventory.ha_inventory_id
    );

    this.setState({
      payout_estimate_site_list,
      inventory_details: payout_estimate_site_list,
      estimate_site_key: estimate_site_key + 1,
    });

    this.calcMappedSqft(payout_estimate_site_list);
  };

  calcMappedSqft = (inventories) => {
    let { annual_amount } = this.state;
    let mapped_sqft = 0;
    inventories.map((x) => (mapped_sqft = x.sqft + mapped_sqft));
    this.formRef.current.setFieldsValue({ mapped_sqft });

    if (annual_amount && mapped_sqft) {
      let amount_sqft_month = parseInt(annual_amount / mapped_sqft / 12 || 0);
      this.formRef.current.setFieldsValue({ amount_sqft_month });
    } else {
      this.formRef.current.setFieldsValue({ amount_sqft_month: 0 });
    }
  };

  handleDocument = (file) => {
    this.setState({ file });
  };

  handleUpload = (ref_id) => {
    const { details, file } = this.state;
    let payment_detail_id = details?.payment_detail?.payment_detail_id;

    let reqUrl = "scheduledtransaction/payment/document/upload";
    let request = file;
    request.reference_id = Number(ref_id || payment_detail_id);

    let reqObj = {
      request,
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (!data) {
        message.error("Document Upload Failed");
      }
    });
  };

  componentDidMount() {
    this.state.mode === "Edit" && this.getDetails();
    this.getStateList();
    this.getEstimateTypeList();
    this.getSisterCompanyList();
    this.getPayeeList();
  }

  render() {
    const {
      mode,
      loading,
      stateList,
      districtList,
      cityList,
      estimateTypeList,
      sisterCompanyList,
      details,
      isDrawer,
      payout_estimate_site_list,
      payeeList,
      estimate_site_key,
    } = this?.state;
    const { TextArea } = Input;
    const { Option } = Select;

    const layout = {
      labelCol: { span: 12 },
      wrapperCol: { span: 12 },
    };

    const layout2 = {
      labelCol: { span: 16 },
      wrapperCol: { span: 8 },
    };

    return (
      <React.Fragment>
        <Row>
          <Col span={24} xs={24} xl={24}>
            <PageTitleDetails
              titleText={mode + " One Time Transaction"}
              isBackBtn="true"
              handleBack={this.handleBack}
            />
            <Form
              name="manual_transactions_add_edit"
              className="manual_transactions_add_edit"
              {...layout}
              onFinish={this.onFinish}
              onValuesChange={this.ValuesChange}
              ref={this.formRef}
              initialValues={{
                cheque_date: dayjs(),
              }}
            >
              <Row>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item label="Estimate Type" name="estimate_type_id">
                    <Select
                      placeholder="Estimate Type"
                      allowClear={true}
                      showSearch={true}
                    >
                      {estimateTypeList.map((x) => (
                        <Option key={x?.estimate_type} value={x?.estimate_type}>
                          {x?.estimate_type}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item
                    label="Payout Estimate Name"
                    name="payout_estimate_name"
                    rules={[
                      {
                        required: true,
                        message: "Please Input Payout Estimate Name !",
                      },
                    ]}
                  >
                    <Input placeholder="Payout Estimate Name" />
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item
                    label="Payout From"
                    name="payout_from_company_id"
                    rules={[
                      {
                        required: true,
                        message: "Please Input Payout From !",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Estimate Type"
                      allowClear={true}
                      showSearch={true}
                    >
                      {sisterCompanyList.map((x) => (
                        <Option
                          key={x?.sister_company_name}
                          value={x?.sister_company_name}
                        >
                          {x?.sister_company_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item
                    label="Account Name"
                    name="payee_id"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Account Name !",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Account Name"
                      allowClear={true}
                      showSearch={true}
                    >
                      {payeeList.map((x) => (
                        <Option key={x?.account_name} value={x?.account_name}>
                          {x?.account_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item label="PID" name="pid">
                    <Input placeholder="PID ID" disabled />
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item label="Authority ID" name="authority_id">
                    <Input placeholder="Authority ID" />
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item
                    label="From Date"
                    name="tenure_from"
                    rules={[
                      {
                        required: true,
                        message: "Please Select From Date !",
                      },
                    ]}
                  >
                    <DatePicker placeholder="From" inputReadOnly={true} />
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item
                    label="To Date"
                    name="tenure_to"
                    rules={[
                      {
                        required: true,
                        message: "Please Select To Date !",
                      },
                    ]}
                  >
                    <DatePicker placeholder="To" inputReadOnly={true} />
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}></Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item label="State" name="state">
                    <Select
                      placeholder="State"
                      allowClear={true}
                      showSearch={true}
                      onChange={() => this.handleTerritoryChange("state")}
                    >
                      {stateList.map((x) => (
                        <Option key={x.state} value={x.state}>
                          {x.state}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item label="District" name="district">
                    <Select
                      placeholder="District"
                      allowClear={true}
                      showSearch={true}
                      onChange={() => this.handleTerritoryChange("district")}
                      onFocus={() => this.handleTerritorySelect("district")}
                    >
                      {districtList.map((x) => (
                        <Option key={x.district} value={x.district}>
                          {x.district}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item
                    label="City"
                    name="city"
                    rules={[
                      {
                        required: true,
                        message: "Please Select City !",
                      },
                    ]}
                  >
                    <Select
                      placeholder="City"
                      showSearch={true}
                      allowClear={true}
                      onChange={() => this.handleTerritoryChange("city")}
                      onFocus={() => this.handleTerritorySelect("city")}
                    >
                      {cityList.map((x) => (
                        <Option key={x.city} value={x.city}>
                          {x.city}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item label="Map HA Sites" name="map_ha_sites">
                    <Button onClick={this.handleDrawer}>
                      Select HA Inventories
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item label="Mapped SQFT" name="mapped_sqft">
                    <InputNumber
                      placeholder="Mapped SQFT"
                      style={{ width: "100%" }}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item
                    label="Amount / SQFT / Month"
                    name="amount_sqft_month"
                  >
                    <InputNumber
                      placeholder="Mapped SQFT"
                      style={{ width: "100%" }}
                      disabled
                    />
                  </Form.Item>
                </Col>
                {payout_estimate_site_list.length > 0 && (
                  <React.Fragment>
                    <Col span={4} xs={24} xl={4}></Col>
                    <Col
                      span={12}
                      xs={24}
                      xl={12}
                      style={{ marginBottom: "24px" }}
                    >
                      <EstimateSiteList
                        key={estimate_site_key}
                        payout_estimate_site_list={payout_estimate_site_list}
                        deleteInventory={this.deleteInventory}
                      />
                    </Col>
                    <Col span={4} xs={24} xl={4}></Col>
                  </React.Fragment>
                )}
              </Row>

              <Row>
                <Col span={16} xs={24} xl={16}>
                  <Row>
                    <Col span={12} xs={24} xl={12}>
                      <Form.Item
                        label="Amount Paid"
                        name="payment_amount"
                        rules={[
                          {
                            required: true,
                            message: "Please Input Amount Paid !",
                          },
                        ]}
                      >
                        <InputNumber
                          style={{ width: "100%" }}
                          min={0}
                          placeholder="Amount Paid"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12} xs={24} xl={12}>
                      <Row>
                        <Col span={18} xs={24} xl={18}>
                          <Form.Item label="GST" name="gst_amount" {...layout2}>
                            <InputNumber
                              style={{ width: "100%" }}
                              min={0}
                              placeholder="GST"
                            />
                          </Form.Item>
                        </Col>

                        <Col span={6} xs={24} xl={6}>
                          <Form.Item label="TDS" name="tds_amount">
                            <InputNumber
                              style={{ width: "100%" }}
                              min={0}
                              placeholder="TDS"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>

                    <Col span={12} xs={24} xl={12}>
                      <Form.Item label="Net Payout" name="net_amount">
                        <InputNumber
                          style={{ width: "100%" }}
                          min={0}
                          placeholder="Net Payout"
                          disabled
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12} xs={24} xl={12}>
                      <Form.Item label="Date" name="cheque_date">
                        <DatePicker placeholder="Date" />
                      </Form.Item>
                    </Col>

                    <Col span={12} xs={24} xl={12}>
                      <Form.Item label="Instrument Number" name="cheque_no">
                        <Input placeholder="Instrument Number" />
                      </Form.Item>
                    </Col>

                    <Col span={12} xs={24} xl={12}>
                      <Form.Item label="Payout Invoice" name="payout_invoice">
                        {(mode === "Add" || details) && (
                          <UploadFileLocal
                            handleUpload={this.handleDocument}
                            document_type={"1"}
                            mode={mode}
                            existingFile={
                              details?.payment_detail?.payout_invoice
                            }
                            deletePath={
                              "scheduledtransaction/payment/document/delete?paymentDetailId=" +
                              details?.payment_detail?.payment_detail_id
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={8} xs={24} xl={8}>
                  <Form.Item label="Remarks" name="remarks">
                    <TextArea rows={3} placeholder="Remarks" />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                className="ant-text-center"
                style={{ marginTop: "24px" }}
                wrapperCol={{ span: 24 }}
              >
                <Button
                  type="primary"
                  size={"large"}
                  icon={<FileAddOutlined />}
                  htmlType="submit"
                  loading={loading}
                >
                  {mode} One Time Transaction
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>

        <Drawer
          title="Add HA Inventories"
          placement="right"
          open={isDrawer}
          onClose={this.handleDrawer}
          width="88%"
          key={isDrawer}
        >
          <PlanSelectInventory
            key={isDrawer}
            addSelectedInventory={this.addSelectedInventory}
            {...this.state}
          />
        </Drawer>
      </React.Fragment>
    );
  }
}

export default ManualTransactionsAddEdit;
