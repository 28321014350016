import React from "react";
import { Row, Col, Table, Button, Radio } from "antd";
import { LineChartOutlined, PlusOutlined } from "@ant-design/icons";
import PageTitleDetails from "../../components/common/page-title-detail";
import ListFilters from "../../components/common/list-filters";
import CommonListBase from "../../base/common-list-base";
import {
  currencyFormatter,
  dateFormatter,
} from "../../components/common/common-formatter";
import PortalUser from "../../iam/components/portal-user";
import { Link } from "react-router-dom";
import SalesEstimateStatistics from "../../components/sales-estimate/sales-estimate-statistics";

class SalesEstimateDetails extends CommonListBase {
  state = {
    listReq:
      "salesestimate/detail/invoicelist?campaignid=" +
      this.props.match.params.id +
      "&",
    delReq: "salesestimate/delete?campaignid=",
    dtlReq: "/sales-estimate/invoice/view/",
    editReq:
      "/sales-estimate/sales-estimate-edit/" + this.props.match.params.id,
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    sorting: [],
    options: [],
    value: "Invoice",
    isStatistics: false,
    screen: "sales-estimate-details",
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "Invoice Date",
        dataIndex: "invoice_date",
        render: (text) => text && dateFormatter(text),
      },
      {
        title: "Invoice Number",
        dataIndex: "invoice_no",
        render: (text, record) => (
          <Link
            style={{ padding: "0px", whiteSpace: "normal", textAlign: "left" }}
            type="link"
            to={this.state.dtlReq + record.invoice_id}
          >
            {text ? text : "Not Assigned"}
          </Link>
        ),
      },
      {
        title: "Display",
        dataIndex: "display_name",
        width: 118,
      },
      {
        title: "Start Date",
        dataIndex: "duration_from",
        render: (text) => dateFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 108,
      },
      {
        title: "End Date",
        dataIndex: "duration_to",
        render: (text) => dateFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 98,
      },
      {
        title: "Status",
        dataIndex: "invoice_status",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Invoice Amount",
        dataIndex: "invoice_amount",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: "Invoice Amount with Tax",
        dataIndex: "total_amount",
        render: (text) => currencyFormatter(parseInt(text)),
        width: 142,
      },
      {
        title: "Campaign Difference",
        dataIndex: "campaign_difference",
        render: (text, record) => (
          <span className={text > 0 ? "ant-color-green" : "ant-color-red"}>
            {currencyFormatter(parseInt(text))} ({record.campaign_diff_per}%)
          </span>
        ),
      },
      {
        title: "HA Markup",
        dataIndex: "ha_markup",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) => (
          <span className={text > 0 ? "ant-color-green" : "ant-color-red"}>
            {currencyFormatter(parseInt(text))} ({record.ha_markup_per}%)
          </span>
        ),
      },
      {
        title: "TA Markup",
        dataIndex: "ta_markup",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) => (
          <span className={text > 0 ? "ant-color-green" : "ant-color-red"}>
            {currencyFormatter(parseInt(text))} ({record.ta_markup_per}%)
          </span>
        ),
      },
      {
        title: "QoS",
        dataIndex: "qos",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) =>
          text && (
            <span className={text > 0 ? "ant-color-green" : "ant-color-red"}>
              {currencyFormatter(parseInt(text))} ({record.qos_per}%)
            </span>
          ),
      },
    ];

    let filterColumns = [
      {
        columnName: "invoice_date",
        displayName: "Invoice Date",
        hideFilter: true,
      },
      {
        columnName: "invoice_number",
        displayName: "Invoice Number",
        default: true,
      },
      { columnName: "display_name", displayName: "Display", hideFilter: true },
      { columnName: "start_date", displayName: "Start Date", hideFilter: true },
      { columnName: "end_date", displayName: "End Date", hideFilter: true },
      { columnName: "invoice_status", displayName: "Status", hideFilter: true },
      {
        columnName: "invoice_amount",
        displayName: "Invoice Amount",
        hideFilter: true,
      },
      {
        columnName: "total_amount",
        displayName: "Invoice Amount with Tax",
        hideFilter: true,
      },
      {
        columnName: "campaign_difference",
        displayName: "Campaign Difference",
        hideFilter: true,
      },
      {
        columnName: "ha_base_rate_diff_amt",
        displayName: "HA Markup",
        hideFilter: true,
      },
      {
        columnName: "ta_base_rate_diff_amt",
        displayName: "TA Markup",
        hideFilter: true,
      },
      { columnName: "qos", displayName: "QoS", hideFilter: true },
    ];

    if (this.state.value === "Credit Note") {
      columns = [
        {
          title: "Credit Note Number",
          dataIndex: "credit_note_no",
          sorter: true,
          sortDirections: ["ascend", "descend", "ascend"],
        },
        {
          title: "Credit Note Date",
          dataIndex: "credit_note_date",
          sorter: true,
          sortDirections: ["ascend", "descend", "ascend"],
          render: (text) => dateFormatter(text),
        },
        {
          title: "Customer Code",
          dataIndex: "customer_code",
        },
        {
          title: "Company Name",
          dataIndex: "company_name",
        },
        {
          title: "Employee",
          dataIndex: "employee_id",
          render: (text) => text && <PortalUser user={text} />,
          width: 140,
        },
        {
          title: "Description",
          dataIndex: "remarks",
        },
        {
          title: "Mapped Invoices",
          dataIndex: "mapped_invoices",
          sorter: true,
          sortDirections: ["ascend", "descend", "ascend"],
        },
        {
          title: "Amount",
          dataIndex: "credit_note_amount",
          sorter: true,
          sortDirections: ["ascend", "descend", "ascend"],
          render: (text) => currencyFormatter(parseInt(text)),
        },
        {
          title: "Amount with GST",
          dataIndex: "total_amount",
          sorter: true,
          sortDirections: ["ascend", "descend", "ascend"],
          render: (text) => currencyFormatter(parseInt(text)),
        },
      ];

      filterColumns = [
        {
          columnName: "credit_note_no",
          displayName: "Credit Note Number",
          default: true,
        },
        {
          columnName: "credit_note_date",
          displayName: "Credit Note Date",
          hideFilter: true,
        },
        {
          columnName: "customer_code",
          displayName: "Customer Code",
          hideFilter: true,
        },
        {
          columnName: "company_name",
          displayName: "Company Name",
        },
        { columnName: "employee_id", displayName: "Employee" },
        { columnName: "remarks", displayName: "Description" },
        {
          columnName: "mapped_invoices",
          displayName: "Mapped Invoices",
          hideFilter: true,
        },
        {
          columnName: "credit_note_amount",
          displayName: "Amount",
          hideFilter: true,
        },
        {
          columnName: "total_amount",
          displayName: "Amount with GST",
          hideFilter: true,
        },
        { columnName: "download", displayName: "Download", hideFilter: true },
      ];
    }

    this.setState({ columnsDefault: columns, filterColumns }, () =>
      this.setDefaultColumns(columns)
    );
  };

  // Add Invoice
  addInvoice = () => {
    this.props.history.push(
      "/sales-estimate/sales-estimate-add/" + this.props.match.params.id
    );
  };

  // Set Tabs
  setTabs = () => {
    const options = [
      { label: "Invoice", value: "Invoice" },
      { label: "Credit Note", value: "Credit Note" },
    ];

    this.setState({ options });
  };

  // Tab Change
  onTabChange = (e) => {
    let activeTab = e.target.value;

    let id = this.props.match.params.id;

    let pagination = {
      current: 1,
      pageSize: 20,
    };

    let listReq = "salesestimate/detail/";

    if (activeTab === "Invoice") {
      listReq = listReq + "invoicelist?campaignid=" + id + "&";
    }
    if (activeTab === "Credit Note") {
      listReq = listReq + "creditnotelist?campaignid=" + id + "&";
    }

    this.setState({ value: activeTab }, () => this.setColumns());
    this.setState({ listReq }, () => this.getResults({ pagination }));
  };

  // Redirect to Sales Estimate List
  handleBack = () => {
    this.props.history.goBack();
  };

  // Handle Statistics
  handleStatistics = () => {
    this.setState({ isStatistics: !this.state.isStatistics });
  };

  componentDidMount() {
    // Get List
    const { pagination } = this.state;
    this.getResults({ pagination });
    this.setColumns();
    this.setTabs();
  }

  render() {
    const {
      data,
      columns,
      pagination,
      loading,
      filterColumns,
      options,
      value,
      isStatistics,
      screen,
    } = this.state;

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageTitleDetails
              titleText={this.props.match.params.title}
              isBackBtn="true"
              handleBack={this.handleBack}
            />

            <div className="page-title-custom-btn sales-dtl-btns">
              <Button
                type="primary"
                onClick={this.handleStatistics}
                style={{ marginRight: "16px" }}
                icon={<LineChartOutlined />}
                className="icon-btn"
              >
                Statistics
              </Button>

              <Button
                type="primary"
                onClick={this.addInvoice}
                icon={<PlusOutlined />}
                className="icon-btn"
              >
                Add Invoice
              </Button>
            </div>

            <Radio.Group
              options={options}
              onChange={this.onTabChange}
              value={options.find((x) => x.value === value) ? value : ""}
              optionType="button"
              buttonStyle="solid"
              loading={true}
              style={{ marginBottom: "16px" }}
            />

            <ListFilters
              items={filterColumns}
              handleSettings={this.handleSettings}
              handleFilters={this.handleFilters}
              key={filterColumns}
              screen={screen}
            />

            <Table
              columns={columns}
              rowKey={(record) => record.project_id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              bordered
              scroll={{ x: 400 }}
              size="small"
            />
          </Col>
        </Row>

        {isStatistics && (
          <SalesEstimateStatistics
            key={isStatistics}
            handleStatistics={this.handleStatistics}
            fromPage="details"
            id={this.props.match.params.id}
          />
        )}
      </React.Fragment>
    );
  }
}

export default SalesEstimateDetails;
