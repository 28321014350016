import React, { Component } from "react";
import { Row, Col, Button, message } from "antd";
import { ajaxRequester } from "../../services/ajax-requester";
import PageTitleDetails from "../../components/common/page-title-detail";
import InvoiceAddList from "../../components/sales-estimate/invoice-add-list";
import InvoiceEditList from "../../components/sales-estimate/invoice-edit-list";
import InvoiceAdd from "../../components/sales-estimate/invoice-add";
import InvoiceDetails from "../../components/sales-estimate/invoice-details";
import InvoiceFormat from "../../components/sales-estimate/invoice-format";
import * as dayjs from "dayjs";
import { globalVal } from "../../helpers/global";

class SalesEstimateAdd extends Component {
  state = {
    step: "select",
    rental_inventory_details: [],
    printing_inventory_details: [],
    installing_inventory_details: [],
    loading: false,
    id: this.props.match.params.id,
  };

  // Handle Step Change
  nextStep = (step) => {
    this.setState({ step });
    step === "create" && this.calcSummary();

    if (this.state.editMode && step === "select") {
      let ext_rental_inventory_details =
        [...this.state.rental_inventory_details] || [];
      let ext_printing_inventory_details =
        [...this.state.printing_inventory_details] || [];
      let ext_installing_inventory_details =
        [...this.state.installing_inventory_details] || [];

      this.setState({
        ext_rental_inventory_details,
        ext_printing_inventory_details,
        ext_installing_inventory_details,
        rental_inventory_details: [],
        printing_inventory_details: [],
        installing_inventory_details: [],
      });
    }

    if (this.state.editMode && step === "edit") {
      this.setState({
        rental_inventory_details: [
          ...(this.state.ext_rental_inventory_details || []),
          ...(this.state.rental_inventory_details || []),
        ],
        printing_inventory_details: [
          ...(this.state.ext_printing_inventory_details || []),
          ...(this.state.printing_inventory_details || []),
        ],
        installing_inventory_details: [
          ...(this.state.ext_installing_inventory_details || []),
          ...(this.state.installing_inventory_details || []),
        ],
        ext_rental_inventory_details: [],
        ext_printing_inventory_details: [],
        ext_installing_inventory_details: [],
      });
    }
  };

  // Set Form Values
  handleAddInvoiceForm = (data) => {
    this.setState({ ...data }, () => this.calcSummary());
  };

  // Redirect to Sales Estimate List
  handleBack = () => {
    this.props.history.goBack();
  };

  // Handle Selection from Lists
  handleSelection = (selectedRows, value) => {
    let rental_inventory_details = [...this.state.rental_inventory_details];
    let printing_inventory_details = [...this.state.printing_inventory_details];
    let installing_inventory_details = [
      ...this.state.installing_inventory_details,
    ];

    if (value === "Rent")
      rental_inventory_details = selectedRows.filter((x) => x);

    if (value === "Print")
      printing_inventory_details = selectedRows.filter((x) => x);

    if (value === "Mount")
      installing_inventory_details = selectedRows.filter((x) => x);

    this.setState({
      rental_inventory_details,
      printing_inventory_details,
      installing_inventory_details,
      selected_inventory_details: {
        rental_inventory_details,
        printing_inventory_details,
        installing_inventory_details,
      },
    });
  };

  // Handle Edit Mode
  handleEdit = (
    rental_inventory_details,
    printing_inventory_details,
    installing_inventory_details
  ) => {
    this.setState({
      rental_inventory_details,
      printing_inventory_details,
      installing_inventory_details,
    });
  };

  // Gettng Seller Details
  getsallerDetails = () => {
    let reqUrl = "configuration/detail";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          sallerDetails: data?.configuration_details,
        });
      },
      "GET"
    );
  };

  // Return Plan Days based on all inventory start and end dates
  getInvoiceDates = () => {
    let invoice_inventory = [...this.state.rental_inventory_details];

    let start_date =
      invoice_inventory.length > 0 ? invoice_inventory[0].start_date : dayjs();
    let end_date =
      invoice_inventory.length > 0 ? invoice_inventory[0].end_date : dayjs();
    let no_of_days = 0;

    invoice_inventory.map((x) =>
      dayjs(start_date).diff(dayjs(x.start_date), "days") > 0
        ? (start_date = x.start_date)
        : start_date
    );

    invoice_inventory.map((x) =>
      dayjs(x.end_date).diff(dayjs(end_date), "days") > 0
        ? (end_date = x.end_date)
        : end_date
    );

    no_of_days = dayjs(end_date).diff(dayjs(start_date), "days") + 1;

    let invoice_dates = {
      start_date: dayjs(start_date).format("YYYY-MM-DD"),
      end_date: dayjs(end_date).format("YYYY-MM-DD"),
      no_of_days: no_of_days,
    };

    return invoice_dates;
  };

  // Return days based on start date and end dates
  getDays = (start_date, end_date) => {
    let no_of_days = dayjs(end_date, "YYYY-MM-DD").diff(
      dayjs(start_date, "YYYY-MM-DD"),
      "days"
    );
    return no_of_days + 1;
  };

  // Calculate Cost
  calcSummary = () => {
    const {
      rental_inventory_details,
      printing_inventory_details,
      installing_inventory_details,
      selected_inventory_details,
      sallerDetails,
      place_of_supply_id,
      gstin,
    } = this.state;

    let gst_type =
      Number(gstin?.substring(0, 2)) ===
      Number(sallerDetails?.gst_number?.substring(0, 2))
        ? "C"
        : "I";

    let total_display_cost = 0;
    let total_printing_cost = 0;
    let total_installing_cost = 0;

    rental_inventory_details.map(
      (x) => (total_display_cost = total_display_cost + x.rental_cost)
    );
    printing_inventory_details.map(
      (x) => (total_printing_cost = total_printing_cost + x.printing_cost)
    );
    installing_inventory_details.map(
      (x) => (total_installing_cost = total_installing_cost + x.installing_cost)
    );
    let invoice_amount =
      total_display_cost + total_printing_cost + total_installing_cost;
    let igst_per = gst_type === "I" ? globalVal?.igst_per || 0 : 0;
    let igst_amount = gst_type === "I" ? (invoice_amount * igst_per) / 100 : 0;
    let cgst_per = gst_type === "C" ? globalVal?.cgst_per || 0 : 0;
    let cgst_amount = gst_type === "C" ? (invoice_amount * cgst_per) / 100 : 0;
    let sgst_per = gst_type === "C" ? globalVal?.sgst_per || 0 : 0;
    let sgst_amount = gst_type === "C" ? (invoice_amount * sgst_per) / 100 : 0;
    let total_amount = invoice_amount + igst_amount + cgst_amount + sgst_amount;

    let campaign_difference = 0;
    selected_inventory_details?.rental_inventory_details.map((x) =>
      rental_inventory_details.find(
        (y) =>
          y.operation_id === x.operation_id &&
          (campaign_difference =
            (y.rental_cost / y.no_of_days - x.rental_cost / x.no_of_days) *
              y.no_of_days +
            campaign_difference)
      )
    );

    selected_inventory_details?.printing_inventory_details.map((x) =>
      printing_inventory_details.find(
        (y) =>
          y.operation_id === x.operation_id &&
          (campaign_difference =
            y.printing_cost - x.printing_cost + campaign_difference)
      )
    );

    selected_inventory_details?.installing_inventory_details.map((x) =>
      installing_inventory_details.find(
        (y) =>
          y.operation_id === x.operation_id &&
          (campaign_difference =
            y.installing_cost - x.installing_cost + campaign_difference)
      )
    );

    let ha_base_rate = 0;
    let ta_base_rate = 0;
    let total_display_cost_ha = 0;
    let total_display_cost_ta = 0;

    rental_inventory_details.map(
      (x) => (
        x.inventory_type !== "TA" &&
          (ha_base_rate =
            ha_base_rate +
            (x.base_rate / 30) * this.getDays(x.start_date, x.end_date)),
        x.inventory_type !== "TA" &&
          (total_display_cost_ha = total_display_cost_ha + x.rental_cost),
        x.inventory_type === "TA" &&
          (ta_base_rate =
            ta_base_rate +
            (x.base_rate / 30) * this.getDays(x.start_date, x.end_date)),
        x.inventory_type === "TA" &&
          (total_display_cost_ta = total_display_cost_ta + x.rental_cost)
      )
    );

    let ha_markup = total_display_cost_ha - ha_base_rate;
    let ta_markup = total_display_cost_ta - ta_base_rate;
    let ha_markup_per =
      Number(((total_display_cost_ha - ha_base_rate) * 100) / ha_base_rate) ||
      0;
    let ta_markup_per =
      Number(((total_display_cost_ta - ta_base_rate) * 100) / ta_base_rate) ||
      0;

    let qos = ha_markup + ta_markup || 0;
    let qos_per =
      Number(((ha_markup + ta_markup) * 100) / (ha_base_rate + ta_base_rate)) ||
      0;

    this.setState({
      total_display_cost,
      total_printing_cost,
      total_installing_cost,
      invoice_amount,
      igst_per,
      igst_amount,
      cgst_per,
      cgst_amount,
      sgst_per,
      sgst_amount,
      total_amount,
      campaign_difference,
      ha_markup,
      ta_markup,
      ha_markup_per,
      ta_markup_per,
      duration_from: this.getInvoiceDates().start_date,
      duration_to: this.getInvoiceDates().end_date,
      due_date: sallerDetails?.current_date
        ? dayjs(sallerDetails?.current_date).add(
            sallerDetails?.invoice_due_date_days,
            "days"
          )
        : dayjs(),
      invoice_type: "E",
      sac_code: sallerDetails?.sac_code || "998366",
      qos,
      qos_per,
    });
  };

  // Create Invoice
  createInvoice = () => {
    this.setState({ loading: true });
    const { editMode } = this.state;

    let reqUrl = "salesestimate/invoice/" + (!editMode ? "create" : "update");
    let reqObj = {
      request: {
        ...this.state,
        sallerDetails: "",
        selected_inventory_details: "",
      },
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data.success) {
        message.success(
          "Invoice" + (!editMode ? " Created " : " Updated ") + "successfully"
        );
        this.handleBack();
      } else {
        message.error(
          data?.detail ||
            "An error occured while processing your request. " + data
        );
        this.setState({ loading: false });
      }
    });
  };

  // Get Inventory Details
  getInvoiceDetails = () => {
    const invoiceId = this.props.match.params.id;

    var reqUrl = "salesestimate/invoice/detail?invoiceid=" + Number(invoiceId);
    var reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState(
          {
            ...data.response,
            isLoading: false,
            id: data.response?.campaign_id,
          },
          () => this.setEditMode()
        );
      },
      "GET"
    );
  };

  setEditMode = () => {
    this.setState({
      selected_inventory_details: {
        rental_inventory_details: this.state.rental_inventory_details,
        printing_inventory_details: this.state.printing_inventory_details,
        installing_inventory_details: this.state.installing_inventory_details,
      },
      step: "create",
    });
  };

  componentDidMount() {
    this.getsallerDetails();
    let editMode = this.props.match.path.includes("sales-estimate-edit");
    this.setState({ editMode }, () => editMode && this.getInvoiceDetails());
  }

  render() {
    const {
      step,
      invoice_format_id,
      editMode,
      loading,
      rental_inventory_details,
      printing_inventory_details,
      installing_inventory_details,
    } = this.state;

    const hasDisabled =
      rental_inventory_details.length > 0 ||
      printing_inventory_details.length > 0 ||
      installing_inventory_details.length > 0;

    let titleText =
      step === "select"
        ? (!editMode ? "Creating" : "Updating") +
          " Invoice - Step 1 - Select Unbilled Items"
        : step === "edit"
        ? (!editMode ? "Creating" : "Updating") +
          " Invoice - Step 2 - Set Dates and Rates"
        : (!editMode ? "Creating" : "Updating") +
          " Invoice - Step 3 - Upload / Select PO and Confirm";

    const hasCreateDisabled =
      this.state.company_name &&
      // this.state.gstin &&
      // this.state.address &&
      this.state.place_of_supply_id &&
      this.state.invoice_format_id;

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageTitleDetails
              titleText={titleText}
              isBackBtn="true"
              handleBack={this.handleBack}
            />

            <div className="page-title-custom-btn sales-add-btns">
              {step === "select" && (
                <Button
                  type="primary"
                  onClick={() => this.nextStep("edit")}
                  disabled={!editMode && !hasDisabled}
                >
                  Step 2
                </Button>
              )}

              {step === "edit" && (
                <React.Fragment>
                  <Button
                    type="secondary"
                    onClick={() => this.nextStep("select")}
                    style={{ marginRight: "8px" }}
                  >
                    Step 1
                  </Button>

                  <Button
                    type="primary"
                    onClick={() => this.nextStep("create")}
                  >
                    Step 3
                  </Button>
                </React.Fragment>
              )}

              {step === "create" && (
                <React.Fragment>
                  <Button
                    type="secondary"
                    onClick={() => this.nextStep("edit")}
                    style={{ marginRight: "8px" }}
                  >
                    Step 2
                  </Button>

                  <Button
                    loading={loading}
                    type="primary"
                    onClick={this.createInvoice}
                    disabled={!hasCreateDisabled}
                  >
                    {!editMode ? "Create" : "Update"} Proforma Invoice
                  </Button>
                </React.Fragment>
              )}
            </div>

            {step === "select" && (
              <InvoiceAddList
                {...this.state}
                handleSelection={this.handleSelection}
              />
            )}

            {step === "edit" && (
              <InvoiceEditList {...this.state} handleEdit={this.handleEdit} />
            )}

            {step === "create" && (
              <React.Fragment>
                <InvoiceAdd
                  handleAddInvoiceForm={this.handleAddInvoiceForm}
                  {...this.state}
                />

                <h2
                  className="ant-border-b"
                  style={{ paddingBottom: "8px", marginBottom: "16px" }}
                >
                  Preview
                </h2>

                <InvoiceDetails {...this.state} />

                <div style={{ marginTop: "24px" }}>
                  <InvoiceFormat {...this.state} key={invoice_format_id} />
                </div>
              </React.Fragment>
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default SalesEstimateAdd;
