import React from "react";
import { Tabs } from "antd";
import CommonListBase from "../../base/common-list-base";
import PageTitle from "../../components/common/page-title";
import SalesMonthlyChart from "../../components/dashboard/sales-monthly-chart";
import SalesCityChart from "../../components/dashboard/sales-city-chart";
import SalesEstimateChart from "../../components/dashboard/sales-estimate-chart";
import SalesEmployeeChart from "../../components/dashboard/sales-employee-chart";
import SalesCityList from "../../components/dashboard/sales-city-list";
import SalesEstimateList from "../../components/dashboard/sales-estimate-list";
import SalesEmployeeList from "../../components/dashboard/sales-employee-list";
import OccupancyMonthlyList from "../../components/dashboard/occupancy-monthly-list";

class SalesDashboard extends CommonListBase {
  state = {
    active: "month",
  };

  tabChange = (key) => {
    this.setState({ active: key });
  };

  render() {
    const { active } = this.state;
    const { TabPane } = Tabs;

    return (
      <div>
        <PageTitle titleText="Occupancy Dashboard" />

        <div style={{ height: "480px", marginBottom: "42px" }}>
          {active === "month" && <SalesMonthlyChart />}
          {active === "city" && <SalesCityChart />}
          {active === "payoutestimate" && <SalesEstimateChart />}
          {active === "employee" && <SalesEmployeeChart />}
        </div>

        <Tabs defaultActiveKey={active} onChange={this.tabChange}>
          <TabPane tab="Monthly Sales" key="month">
            <OccupancyMonthlyList />
          </TabPane>

          <TabPane tab="City Wise" key="city">
            <SalesCityList />
          </TabPane>

          <TabPane tab="Payout Estimate Wise" key="payoutestimate">
            <SalesEstimateList />
          </TabPane>

          <TabPane tab="Employee Wise" key="employee">
            <SalesEmployeeList />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default SalesDashboard;
