import { dateFormatter } from "../../components/common/common-formatter";

const ExcelJS = require("exceljs");

export const exportOperationsToXlsx = (
  reportName,
  inventory_details,
  configuration,
  settings
) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet(reportName);
  let inventories = inventory_details || [];

  const getEmployee = (employee_id) => {
    let employeeDetails = localStorage.getItem("user:" + employee_id);
    employeeDetails = JSON.parse(employeeDetails);
    let employeeName =
      employeeDetails?.data?.firstName + " " + employeeDetails?.data?.lastName;

    return employeeName || "";
  };

  inventories = inventories.map((x, key) => ({
    ...x,
    sr: key + 1,
    location: x?.location,
    quantity: x?.quantity,
    display_qty: x?.quantity * (x?.side_type === "double" ? 2 : 1),
    unmount_display_name: x?.unmount_display_name,
    mount_display_name: x?.mount_display_name,
    assigned_labour_id: x?.assigned_labour_id
      ? getEmployee(x?.assigned_labour_id)
      : "",
    job_date: dateFormatter(x?.job_date),
    job_operation_status: x?.job_operation_status,
    sqft: x?.sqft,
    light_type: x?.light_type,
  }));

  let columns = [
    { title: "Sr", columnName: "sr", width: 4, align: "center" },
    { title: "Location", columnName: "location", width: 62, align: "left" },
    { title: "SQFT", columnName: "sqft", width: 6, align: "center" },
    { title: "Light", columnName: "light_type", width: 6, align: "center" },
    { title: "Qty", columnName: "quantity", width: 4, align: "center" },
    {
      title: "Display Qty",
      columnName: "display_qty",
      width: 14,
      align: "center",
    },
    {
      title: "Unmount Display",
      columnName: "unmount_display_name",
      width: 24,
      align: "left",
    },
    {
      title: "Mount Display",
      columnName: "mount_display_name",
      width: 24,
      align: "left",
    },
    {
      title: "Assigned Mounter",
      columnName: "assigned_labour_id",
      width: 20,
      align: "left",
    },
    {
      title: "Job Date",
      columnName: "job_date",
      width: 12,
      align: "center",
    },
    {
      title: "Operations Status",
      columnName: "job_operation_status",
      width: 24,
      align: "left",
    },
  ];

  settings?.length > 0 &&
    (columns = columns.filter((x) => settings.includes(x.title)));

  const getColAddress = (add) => {
    let address = add.replace(/[0-9]/g, "");
    return address;
  };

  // Header Rows
  sheet.addRow([reportName]);
  sheet.getCell("A1").font = {
    bold: true,
    size: 14,
  };

  // Adding Colums Title
  let titleRowNum = 2;
  sheet.addRow(columns.map((x) => x.title));
  sheet.getRow(titleRowNum).eachCell((cell, index) => {
    cell.font = {
      bold: true,
    };
    cell.border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    cell.alignment = { horizontal: columns[index - 1]?.align };
    sheet.getColumn(getColAddress(cell.address)).width =
      columns[index - 1].width || 0;
  });

  // Adding Sites
  const numFmt = "#,##0";
  let total = 0;
  let total_printing_cost = 0;
  let total_installing_cost = 0;
  let total_monthly_rate = 0;
  let total_amount = 0;
  let gst_amount = 0;
  let grand_total = 0;
  let numRows = 0;
  let siteRows = [];

  inventories.forEach((inventory) => {
    total += inventory.rental_cost;
    total_printing_cost += inventory.printing_cost;
    total_installing_cost += inventory.installing_cost;
    total_monthly_rate += inventory.monthly_rate;
    total_amount += inventory.total_amount;
    gst_amount += inventory.gst_amount;
    grand_total += inventory.grand_total;
    numRows += 1;

    sheet.addRow(columns.map((x) => inventory[x.columnName] || ""));

    if (inventory.ha_base_inventories?.length > 0) {
      siteRows.push("subrow");
      numRows += 1;
      inventory.ha_base_inventories.forEach((groupInventory, gIndex) => {
        sheet.addRow(columns.map((x) => groupInventory[x.columnName] || ""));
        siteRows.push("subrow");
      });
    } else {
      siteRows.push("row");
    }
  });

  siteRows.forEach((row, count) => {
    sheet.getRow(titleRowNum).eachCell((cell, index) => {
      let newcell = sheet.getCell(
        getColAddress(cell.address) + (titleRowNum + count + 1)
      );
      newcell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      newcell.alignment = { horizontal: columns[index - 1]?.align };
      columns[index - 1]?.numberFormat && (newcell.numFmt = numFmt);
      row === "subrow" &&
        (newcell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "F2F2F2" },
        });
    });
  });

  // Exporting File
  workbook.xlsx.writeBuffer().then((buffer) => {
    let blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    let a = document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.style.display = "none";
    a.download = configuration?.exportName ?? "export.xlsx";
    document.body.appendChild(a);
    a.click();
  });
};
