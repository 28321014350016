import React, { Component } from "react";
import { Row, Col, Button } from "antd";
import {
  dateFormatter,
  currencyFormatter,
} from "../../components/common/common-formatter";
import { DownloadOutlined } from "@ant-design/icons";
import PortalUser from "../../iam/components/portal-user";

class InvoiceDetails extends Component {
  state = {};

  render() {
    const results = this.props;
    const { sallerDetails } = results;

    return (
      <Row gutter="16">
        <Col span={10} xs={24} xl={10}>
          {sallerDetails && (
            <Row className="invoice-box">
              <Col span={24}>
                <div>Seller,</div>
                <div>
                  <b>{sallerDetails?.provider_name}</b>
                </div>
                <div>
                  {sallerDetails?.address1 +
                    " " +
                    sallerDetails?.address2 +
                    ", "}
                </div>
                <div>
                  {sallerDetails?.city_name + " - " + sallerDetails?.pin_code}
                </div>
                <div>GSTIN : {sallerDetails?.gst_number}</div>
                <div>State Name : {sallerDetails?.state_name}</div>
                <div>PAN : {sallerDetails?.pan}</div>
              </Col>
            </Row>
          )}

          <Row style={{ marginTop: "16px" }} className="invoice-box">
            <Col span={24}>
              <div>Buyer,</div>
              <div>
                <b>{results?.company_name}</b>
              </div>
              <div>{results?.address}</div>
              <div>GSTIN : {results?.gstin}</div>
              <div>Place of Supply : {results?.place_of_supply}</div>
              <div>State Name : {results?.place_of_supply}</div>
              <div>PAN : {results?.pan}</div>
            </Col>
          </Row>

          <Row style={{ marginTop: "16px" }} className="invoice-box">
            <Col>
              SAC Code : <b> {results?.sac_code}</b>
            </Col>
          </Row>
        </Col>

        <Col span={7} xs={24} xl={7}>
          <Row className="invoice-box invoice-box-right">
            <Col span={24}>
              <div style={{ marginBottom: "12px" }}>
                Invoice Number :
                <b> {results?.invoice_no || results?.proforma_invoice_no}</b>
              </div>
              <div style={{ marginBottom: "12px" }}>
                Invoice Date :
                <b>
                  {" "}
                  {results?.invoice_date &&
                    dateFormatter(results?.invoice_date)}
                </b>
              </div>
              <div style={{ marginBottom: "12px" }}>
                Project Id :
                <b> {results?.project_id || results?.proforma_project_id}</b>
              </div>
              <div style={{ marginBottom: "12px" }}>
                PO Number :<b> {results?.po_number}</b>
                {results?.po_url && (
                  <Button
                    icon={<DownloadOutlined />}
                    className="ant-btn-reset"
                    type="link"
                    disabled={results?.po_url ? false : true}
                    onClick={() => window.open(results?.po_url)}
                    style={{ marginLeft: "8px" }}
                  />
                )}
              </div>
              <div style={{ marginBottom: "12px" }}>
                PO Date :
                <b>
                  {" "}
                  {results?.po_date &&
                    results?.po_date !== "0001-01-01T00:00:00" &&
                    dateFormatter(results?.po_date)}
                </b>
              </div>
              <div style={{ marginBottom: "12px" }}>
                Display Name :<b> {results?.display_name}</b>
              </div>
              <div style={{ marginBottom: "12px" }}>
                From :
                <b>
                  {" "}
                  {results?.duration_from &&
                    dateFormatter(results?.duration_from)}
                </b>
              </div>
              <div style={{ marginBottom: "12px" }}>
                To :
                <b>
                  {" "}
                  {results?.duration_to && dateFormatter(results?.duration_to)}
                </b>
              </div>
              <div style={{ marginBottom: "12px" }}>
                Invoice Due :<b> {dateFormatter(results?.due_date)}</b>
              </div>
              <div style={{ marginBottom: "12px" }}>
                Business By :{" "}
                <b style={{ display: "inline-block" }}>
                  <PortalUser user={results?.employee_id} />
                </b>
              </div>
              <div>
                Notes :<b> {results?.notes}</b>
              </div>
            </Col>
          </Row>
        </Col>

        <Col span={6} xs={24} xl={6}>
          <Row className="invoice-box invoice-box-last">
            <Col span={24}>
              <div style={{ marginBottom: "12px" }}>
                <span style={{ display: "inline-block", width: "50%" }}>
                  Campaign Difference :
                </span>
                <b
                  style={{
                    display: "inline-block",
                    width: "50%",
                    textAlign: "right",
                  }}
                  className={
                    results?.campaign_difference > 0
                      ? "ant-color-green"
                      : "ant-color-red"
                  }
                >
                  {currencyFormatter(parseInt(results?.campaign_difference))}
                </b>
              </div>
              <div style={{ marginBottom: "12px" }}>
                <span style={{ display: "inline-block", width: "50%" }}>
                  HA Markup :
                </span>
                <b
                  style={{
                    display: "inline-block",
                    width: "50%",
                    textAlign: "right",
                  }}
                  className={
                    results?.ha_markup > 0 ? "ant-color-green" : "ant-color-red"
                  }
                >
                  {currencyFormatter(parseInt(results?.ha_markup))}
                  {(results?.ha_markup_per || results?.ha_markup_per !== 0) &&
                    " (" + (parseInt(results?.ha_markup_per) || 0) + "%)"}
                </b>
              </div>
              <div style={{ marginBottom: "12px" }}>
                <span style={{ display: "inline-block", width: "50%" }}>
                  TA Markup :
                </span>
                <b
                  style={{
                    display: "inline-block",
                    width: "50%",
                    textAlign: "right",
                  }}
                  className={
                    results?.ta_markup > 0 ? "ant-color-green" : "ant-color-red"
                  }
                >
                  {currencyFormatter(parseInt(results?.ta_markup))}
                  {(results?.ta_markup_per || results?.ta_markup_per !== 0) &&
                    " (" + (parseInt(results?.ta_markup_per) || 0) + "%)"}
                </b>
              </div>
              <div style={{ marginBottom: "12px" }}>
                <span style={{ display: "inline-block", width: "50%" }}>
                  Rent Amount :
                </span>
                <b
                  style={{
                    display: "inline-block",
                    width: "50%",
                    textAlign: "right",
                  }}
                >
                  {currencyFormatter(parseInt(results?.total_display_cost))}
                </b>
              </div>
              <div style={{ marginBottom: "12px" }}>
                <span style={{ display: "inline-block", width: "50%" }}>
                  Printing Amount :
                </span>
                <b
                  style={{
                    display: "inline-block",
                    width: "50%",
                    textAlign: "right",
                  }}
                >
                  {currencyFormatter(parseInt(results?.total_printing_cost))}
                </b>
              </div>
              <div style={{ marginBottom: "12px" }}>
                <span style={{ display: "inline-block", width: "50%" }}>
                  Mounting Amount :
                </span>
                <b
                  style={{
                    display: "inline-block",
                    width: "50%",
                    textAlign: "right",
                  }}
                >
                  {currencyFormatter(parseInt(results?.total_installing_cost))}
                </b>
              </div>
              <div style={{ marginBottom: "12px" }}>
                <span style={{ display: "inline-block", width: "50%" }}>
                  SubTotal :
                </span>
                <b
                  style={{
                    display: "inline-block",
                    width: "50%",
                    textAlign: "right",
                  }}
                >
                  {currencyFormatter(parseInt(results?.invoice_amount))}
                </b>
              </div>

              {results?.igst_amount !== 0 && (
                <div style={{ marginBottom: "8px" }}>
                  <span style={{ display: "inline-block", width: "50%" }}>
                    IGST @ 18%
                  </span>
                  <b
                    style={{
                      display: "inline-block",
                      width: "50%",
                      textAlign: "right",
                    }}
                  >
                    {currencyFormatter(parseInt(results?.igst_amount))}
                  </b>
                </div>
              )}

              {results?.igst_amount === 0 && (
                <div style={{ marginBottom: "8px" }}>
                  <span style={{ display: "inline-block", width: "50%" }}>
                    CGST @ 9%
                  </span>
                  <b
                    style={{
                      display: "inline-block",
                      width: "50%",
                      textAlign: "right",
                    }}
                  >
                    {currencyFormatter(parseInt(results?.cgst_amount))}
                  </b>
                </div>
              )}

              {results?.igst_amount === 0 && (
                <div style={{ marginBottom: "8px" }}>
                  <span style={{ display: "inline-block", width: "50%" }}>
                    SGST @ 9%
                  </span>
                  <b
                    style={{
                      display: "inline-block",
                      width: "50%",
                      textAlign: "right",
                    }}
                  >
                    {currencyFormatter(parseInt(results?.sgst_amount))}
                  </b>
                </div>
              )}

              <div className="ant-border-t" style={{ paddingTop: "8px" }}>
                <b style={{ display: "inline-block", width: "50%" }}>Total :</b>
                <b
                  style={{
                    display: "inline-block",
                    width: "50%",
                    textAlign: "right",
                  }}
                >
                  {currencyFormatter(parseInt(results?.total_amount))}
                </b>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default InvoiceDetails;
