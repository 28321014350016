import React, { Component } from "react";
import { ajaxRequester } from "../../services/ajax-requester";
import {
  Row,
  Col,
  Spin,
  message,
  Button,
  Drawer,
  Form,
  Input,
  Modal,
} from "antd";
import { CheckOutlined, CheckCircleOutlined } from "@ant-design/icons";
import PageTitleDetails from "../../components/common/page-title-detail";
import TransactionsHistoryList from "../../components/purchase/transactions-history-list";
import TransactionsDetails from "../../components/purchase/transactions-details";
import TransactionsPayment from "../../components/purchase/transactions-payment";
import * as dayjs from "dayjs";
import EstimateChangeLog from "../../components/purchase/estimate-changelog";
import AdminApi from "../../iam/api/admin-api";
import TransactionsActivityLog from "../../components/purchase/transactions-activity-log";
import TransactionsSiteList from "../../components/purchase/transactions-site-list";

class TransactionsAddEdit extends Component {
  state = {
    results: "",
    isLoading: true,
    page: "transactions-add-edit",
    mode: this.props.match.path.includes("add") ? "add" : "update",
    isDrawer: false,
    loading: false,
    isActivityLog: false,
  };

  // Get Transactions Details
  getDetails = () => {
    const { id } = this.props.match.params;
    const { mode } = this.state;

    let reqUrl = "scheduledtransaction/detail?pendingEstimateId=" + id;
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ results: data?.response, isLoading: false });
      },
      "GET"
    );
  };

  // Redirect to List
  handleBack = () => {
    this.props.history.goBack();
  };

  handlePayment = (values) => {
    const { mode, results, file } = this.state;
    const { id } = this.props.match.params;

    values.pending_estimate_id = Number(id);
    values.payment_date = dayjs();

    if (mode === "update") {
      values.payment_detail_id = results?.payment_detail?.payment_detail_id;
    }

    let reqUrl = "scheduledtransaction/payment/" + mode;
    let reqObj = {
      request: {
        ...values,
        cheque_date:
          values?.cheque_date &&
          values?.cheque_date.format("YYYY-MM-DDTHH:mm:ss"),
      },
    };
    ajaxRequester(reqUrl, reqObj, (data) => {
      if (mode === "add" ? data?.payment_detail_id : data.success) {
        message.success("Payment has been added successfully");
        file && this.handleUpload(data?.payment_detail_id);
        this.handleBack();
      } else {
        message.error(
          data?.detail ||
            data?.title ||
            "An error occured while processing your request."
        );
      }
    });
  };

  handleUpload = (ref_id) => {
    const { results, file } = this.state;
    let payment_detail_id = results?.payment_detail?.payment_detail_id;

    let reqUrl = "scheduledtransaction/payment/document/upload";
    let request = file;
    request.reference_id = Number(ref_id || payment_detail_id);

    let reqObj = {
      request,
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      if (!data) {
        message.error("Document Upload Failed");
      }
    });
  };

  // Handle Drawer
  handleDrawer = () => {
    this.setState({ isDrawer: !this.state.isDrawer });
  };

  handleApproval = (values) => {
    const { id } = this.props.match.params;

    let reqUrl = "scheduledtransaction/approve";
    let reqObj = {
      request: {
        pending_estimate_id: Number(id),
        approved_date: dayjs(),
        approved_by_id: AdminApi?.getCurrentUser()?.Id,
        remarks: values?.remarks,
      },
    };
    ajaxRequester(reqUrl, reqObj, (data) => {
      if (data.success) {
        message.success("Scheduled Transaction has been Approved");
        this.handleBack();
        this.handleDrawer();
      } else {
        message.error(
          data?.detail ||
            data?.title ||
            "An error occured while processing your request."
        );
      }
    });
  };

  handleActivityLog = () => {
    this.setState({ isActivityLog: !this.state.isActivityLog });
  };

  componentDidMount() {
    this.getDetails();
  }

  render() {
    const { results, isLoading, isDrawer, loading, isActivityLog } = this.state;

    const { TextArea } = Input;

    const layout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };

    return (
      <div>
        <Row>
          <Col span={24}>
            {!isLoading && (
              <React.Fragment>
                <PageTitleDetails
                  titleText={
                    <React.Fragment>
                      Paying {" - "}
                      {results?.payout_estimate_payee_list[0]?.account_name}
                      {results?.tenure_status && (
                        <span
                          style={{
                            background: "#ff4d4f",
                            padding: "4px 8px",
                            fontSize: "15px",
                            borderRadius: "8px",
                            marginLeft: "16px",
                            color: "#fff",
                          }}
                        >
                          Expired
                        </span>
                      )}
                    </React.Fragment>
                  }
                  isBackBtn="true"
                  handleBack={this.handleBack}
                />

                {!results?.is_approved && (
                  <div className="page-title-custom-btn">
                    <Button
                      type="primary"
                      className="custom-btn-approve icon-btn"
                      onClick={() => this.handleDrawer()}
                      icon={<CheckOutlined />}
                      style={{ marginRight: "0px" }}
                    >
                      Approve
                    </Button>
                  </div>
                )}

                <TransactionsDetails {...this.state} />

                {results?.is_approved && (
                  <TransactionsPayment
                    {...this.state}
                    handlePayment={this.handlePayment}
                    handleUploadFile={(file) => this.setState({ file })}
                    handleActivityLog={this.handleActivityLog}
                  />
                )}

                <TransactionsHistoryList {...results} />

                <TransactionsSiteList {...results} />

                <EstimateChangeLog {...results} />
              </React.Fragment>
            )}

            {isLoading && (
              <div className="list-loader">
                <Spin />
              </div>
            )}
          </Col>
        </Row>

        <Drawer
          title={"Transactions Approval"}
          placement="right"
          open={isDrawer}
          onClose={this.handleDrawer}
          width={"424px"}
          key={isDrawer}
        >
          <Form
            name="transactions_approval"
            className="transactions_approval"
            onFinish={this.handleApproval}
            {...layout}
          >
            <Form.Item label="Remarks" name="remarks">
              <TextArea rows={4} placeholder="Remarks" />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 6 }}>
              <Button
                type="primary"
                icon={<CheckCircleOutlined />}
                htmlType="submit"
                loading={loading}
              >
                Approve
              </Button>
            </Form.Item>
          </Form>
        </Drawer>

        <Modal
          title={"Activity Log"}
          open={isActivityLog}
          key={isActivityLog}
          onCancel={() => this.setState({ isActivityLog: false })}
          footer={null}
          centered
        >
          <TransactionsActivityLog key={isActivityLog} {...results} />
        </Modal>
      </div>
    );
  }
}

export default TransactionsAddEdit;
