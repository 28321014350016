import React, { Component } from "react";
import { Row, Col, Table } from "antd";
import {
  dateFormatter,
  currencyFormatter,
} from "../../components/common/common-formatter";
import PortalUser from "../../iam/components/portal-user";

class POPrint extends Component {
  render() {
    const printColumns = [
      {
        title: "Description",
        dataIndex: "description",
        render: (text, record) =>
          text
            ? text
            : record.inventory_code +
              " - " +
              record.city +
              ", " +
              record.area +
              ", " +
              record.location +
              " - " +
              record.size +
              " " +
              (record.light_type ? record.light_type : "") +
              (record?.notes ? " - " + "(Notes: " + record?.notes + ")" : "") +
              (record?.quantity && record?.quantity > 1
                ? " (Qty : " +
                  record?.quantity +
                  " / Display : " +
                  (record?.side_type === "double"
                    ? record?.quantity * 2
                    : record?.quantity) +
                  ")"
                : ""),
      },
      {
        title: "Start Date",
        dataIndex: "start_date",
        render: (text) => (text ? dateFormatter(text) : ""),
        width: 124,
      },
      {
        title: "End Date",
        dataIndex: "end_date",
        render: (text) => (text ? dateFormatter(text) : ""),
        width: 124,
      },
      {
        title: "Days",
        dataIndex: "no_of_days",
        width: 92,
      },
      {
        title: "Monthly Rate",
        dataIndex: "monthly_rate",
        render: (text) => (text ? currencyFormatter(parseInt(text)) : ""),
        width: 168,
        align: "right",
      },
      {
        title: "Cost",
        dataIndex: "rental_cost",
        align: "right",
        width: 168,
        render: (text, record) =>
          text
            ? currencyFormatter(parseInt(text))
            : record?.manual_cost
            ? currencyFormatter(parseInt(record?.manual_cost))
            : "-",
      },
    ];

    const results = this?.props?.results;

    const configuration_details =
      this?.props?.configurationDetails?.configuration_details;

    const poTerms = this.props?.poTerms || [];

    const inventory_details =
      this?.props?.results?.plan_inventory_details ||
      this?.props?.results?.campaign_inventory_details ||
      [];

    let print_inventory_details = inventory_details.map((x) => ({ ...x }));

    const { total_printing_cost, total_installing_cost } = this?.props?.results;

    let total_printing_sqft = 0;
    let total_installing_sqft = 0;

    print_inventory_details.map(
      (x) => (total_printing_sqft = total_printing_sqft + x?.sqft)
    );

    print_inventory_details.map(
      (x) => (total_installing_sqft = total_installing_sqft + x?.sqft)
    );

    let printing_inventory_cost = [];
    print_inventory_details.reduce((res, value) => {
      if (!res[value.printing_cost / value.sqft]) {
        res[value.printing_cost / value.sqft] = {
          printing_cost: value.printing_cost / value.sqft,
          sqft: 0,
        };
        printing_inventory_cost.push(res[value.printing_cost / value.sqft]);
      }
      res[value.printing_cost / value.sqft].sqft += value.sqft;
      return res;
    }, {});

    let installing_inventory_cost = [];
    print_inventory_details.reduce((res, value) => {
      if (!res[value.installing_cost / value.sqft]) {
        res[value.installing_cost / value.sqft] = {
          installing_cost: value.installing_cost / value.sqft,
          sqft: 0,
        };
        installing_inventory_cost.push(res[value.installing_cost / value.sqft]);
      }
      res[value.installing_cost / value.sqft].sqft += value.sqft;
      return res;
    }, {});

    if (total_printing_cost) {
      print_inventory_details.push({
        ha_inventory_id: "total_printing_cost",

        description:
          printing_inventory_cost &&
          printing_inventory_cost.map((x) => (
            <div>
              {"Printing " +
                x.sqft +
                " SQFT @ " +
                currencyFormatter(parseInt(x.printing_cost)) +
                "RS"}
            </div>
          )),
        rental_cost: total_printing_cost,
      });
    }

    if (total_installing_cost) {
      print_inventory_details.push({
        ha_inventory_id: "total_installing_cost",
        description:
          installing_inventory_cost &&
          installing_inventory_cost.map((x) => (
            <div>
              {"Mounting " +
                x.sqft +
                " SQFT @ " +
                currencyFormatter(parseInt(x.installing_cost)) +
                "RS"}
            </div>
          )),
        rental_cost: total_installing_cost,
      });
    }

    return (
      <React.Fragment>
        <div className="invoice-print-title" style={{ marginTop: "62px" }}>
          <Row>
            <Col span={12}>
              <h2>
                ADVERTISING SERVICES
                <h4>PURCHASE ORDER</h4>
              </h2>
            </Col>
          </Row>
        </div>

        <div
          style={{
            borderBottom: "solid 1px #999",
            paddingLeft: "10px",
            paddingBottom: "12px",
            marginTop: "12px",
          }}
        >
          <Row gutter="16">
            <Col span={14}>
              {configuration_details && (
                <Row>
                  <Col span={24}>
                    <div>To,</div>
                    <div>
                      <b>{configuration_details?.provider_name}</b>
                    </div>
                    <div>
                      {configuration_details?.address1 +
                        " " +
                        configuration_details?.address2 +
                        ", "}
                    </div>
                    <div>
                      {configuration_details?.city_name +
                        " - " +
                        configuration_details?.pin_code}
                    </div>
                    <div>GSTIN : {configuration_details?.gst_number}</div>
                    <div>State Name : {configuration_details?.state_name}</div>
                    <div>PAN : {configuration_details?.pan}</div>
                  </Col>
                </Row>
              )}
            </Col>

            <Col span={10}>
              <Row style={{ marginTop: "12px" }}>
                <Col span={24}>
                  <div>
                    <b>Display Name :</b> {results?.display_name}
                  </div>
                  <div>
                    <b>PO No :</b>
                  </div>
                  <div>
                    <b>PO Date :</b> {dateFormatter()}
                  </div>
                  <div>
                    <b>Point of Contact :</b>{" "}
                    <span style={{ display: "inline-block" }}>
                      <PortalUser user={results?.employee_id} />
                    </span>
                  </div>

                  {this.props?.is_client_approved && (
                    <React.Fragment>
                      <div>
                        <b>Campaign Difference :</b>{" "}
                        <span
                          style={{
                            display: "inline-block",
                          }}
                        >
                          {currencyFormatter(
                            parseInt(results?.campaign_difference)
                          )}
                        </span>
                      </div>
                      <div>
                        <b>HA Markup :</b>{" "}
                        <span
                          style={{
                            display: "inline-block",
                          }}
                        >
                          {currencyFormatter(parseInt(results?.ha_markup))}
                          {(results?.ha_markup_per ||
                            results?.ha_markup_per !== 0) &&
                            " (" +
                              (parseInt(results?.ha_markup_per) || 0) +
                              "%)"}
                        </span>
                      </div>
                      <div>
                        <b>TA Markup :</b>{" "}
                        <span
                          style={{
                            display: "inline-block",
                          }}
                        >
                          {currencyFormatter(parseInt(results?.ta_markup))}
                          {(results?.ta_markup_per ||
                            results?.ta_markup_per !== 0) &&
                            " (" +
                              (parseInt(results?.ta_markup_per) || 0) +
                              "%)"}
                        </span>
                      </div>
                    </React.Fragment>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <div style={{ marginTop: "12px" }}>
          <h2 style={{ marginBottom: "12px" }}>SUMMARY OF CHARGES</h2>
          <Table
            columns={printColumns}
            dataSource={print_inventory_details}
            pagination={false}
            loading={false}
            bordered
            scroll={{ x: 400 }}
            size="small"
          />
        </div>

        <div style={{ marginTop: "8px", marginBottom: "-8px" }}>
          <b>SAC : {configuration_details?.sac_code}</b>
        </div>

        <Row>
          <Col span={16}></Col>
          <Col span={8}>
            <div
              style={{
                marginBottom: "5px",
                paddingBottom: "5px",
                borderBottom: "solid 1px #999",
              }}
            >
              <b style={{ display: "inline-block", width: "50%" }}>
                Display Cost :
              </b>
              <b
                style={{
                  display: "inline-block",
                  width: "50%",
                  textAlign: "right",
                }}
              >
                {currencyFormatter(parseInt(results?.total_display_cost))}
              </b>
            </div>

            {results?.total_printing_cost ? (
              <div style={{ marginBottom: "5px" }}>
                <span style={{ display: "inline-block", width: "50%" }}>
                  Printing Cost :
                </span>
                <span
                  style={{
                    display: "inline-block",
                    width: "50%",
                    textAlign: "right",
                  }}
                >
                  {currencyFormatter(parseInt(results?.total_printing_cost))}
                </span>
              </div>
            ) : (
              ""
            )}

            {results?.total_installing_cost ? (
              <div style={{ marginBottom: "5px" }}>
                <span style={{ display: "inline-block", width: "50%" }}>
                  Installation Cost :
                </span>
                <span
                  style={{
                    display: "inline-block",
                    width: "50%",
                    textAlign: "right",
                  }}
                >
                  {currencyFormatter(parseInt(results?.total_installing_cost))}
                </span>
              </div>
            ) : (
              ""
            )}

            {results?.gst_amount ? (
              <div style={{ marginBottom: "5px" }}>
                <span style={{ display: "inline-block", width: "50%" }}>
                  {"GST (" + results?.gst_per + "%)"}
                </span>
                <span
                  style={{
                    display: "inline-block",
                    width: "50%",
                    textAlign: "right",
                  }}
                >
                  {currencyFormatter(parseInt(results?.gst_amount))}
                </span>
              </div>
            ) : (
              ""
            )}

            <div
              className="ant-border-t"
              style={{
                paddingTop: "5px",
                paddingBottom: "5px",
                borderTop: "solid 1px #999",
                borderBottom: "solid 1px #999",
              }}
            >
              <b style={{ display: "inline-block", width: "50%" }}>
                Total in INR :
              </b>
              <b
                style={{
                  display: "inline-block",
                  width: "50%",
                  textAlign: "right",
                }}
              >
                {currencyFormatter(parseInt(results?.total_amount))}
              </b>
            </div>
          </Col>
        </Row>

        {poTerms && poTerms?.length > 0 && (
          <div
            style={{
              border: "solid 1px #999",
              padding: "8px",
              marginTop: "12px",
            }}
          >
            <b>Terms and Conditions - </b>
            <ol style={{ paddingLeft: "16px" }}>
              {poTerms?.map((x, key) => (
                <li style={{ paddingLeft: "8px" }} key={key}>
                  {x}
                </li>
              ))}
            </ol>
          </div>
        )}

        <div
          style={{
            border: "solid 1px #999",
            padding: "8px",
            marginTop: "24px",
            width: "40%",
            height: "100px",
          }}
        >
          <Row>
            <Col span={24}>
              <div>For,</div>
              <div>
                <b>{this?.props?.print_company_name}</b>
                <br />
                <span style={{ fontSize: "11px" }}>
                  {this?.props?.print_gstin}
                </span>
              </div>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default POPrint;
