import React from "react";
import { Row, Col, Table, Button, Popconfirm } from "antd";
import PageTitle from "../../components/common/page-title";
import ListFilters from "../../components/common/list-filters";
import CommonListBase from "../../base/common-list-base";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import PortalUser from "../../iam/components/portal-user";
import { RolesPermissions } from "../../helpers/roles-permissions";

class PayoutEstimate extends CommonListBase {
  state = {
    listReq: "payoutestimate/list?",
    delReq: "payoutestimate/delete?payoutEstimateId=",
    dtlReq: "operation",
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    sorting: [],
    screen: "payout-estimate",
    isDrawer: false,
    record: "",
    isEditMode: false,
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "PEID",
        dataIndex: "peid",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Type",
        dataIndex: "estimate_type",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "District",
        dataIndex: "district_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "City",
        dataIndex: "city_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Authority ID",
        dataIndex: "authority_id",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Payout Estimate Name",
        dataIndex: "payout_estimate_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Payout From",
        dataIndex: "payout_from_account_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Payment Approval",
        dataIndex: "payout_approval_by_id",
        render: (text) => <PortalUser user={text} />,
      },
      {
        title: "Status",
        dataIndex: "is_active",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text) => (text ? "Active" : "Inactive"),
      },
      {
        title: "Actions",
        dataIndex: "",
        key: "x",
        render: (record) => (
          <React.Fragment>
            <Button
              icon={<EditOutlined />}
              className="ant-btn-reset"
              onClick={() => this.handleEdit(record.payout_estimate_id)}
            />
            {RolesPermissions("su") && (
              <Popconfirm
                title="Are you sure want to delete?"
                placement="left"
                okText="Yes"
                cancelText="No"
                onConfirm={() => this.deleteItem(record.payout_estimate_id)}
              >
                <Button
                  icon={<DeleteOutlined />}
                  className="ant-btn-reset"
                  style={{ marginLeft: "12px" }}
                />
              </Popconfirm>
            )}
          </React.Fragment>
        ),
        align: "center",
      },
    ];

    const filterColumns = [
      { columnName: "peid", displayName: "PEID" },
      {
        columnName: "estimate_type",
        displayName: "Type",
      },
      { columnName: "district_name", displayName: "District" },
      { columnName: "city_name", displayName: "City" },
      { columnName: "authority_id", displayName: "Authority ID" },
      {
        columnName: "payout_estimate_name",
        displayName: "Payout Estimate Name",
        default: true,
      },
      {
        columnName: "payout_from_account_name",
        displayName: "Payout From",
      },
      {
        columnName: "payout_approval_by_id",
        displayName: "Payment Approval",
        hideFilter: true,
      },
      { columnName: "is_active", displayName: "Status", hideFilter: true },
    ];

    this.setState({ columnsDefault: columns, filterColumns }, () =>
      this.setDefaultColumns(columns)
    );
  };

  //Add Payout Estimate
  handleAdd = () => {
    this.props.history.push("/purchase/payout-estimate-add");
  };

  //Edit Payout Estimate
  handleEdit = (payout_estimate_id) => {
    this.props.history.push(
      "/purchase/payout-estimate-edit/" + payout_estimate_id
    );
  };

  handleActiveToggle = (e) => {
    const { pagination } = this.state;
    let listReq = this.state.listReq.includes("showInactive=true&")
      ? this.state.listReq.replace("showInactive=true&", "")
      : this.state.listReq + "showInactive=true&";
    this.setState({ listReq }, () => this.getResults({ pagination }));
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.getResults({ pagination });
    this.setColumns();
  }
  render() {
    const {
      data,
      columns,
      pagination,
      loading,
      filterColumns,
      screen,
      isDrawer,
    } = this.state;

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageTitle
              titleText="Payout Estimate"
              isAddBtn={"Add Payout Estimate"}
              handleAdd={this.handleAdd}
            />

            <ListFilters
              items={filterColumns}
              handleSettings={this.handleSettings}
              handleFilters={this.handleFilters}
              key={filterColumns}
              screen={screen}
            />

            <Table
              columns={columns}
              rowKey={(record) => record?.payout_estimate_id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              bordered
              scroll={{ x: 400 }}
              size="small"
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default PayoutEstimate;
